import { useState, useEffect } from "react";
import axios from "axios";
import authHeader from "./auth-header";
import { API_URL } from "./ApiEndpoints";

axios.defaults.baseURL = API_URL;

export const ApiServices = (params) => {

    const [data, setData] = useState(undefined);
    const [message, setMessage] = useState("");
    
    const [status, setStatus] = useState(false)

    const [has, setHas] = useState(false)

    const [loading, setLoading] = useState(true);

    params.headers = authHeader();

    const fetchData = async () => {
        try {
            const responseJson = await axios.request(params);

            const json = responseJson.data;

            if(json.status==="RC200"){
                setStatus(true)
                setData(json.data);
                setMessage(json.message);
            }else{
                setStatus(false)
                setData(json.data);
                setMessage(json.message);
            }

        } catch (error) {
            setStatus(false)
            setMessage(error.message);
            setLoading(false);
        } finally {
            setLoading(false);
            setHas(true)
        }
    };
    
    useEffect(() => {
      fetchData();
    }, []);
    
    return { has, status, message, data, loading, fetchData };

  };