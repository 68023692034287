
const AccountDelete = () =>{

    return(
        <div className="theme-layout">
            <div className="container-fluid">

                <div className="row">

                    <div className="col-md-12 text-center mt-3">    
                        <a href="/"><img width="240px" src={require('../../assets/images/logo-big.png')} alt="" /></a>
                    </div>

                    <div className="col-md-12">
                        <div className="log-reg-area mt-5" style={{margin:"0 auto 100px auto"}}>
                            <h5 className="text-center">Your account is deleted successfully.</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default AccountDelete;