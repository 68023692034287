import React, { Component, useEffect, useState } from "react";

import InfiniteScroll from 'react-infinite-scroll-component'
import { API_SEARCH, API_SEARCH_JOBS, API_URL } from "../../../services/ApiEndpoints";
import axios from "axios";
import authHeader from "../../../services/auth-header";
import SinglePost from "../feed/SinglePost";
import {Link, useLocation, useParams} from 'react-router-dom'
import { ApiServices } from "../../../services/ApiServices";
import SearchSingleUser from "./SearchSingleUser";
import LoaderWrapperView from "../../libs/LoaderWrapperView";

const jobTypeList = [
    "Full Time",
    "Part Time",
    "Freelancer"
]

const SearchJobs = () =>{

    const {keyword} = useParams()
    const [isLoading, setIsLoading] = useState(true)
    const [resultCount, setResultCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [canLoadMore, setCanLoadMore] = useState(true)
    const [results, setResults] = useState([])

    useEffect(()=>{
        getData()

    }, [keyword])

    const getData = () => {

        if(currentPage==1)
            setIsLoading(true)

        axios
        .get(API_URL + API_SEARCH_JOBS +"?keyword="+ keyword,{
            headers: authHeader()
        })
        .then((response)=>{
            
            if(response.status==200){

                const json = response.data;

                if(json.status=="RC200"){

                    var data = json.data;

                    setResultCount(data.count)

                    setCanLoadMore( currentPage!=data.jobs.last_page )
                    setResults(data.jobs.data)

                }

            }

            if(currentPage==1)
                setIsLoading(false)

        })
        .catch((error)=>{

            if(currentPage==1)
                setIsLoading(false)

        });
    }

    const loadFunc = () => {
        
        setCurrentPage(currentPage+1)

        getData();

    }

    const reloadFeed = () =>{

        this.setState({
            posts: [],
            currentPage:1
        });

        this.getPost();

        window.clearOverlay();

    }
    
    return(

        <LoaderWrapperView isLoading={isLoading}>
            <div className="row">
                <div className="col-lg-12">
                    <h6>{resultCount} Result(s) for "{keyword}"</h6>

                    <div className="row merged20" id="page-contents">
                        <div className="col-lg-12">
                                <div className="frnds">

                                    <InfiniteScroll
                                        dataLength={results.length}
                                        next={loadFunc}
                                        hasMore={canLoadMore}
                                        endMessage={<div className="text-center"><br />That's all for now</div>}
                                        loader={<div className="loader text-center">Loading ...</div>}>
                                        
                                        <ul className="nearby-contct">
                                            {results.map((result, i) => <SingleJob key={"si-"+result.id} item={result} /> )}
                                        </ul>

                                    </InfiniteScroll>
                                
                                </div>
                            
                        </div>
                        
                    </div>
                </div>
            </div>
        </LoaderWrapperView>

    );

};

const SingleJob = (props) =>{

    const {item} = props

    return(
        <li key={item.id}>
            <div className="nearly-pepls">
                <figure>
                    <Link to={`/jobs/${item.slug}`} title="" style={{verticalAlign:"top"}}><img src={item.company_logo} alt={item.title} /></Link>
                </figure>
                <div className="pepl-info">
                    <h4>
                        <Link to={`/jobs/${item.slug}`} title="">{item.title}</Link>
                    </h4>
                    <span>{item.company_name}</span><br />
                    <small>
                        <b>{item.eng_field} | {item.discipline_name} | {item.sub_discipline_name}</b><br />
                        
                        <a href={item.website} target="_blank">{item.website}</a>, {item.phone}<br />
                        
                        {item.date} | Actively Hiring | {jobTypeList[item.job_type]}
                    </small>
                    
                    <Link to={`/jobs/${item.slug}`} title="" className="add-butn" data-ripple="">View Job</Link>
                </div>
            </div>
        </li>
    )
}

export default SearchJobs;