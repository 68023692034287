import React, { useEffect, useState } from "react";
import {useParams} from 'react-router-dom'
import authHeader from "../../../../services/auth-header";
import { API_GET_PROBLEM_OFFERS_CONNECTED_USERS, API_URL } from "../../../../services/ApiEndpoints";
import axios from "axios";
import ProblemSocketChat from "../ProblemSocketChat";

const ProblemCreatorChat = () =>{

    let {slug} = useParams();
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([])
    const [activeUserId, setActiveUserId] = useState(-1)

    const getUsersList = () =>{

      setIsLoading(true)

      axios
      .get( `${API_URL}${API_GET_PROBLEM_OFFERS_CONNECTED_USERS}/${slug}`,{
          params:{},
          headers: authHeader()
      })
      .then((response)=>{
          
          if(response.status==200){

              const json = response.data;

              if(json.status=="RC200"){

                  var data = json.data;

                  setData(data)

                  // set first user as selected
                  if(data.length>0){
                    setActiveUserId(data[0].user_id)
                  }

              }

          }

          setIsLoading(false)

      })
      .catch((error)=>{
          setIsLoading(false)
      });

  }

  useEffect(()=>{
    getUsersList()
  }, [])

  useEffect(()=>{
  }, [data])
  
  const updateUnread = (sender_id, isZero)=>{
    setData( prev =>{
      const newState = prev.map(obj=>{
          if(obj.user_id==sender_id){

            let newCount = isZero ? 0 : (obj.unread+1);

            return {...obj, unread: newCount}
          }

          return obj
      })

      return newState
    })
  }

    return(

        isLoading ? (
          <p>Loading...</p>
        ) : (

          data.length==0 ? (
            <p>Users not found</p>
          ) : (

            <>
              
              <div className="row">
                  <div className="col-md-12">
                        <div className="central-meta">
                          <div className="messages">
                            <div className="message-box">
                              <ul className="peoples">
                                
                              {data.map((user)=>(
                                <li onClick={(e)=>{
                                  setActiveUserId(user.user_id)

                                  updateUnread(user.user_id, true)

                                }} className={activeUserId==user.user_id ? "active" : ""} key={user.id}>
                                    <figure>
                                        <img src={user.profile_image} alt={user.full_name} />
                                        {/* <span className="status f-online"></span> */}
                                    </figure>
                                    <div className="people-name">
                                        <span>{user.full_name}</span>
                                    </div>
                                      {user.unread!==undefined && user.unread>0 && (
                                          <span className="badge badge-danger">{user.unread}</span>
                                      )}
                                </li>
                              ))}

                              </ul>

                              {/* initialize the socket chat */}
                              
                              <ProblemSocketChat onOtherMessageReceived={(message, activeUser)=>{
                                
                                updateUnread(message.sender_id, false)

                              }} idea_id={slug} id={activeUserId}>
                                  
                                  <li className="you">
                                      <figure><img src="images/resources/userlist-2.jpg" alt="" /></figure>
                                      <p>Test</p>
                                  </li>

                              </ProblemSocketChat>
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
            </>

          )

        )

    );
};

export default ProblemCreatorChat;