import React, { useEffect, useRef, useState } from "react";
import {Link, Outlet, Route, Routes, useNavigate, useParams} from 'react-router-dom'
import { useSelector } from "react-redux";
import ExtButton from "../../../libs/ExtButton";
import axios from "axios";
import { API_PROBLEM_OFFERS, API_PROBLEM_START_CHAT, API_RESPOND_TO_PROBLEM_OFFER, API_URL } from "../../../../services/ApiEndpoints";
import authHeader from "../../../../services/auth-header";
import Popups from "../../../libs/Popups";
import { toast } from "react-toastify";

const ProblemOffers = () =>{

    let {slug} = useParams();
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([])

    const getDetails = () =>{

        setIsLoading(true)

        axios
        .get(API_URL + API_PROBLEM_OFFERS,{
            params:{
                problem_id: slug
            },
            headers: authHeader()
        })
        .then((response)=>{
            
            if(response.status==200){

                const json = response.data;

                if(json.status=="RC200"){

                    var data = json.data;

                    setData(data)

                }

            }

            setIsLoading(false)

        })
        .catch((error)=>{
            setIsLoading(false)
        });

    }

    useEffect(()=>{
        getDetails()
    }, [])

    return(
        <div className="row">
            <div className="col-md-12">
                <div className="inbox-lists">
                    
                        { isLoading ? (
                            <p>Loading...</p>
                        ) : (
                            <div className="mesages-lists" style={{paddingTop: "0px"}}>
                                <ul id="message-list" className="message-list">
                                    
                                    {data.length==0 ? (
                                        <p className="mt-3">No response till now</p>
                                    ) : (
                                        data.map((offer, index)=>(
                                            <SingleOffer ideaId={slug} data={offer} key={index} />
                                        ))
                                    )}

                                </ul>
                            </div>
                        )}

                </div>
            </div>
        </div>
    );
};

const SingleOffer = (props) =>{

    const {data, ideaId} = props

    const type = [
        "Want to sell Idea through SCIONS",
        "Want funds from Investors",
        "Want to Collaborate/Partner with like minded SCIONS",
        "Want to Hire employees for my Idea execution"
    ]

    const [isAccept, setIsAccept] = useState(false)
    const [comment, setComment] = useState("")
    const [status, setStatus] = useState(0)

    const [isLoading, setIsLoading] = useState(false)
    const [hasError, setHasError] = useState(false) 
    const [msg, setMsg] = useState("")
    const [open, setOpen] = useState(false)

    useEffect(()=>{
        setComment(data.comments)
        setStatus(data.status)
    }, [data, open])

    const handleSubmit = (e) =>{
        e.preventDefault()

        setIsLoading(true)

        const formdata = new FormData()
        formdata.append("problem_id", ideaId)
        formdata.append("response_id", data.id)
        formdata.append("comments", comment)
        formdata.append("status", isAccept ? "1" : "2")

        axios.post(API_URL + API_RESPOND_TO_PROBLEM_OFFER, formdata,{
            headers:authHeader()
        })
        .then((responsex) => {

            setIsLoading(false)

            if(responsex.data.status=="RC200"){
                setHasError(false)
                setStatus(isAccept ? 1 : 2)
                data.status = isAccept ? 1 : 2
            }else{
                setHasError(true)
            }

            setMsg(responsex.data.message)

            return responsex.data;
        });
        
    }

    let toastIdStartChat = useRef(null);
    const navigation = useNavigate()

    const startChat = (problemId, data) =>{
        
        toastIdStartChat = toast.loading("Loading...", {closeOnClick: true})

        const formdata = new FormData()
        
        formdata.append("problem_id", problemId)
        formdata.append("offer_id", data.id) // -1 for current user

        axios.post(API_URL + API_PROBLEM_START_CHAT, formdata,{
            headers:authHeader()
        })
        .then((responsex) => {

            if(responsex.data.status=="RC200"){

                navigation(`/problemspace/details/${problemId}/chat`)
                
                toast.update(toastIdStartChat, { render: responsex.data.message, type: "success", isLoading: false, autoClose: 5000 });
                    
            }else{
                toast.update(toastIdStartChat, { render: responsex.data.message, type: "error", isLoading: false, autoClose: 5000 });
            }

            return responsex.data;
        });

    }

    const popup = useRef()

    return(
        <li className="unread">
            <div className="more" style={{position: "absolute", right:"30px"}}>
                <div className="more-post-optns">
                    <i className="fa fa-ellipsis-h"></i>
                    <ul>
                        <li>
                            <Popups
                                key={`ip-`+ data.id}
                                ref={popup}
                                label={[<i className="fa fa-pencil"></i>, "Respond"]}
                                title="Respond to Offer"
                                dClassName="modal-lg"
                                onOpen={(e)=>{
                                    setOpen(true)
                                }}
                            >
                                <h6 ><strong>Response from Buyer</strong></h6>
                                <hr />

                                <p style={{margin:"0px"}}><strong>Offer price:</strong> €{data.offer_price}</p><br />
                                
                                <p style={{margin:"0px"}}><strong>Experience around the business problem:</strong></p>
                                { data.experience_around_the_problem && data.experience_around_the_problem.split("\n").map((str, index) => <p key={`px-${index}`}>{str}</p>)}
                                
                                <br />
                                <p style={{margin:"0px"}}><strong>Offer conditions:</strong></p>
                                { data.offer_conditions && data.offer_conditions.split("\n").map((str, index) => <p key={`px-${index}`}>{str}</p>)}
                                
                                <hr />
                                
                                {status==0 ? (
                                    <>
                                        <h6 ><strong>Enter your comments</strong></h6>

                                        <form onSubmit={handleSubmit}>
                                            <textarea value={comment || ""} onChange={(e)=> setComment(e.target.value)} rows="5" className="form-control"></textarea>
                                            <br />

                                            {msg && (
                                                <div className={ hasError ? "alert alert-danger mt-10 text-left" : "alert alert-success mt-10 text-left" } role="alert">
                                                    {msg}
                                                </div>
                                            )}

                                            <div>
                                                <ExtButton className="btn btn-success" isLoading={isLoading} onClick={ (e)=> setIsAccept(true) } type="submit">
                                                    <i className="fa fa-check"></i> Accept
                                                </ExtButton> &nbsp; &nbsp; 
                                                <ExtButton className="btn btn-danger" isLoading={isLoading} onClick={ (e)=> setIsAccept(false) } type="submit">
                                                    <i className="fa fa-close"></i> Reject
                                                </ExtButton>
                                            </div>
                                        </form>
                                    </>
                                ) : (
                                    <>
                                        <h6 ><strong>Your comments</strong></h6>

                                        <p>
                                            <strong>Status: </strong>
                                            <span className={`badge badge-`+(
                                                data.status==0 ? "warning" : (
                                                    data.status==1 ? "success" : "danger"
                                                )
                                            )}>{(
                                                data.status==0 ? "Pending" : (
                                                    data.status==1 ? "Accepted" : "Rejected"
                                                )
                                            )}</span>
                                        </p>

                                        { comment && comment.split("\n").map((str, index) => <p key={`px-${index}`}>{str}</p>)}

                                        {msg && (
                                            <div className={ hasError ? "alert alert-danger mt-10 text-left" : "alert alert-success mt-10 text-left" } role="alert">
                                                {msg}
                                            </div>
                                        )}

                                        <br />
                                        <ExtButton className="btn btn-dark btn-sm" type="button" onClick={(e)=> popup.current.close()}>
                                        <i className="fa fa-close"></i> Close
                                        </ExtButton>
                                    </>
                                )}

                            </Popups>
                        </li>
                        <li onClick={(e)=>startChat(data.problemspace_id, data)}><i className="fa fa-comments"></i>Start Chat</li>
                        <li><i className="fa fa-trash"></i>Delete Response</li>
                    </ul>
                </div>
            </div>

            <h3 className="sender-name">{data.full_name} <span className={`badge badge-`+(
                data.status==0 ? "warning" : (
                    data.status==1 ? "success" : "danger"
                )
            )}>{(
                data.status==0 ? "Pending" : (
                    data.status==1 ? "Accepted" : "Rejected"
                )
            )}</span></h3>

            <p>
                <p style={{margin:"0px"}}><strong>Offer price:</strong> €{data.offer_price}</p>

                { data.experience_around_the_problem && data.experience_around_the_problem.split("\n").map((str, index) => <p key={`px-${index}`}>{str}</p>)}

                {data.documents_upload && data.documents_upload.map((doc, index)=>(
                    <>
                        <a href={doc} className="" target="_blank">Download Document</a><br />
                    </>
                ))}

            </p>
        </li>
    )

}

export default ProblemOffers;