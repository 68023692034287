import axios from "axios";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { API_GET_IMPRESSED_LIST, API_URL } from "../../../services/ApiEndpoints";
import authHeader from "../../../services/auth-header";
import ExPopover from "../../libs/ExPopover"
import LoaderWrapperView from "../../libs/LoaderWrapperView";
import Popups from "../../libs/Popups";
import SearchSingleUser from "../search/SearchSingleUser";

const ViewImpressedList = (props) =>{

    const {id} = props

    const [loading, setLoading] = useState(false)
    const [count, setCount] = useState(false)
    const [list, setList] = useState([])

    const [listAll, setListAll] = useState([])

    const get_list = () => {
        
        setLoading(true)

        axios
        .get(`${API_URL}${API_GET_IMPRESSED_LIST}?feed_id=${id}&all=0`,{
            headers: authHeader()
        })
        .then((response)=>{
            
            if(response.status==200){

                const json = response.data;

                if(json.status=="RC200"){
                    
                    setList(json.data.list)
                    setCount(json.data.has_more)
                }

            }

            setLoading(false)

        })
        .catch((error)=>{
            setLoading(false)
        });
    }

    const [loadingAll, setLoadingAll] = useState(false)

    const get_list_all = () => {
        
        setLoadingAll(true)

        axios
        .get(`${API_URL}${API_GET_IMPRESSED_LIST}?feed_id=${id}&all=1`,{
            headers: authHeader()
        })
        .then((response)=>{
            
            if(response.status==200){

                const json = response.data;

                if(json.status=="RC200"){
                    setListAll(json.data)
                }

            }

            setLoadingAll(false)

        })
        .catch((error)=>{
            setLoadingAll(false)
        });
    }

    const popup = useRef()

    const fields = (user) => {
        const arr = []
        arr.push(user.eng_field)

        if(user.discipline_name)
            arr.push(user.discipline_name)
        
        if(user.sub_discipline_name)
            arr.push(user.sub_discipline_name)
        
        return arr.join(" - ")
    }

    return(
        <ExPopover loading={loading} className="ml-3" onToggle={(show)=>{
            if(show){
                get_list()
            }
        }} placement="bottom" content={(
            <LoaderWrapperView isLoading={loading}>
                <ul>
                    {list && list.map((val, index)=>(
                        <li>
                            <Link to={`/${val.public_url}`}>{val.name}</Link>
                        </li>
                    ))}

                    {count && (
                        <li>
                            <Popups
                                ref={popup}
                                label="View more"
                                title="Impressed Scions"
                                btnCancel={false}
                                button={false}
                                btnSave={false}
                                onOpen={(e)=> get_list_all() }
                                onSaveClickListener={(e)=>{
                                    popup.current.close()
                                }}
                                className=""
                                dClassName="modal-lg"
                            >
                                <LoaderWrapperView isLoading={loadingAll}>
                                    <div className="frnds">
                                        <ul style={{marginTop:"10px"}} className="nearby-contct">
                                            {listAll && listAll.map((user, i) => (
                                                <li>
                                                    <div className="nearly-pepls">
                                                        <figure>
                                                            <Link to={`/${user.public_url}`} title=""><img style={{width:"55px", height:"60px"}} src={user.profile_image} alt="" /></Link>
                                                        </figure>
                                                        <div className="pepl-info">
                                                            <h4>
                                                                <Link to={`/${user.public_url}`} href="#" title="">{user.full_name} ({user.role_name})</Link>
                                                            </h4>
                                                            <span>{fields(user)}</span>
                                                            
                                                            { user.friend_status==1 ? (
                                                                <div>
                                                                    <p><i className="fa fa-link"></i> Connected Scion</p>
                                                                </div>
                                                            ) : ""}
                                        
                                                        </div>
                                                    </div>
                                                </li>
                                            ) )}
                                        </ul>
                                    </div>
                                </LoaderWrapperView>

                            </Popups>
                        </li>
                    )}
                    
                </ul>
            </LoaderWrapperView>
        )}>
            {props.children}
        </ExPopover>
    )

}

export default ViewImpressedList