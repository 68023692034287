import axios from "axios";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { API_GET_IDEA_DETAILS_BY_PATENT_NUMBER, API_URL } from "../../../services/ApiEndpoints";
import authHeader from "../../../services/auth-header";

const IdeaDescViewByPatentId = forwardRef((props, ref) =>{

    const [isLoading, setIsLoading] = useState(0)
    const [desc, setDesc] = useState("")
    const [abstract, setAbstract] = useState("")
    const [title, setTitle] = useState("")

    useImperativeHandle(ref, () => ({

        getPatentDetails(ptnumber) {

            if(!ptnumber) return;
    
            setIsLoading(1)
    
            axios
            .get(`${API_URL}${API_GET_IDEA_DETAILS_BY_PATENT_NUMBER}/${ptnumber}`,{
                headers: authHeader()
            })
            .then((response)=>{
                
                
    
                if(response.status==200){
                    const json = response.data;

                    if(json.status=="RC100"){
                        setIsLoading(0)
                    }else{
                        setIsLoading(2)

                        setDesc(json.data.desc)
                        setAbstract(json.data.abstract)
                        setTitle(json.data.title)
                    }
                }
    
            })
            .catch((error)=>{
                setIsLoading(0)
            });
        }

    }));

    return(
        isLoading!=0 ? (
            <div className="row ptfinder">

                <div className="col-md-12">
                    <div className="ptfinder-cnt">
                        
                        {isLoading==1 ? (
                            <div className="loader">
                                <img src={require("../../../assets/images/loading-gif.gif")} /> &nbsp; Loading...
                            </div>
                        ) : (
                            <>
                                <h6>Idea Details:</h6>

                                {title && (
                                    <>
                                        <strong>Title: </strong>
                                        <p><small>{title}</small></p>
                                    </>
                                )}

                                {abstract && (
                                    <>
                                        <strong>Abstract: </strong>
                                        <p><small>{abstract}</small></p>
                                    </>
                                )}

                                {desc && (
                                    <>
                                        <strong>Description: </strong>
                                        <p><small>{desc}</small></p>
                                    </>
                                )}

                                <div className="text-center">
                                    <h4>Is this your idea?</h4>
                                    
                                    <button type="button" onClick={(e)=>{
                                        setIsLoading(0)
                                    }} className="mtr-btn"><span>No</span></button> &nbsp;
                                    <button type="button" onClick={(e)=>{
                                        props.onYes && props.onYes(title, desc, abstract)
                                        setIsLoading(0)
                                    }} className="mtr-btn"><span>Yes</span></button>
                                </div>
                            </>
                        )}

                    </div>
                </div>

            </div>
        ) : ""
    )

})

export default IdeaDescViewByPatentId