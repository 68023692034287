import React, { useEffect, useRef, useState } from "react";
import {Link, Outlet, Route, Routes, useParams} from 'react-router-dom'
import { useSelector } from "react-redux";
import Select2 from "../../../libs/Select2";
import ExtButton from "../../../libs/ExtButton";
import axios from "axios";
import { API_PROBLEM_MY_OFFERS, API_URL } from "../../../../services/ApiEndpoints";
import authHeader from "../../../../services/auth-header";
import Popups from "../../../libs/Popups";

const ProblemOffersList = () =>{

    let {slug} = useParams();
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([])

    const getDetails = () =>{

        setIsLoading(true)

        axios
        .get(API_URL + API_PROBLEM_MY_OFFERS,{
            params:{
                problem_id: slug
            },
            headers: authHeader()
        })
        .then((response)=>{
            
            if(response.status==200){

                const json = response.data;

                if(json.status=="RC200"){

                    var data = json.data;

                    setData(data)

                }

            }

            setIsLoading(false)

        })
        .catch((error)=>{
            setIsLoading(false)
        });

    }

    useEffect(()=>{
        getDetails()
    }, [])

    return(
        <div className="row">
            <div className="col-md-12">
                <div className="inbox-lists">
                    
                        { isLoading ? (
                            <p>Loading...</p>
                        ) : (
                            <div className="mesages-lists" style={{paddingTop: "0px"}}>
                                <ul id="message-list" className="message-list">
                                    
                                    {data.length==0 ? (
                                        <p>Not found</p>
                                    ) : (
                                        data.map((offer, index)=>(
                                            <SingleOffer ideaId={slug} data={offer} key={index} />
                                        ))
                                    )}

                                </ul>
                            </div>
                        )}

                </div>
            </div>
        </div>
    );
};

const SingleOffer = (props) =>{

    const {data} = props

    return(
        <li className="unread">

            <strong>My Offer Details: </strong><br />
            <p>
                <p style={{margin:"0px"}}><strong>Offer price:</strong> €{data.offer_price}</p>
            </p>

            <p><strong>Experience around the business problem: </strong></p>
            { data.experience_around_the_problem && data.experience_around_the_problem.split("\n").map((str, index) => <p key={`px-${index}`}>{str}</p>)}

            <br />
            <p><strong>Offer Conditions: </strong></p>
            { data.offer_conditions && data.offer_conditions.split("\n").map((str, index) => <p key={`px-${index}`}>{str}</p>)}

            <br />
            <p><strong>Status: </strong></p> <span className={`badge badge-`+(
                data.status==0 ? "warning" : (
                    data.status==1 ? "success" : "danger"
                )
            )}>{(
                data.status==0 ? "Pending" : (
                    data.status==1 ? "Accepted" : "Rejected"
                )
            )}</span>
            
            {data.status!=0 && (
                <div className="ofr-slr-rsp">
                    <strong>Comments from Owner: </strong>
                    { data.comments && data.comments.split("\n").map((str, index) => <p key={`px-${index}`}>{str}</p>)}
                </div>
            )}

        </li>
    )

}

export default ProblemOffersList;