import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import NotificationPopup from "../private/topbar/NotificationsPopup";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchBar from "./SearchBar";
import Popups from "../libs/Popups";
import InviteUsersWidget from "../private/widgets/InviteUsersWidget";
import TransPopup from "../libs/TransPopup";

const TopBar = () => {

    const stick = useRef()

    const {userData} = useSelector(state => state.userData )

    const { user: currentUser } = useSelector((state) => state.auth);

    const inviteUsersPopup = useRef()

    useEffect(() => {
        
        /*let externalScript = null;

        document.getElementById("topbars").addEventListener('mousemove', function(){
                    
            if(document.getElementById("external-script")==null){
                externalScript = document.createElement("script")
                externalScript.onerror = function(){};
                externalScript.id = "external-script";
                externalScript.async = true;
                externalScript.type = "text/javascript";
                externalScript.setAttribute("crossorigin", "anonymous");
                document.body.appendChild(externalScript);
                externalScript.src = '/script.js';
            }
        
        });
        
        return () => {
            if(externalScript!=null){
                document.body.removeChild(externalScript);
            }
        };*/

      }, []);

      const [isMyAccountOpen, setIsMyAccountOpen] = useState(false)

    return(
        <div ref={stick} id="topbars" className="topbar is_stuck stick">
            <div className="logo" style={{width: "15%"}} >
                <Link to="/overview"><img width="180px" src={require("../../assets/images/logo.png")} alt="" /></Link>
            </div>

            <div className="top-area">
                <div className="top-search">
                    <SearchBar />
                </div>

                <ul className="setting-area">
                    <li>
                        <Link to="/overview" title="Home"><i className="ti-home"></i></Link>
                    </li>
                    <li className="popups">
                        <NotificationPopup />
                    </li>
                </ul>

                <div onClick={(e)=> setIsMyAccountOpen(!isMyAccountOpen) } className="user-img">
                    <img src={currentUser.profile_image} alt="" />
                    <span className="status f-online"></span>
                    <div className={`user-setting ${isMyAccountOpen ? "active" : ""}`}>
                        
                        {userData.is_premium_user==undefined ? "" : (
                            userData.user_role==0 || userData.user_role==1 || userData.user_role==4 ? (
                                userData.is_premium_user==0 ? (
                                    <Link to="/upgrade" title=""><img src={require("../../assets/images/ic-subscribe.png")} /> Upgrade to Premium</Link>
                                ) : (
                                    <Link to="/subscription" title=""><img src={require("../../assets/images/ic-my-subscription.png")} /> My Package</Link>
                                )
                            ) : ""
                        )}

                        <Link to="/profile/edit" title=""><img src={require("../../assets/images/ic_edit-profile.png")} /> edit profile</Link>
                        <Link to="/ideas/my-ideas" title=""><img src={require("../../assets/images/ic_my-idea.png")} /> Ideaspace</Link>
                        {/* <Link to="/problemspace/my-problems"><img src={require("../../assets/images/ic-problemspace.png")} /> Eureka Space</Link> */}
                        {/* <Link to="/my-jobs" title=""><img src={require("../../assets/images/ic_my-jobs.png")} /> My Jobs</Link> */}

                        {userData.user_role!=4 && (
                            <Link to="/organizations"><img src={require("../../assets/images/ic_my-org.png")} /> My Organizations</Link>
                        )}
                        
                        <a href="" onClick={(e)=>{
                            e.preventDefault()

                            inviteUsersPopup.current.open()

                        }} title=""><img src={require("../../assets/images/ic-invite-users.png")} /> invite users</a>
                        <Link to="/profile/account-settings" title=""><img src={require("../../assets/images/ic_settings.png")} /> account setting</Link>
                        <Link to="/logout"><img src={require("../../assets/images/ic_logout.png")} /> log out</Link>
                    </div>
                </div>
                {/* <span className="ti-menu main-menu" data-ripple=""></span> */}
            </div>

            <ToastContainer />

            <TransPopup
                ref={inviteUsersPopup}
                label=""
                hideButton={true}
                title=""
                btnCancel={false}
                btnSave={false}
                className="btn btn-sm btn-success"
                dClassName="invite-popup"
            >
                <div style={{background:"#FFFFFF"}}>
                    <InviteUsersWidget close={(e)=>{
                        inviteUsersPopup.current.close()
                    }} />
                </div>
            </TransPopup>
        </div>
    )

};

export default TopBar;