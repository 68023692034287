import React, { useEffect, useState } from "react";
import {useParams} from 'react-router-dom'
import authHeader from "../../../services/auth-header";
import { API_GET_IDEA_OFFERS_CONNECTED_USERS, API_URL } from "../../../services/ApiEndpoints";
import axios from "axios";
import SocketChat from "./SocketChat";

const IdeaBuyerChat = () =>{

  let {slug} = useParams();
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const [activeUserId, setActiveUserId] = useState(-1)

  const getUsersList = () =>{

    setIsLoading(true)

    axios
    .get( `${API_URL}${API_GET_IDEA_OFFERS_CONNECTED_USERS}/${slug}`,{
        params:{},
        headers: authHeader()
    })
    .then((response)=>{
        
        if(response.status==200){

            const json = response.data;

            if(json.status=="RC200"){

                var data = json.data;

                setData(data)

                // set first user as selected
                if(data.length>0){
                  setActiveUserId(data[0].user_id)
                }

            }

        }

        setIsLoading(false)

    })
    .catch((error)=>{
        setIsLoading(false)
    });

}

useEffect(()=>{
  getUsersList()
}, [])
  

  return(

      isLoading ? (
        <p>Loading...</p>
      ) : (

        data.length==0 ? (
          <p>Users not found</p>
        ) : (

          <>
            
            <div className="row">
                <div className="col-md-12">
                      <div className="central-meta">
                        <div className="messages">
                          <div className="message-box">
                            <ul className="peoples">
                              
                            {data.map((user)=>(
                              <li className="active" key={user.id}>
                                  <figure>
                                      <img src={user.profile_image} alt={user.full_name} />
                                      {/* <span className="status f-online"></span> */}
                                  </figure>
                                  <div className="people-name">
                                      <span>{user.full_name}</span>
                                  </div>
                              </li>
                            ))}

                            </ul>

                            {/* initialize the socket chat */}
                            
                            <SocketChat idea_id={slug} id={activeUserId}  />
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
          </>

        )

      )

  );
};

export default IdeaBuyerChat;