import { useEffect } from "react"
import { useState } from "react"

const WordLimiter = (props) =>{

    const [value, setValue] = useState(props.value)
    const [maxWords, setMaxWords] = useState(props.maxWords || -1)
    const [showCounter, setShowCounter] = useState(false)
    const [required, setRequired] = useState(props.req)

    const [wordCount, setWordCount] = useState(0)

    const check_words = (e) => {

        let value = e.target.value

        setValue( value.replace(/[0-9]/gi,"") )

        var BACKSPACE  = 8;
        var DELETE     = 46;
        var valid_keys = [BACKSPACE, DELETE];
        var words      = value.split(' ');

        if (maxWords!=-1 && words.length >= maxWords && valid_keys.indexOf(e.keyCode) == -1) {
            e.preventDefault();
            words.length = maxWords;
            e.target.value = words.join(' ');
        }

        if(e.target.value.length==0){
            setWordCount(0)
        }else{
            setWordCount(words.length)
        }

        setValue(e.target.value)

        props.onChange && props.onChange(e.target.value)

        
    }

    useEffect(()=>{
        var words = value.split(' ');

        if(value.length==0){
            setWordCount(0)
        }else{
            setWordCount(words.length)
        }
    }, [value])

    useEffect(()=>{
        setValue(props.value)
        setRequired(props.req===undefined ? true : props.req)
    }, [props])

    return(
        <div className={`form-group ${props.className}`}>
            <textarea onFocus={(e)=>{
                setShowCounter(true)
            }} onBlur={(e)=>{
                setShowCounter(false)
            }} rows={props.rows || "4"} value={value} onChange={(e)=> {
                check_words(e)
            }} required={required}></textarea>
            <label className="control-label">{props.label} {props.label && (
                <>{maxWords==-1 ? "" : `(max. ${maxWords} words)`} {required ? `*` : ""}</>
            )} </label>
            <i className="mtrl-select"></i>
            {showCounter && maxWords!=-1 && (
                <span className="word-limiter-show"><small>{wordCount} word(s) / {maxWords} words</small></span>
            )}
        </div>
    )

}

export default WordLimiter