import { LIVE_SOCKET_IP, TEST_SOCKET_IP } from "./ApiEndpoints";

export default function authHeader() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.token) {
      return { Authorization: 'Bearer ' + user.token };
    } else {
      return {};
    }
}

export function updateRole(roleId, roleName){
  const user = JSON.parse(localStorage.getItem('user'));
  
  if (user && user.token) {
    
    user.role_id = roleId
    user.role = roleName

    localStorage.setItem("user", JSON.stringify(user));

  }

  return user
}

export function socketIP(){
  
  const user = JSON.parse(localStorage.getItem('user'));

  if(process.env.NODE_ENV==="development" || window.location.hostname==="test.scions.tech")
      return `${TEST_SOCKET_IP}/websocket/?token=${user.token}`
  
  return `${LIVE_SOCKET_IP}/websocket/?token=${user.token}`
    
}