import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";

const CommonPagesFooter = () =>{

    useEffect(() => {
        
        let externalScript = null;

        //window.addEventListener('scroll', function(){
            
                setTimeout(()=>{
                    if(document.getElementById("external-script")==null){
                        externalScript = document.createElement("script")
                        externalScript.onerror = function(){};
                        externalScript.id = "external-script";
                        externalScript.async = true;
                        externalScript.type = "text/javascript";
                        externalScript.setAttribute("crossorigin", "anonymous");
                        document.body.appendChild(externalScript);
                        externalScript.src = '/script.js';
                    }
                }, 1000)
        
       // });
        
        return () => {
            if(externalScript!=null){
                document.body.removeChild(externalScript);
            }
        };

      }, []);


    return(
        <>
            <div className="responsive-header">
                <div className="mh-head first mm-sticky mh-btns-left mh-btns-right mh-sticky">
                    <span className="mh-text">
                        <a href="/" title=""><img src={require("../../assets/images/logo.png")} alt="" /></a>
                    </span>
                    <span className="mh-btns-right w-40"><a className="fa fa-align-justify" href="#othermenu"></a></span>
                </div>
            </div> 

            <nav id="othermenu" className="mm-menu mm-offcanvas mm-right">
                <ul>
                    <li><NavLink to="/privacy-policy" title="Privacy Policy">Privacy Policy</NavLink></li>
                    <li><NavLink to="/cookie-policy" title="Terms &amp; Conditions">Cookie Policy</NavLink></li>
                    <li><NavLink to="/terms-and-conditions" title="Terms &amp; Conditions">Terms &amp; Conditions</NavLink></li>
                    <li><a target="_blank" href="https://forms.gle/GvGbQLiRVrLnx5DG9" title="Terms &amp; Conditions">Feedback</a></li>
                </ul>
            </nav>

            <div class="topbar stick">
                <div class="logo">
                    <a title="" href="/"><img src={require("../../assets/images/logo.png")} alt="" /></a>
                </div>

                <div class="top-area">
                    <ul class="setting-area">
                        <li><NavLink to="/privacy-policy" title="Privacy Policy">Privacy Policy</NavLink></li>
                        <li><NavLink to="/cookie-policy" title="Terms &amp; Conditions">Cookie Policy</NavLink></li>
                        <li><NavLink to="/terms-and-conditions" title="Terms &amp; Conditions">Terms &amp; Conditions</NavLink></li>
                        <li><a target="_blank" href="https://forms.gle/GvGbQLiRVrLnx5DG9" title="Terms &amp; Conditions">Feedback</a></li>
                    </ul>
                </div>
            </div>
        </>

    )

}

export default CommonPagesFooter;