import InviteUsersWidget from "../widgets/InviteUsersWidget";

const InviteUsers = () =>{

    return(
        <section>
            <div className="gap2 gray-bg">
                <div className="container">
                    <InviteUsersWidget />
                </div>
            </div>
        </section>
    )

}

export default InviteUsers;