import React, { useEffect, useState } from "react";
import { API_UPDATE_EXPERIENCE, API_URL } from "../../../services/ApiEndpoints";
import axios from "axios";
import authHeader from "../../../services/auth-header";
import ExtButton from "../../libs/ExtButton";
import { DatePicker, df, mode } from "../../../helpers/DatePicker";
import { useOutletContext } from "react-router-dom";

const Experience = (props) =>{
    
    const [allowReload, setAllowReload] = useOutletContext();

    const {experience} = props

    const [data, setData] = useState(experience)

    useEffect(() => {
        setData(experience);
    }, [experience]);

    const [isLoading, setIsLoading] = useState(false)
    const [hasError, setHasError] = useState(false) 
    const [msg, setMsg] = useState("")

    const addMoreExperience = (e) =>{
        e.preventDefault()

        setData( data.concat({
            id:-1,
            title:"",
            org_name:"",
            location:"",
            start_date:"",
            end_date:"",
            till_date:"",
            job_desc:""
        }) )
    }

    const handleChange = (index, e) => {

        var experience = [...data]
        experience[index][e.target.name] = e.target.value

        setData(experience)

    }

    const handleDateChange = (index, date, field) => {

        var experience = [...data]
        experience[index][field] = date

        setData(experience)

    }

    const handleTillDate = (index, e) =>{
        var experience = [...data]
        experience[index]["till_date"] = e.target.checked ? 1 : 0
        experience[index]["end_date"] = e.target.checked ? "" : experience[index]["end_date"]

        setData(experience)
    }


    const handleRemove = (index, e) => {
        e.preventDefault()

        var experience = [...data]
        experience.splice(index, 1)

        setData(experience)

    }

    const handleSubmit = (e) =>{

        e.preventDefault()

        setHasError(false)
        setMsg("")
        setIsLoading(true)

        var formdata = new FormData()

        data.map((item, index) => {
            formdata.append("title["+ index +"]", item.title)
            formdata.append("org_name["+ index +"]", item.org_name)
            formdata.append("location["+ index +"]", item.location)
            formdata.append("start_date["+ index +"]", item.start_date)
            formdata.append("end_date["+ index +"]", item.end_date)
            formdata.append("till_date["+ index +"]", item.till_date)
            formdata.append("job_desc["+ index +"]", item.job_desc)
            formdata.append("id["+ index +"]", item.id)
        })

        axios.post(API_URL + API_UPDATE_EXPERIENCE, formdata,{
            headers:authHeader()
        })
        .then((responsex) => {

            setIsLoading(false)

            if(responsex.data.status=="RC200"){
                setHasError(false)

                /* update profile completeness */
                setAllowReload(allowReload+1)
            }else{
                setHasError(true)
            }

            setMsg(responsex.data.message)

            return responsex.data;
        });

    }

    return(
        <div className="central-meta" id="experience">
            <div className="editing-info">
                <h5 className="f-title">Work Experience</h5>

                <form onSubmit={handleSubmit} method="post">
                    
                { data.length>0 && (
                        data.map((item, index)=>{
                            return(
                                <div key={index} className="row">
                                    <div className="col-md-5">
                                        <div className="form-group ">
                                            <input type="text" value={item.title || ""} name="title" onChange={ (e) => handleChange(index, e) } required="required" />
                                            <label className="control-label">Job Title *</label>
                                            <i className="mtrl-select"></i>
                                        </div>
                                    </div>
                                    
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <input type="text" value={item.org_name || ""} name="org_name" onChange={ (e) => handleChange(index, e) } required="required" />
                                            <label className="control-label">Organization Name *</label>
                                            <i className="mtrl-select"></i>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="text-center form-group ">
                                            <a onClick={ (e) =>{ handleRemove(index, e) } } className="btn btn-link text-danger"><i className="fa fa-close"></i></a>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input type="text" value={item.location || ""} name="location" onChange={ (e) => handleChange(index, e) } required="required" />
                                            <label className="control-label">Location (City/Country) *</label>
                                            <i className="mtrl-select"></i>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <DatePicker readOnly={true} format={df.MY} mode={mode.month} value={item.start_date || ""} onChange={ (e, date)=>{
                                            handleDateChange(index, date, "start_date")
                                        }}  required={true} >
                                            Start Date *
                                        </DatePicker>
                                    </div>

                                    
                                    { item.till_date==0 && (
                                        <div className="col-md-4">
                                            <DatePicker readOnly={true} format={df.MY} mode={mode.month} value={item.end_date || ""} onChange={ (e, date)=>{
                                                handleDateChange(index, date, "end_date")
                                            }}  required={true} >
                                                End Date *
                                            </DatePicker>
                                        </div>
                                    ) }

                                    <div className="col-md-4">
                                        <div className="form-group checkbox">
                                            <label>
                                                <input name="till_date" value="1" defaultChecked={item.till_date==1} onChange={ (e) => {
                                                    handleTillDate(index, e)
                                                } } type="checkbox" />
                                                <i className="check-box"></i>Present
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <textarea name="job_desc" value={item.job_desc || ""} rows="4" id="textarea" onChange={ (e) => handleChange(index, e) } required="required"></textarea>
                                            <label className="control-label" htmlFor="textarea">Job Description *</label>
                                            <i className="mtrl-select"></i>
                                        </div>
                                    </div>
                                    
                                </div>
                            )
                        })
                    ) }
                    
                    <div className="submit-btns col-md-12">
                        <a onClick={addMoreExperience} ><i className="fa fa-plus-circle"></i> Add work experience</a>
                    </div>

                    {msg && (
                        <div className={ hasError ? "alert alert-danger mt-10" : "alert alert-success mt-10" } role="alert">
                            {msg}
                        </div>
                    )}

                    <div className="submit-btns">
                        <ExtButton isLoading={isLoading} className="mtr-btn" type="submit">
                            <span>Update</span>
                        </ExtButton>
                    </div>
                </form>
            </div>
        </div>

    );
};

export default Experience;