import axios from "axios";
import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate } from 'react-router-dom'
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";

import { login } from "../actions/auth";

import {
    CLEAR_MESSAGE,
    LOGIN_FAIL,
    SET_MESSAGE,
  } from "../actions/types";

import { API_GET_PWD_RESET_LINK, API_LOGIN, API_URL } from "../services/ApiEndpoints";
import FormReCaptchaV3 from "./libs/FormReCaptchaV3";
import ReCaptchaV3 from "./libs/ReCaptchaV3";

const ForgotPassword = (props) =>{
    
    const [emailAddress, setEmailAddress] = useState("")

    const [isError, setisError] = useState(false);
    const [msg, setMsg] = useState("")

    const [loading, setLoading] = useState(false);

    const handleValidation = () =>{
        
        let formIsValid = true;

        if(!emailAddress){
            formIsValid = false;
        }

        return formIsValid;
    }

    const handleSubmit = (captchaToken) =>{
            
        setLoading(true);

        axios.post(API_URL + API_GET_PWD_RESET_LINK, {
            email: emailAddress,
            "recaptcha_token":captchaToken
            })
            .then((response) => {

                setLoading(false);

                setMsg(response.data.message)
                
                if(response.data.status=="RC200"){

                    setEmailAddress("")
                    setisError(false)

                }else{

                    setisError(true)

                }                    

                return response.data;
            });

    };

    return(
        <div className="theme-layout">
            <div className="container-fluid">
                <div className="row">

                    <div className="col-md-12 text-center mt-3">    
                        <a href="/"><img width="240px" src={require('../assets/images/logo-big.png')} alt="" /></a>
                    </div>

                    <div className="col-md-12">
                            
                        <div className="log-reg-area mt-5" style={{margin:"0 auto 100px auto"}}>
                            
                            <h3 className="log-title text-center">Forgot Password</h3>
                            
                            <p className="text-center">Enter your registered email address to get password reset link</p>
                            
                            <FormReCaptchaV3 onLoadStatusUpdate={(status)=>{
                                setLoading(status)
                            }} action="forgot_password" onSubmit={handleSubmit}>

                                <div className="form-group">
                                    <input type="text" id="input-username" value={emailAddress} onChange={ (e) => setEmailAddress(e.target.value) } required="required" />
                                    <label className="control-label" htmlFor="input-username">Email Address *</label>
                                    <i className="mtrl-select"></i>
                                </div>

                                    {msg && (
                                        <div className={ isError ? "alert alert-danger" : "alert alert-success" } role="alert">
                                            {msg}
                                        </div>
                                    )}

                                    <button className="mtr-btn signin" disabled={loading} type="submit">
                                        {loading && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        <span>Get Password Reset Link</span>
                                    </button>
                                    
                                    <br /><br />

                                <Link to="/login" className="already-have">Back to login</Link>

                            </FormReCaptchaV3>
                        </div>
                            
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;