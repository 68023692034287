import { Modal } from "react-bootstrap";
import { confirm } from "react-confirm-box";
import ExtButton from "../components/libs/ExtButton";

export const confirmDialogOptions = {

    render: (message, onConfirm, onCancel) => {
      return (
        <Modal show={true} centered aria-labelledby="contained-modal-title-vcenter" onHide={onCancel}>  
            <Modal.Body style={{padding:"20px"}}>
                
                <h5>{message}</h5><br />
                
                <div className="text-right">
                    <ExtButton className="btn" onClick={onCancel} type="button">
                        <i className="fa fa-close"></i> No
                    </ExtButton> &nbsp; &nbsp;

                    <ExtButton className="btn btn-theme more-action" onClick={onConfirm} type="button">
                        <i className="fa fa-check"></i> Yes
                    </ExtButton>
                </div>

            </Modal.Body>
        </Modal>
      );
    }
  }

  export const USER_ROLE_NORMAL = 0
  export const USER_ROLE_SUPER_ADMIN = 1
  export const USER_ROLE_SUB_ADMIN = 2
  export const USER_ROLE_ORG_USER = 3

  export const printPhoneNumber = (code, phone) =>{
  
    if(code){
      return `+${code}-${phone}`
    }

    return phone

}