import axios from "axios";
import { useEffect, useRef, useState } from "react";
import Select2 from '../../libs/Select2'
import { Link, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { API_DELETE_IDEA, API_DUPLICATE_IDEA, API_GET_DISCIPLINES_BY_FIELD_ID, API_GET_DISCIPLINE_BY_ID, API_GET_ENGINEERING_FIELDS, API_GET_IDEA, API_IDEA_DEAL_COMPLETED, API_IDEA_OFFER_ACCEPTED_USERS, API_UPDATE_IDEA, API_URL } from "../../../services/ApiEndpoints";
import authHeader from "../../../services/auth-header";
import { DatePicker, df, mode } from "../../../helpers/DatePicker";
import ExtButton from "../../libs/ExtButton";
import FieldsSelector from '../../libs/FieldsSelector'
import {organizationSize, typeOfOrganization} from '../Consts'
import ExToolTip from "../../libs/ExToolTip";
import Popups from "../../libs/Popups";
import { toast } from "react-toastify";
import LoaderWrapperView from '../../libs/LoaderWrapperView'
import IdeaTLRV4 from "./IdeaTLRV4";
import WordLimiter from "../../libs/WordLimiter";
import IdeaOptInOut from "./IdeaOptInOut";
import Select2Multiple from "../../libs/Select2Multiple";
import ImageCropperPopup from "../../libs/image/ImageCropperPopup";
import Select2GroupsMultiple from "../../libs/Select2GroupsMultiple";

const EditIdea = (props) => {

    let {slug} = useParams();

    const select2PD = useRef(null)
    const select2PSD = useRef(null)

    const [listPatientedWith] = useState([
        {isChecked:false, name:"EU Body", id:0},
        {isChecked:false, name:"USA Body", id:1},
        {isChecked:false, name:"Asian Organization", id:2}
    ])

    const [typeList] = useState([
        {isSelected:false, name:"Loan", id:0},
        {isSelected:false, name:"Investment", id:1}
    ])

    const [stageList] = useState([
        // {isSelected:false, name:"Startup stage", id:0},
        {isSelected:false, name:"Idea Stage", id:1},
        {isSelected:false, name:"Pre-Seed Stage", id:2},
        {isSelected:false, name:"Seed Stage", id:3},
        {isSelected:false, name:"Early Stage", id:4},
        {isSelected:false, name:"Growth Stage", id:5},
        {isSelected:false, name:"Expansion Stage", id:6},
    ])

    const [listEngineeringField, setListEngineeringField] = useState([])
    const [listDiscipline, setListDiscipline] = useState([])
    const [listSubDiscipline, setListSubDiscipline] = useState([])
    const [listIPD, setListIPD] = useState([])
    const [listOtherDoc, setListOtherDoc] = useState([])
    const [listCertifications, setListCertifications] = useState([])
    
    const [isLoadingEngField, setIsLoadingEngField] = useState(true)
    const [isLoading, setIsLoading] = useState(false)

    const getEngFields = () =>{

        setIsLoadingEngField(true)
        setIsLoading(true)

        axios
        .get(API_URL + API_GET_ENGINEERING_FIELDS,{
            headers: authHeader()
        })
        .then((response)=>{
             
            setIsLoadingEngField(false)

            if(response.status==200){

                const json = response.data;

                setListEngineeringField(json.data.engineering_field)

            }

            getDetails()

        })
        .catch((error)=>{
            getDetails()
        });
    }

    const getDeciplinesById = (targetOne, targetTwo, id) =>{

        axios
         .get(API_URL + API_GET_DISCIPLINES_BY_FIELD_ID +"?id="+ id,{
             headers: authHeader()
         })
         .then((response)=>{
           
             if(response.status==200){
 
                 const json = response.data.data;
 
                 if(targetOne!=null){
                    targetOne.current.updateSelect(json)
                 }
                 
                 if(targetTwo!=null)
                     targetTwo.current.updateSelect([])
                 
             }
 
         })
         .catch((error)=>{
 
         });
 
     }
    
    // states for the fields start

    const [title, setTitle] = useState("")
    const [isPatented, setIsPatented] = useState(0)
    const [privateIdea, setIsPrivateIdea] = useState(0)
    const [patentedWith, setPatentedWith] = useState("")
    const [filedName, setFiledName] = useState("")
    const [disciplineName, setDisciplineName] = useState("")
    const [engField, setEngField] = useState([])
    const [discipline, setDiscipline] = useState([])
    const [subDiscipline, setSubDiscipline] = useState(-1)

    const [valueProposition, setValueProposition] = useState("")
    const [abstract, setAbstract] = useState("")
    const [description, setDescription] = useState("")
    
    const [image, setImage] = useState(null)
    const [imageSrc, setImageSrc] = useState("")

    const [intellectualPropertyDocuments, setIntellectualPropertyDocuments] = useState([null])
    const [otherDocument, setOtherDocument] = useState([null])
    const [certifications, setCertifications] = useState([null])

    const [ipInitial, setIpInitial] = useState(false)
    const [ipBusinessPlan, setIpBusinessPlan] = useState(false)
    const [ipPrototypeAvailable, setIpPrototypeAvailable] = useState(false)
    const [ipExperiencingCustomer, setIpExperiencingCustomer] = useState(false)
    const [ipCurrentSelected, setIpCurrentSelected] = useState(-1)

    const [isIdeaPublished, setIsIdeaPublished] = useState(-1)
    const [isDealCompleted, setIsDealCompleted] = useState(-1)
    const [anyOfferAccepted, setAnyOfferAccepted] = useState(-1)
    const [tlr, setTlr] = useState(-1)
    const [id, setId] = useState(-1)

    const [wantTosell, setWantToSell] = useState({
        status: false,
        disabled: false,
        amount:"0",
        price_fixed:0,
        offer_valid_till:"",
        ownership_info:"",
        pay_condition:"",
        post_settlement:"",
        is_completed:0
    })

    const [wantFunds, setWantFunds] = useState({
        status: false,
        disabled: false,
        ownership_info:"",
        type:-1,
        stage:-1,
        offer:"",
        loan_offer:"",
        is_completed:0
    })
    
    const [wantCollaborate, setWantCollaborate] = useState({
        status: false,
        disabled: false,
        position_offered:"",
        partnership_proposal:"",
        conditions:"",
        is_completed:0
    })

    const [wantHireEmp, setWantHireEmp] = useState({
        status: false,
        disabled: false,
    })

    const blankJobObj = {
        id:-1,
        title:"",
        field:-1,
        discipline:-1,
        subDiscipline:-1,
        jobType:-1,
        salary:"",
        description:"",
        orgId:-1,
        companyName:"",
        aboutCompany:"",
        website:"",
        phone:"",
        companySize:"",
        application_deadline:"",
        companyLogo:"",
        companyLogoObj:null,
        location:"",
        type_of_organization:-1,
        selectedDisciplines:[],
        selectedSubDisciplines:[]
    }

    const [wantHireEmpDetails, setWantHireEmpDetails] = useState([
        blankJobObj
    ])

    const jobTypeList = [
        {name:"Full Time", id:0},
        {name:"Part Time", id:1},
        {name:"Freelancer", id:2}
    ]

    const [patientFiledBy, setPatientFiledBy] = useState(0)
    const [patientFiledByName, setPatientFiledByName] = useState("")
    const [patentedNumber, setPatentedNumber] = useState("")
    const [patentRegDate, setPatentRegDate] = useState("")

    let patientFiledByList = [
        {"name":"Self", id:0},
        {"name":"Other", id:1}
    ]

    const handleJobFieldChange = (index, name, val) => {

        var dt = [...wantHireEmpDetails]
        dt[index][name] = val

        setWantHireEmpDetails(dt)

    }

    const [optInOut, setOptInOut] = useState(-1)
    const [ideaSlug, setIdeaSlug] = useState("")

    // states for the fields end

    const getDetails = () =>{

        setIsLoading(true)

        axios
        .get(API_URL + API_GET_IDEA +"/"+ slug,{
            headers: authHeader()
        })
        .then((response)=>{
                
            if(response.status==200){

                const json = response.data;

                if(json.status=="RC200"){

                    var data = json.data;
                   
                    setTitle(data.idea_title)
                    setId(data.id)
                    setIsPublish(data.is_published)
                    setIsDealCompleted(data.is_deal_completed)
                    setAnyOfferAccepted(data.has_any_offer_accepted)

                    const field_find_id = data.sub_discipline_ids.split(',').some((id)=>{
                        return id == -1
                    }) 
            
                    if(field_find_id){
                    setFiledName(data.sub_discipline_names.split(",")[data.sub_discipline_names.split(",").length -2])
                    setDisciplineName(data.sub_discipline_names.split(",")[data.sub_discipline_names.split(",").length -1])
                    }

                    setIsPatented(data.idea_patented)
                    setIsPrivateIdea(data.is_private)
                    setPatentedWith(data.idea_patented_with)
                    setPatentedNumber(data.patent_number)
                    setPatentRegDate(data.idea_patent_date_of_registration)

                    setPatientFiledBy(data.filed_by)
                    setPatientFiledByName(data.filed_by_name)
                    
                    setEngField(`${data.fields_id}`.split(","))
                    setDiscipline(`${data.sub_discipline_ids}`.split(","))
                    setSubDiscipline(data.sub_discipline)

                    setValueProposition(data.introduction)
                    setAbstract(data.abstract)
                    setDescription(data.description)

                    setIpInitial(data.idea_phase_initial===1)
                    setIpBusinessPlan(data.idea_business_plan===1)
                    setIpPrototypeAvailable(data.idea_prototype_available===1)
                    setIpExperiencingCustomer(data.idea_experiencing_customer===1)

                    setOptInOut(data.opt_in_for_public)
                    setIdeaSlug(data.slug)

                    if(data.idea_phase_initial==1){
                        setIpCurrentSelected(1)
                    }
                    
                    if(data.idea_business_plan==1){
                        setIpCurrentSelected(2)
                    }

                    if(data.idea_prototype_available==1){
                        setIpCurrentSelected(3)
                    }

                    if(data.idea_experiencing_customer==1){
                        setIpCurrentSelected(4)
                    }

                    setImageSrc(data.idea_image)
                    setTlr(data.tech_readiness_level)

                    setListDiscipline(data.selected_disciplines)
                    setListSubDiscipline(data.selected_sub_disciplines)
                    setListIPD(data.intellectual_property_documents)
                    setListOtherDoc(data.other_document)
                    setListCertifications(data.certifications)

                    let isOtherDisabled = false
                    let isSellDisabled = false

                    if(data.has_sell_idea==1){
                        isOtherDisabled = true
                        isSellDisabled = false
                    }else if(data.has_fund_investor==1 || data.has_collaborate==1){
                        isOtherDisabled = false
                        isSellDisabled = true
                    }
                    
                    setWantToSell({
                        status: data.has_sell_idea==1,
                        disabled: isSellDisabled,
                        amount: data.sell_idea_amount,
                        price_fixed: data.sell_idea_price_fixed,
                        offer_valid_till: data.sell_idea_offer_valid_till,
                        ownership_info: data.sell_idea_ownership_info,
                        pay_condition: data.sell_idea_pay_condition,
                        post_settlement: data.sell_idea_post_settlement,
                        is_completed: data.sell_idea_is_completed
                    });

                    setWantFunds(prev => ({
                        ...prev,
                        status: data.has_fund_investor===1,
                        disabled: isOtherDisabled,
                        ownership_info: data.fund_investor_ownership_info,
                        type: data.fund_investment_type,
                        stage: data.fund_investment_stage,
                        offer: data.fund_investment_offer,
                        loan_offer: data.fund_investment_loan_offer,
                        is_completed: data.fund_investment_is_completed
                    }))

                    setWantCollaborate(prev=>({
                        ...prev,
                        status: data.has_collaborate===1,
                        disabled: isOtherDisabled,
                        position_offered: data.collaborate_position_offered,
                        partnership_proposal: data.collaborate_partnership_proposal,
                        conditions: data.collaborate_conditions,
                        is_completed: data.collaborate_is_completed
                    }))

                    setWantHireEmp( prev =>({
                        ...prev,
                        status: data.jobs_details.length>0,
                        disabled: isOtherDisabled
                    }))

                    let jd = []

                    data.jobs_details.map((job, index)=>{

                        const obj = {
                            id:job.id,
                            title:job.title,
                            field:job.department,
                            discipline:job.discipline,
                            subDiscipline:job.sub_discipline,
                            jobType:job.job_type,
                            salary:job.salary,
                            description:job.description,
                            companyName:job.company_name,
                            aboutCompany:job.about_company,
                            website:job.website,
                            phone:job.phone,
                            companySize:job.company_size,
                            application_deadline:job.application_deadline,
                            companyLogo:job.company_logo,
                            location:job.location,
                            type_of_organization:job.type_of_organization,
                            companyLogoObj:null,
                            selectedDisciplines:job.selected_disciplines,
                            selectedSubDisciplines:job.selected_sub_disciplines
                        }

                        jd.push(obj)

                    })

                    if(jd.length==0) jd.push(blankJobObj)

                    setWantHireEmpDetails(jd)
                    setIsIdeaPublished(data.is_published)

                    getDeciplinesById(select2PD, select2PSD, `${data.fields_id}`.split(","))

                }

            }

            setIsLoading(false)

            

        })
        .catch((error)=>{
            setIsLoading(false)
        });

    }

    useEffect(()=>{
        getEngFields()
    }, [])

    const [isActuallyPublish, setIsActuallyPublish] = useState(false)
    const [isPublish, setIsPublish] = useState(false)
    const [clicked, setClicked] = useState(0)
    
    const [isUpdateLoading, setIsUpdateLoading] = useState(false)
    const [hasError, setHasError] = useState(false) 
    const [msg, setMsg] = useState("")
    const [allowReload, setAllowReload] = useOutletContext();

    const handleSubmit = (e) =>{
        e.preventDefault()
        handleSubmitPublish(false)
    }

    const handleSubmitPublish = (isPublishIdea) =>{
        
        setIsUpdateLoading(true)

        const formdata = new FormData()
        
        formdata.append("idea_id", slug)
        formdata.append("idea_title", title)
        formdata.append("idea_patented", isPatented)
        formdata.append("is_private", privateIdea)
        formdata.append("idea_patented_with", patentedWith)
        formdata.append("idea_patent_date_of_registration", patentRegDate);
           
        const field_find_id = engField.some((id)=>{
                return id == -1
            }) 

            if(field_find_id){
            formdata.append("field_name", filedName)
            formdata.append("discipline_name", disciplineName)
        }
     
        formdata.append("category", engField)
        formdata.append("sub_category", discipline.join(","))
        formdata.append("field", subDiscipline)
        formdata.append("introduction", valueProposition)
        
        formdata.append("filed_by", patientFiledBy);
        formdata.append("filed_by_name", patientFiledByName);
        formdata.append("patent_number", patentedNumber);
        formdata.append("tech_readiness_level", tlr);

        if(image!==null)
            formdata.append("idea_image", image)

        intellectualPropertyDocuments.length>0 && intellectualPropertyDocuments.map((val, index)=>{
            if(val!==null)
                formdata.append("intellectual_property_documents["+ index +"]", val)
        })

        otherDocument.length>0 && otherDocument.map((val, index)=>{
            if(val!==null)
                formdata.append("other_document["+ index +"]", val)
        })

        certifications.length>0 && certifications.map((val, index)=>{
            if(val!==null)
                formdata.append("certifications["+ index +"]", val)
        })

        formdata.append("abstract", abstract)
        formdata.append("description", description)
        formdata.append("idea_phase_initial", ipInitial ? 1 : 0)
        formdata.append("idea_business_plan", ipBusinessPlan ? 1 : 0)
        formdata.append("idea_prototype_available", ipPrototypeAvailable ? 1 : 0)
        formdata.append("idea_experiencing_customer", ipExperiencingCustomer ? 1 : 0)

        formdata.append("has_sell_idea", wantTosell.status ? 1 : 0)

        if(wantTosell.status){
            formdata.append("sell_idea_amount", wantTosell.amount)
            formdata.append("sell_idea_price_fixed", wantTosell.price_fixed)
            formdata.append("sell_idea_offer_valid_till", wantTosell.offer_valid_till)
            formdata.append("sell_idea_ownership_info", wantTosell.ownership_info)
            formdata.append("sell_idea_pay_condition", wantTosell.pay_condition)
            formdata.append("sell_idea_post_settlement", wantTosell.post_settlement)
            formdata.append("sell_idea_is_completed", wantTosell.is_completed)
        }

        formdata.append("has_collaborate", wantCollaborate.status ? 1 : 0)

        if(wantCollaborate.status){
            formdata.append("collaborate_position_offered", wantCollaborate.position_offered)
            formdata.append("collaborate_partnership_proposal", wantCollaborate.partnership_proposal)
            formdata.append("collaborate_conditions", wantCollaborate.conditions)
            formdata.append("collaborate_is_completed", wantCollaborate.is_completed)
        }
        
        formdata.append("has_fund_investor", wantFunds.status ? 1 : 0)

        if(wantFunds.status){
            formdata.append("fund_investor_ownership_info", wantFunds.ownership_info)
            formdata.append("fund_investment_type", wantFunds.type)
            formdata.append("fund_investment_stage", wantFunds.stage)
            formdata.append("fund_investment_offer", wantFunds.offer)
            formdata.append("fund_investment_loan_offer", wantFunds.loan_offer)
            formdata.append("fund_investment_is_completed", wantFunds.is_completed)
        }
        
        formdata.append("has_job", wantHireEmp.status ? 1 : 0)

        if(wantHireEmp.status){
            wantHireEmpDetails.map((job, index)=>{
                
                formdata.append(`job_id[${index}]`, job.id)
                formdata.append(`job_title[${index}]`, job.title)
                formdata.append(`job_field[${index}]`, job.field)
                formdata.append(`job_discipline[${index}]`, job.discipline)
                formdata.append(`job_sub_discipline[${index}]`, job.subDiscipline)
                formdata.append(`job_description[${index}]`, job.description)
                formdata.append(`job_type[${index}]`, job.jobType)
                formdata.append(`job_salary[${index}]`, job.salary)
                formdata.append(`organization_id[${index}]`, job.orgId)
                formdata.append(`application_deadline[${index}]`, job.application_deadline)

                formdata.append(`org_company_name[${index}]`, job.companyName)
                formdata.append(`org_about_company[${index}]`, job.aboutCompany)
                formdata.append(`org_website[${index}]`, job.website)
                formdata.append(`org_phone[${index}]`, job.phone)
                formdata.append(`org_company_size[${index}]`, job.companySize)
                formdata.append(`location[${index}]`, job.location)
                formdata.append(`type_of_organization[${index}]`, job.type_of_organization)
                
                if(job.companyLogoObj)
                    formdata.append(`org_company_logo[${index}]`, job.companyLogoObj)
            })
        }
        
        if(isIdeaPublished){
            formdata.append("is_published", 1)
        }else{
            formdata.append("is_published", isPublishIdea? 1 : 0)
        }
            

        axios.post(API_URL + API_UPDATE_IDEA, formdata,{
            headers:authHeader()
        })
        .then((responsex) => {

            setIsUpdateLoading(false)

            if(responsex.data.status=="RC200"){
                setHasError(false)

                /*if(!isIdeaPublished){
                    setIsIdeaPublished(isPublishIdea? 1 : 0)
                }*/

                setAllowReload(allowReload+1)

                if(isPublishIdea==1){
                    navigation(`/ideas/details/${id}/ai-hub`);
                }

                toast.success(responsex.data.message)
                    
            }else{
                setHasError(true)
                toast.error(responsex.data.message)
            }

            //setMsg(responsex.data.message)

            return responsex.data;
        });

    }

    const [isLoadingDelete, setIsLoadingDelete] = useState(false)
    const [isLoadingDuplicate, setIsLoadingDuplicate] = useState(false)

    const popupDelete = useRef()
    const navigation = useNavigate()
    
    const deleteIdea = () =>{
        
        setIsLoadingDelete(true)

        const formdata = new FormData()
        formdata.append("id", slug)

        axios.post(API_URL + API_DELETE_IDEA, formdata,{
            headers:authHeader()
        })
        .then((responsex) => {

            setIsLoadingDelete(false)

            if(responsex.data.status=="RC200"){
                toast.success(responsex.data.message)
                navigation("/ideas/my-ideas")
            }else{
                setIsLoadingDelete(true)
                toast.error(responsex.data.message)
            }

            return responsex.data;
        });

    }
    
    const popupDuplicate = useRef()

    const duplicateIdea = () =>{
        
        setIsLoadingDuplicate(true)

        const formdata = new FormData()
        formdata.append("id", slug)

        axios.post(API_URL + API_DUPLICATE_IDEA, formdata,{
            headers:authHeader()
        })
        .then((responsex) => {

            setIsLoadingDuplicate(false)

            if(responsex.data.status=="RC200"){
                toast.success(responsex.data.message)
                navigation(`/ideas/my-ideas`)
            }else{
                setIsLoadingDuplicate(true)
                toast.error(responsex.data.message)
            }

            return responsex.data;
        });

    }

    const popupPublishIdea = useRef()

    const form = useRef()

    
    const popupDealCompleted = useRef()
    const [isLoadingDealComplete, setIsLoadingDealComplete] = useState(false)
    const [closeFrom, setCloseFrom] = useState(-1)
    const [dealCompletedComment, setDealCompletedComment] = useState("")

    const markAsDealCompleted = () =>{
        
        if(closeFrom==-1){
            alert("Where you closed the deal?")
            return
        }

        popupDealCompleted.current.close()

        setIsLoadingDealComplete(true)

        const formdata = new FormData()
        formdata.append("idea_id", id)
        formdata.append("deal_close_from", closeFrom)
        formdata.append("comments",dealCompletedComment)

        axios.post(API_URL + API_IDEA_DEAL_COMPLETED, formdata,{
            headers:authHeader()
        })
        .then((responsex) => {

            setIsLoadingDealComplete(false)

            if(responsex.data.status=="RC200"){
                toast.success(responsex.data.message)
                setIsDealCompleted(1)
            }else{
                setIsLoadingDealComplete(false)
                toast.error(responsex.data.message)
            }

            return responsex.data;
        });

    }

    const [isLoadingULI, setIsLoadingULI] = useState(false)
    const [userListforIdeaClosed, setUserListforIdeaClosed] = useState([])

    const getUserListForIdeaClose = () =>{

        setIsLoadingULI(true)

        axios
        .get( `${API_URL}${API_IDEA_OFFER_ACCEPTED_USERS}?idea_id=${id}`,{
            params:{},
            headers: authHeader()
        })
        .then((response)=>{

            if(response.status==200){
    
                const json = response.data;
    
                if(json.status=="RC200"){
                    setUserListforIdeaClosed(json.data)
                }

                setIsLoadingULI(false)
    
            }

        });
            
            

    }

  return (
    
    // <section>
    //     <div className="mt-5">
    //         <div className="container">
                
                <>
                    {isLoading ? (
                        <p>Loading...</p>
                    ) : (
                        
                        isIdeaPublished==1 ? (
                            <>
                                
                                {anyOfferAccepted==0 && isDealCompleted==0 ? (
                                    <IdeaOptInOut slug={ideaSlug} ideaId={id} current={optInOut} />
                                ): ""}

                                <strong>You can not edit an Idea once it is published. You can only take the following actions based on the Idea Stage.</strong>
                                <p> For eg. if you are in talks with other SCIONS you can still <strong style={{"color":"#f05a24"}}>Delete</strong> and <strong style={{"color":"#f05a24"}}>Duplicate</strong> your idea to create a new one. However, if you have accepted an offer from a SCIONS, you can only use <strong style={{"color":"#f05a24"}}>Complete Deal and Close Idea</strong></p>
                                <br />

                                {anyOfferAccepted==0 && isDealCompleted==0 ? (
                                    <>
                                        <div className="mb-2" style={{display:"inline-block"}}>
                                            <Popups
                                                ref={popupDelete}
                                                label="Delete Idea"
                                                title="Delete Idea"
                                                btnCancel={true}
                                                btnSave={true}
                                                onSaveClickListener={(e)=>{
                                                    popupDelete.current.close()
                                                    deleteIdea()
                                                }}
                                                className="btn btn-sm btn-success m-1"
                                                dClassName="modal-lg"
                                            >
                                                <p>Are you sure, do you want to delete idea?</p>
                                            </Popups>

                                            <Popups
                                                ref={popupDuplicate}
                                                label="Duplicate Idea"
                                                title="Duplicate Idea"
                                                btnCancel={true}
                                                btnSave={true}
                                                onSaveClickListener={(e)=>{
                                                    popupDuplicate.current.close()
                                                    duplicateIdea()
                                                }}
                                                className="btn btn-sm btn-success m-1"
                                                dClassName="modal-lg"
                                            >
                                                <p>Are you sure, do you want to delete current idea and create new idea with current idea details?</p>
                                            </Popups>

                                        </div>

                                    </>
                                ) : ""}

                                {isDealCompleted==0 && (
                                    <Popups
                                        ref={popupDealCompleted}
                                        label="Complete Deal &amp; Close Idea"
                                        title="Complete Deal &amp; Close Idea"
                                        btnCancel={true}
                                        btnSave={true}
                                        onSaveClickListener={(e)=>{
                                            markAsDealCompleted()
                                        }}
                                        onButtonClick={(e)=>{
                                            getUserListForIdeaClose()
                                        }}
                                        className="btn btn-sm btn-success"
                                        dClassName="modal-lg"
                                    >
                                        <LoaderWrapperView isLoading={isLoadingULI} >
                                            <div className="row">
                                                <div className="col-md-12">
                                                    {/* <p>Are you sure, do you want to complete deal and close this idea?</p> */}
                                                    Where you closed the deal?
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form-radio">
                                                        <div className="radio">
                                                            <label><input value="0" type="radio" defaultChecked={closeFrom==0} onChange={ (e) => {
                                                                setCloseFrom(parseInt(e.target.value))
                                                            } } name="gender" /><i className="check-box"></i>In SCIONS</label>
                                                        </div>
                                                        <div className="radio">
                                                            <label><input value="1" type="radio" defaultChecked={closeFrom==1} onChange={ (e) => {
                                                                setCloseFrom(parseInt(e.target.value))
                                                            } } name="gender" /><i className="check-box"></i>Outside SCIONS</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12">

                                                    {closeFrom==0 && (
                                                        <ul>
                                                            {userListforIdeaClosed.map((usr)=>(
                                                                <li>{usr.full_name}</li>
                                                            ))}
                                                        </ul>
                                                    )}

                                                    {closeFrom==1 && (
                                                        <div className="form-group">
                                                            <label for="input">Comments (Optional) </label>
                                                            <textarea value={dealCompletedComment || ""} onChange={(e)=> setDealCompletedComment(e.target.value)} rows="5" id="textarea"  required="required"></textarea>
                                                            <i className="mtrl-select"></i>
                                                        </div>
                                                    )}

                                                </div>
                                                
                                            </div>
                                        </LoaderWrapperView>
                                        
                                    </Popups>
                                )}
                                
                            </>
                        ) : (
                            <form ref={form} onSubmit={handleSubmit} method="post">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="central-meta">
                                            
                                                <div className="row">
                                                
                                               

                                                    <div className="col-md-4">                                                
                                                        <div className="form-radio">
                                                            Is your Idea patented?&nbsp;&nbsp;&nbsp;
                                                            <div className="radio">
                                                                <label>
                                                                    <input type="radio" onChange={(e)=> setIsPatented(parseInt(e.target.value))} value="1" defaultChecked={isPatented===1} name="p_radio" />
                                                                    <i className="check-box"></i>YES
                                                                </label>
                                                            </div>
                                                            <div className="radio">
                                                                <label>
                                                                    <input type="radio" onChange={(e)=> setIsPatented(parseInt(e.target.value))} defaultChecked={isPatented===0} value="0" name="p_radio" />
                                                                    <i className="check-box"></i>NO
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {isPatented===1 && (
                                                    <>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label>Patent ID*</label>
                                                                    <input type="text" value={patentedNumber} onChange={(e)=> setPatentedNumber(e.target.value)} required="required" />
                                                                    <i className="mtrl-select"></i>
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label>Where your idea is patented? * <ExToolTip name="Please provide the region where your idea is protected. You can use multiple regions with the delimeter 'semicolon;'."></ExToolTip> </label>

                                                                    <input type="text" value={patentedWith} onChange={(e)=> setPatentedWith(e.target.value)} required="required" />
                                                                    <i className="mtrl-select"></i>
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <DatePicker withLabel={true} readOnly={true} format={df.DMY} value={patentRegDate || ""} mode={mode.day} onChange={ (e, date)=>{
                                                                        setPatentRegDate(date)
                                                                    }} required="required">
                                                                        Patent Registration Date*
                                                                    </DatePicker>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label>Patent filed by *</label>
                                                                    <Select2 placeholder={"Select appropriate option"} 
                                                                        directValues={false} 
                                                                        onSelect={ (e) => setPatientFiledBy(parseInt(e.target.value) ) } 
                                                                        value={patientFiledBy} 
                                                                        name="patente_filed_by" 
                                                                        keys="name" 
                                                                        data={patientFiledByList} />
                                                                </div>
                                                            </div>

                                                            {patientFiledBy==1 && (
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label>Person name or Organization name</label>

                                                                        <input type="text" value={patientFiledByName} onChange={(e)=> setPatientFiledByName(e.target.value)} required={patientFiledBy==1} />
                                                                        <i className="mtrl-select"></i>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>

                                                    </>
                                                )}

                                                <div className="row">

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <input type="text" value={title} onChange={(e)=> setTitle(e.target.value)} required="required" />
                                                            <label className="control-label">Idea Title *</label>
                                                            <i className="mtrl-select"></i>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="form-group ">

                                                            {isLoadingEngField ? (
                                                                <p>loading...</p>
                                                            ) : (
                                                                <>
                                                                    <label>Select your idea field *</label>
                                                                    <Select2Multiple value={engField} onSelect={
                                                                        (array) => {
                                                                            setEngField(array)
                                                                            getDeciplinesById(select2PD, select2PSD, array.join(","))
                                                                         
                                                                        }
                                                                    } required={false} keys={"field_name"} directValues={false} placeholder={"Select your idea field"} data={listEngineeringField} />
                                                                </>
                                                            )}

                                                                
                                                        </div>
                                                        { engField.some((id)=>{
                                                return id == -1
                                            })  ?
                                                <>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <input value={filedName} onChange={(e) => setFiledName(e.target.value)} required="required" />
                                                            <label className="control-label">Enter your idea field *</label>
                                                            <i className="mtrl-select"></i>
                                                        </div>
                                                    </div>

                                                </>
                                                : ""}
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Which one best describe your idea discipline *</label>
                                                            <Select2GroupsMultiple value={discipline} onSelect={(array)=>{
                                                                setDiscipline(array)
                                                            }} keys="field_name" required={false} ref={select2PD} data={listDiscipline} placeholder="Select" />
                                                        {/* <Select2 ref={select2PD} value={discipline} onSelect={ (e) => {
                                                            setDiscipline(e.target.value)
                                                            // getDeciplinesById(select2PSD, null, e.target.value,e)
                                                        } } name="discipline" keys={"field_name"} required={false} data={listDiscipline} directValues={false} placeholder={"Which one best describe your idea discipline"} /> */}
                                                        </div>
                                                        { engField.some((id)=>{
                                                return id == -1
                                            })  ?
                                                <>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <input value={disciplineName} onChange={(e) => setDisciplineName(e.target.value)} required="required" />
                                                            <label className="control-label">Enter your idea discipline *</label>
                                                            <i className="mtrl-select"></i>
                                                        </div></div>

                                                </>
                                                : ""}

                                                    </div>

                                                    {/* <div className="col-md-4">
                                                        <div className="form-group">
                                                            <Select2 ref={select2PSD} 
                                                                value={subDiscipline} 
                                                                onSelect={ (e) => setSubDiscipline(e.target.value) } 
                                                                name="sub_discipline"
                                                                keys={"field_name"}
                                                                data={listSubDiscipline}
                                                                placeholder={"--- Select Sub Discipline ---"} />

                                                        </div>
                                                    </div> */}

                                                    <div className="col-md-12">
                                                    <label style={{fontSize: ".8rem", fontWeight: "400", color:"#2a2a2a", margin:"0px"}}>Value Proposition * (max. 50 words) <ExToolTip name="Please note that a strong value proposition is crucial for demonstrating your idea and its market potential. Ensure that it is crafted with precision and clarity." /></label>
                                                        <WordLimiter req={true} rows="4" className="m-0" maxWords="50" value={valueProposition || ""} onChange={(val)=>{
                                                            setValueProposition(val)
                                                        }} label="" />
                                                    </div>
                                                    
                                                    <div className="col-md-12">
                                                        <br />
                                                        <WordLimiter req={false} rows="4" maxWords="250" value={abstract || ""} onChange={(val)=>{
                                                            setAbstract(val)
                                                        }} label="Abstract *" />
                                                    </div>

                                                    <div className="col-md-12">
                                                        <br />
                                                        <WordLimiter req={false} rows="4" maxWords="500" value={description || ""} onChange={(val)=>{
                                                            setDescription(val)
                                                        }} label="Description" />
                                                        <br /><br />
                                                    </div>
                                                    
                                                    {/* <div className="col-md-12">
                                                        <div className="form-group">
                                                            <textarea rows="4" value={valueProposition || ""} onChange={(e)=> setValueProposition(e.target.value)} required="required"></textarea>
                                                            <label className="control-label">Value Proposition (max. 20 words)</label>
                                                            <i className="mtrl-select"></i>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <textarea rows="4" value={abstract || ""} onChange={(e)=> setAbstract(e.target.value)} required="required"></textarea>
                                                            <label className="control-label">Abstract (max. 250 words)</label>
                                                            <i className="mtrl-select"></i>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <textarea rows="4" value={description || ""} onChange={(e)=> setDescription(e.target.value)} required="required"></textarea>
                                                            <label className="control-label" htmlFor="textarea">Description (max. 500 words)</label>
                                                            <i className="mtrl-select"></i>
                                                        </div>
                                                    </div> */}

                                                    <div className="col-md-5">
                                                        <div className="form-group">Upload a suitable image for your Idea :<br /><small>(Note: Only PNG, JPG and JPEG allowed)</small></div>
                                                    </div>

                                                    <div className="col-md-4">
                                                            
                                                            <ImageCropperPopup width={250} height={250} title="Select Idea Image" className='form-control file-picker-theme' onImageCroped={
                                                                (base64, blob) => {
                                                                    setImage(blob)
                                                                }
                                                            } />

                                                            {/* <input onClick={ (e) => e.target.value="" } onChange={ (e)=> {
                                                                var files = e.target.files;

                                                                if(files.length==0) return;

                                                                setImage(files[0])

                                                            }} name="" className="form-control-file file-picker-theme" type="file" /> */}
                                                    </div>

                                                    <div className="col-md-2">
                                                        <img src={imageSrc} alt="" />
                                                    </div>

                                                    <div className="col-md-12 mt-5">
                                                        <h5>Upload all Idea related documents</h5>
                                                        <small>(Note: Only PDF, JPEG, PNG images allowed)</small>
                                                    </div>

                                                    {/* Intellectual Property start */}

                                                </div>

                                                <div className="row mt-4">
                                                    <div className="col-md-6">
                                                        <p>
                                                            <strong>Intellectual Property Documents (if any)</strong>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-1"></div>
                                                    <div className="col-md-5">
                                                        <p>
                                                            <strong>Intellectual Property Documents List</strong>
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="row">

                                                    <div className="col-md-6">
                                                        
                                                        { intellectualPropertyDocuments.map((value, index)=>(
                                                            <input key={index} onClick={ (e) => e.target.value="" } onChange={ (e)=> {
                                                                var files = e.target.files;

                                                                if(files.length==0) return;

                                                                let ipd = [...intellectualPropertyDocuments]

                                                                ipd[index] = files[0];

                                                                setIntellectualPropertyDocuments(ipd)

                                                            }} name="ipd" type="file" className="form-control-file file-picker-theme" />
                                                        ))}

                                                    </div>

                                                    <div className="col-md-1">
                                                        <a href="#" onClick={ (e)=>{
                                                            e.preventDefault();
                                                            setIntellectualPropertyDocuments(intellectualPropertyDocuments.concat(null))

                                                        }} data-toggle="tooltip" data-placement="top" title="Add more documents"><i className="fa fa-plus-circle"></i></a>
                                                    </div>
                                                    <div className="col-md-5">

                                                        {listIPD.length>0 ? (
                                                            listIPD.map((item, index)=>(
                                                                <a key={index} style={{display:"block"}} href={item} target="_blank" >{`View Document ${index+1}`}</a>
                                                            ))
                                                        ) : (
                                                            <p>Intellectual Property Documents not uploaded</p>
                                                        )}
                                                    </div>
                                                </div>

                                                {/* Intellectual Property end */}

                                                {/* Certifications start */}

                                                <div className="row mt-4">
                                                    <div className="col-md-6">
                                                        <p>
                                                            <strong>Certifications (if any)</strong>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-1"></div>
                                                    <div className="col-md-5">
                                                        <p>
                                                            <strong>Certifications List</strong>
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    
                                                    <div className="col-md-6">
                                                        { certifications.map((value, index)=>(
                                                            <input key={index} onClick={ (e) => e.target.value="" } onChange={ (e)=> {
                                                                var files = e.target.files;

                                                                if(files.length==0) return;

                                                                let ipd = [...certifications]

                                                                ipd[index] = files[0];

                                                                setCertifications(ipd)

                                                            }} name="ipd" type="file" className="form-control-file file-picker-theme" />
                                                        ))}
                                                    </div>

                                                    <div className="col-md-1">
                                                        <a href="#" onClick={ (e)=>{
                                                                e.preventDefault();
                                                                setCertifications(certifications.concat(null))

                                                            }} data-toggle="tooltip" data-placement="top" title="Add more documents"><i className="fa fa-plus-circle"></i></a>
                                                    </div>

                                                    <div className="col-md-5">

                                                        {listCertifications.length>0 ? (
                                                            listCertifications.map((item, index)=>(
                                                                <a key={index} style={{display:"block"}} href={item} target="_blank" >{`View Certificate ${index+1}`}</a>
                                                            ))
                                                        ) : (
                                                            <p>Certification documents not uploaded</p>
                                                        )}
                                                    </div>

                                                </div>

                                                {/* Certifications end */}

                                                {/* Other documents start */}

                                                <div className="row mt-4">
                                                    <div className="col-md-6">
                                                        <p>
                                                            <strong>Other Documents (if any)</strong>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-1"></div>
                                                    <div className="col-md-5">
                                                        <p>
                                                            <strong>Other Documents List</strong>
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                
                                                    <div className="col-md-6">
                                                        { otherDocument.map((value, index)=>(
                                                            <input key={index} onClick={ (e) => e.target.value="" } onChange={ (e)=> {
                                                                var files = e.target.files;

                                                                if(files.length==0) return;

                                                                let ipd = [...otherDocument]

                                                                ipd[index] = files[0];

                                                                setOtherDocument(ipd)

                                                            }} name="ipd" type="file" className="form-control-file file-picker-theme" />
                                                        ))}
                                                    </div>

                                                    <div className="col-md-1">
                                                        <a href="#" onClick={ (e)=>{
                                                                e.preventDefault();
                                                                setOtherDocument(otherDocument.concat(null))

                                                            }} data-toggle="tooltip" data-placement="top" title="Add more documents"><i className="fa fa-plus-circle"></i></a>
                                                    </div>

                                                    <div className="col-md-5">

                                                        {listOtherDoc.length>0 ? (
                                                            listOtherDoc.map((item, index)=>(
                                                                <a key={index} style={{display:"block"}} href={item} target="_blank" >{`View Document ${index+1}`}</a>
                                                            ))
                                                        ) : (
                                                            <p>Other Documents not uploaded</p>
                                                        )}
                                                    </div>
                                                </div>

                                                {/* Other documents end */}

                                                <div className="row">
                                                    <div className="col-md-12"><hr /></div>
                                                    <div className="col-md-12 mt-3"><h5>Idea Phase</h5></div>

                                                    <div className="col-md-3">
                                                        {/* disabled={ipCurrentSelected>=2}  */}
                                                            <input  checked={ipInitial} onChange={(e)=>{
                                                            
                                                            setIpInitial(e.target.checked)

                                                            // setIpCurrentSelected(e.target.checked ? 1 : -1)

                                                        }} type="checkbox" value="1" /> Initial
                                                    </div>

                                                    <div className="col-md-3">
                                                        {/* disabled={ipCurrentSelected>=3}  */}
                                                        <input checked={ipBusinessPlan} onChange={(e)=>{
                                                            setIpBusinessPlan(e.target.checked)

                                                            // setIpCurrentSelected(e.target.checked ? 2 : -1)

                                                            // setIpInitial(e.target.checked)

                                                        }} type="checkbox" value="1" /> Business Plan
                                                    </div>

                                                    <div className="col-md-3">
                                                        {/* disabled={ipCurrentSelected>=4} */}
                                                        <input  checked={ipPrototypeAvailable} onChange={(e)=>{
                                                            setIpPrototypeAvailable(e.target.checked)

                                                            // setIpCurrentSelected(e.target.checked ? 3 : -1)

                                                            // setIpInitial(e.target.checked)
                                                            // setIpBusinessPlan(e.target.checked)

                                                        }} type="checkbox" value="1" /> Prototype Available
                                                    </div>

                                                    <div className="col-md-3">
                                                        <input checked={ipExperiencingCustomer} onChange={(e)=>{
                                                            setIpExperiencingCustomer(e.target.checked)
                                                            
                                                            // setIpCurrentSelected(e.target.checked ? 4 : -1)

                                                            // setIpInitial(e.target.checked)
                                                            // setIpBusinessPlan(e.target.checked)
                                                            // setIpPrototypeAvailable(e.target.checked)

                                                        }} type="checkbox" value="1" /> Experiencing Customer
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <IdeaTLRV4 selected={tlr} onSelected={(id)=> setTlr(id) } />
                                                    </div>
                                                </div>
                                            
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="central-meta" style={{background:"#ffd6c8", color:"#000"}}>
                                            <h5 className="mb-4">I want to share my idea: </h5>
                                            <div className="col-md-12">

                                                {/* Sell idea start */}

                                                <p>
                                                    <input 
                                                        name="chk_sell_idea"
                                                        defaultChecked={wantTosell.status}
                                                        disabled={wantTosell.disabled}
                                                        onChange = { (e)=>{
                                                            const val = e.target.value
                                                            const isChecked = e.target.checked

                                                            setWantToSell( prev => ({...prev, status:isChecked}))

                                                            if(isChecked){
                                                                setWantToSell( prev => ({...prev, disabled:false}))
                                                                setWantFunds( prev => ({...prev, disabled: true}))
                                                                setWantCollaborate( prev => ({...prev, disabled: true}))
                                                                setWantHireEmp( prev => ({...prev, disabled: true}))
                                                            }else{
                                                                setWantToSell( prev => ({...prev, disabled:false}))
                                                                setWantFunds( prev => ({...prev, disabled: false}))
                                                                setWantCollaborate( prev => ({...prev, disabled: false}))
                                                                setWantHireEmp( prev => ({...prev, disabled: false}))
                                                            }
                                                        }}
                                                        type="checkbox"
                                                        value="1" /> <strong>To sell</strong>
                                                </p>

                                                {wantTosell.status && (
                                                    <div className="col-md-12 idea-sub-sections">
                                                        {/* <h6>Sell it</h6> */}

                                                        <div className="row">

                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <input type="text"
                                                                        name="amount"
                                                                        value={wantTosell.amount==0 ? "" : wantTosell.amount }
                                                                        onChange={(e)=> setWantToSell( prev => ({...prev, amount:e.target.value})) }
                                                                        id="input"
                                                                        required={wantTosell.status} />
                                                                    <label className="control-label" htmlFor="input">Offer expectation (€) *</label>
                                                                    <i className="mtrl-select"></i>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-1">
                                                                <ExToolTip name="Please share the expected amount for your Idea sale in Euros."></ExToolTip>
                                                            </div>

                                                            <div className="col-md-4">
                                                                <div className="form-radio">
                                                                    Is the price&nbsp;*&nbsp;&nbsp;
                                                                    <div className="radio">
                                                                        <label>
                                                                            <input 
                                                                                name="is_fix_price"
                                                                                onChange={(e)=> setWantToSell( prev => ({...prev, price_fixed:0})) }
                                                                                value="0"
                                                                                type="radio"
                                                                                defaultChecked={wantTosell.price_fixed==0} />
                                                                            <i className="check-box"></i>Negotiable
                                                                        </label>
                                                                    </div>
                                                                    <div className="radio">
                                                                        <label>
                                                                            <input
                                                                                name="is_fix_price"
                                                                                onChange={(e)=> setWantToSell( prev => ({...prev, price_fixed:1})) }
                                                                                value="1"
                                                                                type="radio"
                                                                                defaultChecked={wantTosell.price_fixed==1} />
                                                                            <i className="check-box"></i>Fixed
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-4">
                                                                    <DatePicker allowPastDate={false} readOnly={true} format={df.DMY} value={wantTosell.offer_valid_till || ""} mode={mode.day} onChange={ (e, date)=>{
                                                                        setWantToSell( prev => ({...prev, offer_valid_till:date}))
                                                                    }}  required={wantTosell.status} >
                                                                        Offer valid till *
                                                                    </DatePicker>
                                                            </div>

                                                            <div className="col-md-11">
                                                                <div className="form-group">
                                                                    <textarea rows="4" value={wantTosell.ownership_info || ""} onChange={(e)=> setWantToSell( prev => ({...prev, ownership_info:e.target.value})) } required={wantTosell.status}></textarea>
                                                                    <label className="control-label">Ownership Information *</label>
                                                                    <i className="mtrl-select"></i>
                                                                </div>    
                                                            </div>

                                                            <div className="col-md-1">
                                                                <ExToolTip name="Shareholders/Equity information"/>
                                                            </div>

                                                            <div className="col-md-11">
                                                                <div className="form-group">
                                                                    <textarea rows="4" value={wantTosell.pay_condition || ""} onChange={(e)=> setWantToSell( prev => ({...prev, pay_condition:e.target.value})) } required={wantTosell.status}></textarea>
                                                                    <label className="control-label">Payment Condition *</label>
                                                                    <i className="mtrl-select"></i>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-1">
                                                                <ExToolTip name="Explain the expected mode of payment and possible installments."/>
                                                            </div>

                                                            <div className="col-md-11">
                                                                <div className="form-group">
                                                                    <textarea rows="4" value={wantTosell.post_settlement || ""} onChange={(e)=> setWantToSell( prev => ({...prev, post_settlement:e.target.value})) } required={wantTosell.status}></textarea>
                                                                    <label className="control-label" htmlFor="textarea">Post-Settlement *</label>
                                                                    <i className="mtrl-select"></i>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-1">
                                                                <ExToolTip name="Explain to buyer about how the ownership will be transffered." />
                                                            </div>

                                                        </div>
                                                    </div>
                                                )}

                                                {/* Sell idea end */}

                                                {/* want fund start */}

                                                    <p>
                                                        <input 
                                                            name="want_fund"
                                                            defaultChecked={wantFunds.status}
                                                            disabled={wantFunds.disabled}
                                                            onChange={(e)=>{
                                                                const val = e.target.value
                                                                const isChecked = e.target.checked

                                                                setWantFunds( prev => ({...prev, status:isChecked}))

                                                                if(isChecked){
                                                                    setWantToSell( prev => ({...prev, disabled:true}))
                                                                    setWantFunds( prev => ({...prev, disabled: false}))
                                                                    setWantCollaborate( prev => ({...prev, disabled: false}))
                                                                    setWantHireEmp( prev => ({...prev, disabled: false}))
                                                                }else{
                                                                    setWantToSell( prev => ({...prev, disabled:false}))
                                                                    setWantFunds( prev => ({...prev, disabled: false}))
                                                                    setWantCollaborate( prev => ({...prev, disabled: false}))
                                                                    setWantHireEmp( prev => ({...prev, disabled: false}))
                                                                }

                                                            }}
                                                            type="checkbox"
                                                            value="2" /> <strong>To find investor(s)</strong>
                                                    </p>

                                                    { wantFunds && wantFunds.status && (
                                                        <div className="col-md-12 idea-sub-sections">
                                                            {/* <h6>I share my idea to find investors.</h6> */}
                                                            <div className="row">

                                                                <div className="col-md-11">
                                                                    <div className="form-group ">
                                                                        <textarea rows="4" value={wantFunds.ownership_info || ""} onChange={ (e) => setWantFunds( prev => ({...prev, ownership_info: e.target.value})) } required={wantFunds.status}></textarea>
                                                                        <label className="control-label" htmlFor="textarea">Ownership information *</label>
                                                                        <i className="mtrl-select"></i>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-1">
                                                                    <ExToolTip name="Shareholders/Equity information"/>
                                                                </div>

                                                                <div className="col-md-4">
                                                                    <div className="form-group ">
                                                                        <label>What is your required investment</label>
                                                                        <Select2 placeholder={"Select"} 
                                                                            directValues={false} 
                                                                            onSelect={ (e) => {
                                                                                let val= e.target.value==="" ? "" : parseInt(e.target.value)
                                                                                setWantFunds( prev => ({...prev, type: val}))
                                                                            } } 
                                                                            value={wantFunds.type} 
                                                                            name="inv_type" 
                                                                            keys="name" 
                                                                            data={typeList} />
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-4">
                                                                    <div className="form-group ">
                                                                        <label>What is your idea stage</label>
                                                                        <Select2 placeholder={"Select"} 
                                                                            directValues={false} 
                                                                            onSelect={ (e) => {
                                                                                setWantFunds( prev => ({...prev, stage: e.target.value}))
                                                                            } } 
                                                                            value={wantFunds.stage} 
                                                                            name="stage" 
                                                                            keys="name" 
                                                                            data={stageList} />
                                                                    </div>
                                                                </div>

                                                                {wantFunds.type===1 ? (
                                                                    <div className="col-md-11">
                                                                        <div className="form-group ">
                                                                            <textarea rows="4" value={wantFunds.offer || ""} onChange={ (e) => setWantFunds( prev => ({...prev, offer: e.target.value})) } required={wantFunds.status}></textarea>
                                                                            <label className="control-label">Investment option *</label><i className="mtrl-select"></i>
                                                                        </div>
                                                                    </div>
                                                                ) : wantFunds.type===0 ? (
                                                                    <div className="col-md-11">
                                                                        <div className="form-group ">
                                                                            <textarea rows="4" value={wantFunds.loan_offer || ""} onChange={ (e) => setWantFunds( prev => ({...prev, loan_offer: e.target.value})) } required={wantFunds.status}></textarea>
                                                                            <label className="control-label">Loan option *</label>
                                                                            <i className="mtrl-select"></i>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <></>
                                                                )}

                                                                {wantFunds.type===1 || wantFunds.type===0 ? (
                                                                    <div className="col-md-1">
                                                                        <ExToolTip placement="left" type={wantFunds.type} name={wantFunds.type==1 ?
                                                                            "You can provide different options to investor in this section such as offering the number of shares, percentage of shares, value of shares and so on. Be more specific to attract the investors." 
                                                                            : "You can describe your request of loan with extra information such as amount, rate of interest, loan term and so on."} />
                                                                    </div>
                                                                ) : ""}

                                                            </div>
                                                        </div>
                                                    )}

                                                {/* want fund end */}
                                                
                                                {/* collaborate start */}

                                                    <p>
                                                        <input 
                                                            name="collaborate"
                                                            defaultChecked={wantCollaborate.status}
                                                            disabled={wantCollaborate.disabled}
                                                            onChange={(e)=>{
                                                                const val = e.target.value
                                                                const isChecked = e.target.checked

                                                                setWantCollaborate( prev => ({...prev, status:isChecked}))

                                                                if(isChecked){
                                                                    setWantToSell( prev => ({...prev, disabled:true}))
                                                                    setWantFunds( prev => ({...prev, disabled: false}))
                                                                    setWantCollaborate( prev => ({...prev, disabled: false}))
                                                                    setWantHireEmp( prev => ({...prev, disabled: false}))
                                                                }else{
                                                                    setWantToSell( prev => ({...prev, disabled:false}))
                                                                    setWantFunds( prev => ({...prev, disabled: false}))
                                                                    setWantCollaborate( prev => ({...prev, disabled: false}))
                                                                    setWantHireEmp( prev => ({...prev, disabled: false}))
                                                                }

                                                            }}
                                                            type="checkbox"
                                                            value="3" /> <strong>To find collaborator(s)</strong>
                                                    </p>

                                                    { wantCollaborate && wantCollaborate.status && (
                                                        <div className="col-md-12 idea-sub-sections">
                                                            {/* <h6>Find Collaborator</h6> */}
                                                            <div className="row">
                                                                <div className="col-md-11">
                                                                    <div className="form-group">
                                                                        <textarea rows="4" value={wantCollaborate.position_offered || ""} onChange={ (e) => setWantCollaborate( prev => ({...prev, position_offered: e.target.value})) } required={wantCollaborate.status}></textarea>
                                                                        <label className="control-label">Position offered *</label>
                                                                        <i className="mtrl-select"></i>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-1">
                                                                    <ExToolTip name="You can provide the details of the offered position such as CTO, CFO etc. You may include all the roles and responsibilities briefly."/>
                                                                </div>
                                                                
                                                                <div className="col-md-11">
                                                                    <div className="form-group">
                                                                        <textarea rows="4" value={wantCollaborate.partnership_proposal || ""} onChange={ (e) => setWantCollaborate( prev => ({...prev, partnership_proposal: e.target.value})) } required={wantCollaborate.status}></textarea>
                                                                        <label className="control-label">Partnership proposal *</label>
                                                                        <i className="mtrl-select"></i>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-1">
                                                                    <ExToolTip name="You can explain briefly about your business plan for this idea. In case of detailed discussions, you can always use the chat option with interested collaborator."/>
                                                                </div>

                                                                <div className="col-md-11">
                                                                    <div className="form-group">
                                                                        <textarea rows="4" value={wantCollaborate.conditions || ""} onChange={ (e) => setWantCollaborate( prev => ({...prev, conditions: e.target.value})) } required={wantCollaborate.status}></textarea>
                                                                        <label className="control-label">Conditions</label>
                                                                        <i className="mtrl-select"></i>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-1">
                                                                    <ExToolTip name="You can provide all the conditions for the collaboration such as equity or share percentage, try-out period (probabtion) between collaborators, or any other financials if you want to mention."/>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    )}

                                                {/* collaborate end */}
                                                
                                                {/*<p>
                                                    <input
                                                        name="empl"
                                                        defaultChecked={wantHireEmp.status}
                                                        disabled={wantHireEmp.disabled}
                                                        onChange={(e)=>{
                                                            const val = e.target.value
                                                            const isChecked = e.target.checked

                                                            setWantHireEmp( prev => ({...prev, status:isChecked}))

                                                            if(isChecked){
                                                                setWantToSell( prev => ({...prev, disabled:true}))
                                                                setWantFunds( prev => ({...prev, disabled: false}))
                                                                setWantCollaborate( prev => ({...prev, disabled: false}))
                                                                setWantHireEmp( prev => ({...prev, disabled: false}))
                                                            }else{
                                                                setWantToSell( prev => ({...prev, disabled:false}))
                                                                setWantFunds( prev => ({...prev, disabled: false}))
                                                                setWantCollaborate( prev => ({...prev, disabled: false}))
                                                                setWantHireEmp( prev => ({...prev, disabled: false}))
                                                            }

                                                        }}
                                                        type="checkbox"
                                                        value="4" /> <strong>Find expertise/freelancers to grow it up</strong>
                                                </p>

                                                 { wantHireEmp && wantHireEmp.status && (
                                                    <div className="col-md-12 idea-sub-sections">
                                                        <h6>Find expertise/freelancers to grow it up</h6>

                                                        {wantHireEmpDetails.length>0 && (
                                                            wantHireEmpDetails.map((job, index)=>(
                                                                <div key={"job_"+index}>
                                                                    <div className="row">

                                                                        <div className="col-md-10">
                                                                            <div className="form-group">
                                                                                
                                                                                <input type="text" 
                                                                                    onChange={(e)=> handleJobFieldChange(index, "title", e.target.value) }
                                                                                    value={job.title}
                                                                                    required="required" />
                                                                                <label className="control-label">Job Title *</label>
                                                                                <i className="mtrl-select"></i>

                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            <div className="text-center form-group ">
                                                                                <a onClick={ (e) =>{ 
                                                                                    e.preventDefault()

                                                                                    var dt = [...wantHireEmpDetails]
                                                                                    dt.splice(index, 1)

                                                                                    setWantHireEmpDetails(dt)

                                                                                    } } className="btn btn-link text-danger"><i className="fa fa-close"></i></a>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                        <FieldsSelector 
                                                                            fplaceholder="Select your job field"
                                                                            dplaceholder="Which one best describes your job discipline area"
                                                                            sdplaceholder="Select your expertise area"
                                                                            field={job.field}
                                                                            discipline={job.discipline}
                                                                            subDiscipline={job.subDiscipline}
                                                                            disc={job.selectedDisciplines}
                                                                            sDisc={job.selectedSubDisciplines}
                                                                            onSelectF={(val)=> {
                                                                                handleJobFieldChange(index, "field", val)
                                                                            } }
                                                                            onSelectD={(val)=> {
                                                                                handleJobFieldChange(index, "discipline", val)
                                                                            } }
                                                                            onSelectSD={(val)=> {
                                                                                handleJobFieldChange(index, "subDiscipline", val)
                                                                            } }
                                                                        />

                                                                        <div className="row">

                                                                            <div className="col-md-4">
                                                                                <div className="form-group">
                                                                                    <Select2 placeholder={"Job Type"} 
                                                                                        directValues={false} 
                                                                                        onSelect={ (e) => {
                                                                                            handleJobFieldChange(index, "jobType", parseInt(e.target.value))
                                                                                        } } 
                                                                                        value={job.jobType} 
                                                                                        name="job_type" 
                                                                                        keys="name" 
                                                                                        data={jobTypeList} />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <div className="form-group">
                                                                                    <input type="text" 
                                                                                        onChange={(e)=> handleJobFieldChange(index, "salary", e.target.value) }
                                                                                        value={job.salary}
                                                                                        required="required" />
                                                                                    <label className="control-label">Add Salary (€) *</label>
                                                                                    <i className="mtrl-select"></i></div>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <DatePicker readOnly={true} format={df.DMY} value={job.application_deadline || ""} mode={mode.day} onChange={ (e, date)=>{
                                                                                    handleJobFieldChange(index, "application_deadline", date)
                                                                                }} required="required">
                                                                                    Application Deadline
                                                                                </DatePicker>
                                                                            </div>

                                                                        <div className="col-md-12">
                                                                            <div className="form-group">
                                                                                <textarea rows="4" 
                                                                                    onChange={(e)=> handleJobFieldChange(index, "description", e.target.value) }
                                                                                    value={job.description}
                                                                                    required="required"></textarea>
                                                                                <label className="control-label">Job Description</label>
                                                                                <i className="mtrl-select"></i>
                                                                            </div>
                                                                        </div>
                                                                        </div>

                                                                        <div className="row">
                                                                            <div className="col-md-4">
                                                                                <div className="form-group">
                                                                                    <input type="text"
                                                                                            onChange={(e)=> handleJobFieldChange(index, "companyName", e.target.value) }
                                                                                            value={job.companyName}
                                                                                            required="required" />
                                                                                    <label className="control-label" >Organization Name *</label>
                                                                                    <i className="mtrl-select"></i>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <div className="form-group">
                                                                                    <Select2 placeholder={"Type of Organization"} 
                                                                                        directValues={false} 
                                                                                        onSelect={ (e) => {
                                                                                            handleJobFieldChange(index, "type_of_organization", parseInt(e.target.value))
                                                                                        } } 
                                                                                        value={job.type_of_organization} 
                                                                                        name="type_of_organization" 
                                                                                        keys="name" 
                                                                                        data={typeOfOrganization} />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <div className="form-group">
                                                                                    <input type="text"
                                                                                        onChange={(e)=> handleJobFieldChange(index, "location", e.target.value) }
                                                                                        value={job.location}
                                                                                        required="required" />
                                                                                    <label className="control-label" >Location *</label>
                                                                                    <i className="mtrl-select"></i>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-12">
                                                                                <div className="form-group">
                                                                                    <textarea rows="4" 
                                                                                        onChange={(e)=> handleJobFieldChange(index, "aboutCompany", e.target.value) }
                                                                                        value={job.aboutCompany}
                                                                                        required="required"></textarea>
                                                                                    <label className="control-label">About Organization</label>
                                                                                    <i className="mtrl-select"></i>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <div className="form-group">
                                                                                    <input type="text" 
                                                                                        onChange={(e)=> handleJobFieldChange(index, "website", e.target.value) }
                                                                                        value={job.website}
                                                                                        required="required" />
                                                                                    <label className="control-label">Website *</label>
                                                                                    <i className="mtrl-select"></i>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <div className="form-group">
                                                                                    <input type="text" 
                                                                                        onChange={(e)=> handleJobFieldChange(index, "phone", e.target.value) }
                                                                                        value={job.phone}
                                                                                        required="required" />
                                                                                    <label className="control-label">Phone *</label>
                                                                                    <i className="mtrl-select"></i>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <div className="form-group">
                                                                                <Select2 placeholder={"Organization Size"} 
                                                                                    directValues={false} 
                                                                                    onSelect={ (e) => {
                                                                                        handleJobFieldChange(index, "companySize", parseInt(e.target.value))
                                                                                    } } 
                                                                                    value={job.companySize} 
                                                                                    name="size" 
                                                                                    keys="name" 
                                                                                    data={organizationSize} />
                                                                                </div>
                                                                            </div>
                                                                            

                                                                            <div className="col-md-12 mt-4">
                                                                                <p><strong>Add Organization Logo</strong></p>
                                                                            </div>

                                                                            <div className="col-md-9">
                                                                                <input name="Upload Documents"
                                                                                    onClick={ (e) => {
                                                                                        e.target.value="";
                                                                                        handleJobFieldChange(index, "companyLogoObj", null)
                                                                                    }}
                                                                                    onChange={ (e)=> {
                                                                                        var files = e.target.files;

                                                                                        if(files.length==0) return;

                                                                                        handleJobFieldChange(index, "companyLogoObj", files[0])

                                                                                    }}
                                                                                    type="file"
                                                                                    size="5"
                                                                                    className="form-control-file" />
                                                                            </div>
                                                                        </div>
                                                                    <hr />
                                                                </div>
                                                            ))
                                                        )}

                                                        <div className="submit-btns col-md-12 text-right">
                                                            <a onClick={(e)=>{
                                                                e.preventDefault()

                                                                setWantHireEmpDetails(
                                                                    wantHireEmpDetails.concat(blankJobObj)
                                                                )

                                                            }} ><i className="fa fa-plus-circle"></i> Add</a>
                                                        </div>
                                                            
                                                                
                                                    </div>
                                                )} */}

                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-md-12">                                                
                                        <div className="central-meta">
                                            <div className="form-radio">
                                                <strong>I want to keep my idea private&nbsp;&nbsp;&nbsp;</strong>
                                                <ExToolTip name="To use the proposition of SCIONS AI Hub without sharing the idea in public, Select 'Yes'."/>
                                                <br />
                                                <div className="radio">
                                                    <label>
                                                        <input type="radio" onChange={(e)=> setIsPrivateIdea(parseInt(e.target.value))} value="1" defaultChecked={privateIdea===1} name="p_radiox" />
                                                        <i className="check-box"></i>YES
                                                    </label>
                                                </div>
                                                <div className="radio">
                                                    <label>
                                                        <input type="radio" onChange={(e)=> setIsPrivateIdea(parseInt(e.target.value))} defaultChecked={privateIdea===0} value="0" name="p_radiox" />
                                                        <i className="check-box"></i>Not Needed
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        </div>

                                    <div className="col-md-12 text-center">
                                        
                                        {msg && (
                                            <div className={ hasError ? "alert alert-danger mt-10 text-left" : "alert alert-success mt-10 text-left" } role="alert">
                                                {msg}
                                            </div>
                                        )}

                                        {isIdeaPublished ? (
                                            <ExtButton name="save" onClick={ (e)=> setIsPublish(false) } isLoading={isUpdateLoading} type="submit" className="mtr-btn">
                                                <span>Save Idea</span>
                                            </ExtButton>
                                        ) : (
                                            <>
                                                <ExtButton name="save" onClick={ (e)=> {
                                                    setClicked(0)
                                                    setIsPublish(false)
                                                } } isLoading={isUpdateLoading} showLoading={clicked==0} type="submit" className="mtr-btn">
                                                    <span>Save Idea</span>
                                                </ExtButton> &nbsp; &nbsp; 
                                                
                                                <Popups
                                                    ref={popupPublishIdea}
                                                    label="Publish your idea"
                                                    title="Publish Idea"
                                                    btnSaveLabel="Publish your idea"
                                                    span={true}
                                                    isLoading={isUpdateLoading}
                                                    type="button"
                                                    btnCancel={true}
                                                    btnSave={true}
                                                    button={true}
                                                    onSaveClickListener={(e)=>{
                                                        popupPublishIdea.current.close()
                                                        setClicked(1)
                                                        setIsPublish(true)
                                                        handleSubmitPublish(true)
                                                        
                                                    }}
                                                    className="mtr-btn"
                                                    dClassName="modal-lg"
                                                >
                                                    <div className="text-center">
                                                        <p>Are you completely ready with the idea? <br />
                                                        Once the idea is published, you can't edit it anymore. You can still cancel and just save the idea in case you have doubts.
                                                        {privateIdea==1 ? (
                                                            <p>Your idea will not be publicly published.</p>
                                                        ) : (
                                                            <p>Your idea will be reachable on SCIONS publicly.</p>
                                                        )}
                                                        </p>
                                                    </div>
                                                </Popups>

                                            </>
                                        )}
                                        <br /><br />
                                    </div>

                                </div>
                            </form>
                        )

                    )}
                </>

    //         </div>
    //     </div>
    // </section>

  );

};

export default EditIdea;
