import { Link } from "react-router-dom";

const CompLoaderLayout = (props) => {

  return (
        <div className={`preloader ` + (props.className ? props.className : "")}>
            <img className="preloader__image" width="200" src={require("../../assets/images/logo.png")} alt="" />
        </div>
  );

};

export default CompLoaderLayout;
