import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import msgType from './message'
import userData from './message'

export default combineReducers({
  auth,
  message,
  msgType,
  userData
});
