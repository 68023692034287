import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  API_GET_PUBLIC_ORG_LIST,
  API_URL,
} from "../../../../services/ApiEndpoints";
import axios from "axios";
import authHeader from "../../../../services/auth-header";
import React, { useEffect, useState } from "react";
import LoaderWrapperView from "../../../libs/LoaderWrapperView";
import { organizationSize, typeOfOrganization } from "../../Consts";
import { printPhoneNumber } from "../../../../helpers/configs";

const OrganizaationList = (props) => {
  const queryString = require("query-string");

  const location = useLocation();

  const [currentPage, setCurrentPage] = useState(1);
  const [list, setList] = useState([]);
  const [links, setLinks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [filters, setFilters] = useState({
    type: [],
    size: [],
  });

  const getData = () => {
    setIsLoading(true);

    axios
      .get(
        `${API_URL}${API_GET_PUBLIC_ORG_LIST}?page=${currentPage}&type_of_organization=${filters.type}&company_size=${filters.size}`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status == 200) {
          const json = response.data;

          if (json.status == "RC200") {
            var resp = json.data;

            setLinks(resp.links);
            setList(resp.data);
          }

          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const updateFilter = (e, key, id) => {
    const obj = { ...filters };

    // console.log(key + " = " + id)

    if (e.target.checked) {
      obj[key].push(id);
    } else {
      let arr = obj[key];

      let index = arr.indexOf(id);

      arr.splice(index, 1);

      obj[key] = arr;
    }

    setFilters(obj);
  };

  const handleLinkClick = (e, cPage) => {
    e.preventDefault();
    setCurrentPage(cPage);
  };

  const printLinks = (link) => {
    const isNullLink = link.url ? "" : "disabled";

    if (link.label === "...") {
      return (
        <li key={link.label} className={`page-item ${isNullLink}`}>
          <a
            className="page-link"
            onClick={(e) => e.preventDefault()}
            href="#a"
          >
            {link.label}
          </a>
        </li>
      );
    }

    if (link.label === "&laquo; Previous") {
      return (
        <li key={link.label} className={`page-item ${isNullLink}`}>
          <a
            onClick={(e) => handleLinkClick(e, currentPage - 1)}
            className="page-link prev"
            href="#a"
          >
            Previous
          </a>
        </li>
      );
    }

    if (link.label === "Next &raquo;") {
      return (
        <li key={link.label} className={`page-item ${isNullLink}`}>
          <a
            onClick={(e) => handleLinkClick(e, currentPage + 1)}
            className="page-link next"
            href="#n"
            aria-label="Next"
          >
            Next
          </a>
        </li>
      );
    }

    const isActive = link.active ? "active" : "";

    return (
      <li key={link.label} className={`page-item ${isActive}`}>
        <a
          className="page-link"
          onClick={(e) => handleLinkClick(e, parseInt(link.label, 10))}
          href="#a"
        >
          {link.label}
        </a>
      </li>
    );
  };

  useEffect(() => {
    getData();
  }, [currentPage]);

  
  useEffect(() => {
    setCurrentPage(1);
    getData();
  }, [filters]);

  return (
    <section>
      <div className="mt-4">
        <div className="container">
          <div className="row" id="page-contents">
            <div className="col-md-3">
              <aside className="sidebar stick-widget">
                <div className="widget">
                  <h4 className="widget-title">Filter by Type</h4>
                  <ul className="mb0">
                    {typeOfOrganization.map((val, index) => (
                      <li key={val.id}>
                        <label>
                          <input
                            onChange={(e) => updateFilter(e, "type", val.id)}
                            type="checkbox"
                          />{" "}
                          {val.name}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="widget">
                  <h4 className="widget-title">Filter by Size</h4>
                  <ul className="mb0">
                    {organizationSize.map((val, index) => (
                      <li key={val.id}>
                        <label>
                          <input
                            onChange={(e) => updateFilter(e, "size", val.id)}
                            type="checkbox"
                          />{" "}
                          {val.name}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              </aside>
            </div>

            <LoaderWrapperView isLoading={isLoading}>
              <div className="col-md-9">
                <div className="row">
                  {list && list.length == 0 ? (
                    <p>Organization not found</p>
                  ) : (
                    list.map((org, i) => (
                      <SingleOrganizaation key={org.id} data={org} />
                    ))
                  )}
                </div>

                {links.length > 3 && (
                  <div className="col-md-12">
                    <nav className="mt-4 mb-3">
                      <ul className="pagination justify-content-center mb-0">
                        {links.map((link) => printLinks(link))}
                      </ul>
                    </nav>
                  </div>
                )}
              </div>
            </LoaderWrapperView>
          </div>
        </div>
      </div>
    </section>
  );
};

const SingleOrganizaation = (props) => {
  const { data } = props;

  return (
    <div className="col-md-4">
      <div className="g-post-classic idea-min-height">
        <figure style={{background:"#f9f9f9", minHeight:"250px"}}>
          <Link to={`/organizations-list/${data.slug}`}>
            <img
              style={{ "object-fit": "contain", "min-height":"250px" }}
              alt={data.company_name}
              src={data.company_logo}
            />
          </Link>
        </figure>
        <div style={{ padding: "10px", minHeight:"180px" }} className="g-post-meta">
          <div style={{border:"0px", margin:"0px", padding:"0px"}} className="post-title">
            <h4 style={{color:"#f05a24", "font-weight": "600"}}>
              <Link
                className="text-max-2lines"
                style={{"text-transform": "capitalize"}}
                to={`/organizations-list/${data.slug}`}
              >
                {data.company_name}
              </Link>
            </h4>
            <p style={{fontSize:"14px", margin:"0px"}}>
              {data.website && (
                <>
                  <a href={data.website} target="_blank">
                    <i className="fa fa-globe"></i>{" View Website"}&nbsp;
                  </a>
                </>
              )}

              {data.phone && (
                <>
                  <br /><i className="fa fa-phone"></i>&nbsp;
                  <a href={`tel:${data.phone}`}>
                    {printPhoneNumber(data.phone_code, data.phone)}
                  </a>
                </>
              )}

              <br /><i className="fa fa-tags"></i> {typeOfOrganization[data.type_of_organization].name}
              <br /><i className="fa fa-users"></i> {organizationSize[data.company_size].name} Employees
              {data.location && (
                <>
                  <br />&nbsp;<i style={{fontSize:"16px"}} className="fa fa-map-marker"></i>&nbsp;{data.location}
                </>
              )}
            </p>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default OrganizaationList;
