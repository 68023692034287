import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { API_GET_PROBLEM, API_URL } from "../../../../services/ApiEndpoints";
import authHeader from "../../../../services/auth-header";
const MyProblemViewDetails = () =>{

    let {slug} = useParams();
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState({})

    const getDetails = () =>{

        setIsLoading(true)

        axios
        .get(API_URL + API_GET_PROBLEM +"/"+ slug,{
            headers: authHeader()
        })
        .then((response)=>{

            setIsLoading(false)
            
            if(response.status==200){

                const json = response.data;

                if(json.status=="RC200"){

                    var data = json.data;

                    setData(data)

                }

            }

        })
        .catch((error)=>{
            setIsLoading(false)
        });

    }

    useEffect(()=>{
        getDetails()
    }, [])

    return(
        <section>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            
                            {isLoading ? (
                                <>Loading</>
                            ) : (
                                <div id="page-contents" className="row merged20">
                                    <div className="col-lg-12">

                                        <div className="main-wraper" id="idea_panel">
                                            <div className="row">
                                                <div className="col-md-9">
                                                    <div className="course-details">
                                                        <div className="row">
                                                            <div className="col-md-7">
                                                                <h3>{data.pain_point}</h3>
                                                                <h5></h5>
                                                            </div>
                                                            
                                                            {data.problem_patented===1 && (
                                                                <div className="col-md-5"><span className="course-price">Patented with {data.problem_patented_with}</span><span className="course-price">Patent ID: {data.patented_number}</span></div>
                                                            )}

                                                        </div>
                                                        <div className="create-by">
                                                            <figure><img src={data.profile_image} style={{width:"45px", height:"45px"}} alt="" /></figure>
                                                            <div>
                                                                <span>{data.full_name}&nbsp;&nbsp;
                                                                    <i className="fa fa-comment" title="Chat with Owner" data-toggle="tooltip" data-placement="right"></i>
                                                                </span>
                                                                <em>Added: {data.date}</em>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="col-md-3">
                                                    <div className="course-video">
                                                        <figure><img style={{maxHeight:"200px", width:"auto"}} src={data.image} alt="" /></figure>
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <h4 className="idea-title">Details of the Business Problem</h4>
                                                    <strong>Company Name: </strong> {data.company_name}<br />
                                                    <strong>Location: </strong> {data.location}<br />
                                                    <strong>Offered Price: </strong> €{data.offered_price}<br />
                                                    <strong>Proposal deadine date: </strong> {data.proposal_date_deadline}<br />
                                                    <strong>Proposal deadine due date: </strong> {data.proposal_date_due_deadline}<br />
                                                    <hr />
                                                    <strong>Description</strong><br />
                                                    <hr />
                                                    { data.description && data.description.split("\n").map((str, index) => <p key={`px-${index}`}>{str}</p>)}

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            
                        </div>
                    </div>
                </div>
        </section>
    )
}

export default MyProblemViewDetails;