
import Select from 'react-select'

const Test = () =>{

    const list = [
        {
            label: "group 1",
            options: [
              {
                label: ">>> nested group 1",
                options: [
                  {
                    label: "nested 1",
                    value: 1
                  },
                  {
                    label: "nested 2",
                    value: 2
                  }
                ]
              },
              {
                label: ">>> nested group 2",
                options: [
                  {
                    label: "nested 1",
                    value: 3
                  }
                ]
              },
              {
                label: ">>> nested group 3",
                options: [
                  {
                    label: ">>> >>> nested group 3b",
                    value: 4,
                    options: [
                      {
                        label: "nested 1",
                        value: 5
                      },
                      {
                        label: "nested 2",
                        value: 6
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            label: "group 2",
            options: [
              {
                label: ">>> nested group 1",
                options: [
                  {
                    label: "nested 1",
                    value: 7
                  },
                  {
                    label: "nested 2",
                    value: 8
                  },
                  {
                    label: "nested 3",
                    value: 9
                  },
                  {
                    label: "nested 4",
                    value: 10
                  },
                  {
                    label: "nested 5",
                    value: 11
                  }
                ]
              }
            ]
          }
    ]

    

    return(
        <div className='row'>
            <div className='col-md-4'>

            </div>
            <div className='col-md-4'> <br /><br /><br />
                <Select isSearchable={true} isMulti={true} options={list} />
            </div>
        </div>
    )
}




export default Test