const PaymentFail = () =>{

    return(
        <section className="mb-5 mt-4">
            <div className="gap gray-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="alert text-center alert-danger">
                                <p>Sorry, something went wrong with the transaction. <br />Please get in touch with us to continue processing your payment and complete your registration. <br />Thank you and we look forward to working with you soon.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default PaymentFail;