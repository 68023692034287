import React, { Component, useEffect, useState } from "react";

import { API_SEND_FRIEND_REQUEST, API_URL } from "../../../services/ApiEndpoints";
import axios from "axios";
import authHeader from "../../../services/auth-header";
import ExtButton from '../../libs/ExtButton'
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { OPEN_CHAT } from "../../../actions/types";

const SearchSingleUser = (props) =>{

    const {user} = props

    const dispatch = useDispatch();

    const [friendStatus, setFriendStatus] = useState(-1)
    const [isLoading, setIsLoading] = useState(false)
    const [fields, setFields] = useState("")

    useEffect(()=>{

        setFriendStatus(user.friend_status)

        const arr = []
        arr.push(user.eng_field)

        if(user.discipline_name)
            arr.push(user.discipline_name)
        
        if(user.sub_discipline_name)
            arr.push(user.sub_discipline_name)

        setFields( arr.join(" - ") )

    }, [])

    const sendFriendRequest = (e) => {
        
        e.preventDefault()

        setIsLoading(true)

        axios
            .post(API_URL + API_SEND_FRIEND_REQUEST,{
                reciever_id: user.id
            },{
                headers: authHeader()
            })
            .then((response)=>{
                
                if(response.status==200){

                    setIsLoading(false)

                    const json = response.data;

                    if(json.status=="RC200"){
                        setFriendStatus(0)
                        toast.success(json.message)
                    }else{
                        toast.error(json.message)
                    }

                }

            })
            .catch((error)=>{

            });

    }

    return(

        <li>
            <div className="nearly-pepls">
                <figure>
                    <Link to={`/${user.public_url}`} title=""><img style={{width:"64px", height:"64px"}} src={user.profile_image} alt="" /></Link>
                </figure>
                <div className="pepl-info">
                    <h4 style={{margin:"0px"}}>
                        <Link to={`/${user.public_url}`} href="#" title="">{user.full_name}</Link>
                    </h4>
                    <br />{user.role_name}
                    <span>{fields}</span>
                    
                    { friendStatus==1 ? (
                        <div>
                            <p style={{marginBottom:"0px"}}><i className="fa fa-link"></i> Connected Scion</p>
                            <a href="" onClick={(e)=>{
                                e.preventDefault()

                                dispatch({
                                    type: OPEN_CHAT,
                                    userData: {
                                        id: user.id,
                                        full_name: user.full_name,
                                        image: user.profile_image
                                    }
                                });
                                
                            }} title="" className="add-user-butn" data-ripple="">Start Chat</a>
                        </div>
                    ) : (
                        friendStatus==0 ? (
                            <a href="#" title="" className="add-user-butn off" data-ripple="">Connect Request Sent</a>
                        ) : (
                            <ExtButton isLoading={isLoading} type="link" className="add-user-butn" onClick={ (e) => sendFriendRequest(e)} >
                                Connect Scion
                            </ExtButton>
                        )
                    )}

                </div>
            </div>
        </li>

    );

};

export default SearchSingleUser;