import React, { useCallback, useEffect, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import authHeader, { socketIP } from "../../../services/auth-header";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import {
  API_GET_IDEA_OFFERS_CONNECTED_USERS_MESSAGES,
  API_URL,
} from "../../../services/ApiEndpoints";
import { ReverseScrollBar } from "../../libs/ReverseScrollBar";
import { useAudio } from "../../libs/useAudio";
import { NotificationSound } from "../Consts";
import { useSelector } from "react-redux";

const SocketChat = (props) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [playing, toggle] = useAudio(NotificationSound);

  const { idea_id, id: activeUserId } = props;

  const [socketUrl, setSocketUrl] = useState(socketIP());
  const [messageHistory, setMessageHistory] = useState([]);

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);

  const [userMessage, setUserMessage] = useState("");

  useEffect(() => {
    getMessages();
  }, [activeUserId]);

  useEffect(() => {
    if (lastMessage !== null) {
      const data = JSON.parse(lastMessage.data);

      if (data.hasOwnProperty("command")) {
        if (data.type == 0) {
          if (data.sender_id == activeUserId) {
            setMessageHistory((prev) =>
              [
                {
                  id: -1,
                  is_received: true,
                  date: "Now",
                  sender_id: data.sender_id,
                  message: data.message,
                },
              ].concat(prev)
            );
          } else {
            toggle();
            props.onOtherMessageReceived &&
              props.onOtherMessageReceived(data, activeUserId);
          }
        }
      }
    }
  }, [lastMessage]);

  const handleClickSendMessage = () => {
    if (userMessage) {
      let msg = JSON.stringify({
        command: "message",
        idea_id: idea_id,
        to: activeUserId || -1,
        message: userMessage,
        type: 0,
      });
      sendMessage(msg);
      setUserMessage("");

      setMessageHistory((prev) =>
        [
          {
            id: -1,
            is_received: false,
            date: "Now",
            sender_id: activeUserId,
            message: userMessage,
          },
        ].concat(prev)
      );
    }
  };

  useEffect(() => {
    if (readyState == ReadyState.OPEN) {
      console.log("connected");

      setMessageHistory([]);

      sendMessage(JSON.stringify({ command: "register" }));

      getMessages();
    }
  }, [readyState]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [canLoadMore, setCanLoadMore] = useState(true);

  const getMessages = () => {
    setIsLoading(true);

    axios
      .get(
        `${API_URL}${API_GET_IDEA_OFFERS_CONNECTED_USERS_MESSAGES}/${idea_id}/${activeUserId}`,
        {
          params: {
            page: 1,
          },
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status == 200) {
          setPage(1);

          const json = response.data;

          if (json.status == "RC200") {
            var data = json.data;

            setCanLoadMore(page != data.last_page);

            const list = data.data;

            setMessageHistory(list);
          }
        }

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const loadFunc = () => {
    if (activeUserId == -1) return;
    if (!canLoadMore) return;
    if (isLoading) return;

    if (messageHistory.length == 0) {
      setCanLoadMore(false);
      return;
    }

    setIsLoading(true);

    axios
      .get(
        `${API_URL}${API_GET_IDEA_OFFERS_CONNECTED_USERS_MESSAGES}/${idea_id}/${activeUserId}`,
        {
          params: {
            page: page + 1,
          },
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status == 200) {
          setPage(page + 1);

          const json = response.data;

          if (json.status == "RC200") {
            var data = json.data;

            setCanLoadMore(page != data.last_page);

            const list = data.data;

            setMessageHistory(messageHistory.concat(list));
          }
        }

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const timelineRef = React.useRef();

  return (
    <div className="cm-chat peoples-mesg-box">
      <div className="chat-list">
        <div style={{ display: "flex" }}>
          <ReverseScrollBar
            canLoadMore={canLoadMore}
            innerRef={timelineRef}
            items={messageHistory}
            image=""
            id="scrollableDivIdea"
            fetch={loadFunc}
          >
            {messageHistory.map((val, index) => {
              return (
                <div
                  key={val.id}
                  className={val.is_received ? "message me" : "message you"}
                >
                  <div className="notification-event">
                    <span className="chat-message-item">
                      {val.message &&
                        val.message
                          .split("\n")
                          .map((str, index) => (
                            <p key={`px-${index}`}>{str}</p>
                          ))}
                    </span>
                    <span className="notification-date">
                      <time className="entry-date updated">{val.date}</time>
                    </span>
                  </div>
                </div>
              );
            })}
          </ReverseScrollBar>
        </div>
      </div>

      {/* <div 
                id="scrollableDiv"
                style={{
                    height: 322,
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column-reverse',
                }}>

                
                  <InfiniteScroll
                      dataLength={messageHistory.length}
                      next={loadFunc}
                      hasMore={canLoadMore}
                      scrollableTarget="scrollableDiv"
                      style={{ display: 'flex', flexDirection: 'column-reverse' }}
                      inverse={true}
                      endMessage={ messageHistory.length>10 && (<div className="text-center">No more messages found</div>) }
                      loader={<div className="loader text-center">Loading ...</div>}>
                      
                      <ul className="chatting-area">
                          {messageHistory.length>0 && (
                              
                              messageHistory.map((val)=>(
                                  <li key={val.id} className={val.is_received ? "you" : "me"}>
                                      <figure><img src="images/resources/userlist-2.jpg" alt="" /></figure>
                                      <p>{val.message}</p>
                                  </li>
                              ))
                          )}
                      </ul>

                  </InfiniteScroll>

            </div> */}

      <div className="message-text-container">
        <form method="post">
          <textarea
            placeholder="Type message here..."
            readOnly={readyState !== ReadyState.OPEN}
            value={userMessage}
            onChange={(e) => setUserMessage(e.target.value)}
          ></textarea>
          <button
            disabled={readyState !== ReadyState.OPEN}
            type="button"
            onClick={handleClickSendMessage}
            title="send"
          >
            <i className="fa fa-paper-plane"></i>
          </button>
        </form>
      </div>
    </div>
  );
};

export default SocketChat;
