import React, { useEffect, useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

function ImageCropper(props) {
  let {
    imageToCrop,
    onImageCropped,
    width,
    height,
    minWidth,
    minHeight,
    maxWidth,
    maxHeight,
  } = props;

  const [cropConfig, setCropConfig] = useState(
    // default crop config
    {
      unit: "px",
      aspect: 1 / 1,
      x: 0,
      y: 0,
      width: props.width === undefined ? 200 : props.width,
      height: props.height === undefined ? 200 : props.height,
    }
  );

  useEffect(() => {
    width = width === undefined ? 200 : width;
    height = height === undefined ? 200 : height;
    minWidth = minWidth === undefined ? width : minWidth;
    minHeight = minHeight === undefined ? height : minHeight;
    maxWidth = maxWidth === undefined ? width : maxWidth;
    maxHeight = maxHeight === undefined ? height : maxHeight;

    // console.log(width)
    // console.log(minWidth)
  }, [width, height, minWidth, minHeight, maxWidth, maxHeight]);

  const [imageRef, setImageRef] = useState();

  function getCroppedImage(sourceImage, cropConfigx, fileName) {
    // creating the cropped image from the source image
    const canvas = document.createElement("canvas");
    const scaleX = sourceImage.naturalWidth / sourceImage.width;
    const scaleY = sourceImage.naturalHeight / sourceImage.height;
    canvas.width = cropConfigx.width;
    canvas.height = cropConfigx.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      sourceImage,
      cropConfigx.x * scaleX,
      cropConfigx.y * scaleY,
      cropConfigx.width * scaleX,
      cropConfigx.height * scaleY,
      0,
      0,
      cropConfigx.width,
      cropConfigx.height
    );

    return new Promise((resolve) => {
      /* canvas.toBlob(
                (blob) => {
                    // returning an error
                    if (!blob) {
                        reject(new Error('Canvas is empty'));
                        return;
                    }

                    blob.name = fileName;
                    // creating a Object URL representing the Blob object given
                    const croppedImageUrl = window.URL.createObjectURL(blob);

                    resolve(croppedImageUrl);
                }, 'image/jpeg'
            ); */

      //   console.log(fileName);
      resolve(canvas.toDataURL());
    });
  }

  async function cropImage(crop) {
    if (imageRef && crop.width && crop.height) {
      const croppedImage = await getCroppedImage(
        imageRef,
        crop,
        "croppedImage.jpeg" // destination filename
      );

      // calling the props function to expose
      // croppedImage to the parent component
      onImageCropped(croppedImage);
    }
  }

  useEffect(() => {
    cropImage(cropConfig);
  }, [imageToCrop, imageRef]);

  return (
    <ReactCrop
      src={imageToCrop}
      crop={cropConfig}
      ruleOfThirds={true}
      locked={true}
      minWidth={props.minWidth === undefined ? 200 : props.minWidth}
      minHeight={props.minHeight === undefined ? 200 : props.minHeight}
      maxWidth={props.maxWidth === undefined ? 200 : props.maxWidth}
      maxHeight={props.maxHeight === undefined ? 200 : props.maxHeight}
      onImageLoaded={(imageRef) => setImageRef(imageRef)}
      onComplete={(cropConfigx) => cropImage(cropConfigx)}
      onChange={(cropConfigx) => setCropConfig(cropConfigx)}
      crossorigin="anonymous" // to avoid CORS-related problems
    />
  );
}

ImageCropper.defaultProps = {
  onImageCropped: () => {},
};

export default ImageCropper;
