import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import CanvasJSReact from '@canvasjs/react-charts';
import { useEffect, useState } from "react";
import { API_OVERVIEW, API_URL } from "../../../services/ApiEndpoints";
import axios from "axios";
import authHeader from "../../../services/auth-header";
import LoaderWrapperView from "../../libs/LoaderWrapperView";
import { organizationSize, typeOfOrganization } from "../Consts";

import linkdinlogo from "../../../assets/images/ic-linkedin-logo.png";
import scionlogo from "../../../assets/images/ic-scion-logo.png";


var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Overview = () =>{

    const { user: currentUser } = useSelector((state) => state.auth);

    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState({})

    const getDetails = () =>{

        setIsLoading(true)

        axios
        .get(API_URL + API_OVERVIEW,{
            headers: authHeader()
        })
        .then((response)=>{

            if(response.status==200){

                const json = response.data;
                setData(json.data)
                setIsLoading(false)
                console.log(json.data)
                
            }

        })
        .catch((error)=>{
            setIsLoading(false)
        });
    }

    const [currentTimeStr, setCurrentTimeStr] = useState("")

    useEffect(()=>{

        let hr = new Date().getHours()

        if(hr >= 5 && hr < 12 )
            setCurrentTimeStr("Morning")
        else if(hr >= 12 && hr < 15)
            setCurrentTimeStr("Afternoon")
        else if(hr >= 15 && hr < 20)
            setCurrentTimeStr("Evening")
        else
            setCurrentTimeStr("Night")

        getDetails()
    }, [])

    return(
        <section className="overview-section">
            <div className="gap2 gray-bg">
                <div className="container-fluid">
                    
                <LoaderWrapperView isLoading={isLoading}>

                    <div className="central-meta">
                        <div className="row">
                            <div className="col-md-3 d-flex align-items-center mb-4">
                                <div className="overview-profile">
                                    <img src={data.image} alt="" />
                                    <div>
                                        Good {currentTimeStr},<br />
                                        <b>{data.name}</b><br />
                                        <small>Welcome back</small>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 mb-4">
                                <div className="tiles">
                                    <span>No. of Organizations on SCIONS</span>
                                    <h1 className="text-right">{data.total_org}</h1>
                                </div>
                            </div>
                            <div className="col-md-3 mb-4">
                                <div className="tiles">
                                    <span>No. of Investors on SCIONS</span>
                                    <h1 className="text-right">{data.total_investors}</h1>
                                </div>
                            </div>
                            <div className="col-md-3 mb-4">
                                <div className="tiles">
                                    <span>Experts on SCIONS</span>
                                    <h1 className="text-right">{data.total_experts}</h1>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3"></div>
                            <div className="col-md-9">
                                <div  style={{alignItems: "center", display:"flex", justifyContent:"end"}}>
                                    <img style={{width:"32px"}} src="/idea.png" />
                                    <p style={{margin:"0px", marginLeft: "10px", marginTop: "5px"}}>Do you have an idea and want to explore the opportunities? Lets <Link style={{color: "#f05a24"}} to="/ideas/create">start here</Link>.</p>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    <h3 className="mt-3 overview-headings">YOUR SCIONS SPACE</h3>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card overview-card">
                                <div className="card-header">
                                    <h4>My SCIONS</h4>
                                </div>
                                <div className="card-body">
                                    <div className="my-scions-list">
                                        
                                        {data?.my_scions?.length>0 ? (
                                            <>
                                                {data.my_scions.map((val, key)=>(
                                                    <Link to={`/${val.public_url}`}>
                                                        <div key={`my_scion_${key}`} className="lst">
                                                            <img src={val.profile_image}/>
                                                            <div>
                                                                <span>{val.full_name}</span>
                                                                <p>{`${val.city}, ${val.country_name}`}</p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ))}

                                                <div className="text-right pos-right-bottom m-3">
                                                    <Link className="link" to="/profile/scions/my-scions">View all...</Link>
                                                </div>
                                            </>
                                        ) : (
                                            <p className="text-center">You do not have any connected scion.</p>
                                        )}

                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card overview-card">
                                <div className="card-header">
                                    <h4>My Idea Space</h4>
                                </div>
                                <div className="card-body">
                                    <div className="my-idea-space-list">

                                        {data?.my_ideas?.length>0 ? (
                                            <>
                                                <p>You have posted {data.total_ideas_posted} ideas till now</p>

                                                {data.my_ideas.map((val, key)=>(
                                                    <div className="lst">
                                                        <img src={val.idea_image}/>
                                                        <div>
                                                            <span>{val.idea_title}</span>
                                                            <p>{val.introduction}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        ) : (
                                            <p className="text-center">No data posted till now... <br />Start posting with community and get investments</p>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-3">
                            <div className="card overview-card">
                                <div className="card-header">
                                    <h4>My Eureka Space</h4>
                                </div>
                                <div className="card-body">
                                    <div className="my-idea-space-list">
                                        {data?.my_eureka?.length>0 ? (
                                            <>
                                                {data.my_eureka.map((val, key)=>(
                                                    <div className="lst">
                                                        <img src={val.image}/>
                                                        <div>
                                                            <span>{val.pain_point}</span>
                                                            <p>{val.description}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        ) : (
                                            <p className="text-center">No data posted till now... <br />Start posting with community and get ready solutions</p>
                                        )}
                                    </div>    
                                </div>
                            </div>
                        </div> */}
                        <div className="col-md-4">
                            <div className="card overview-card">
                                <div className="card-header">
                                    <h4>My Organization(s)</h4>
                                </div>
                                <div className="card-body">
                                    
                                    <div className="my-idea-space-list">
                                            
                                        {data.my_org ? (
                                            <>
                                                <p>You have created 1 organization.</p>

                                                <div className="lst">
                                                    <img src={data.my_org.company_logo}/>
                                                    <div>
                                                        <span>{data.my_org.company_name}</span>
                                                        <p>{data.my_org.location}<br />{data.my_org.phone}<br /><a target="_blank" href={data.my_org.website}>{data.my_org.website}</a></p>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <p className="text-center">You do not have any organization</p>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h3 className="mt-3 overview-headings">SCIONS ECOSYSTEM</h3>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card overview-card">
                                <div className="card-header">
                                    <h4>Organizations on SCIONS</h4>
                                </div>
                                <div className="table-responsive">
                                    <table className="table vm">
                                        <thead>
                                            <tr>
                                                <th>Company</th>
                                                <th>Category</th>
                                                <th>Employees</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            
                                            {data?.org_on_scions?.map((val, key)=>(
                                                <tr>
                                                    <td>
                                                        <div className="d-flex">
                                                            <img className="img-circle-40 mr-2" src={val.company_logo} />
                                                            <div>
                                                                <p className="m-0">{val.company_name}<br /><a style={{ color: "#f05a24" }} href={val.website} target="_blank">{val.website}</a></p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>{typeOfOrganization[val.type_of_organization].name}</td>
                                                    <td>{organizationSize[val.company_size].name}</td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                </div>

                                {data?.org_on_scions?.length>0 ? (
                                    <div className="text-right m-3 pos-right-bottom">
                                        <Link className="link" to="/organizations-list">View all...</Link>
                                    </div>
                                ) : (
                                    <div className="text-center m-3">
                                        Organizations not found.
                                    </div>
                                )}

                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card overview-card" style={{"minHeight":"600px"}}>
                                <div className="card-header">
                                    <h4>Investors on SCIONS</h4>
                                </div>
                                
                                <div className="table-responsive">
                                    <table className="table vm">
                                        <thead>
                                            <tr>
                                                <th width="40%">Investor</th>
                                                <th width="40%">Location</th>
                                                <th width="20%">View Profile</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            
                                            {data?.investors_on_scions?.map((val, key)=>(
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <img className="img-circle-40 mr-2" src={val.profile_image} />
                                                            <div>
                                                                <p className="m-0">{val.first_name}<br /></p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>{val.city}</td>
                                                    <td>
                                                    {val.linked_in_url ? (
                                                            <a
                                                                href={val.linked_in_url}
                                                                target="_blank"
                                                            >
                                                                <img
                                                                src={linkdinlogo}
                                                                className="img-fluid img-thumbnail px-1"
                                                                style={{ width: "100px", marginTop:"10px", marginRight:"10px"  }}
                                                                />
                                                            </a>
                                                            ) : (
                                                            ""
                                                            )}

                                                            {val.public_url ? (
                                                                <a
                                                                    href={val.public_url}
                                                                    target="_blank"
                                                                >
                                                                    <img
                                                                    src={scionlogo}
                                                                    className="img-fluid img-thumbnail px-1"
                                                                    style={{ width: "100px", marginTop:"10px", marginRight:"10px"  }}
                                                                    />
                                                                </a>
                                                                ) : (
                                                                ""
                                                                )}

                                                    </td>
                                                </tr>
                                            ))}
                                            
                                        </tbody>
                                    </table>
                                </div>

                                {data?.investors_on_scions?.length>0 ? (
                                    <div className="text-right m-3 pos-right-bottom">
                                        <Link className="link" to="/angelinvestor">View all...</Link>
                                    </div>
                                ) : (
                                    <div className="text-center m-3">
                                        Investors not found.
                                    </div>
                                )}

                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card overview-card" style={{"minHeight":"600px"}}>
                                <div className="card-header">
                                    <h4>Experts on SCIONS</h4>
                                </div>
                                
                                <div className="table-responsive">
                                    <table className="table vm">
                                        <thead>
                                            <tr>
                                                <th width="40%">Name</th>
                                                <th width="40%">Location</th>
                                                <th width="20%">View Profile</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.experts_on_scions?.map((val, key)=>(
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <img className="img-circle-40 mr-2" src={val.profile_image} />
                                                            <div>
                                                                <p className="m-0">{val.first_name}<br />
                                                                    
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>{val.city}</td>
                                                    <td>
                                                        {val.linked_in_url ? (
                                                            <a
                                                                href={val.linked_in_url}
                                                                target="_blank"
                                                            >
                                                                <img
                                                                src={linkdinlogo}
                                                                className="img-fluid img-thumbnail px-1"
                                                                style={{ width: "100px", marginTop:"10px", marginRight:"10px"  }}
                                                                />
                                                            </a>
                                                            ) : (
                                                            ""
                                                            )}

                                                            {val.public_url ? (
                                                                <a
                                                                    href={val.public_url}
                                                                    target="_blank"
                                                                >
                                                                    <img
                                                                    src={scionlogo}
                                                                    className="img-fluid img-thumbnail px-1"
                                                                    style={{ width: "100px", marginTop:"10px", marginRight:"10px"  }}
                                                                    />
                                                                </a>
                                                                ) : (
                                                                ""
                                                                )}

                                                    </td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                </div>

                                {/* <div className="text-right m-3 pos-right-bottom">
                                    <Link className="link" to="">View all...</Link>
                                </div> */}


                            </div>
                        </div>

                    </div>

                    <div className="row mt-3">
                        <div className="col-md-3">
                            <div className="card overview-card" style={{"minHeight":"550px"}}>
                                <div className="card-header">
                                    <h4>No. of experts in categories</h4>
                                </div>
                                
                                <div className="d-flex justify-content-center flex-wrap" style={{"width":"100%"}} >
                                    {data?.categories_for_ideas?.map((val)=>(
                                        <div className="tiles tiles-box" style={{"flex":"100%"}} >
                                            <h1>{val.total}</h1>
                                            <p className=""><span>Field in</span><br />{val.discipline_name}</p>
                                        </div>
                                    ))}
                                    
                                </div>

                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="card overview-card" style={{"minHeight":"550px"}}>
                                <div className="card-header">
                                    <h4>Global Expert Data</h4>
                                </div>
                                <div className="card-body">
                                    <ChartInvestors total={data.investors_per_funding_stage_total} data={data.investors_per_funding_stage} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card overview-card" style={{"minHeight":"550px"}}>
                                <div className="card-header">
                                    <h4>Global Organization Data</h4>
                                </div>
                                <div className="card-body">
                                    <GlobalOrgData data={data.global_org_data} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card overview-card" style={{"minHeight":"550px"}}>
                                <div className="card-header">
                                    <h4>Global Investor Data</h4>
                                </div>
                                <div className="card-body">
                                    <GlobalInvestorsData total={data.global_investor_data_total} data={data.global_investor_data} />
                                </div>
                            </div>
                        </div>
                    </div>

                </LoaderWrapperView>

                </div>
            </div>
        </section>
    )
}

const ChartInvestors = (props) => {

    const [options, setOptions] = useState({})

    useEffect(()=>{
        
        setOptions({
            animationEnabled: true,
            title: {
                text: ""
            },
            subtitles: [{
                text: `${props.total??0}`,
                verticalAlign: "center",
                fontSize: 54,
                dockInsidePlotArea: true
            }],
            data: [{
                type: "doughnut",
                showInLegend: true,
                indexLabel: "",
                yValueFormatString: "#,###'%'",
                dataPoints: props.data ?? []
            }]
        })
        
    },[props])

    

    return(
        options && <CanvasJSChart options = {options} />
    )

}

const GlobalInvestorsData = (props) => {

    const [options, setOptions] = useState({})

    useEffect(()=>{
        
        setOptions({
            animationEnabled: true,
            title: {
                text: ""
            },
            subtitles: [{
                text: `${props.total??0}`,
                verticalAlign: "center",
                fontSize: 54,
                dockInsidePlotArea: true
            }],
            data: [{
                type: "doughnut",
                showInLegend: true,
                indexLabel: "",
                yValueFormatString: "#,###'%'",
                dataPoints: props.data ?? []
            }]
        })
        
    },[props])

    return(
        options && <CanvasJSChart options = {options} />
    )

}

const GlobalOrgData = (props) => {

    const [options, setOptions] = useState({})

    useEffect(()=>{
        
        setOptions({
            animationEnabled: true,
            exportFileName: "New Year Resolutions",
            exportEnabled: false,
            title:{
                text: ""
            },
            data: [{
                type: "pie",
                showInLegend: true,
                legendText: "{label}",
                toolTipContent: "{label}: <strong>{y}%</strong>",
                indexLabel: "{y}%",
                indexLabelPlacement: "inside",
                dataPoints: props.data ?? []
            }]
        })
        
    },[props])

    return(
        options && <CanvasJSChart options = {options} />
    )

}

export default Overview;

