import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom'

import { API_RESEND_VERIFICATION_LINK, API_URL, RE_CAPTCHA_SITE_KEY } from "../services/ApiEndpoints";
import ExtButton from "./libs/ExtButton";
import FormReCaptchaV3 from "./libs/FormReCaptchaV3";
import ReCaptchaV3 from "./libs/ReCaptchaV3";

const GetVerificationLink = (props) =>{

    const [emailAddress, setEmailAddress] = useState("")
    const [message, setMessage] = useState("");
    const [isError, setisError] = useState(false);

    const [loading, setLoading] = useState(false);

    const handleSubmit = (captchaToken) =>{

        setLoading(true);

        axios.post(API_URL + API_RESEND_VERIFICATION_LINK, {
            email: emailAddress,
            "recaptcha_token":captchaToken
            })
            .then((response) => {
                
                if(response.data.status=="RC200"){
                    setisError(false)
                }else{
                    setisError(true)
                }
                
                setMessage(response.data.message)

                setLoading(false);

                return response.data;
            });

    };

    return(
        <div className="theme-layout">
            
            <div className="container-fluid">
                <div className="row">
                    
                    <div className="col-md-12 text-center mt-3">    
                        <a href="/"><img width="240px" src={require('../assets/images/logo-big.png')} alt="" /></a>
                    </div>
                    
                    <div className="col-md-12">
                        <div className="log-reg-area mt-5" style={{margin:"0 auto 100px auto"}}>
                            
                            <h3 className="log-title text-center">Resend Email Verification Link</h3>
                            
                            <p className="text-center">Enter your registered email address to get email verification link</p>
                            
                            <FormReCaptchaV3 onLoadStatusUpdate={(status)=>{
                                setLoading(status)
                            }} action="get_verification_link" onSubmit={handleSubmit}>

                                <div className="form-group">
                                    <input type="text" id="input-username" onChange={ (e) => setEmailAddress(e.target.value) } required="required" />
                                    <label className="control-label" htmlFor="input-username">Email Address *</label>
                                    <i className="mtrl-select"></i>
                                </div>

                                    {message && (
                                        <div className={ isError ? "alert alert-danger" : "alert alert-success" } role="alert">
                                            {message}
                                        </div>
                                    )}

                                    <ExtButton type="submit" isDisabled={loading} className="mtr-btn signin">
                                        <span>Send Verification Link</span>
                                    </ExtButton>

                                    <br /><br />

                                <Link to="/login" className="already-have">Back to login</Link>

                            </FormReCaptchaV3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GetVerificationLink;