import React, { useEffect, useState } from "react";

import { API_SIGN_NDA, API_URL, API_WIDGET_IDEAS } from "../../../services/ApiEndpoints";
import axios from "axios";
import authHeader from "../../../services/auth-header";
import { Link, useNavigate } from "react-router-dom";
import { Button, Modal } from 'react-bootstrap';
import NDAText from "../ideas/NDAText";
import { toast } from "react-toastify";
import ExtButton from "../../libs/ExtButton";

const LatestIdeasWidget = () =>{

    const [isLoading, setIsLoading] = useState(true)

    const [data, setData] = useState([])

    useEffect(()=>{
        
        getData()

    }, []);

    const getData = () =>{

        setIsLoading(true)

        axios
        .get(API_URL + API_WIDGET_IDEAS,{
            headers: authHeader()
        })
        .then((response)=>{
            
            if(response.status==200){

                const json = response.data;

                if(json.status=="RC200"){

                    setData(json.data)
                
                }

            }

            setIsLoading(false)

        })
        .catch((error)=>{
            setIsLoading(false)
        });

    };

    const [showNDA, setShowNDA] = useState(false)
    const [currentData, setCurrentData] = useState({})

    const [isNDALoading, setIsNDALoading] = useState(false)
    const navigate = useNavigate()

    const submitNDA = () =>{

        setIsNDALoading(true)

        var formdata = new FormData()
        formdata.append("slug", currentData.slug);

        axios.post(API_URL + API_SIGN_NDA, formdata,{
            headers:authHeader()
        })
        .then((responsex) => {

            setIsNDALoading(false)

            if(responsex.data.status=="RC200"){

                toast.success(responsex.data.message)

                setShowNDA(false)

                navigate(`/ideas/view/${currentData.slug}`)

            }

            return responsex.data;
        }).error(()=>{
            setIsNDALoading(false)
        });

    }

    return(
        <div className="widget ">
            <h4 className="widget-title">Recent Ideas shared</h4>

            {isLoading ? (
                <p>loading....</p>
            ) : ( 
                <>
                    <ul className="short-profile">
                        
                        {data.map((idea, index)=>(
                            <li key={idea.id}>
                                <a onClick={(e)=>{
                                    e.preventDefault()
                                    
                                    if(idea.is_nda_done==1)
                                        navigate(`/ideas/view/${idea.slug}`)
                                    else{
                                        setCurrentData(idea)
                                        setShowNDA(true)
                                    }

                                }}>
                                    <div className="row">
                                        <div className="col-md-5 col-5">
                                            <img src={idea.idea_image} />
                                        </div>
                                        <div className="col-md-7 col-7 pl-0">
                                            <strong>{idea.idea_title}</strong>
                                            { idea.introduction && idea.introduction.split("\n").map((str, index) => <p key={`px-${index}`}>{str}</p>)}
                                        </div>
                                    </div>
                                </a>
                            </li>
                        ))}

                    </ul>

                    <div className="col-12 text-center mb-3">
                        <Link to="/ideas"> View All <i className="fa fa-angle-right"></i></Link>
                    </div>

                    <Modal noMargin dialogClassName={"full-modal"} show={showNDA} aria-labelledby="contained-modal-title-vcenter" onHide={()=>setShowNDA(false)}>  
                    
                        <Modal.Body>
                            
                            <NDAText secondParty={currentData ? currentData.full_name : ""} secondPartyRole={currentData ? currentData.role_name : ""} ideaTitle={currentData ? currentData.idea_title : ""} />

                            <br /><br />


                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <ExtButton isLoading={isNDALoading} showLoading={false} type="button" className="btn btn-link mr-50" onClick={()=>setShowNDA(false)}><span>Cancel</span></ExtButton>

                                    <ExtButton isLoading={isNDALoading} showLoading={true} type="button" className="mtr-btn" onClick={(e)=>{
                                        submitNDA()
                                    }}><span>I understand and Agree</span></ExtButton>
                                </div>
                            </div>


                        </Modal.Body>

                    </Modal>
                </>
            )}

        </div>
    );

};

export default LatestIdeasWidget;