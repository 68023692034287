
const HowItWorks = (props) =>{

    return(
        <div className="central-meta">

            <h2 className="text-center">How it Works?</h2>

            <button type="button" style={{position:"absolute", top:"20px", right:"20px"}} className="close" onClick={(e)=>{
                props.hide && props.hide()
            }} ><i className="fa fa-times"></i></button>
            <hr />

            <div className="row">
                <div className="col-md-4"><img src="/assets/images/create-idea-img.jpg" width="500" height="450" className="img-fluid" /></div>
                <div className="col-md-8">
                    <h3>Create an IDEA</h3>
                    <p className="text-justify">Provide the details on your IDEA as much as you can. Do not forget, adding a nice image always can added value to your IDEA.</p>
                    <p className="text-justify">
                        You can upload the documents to support your IDEA such as; Intellectual property, certificates, or any other related documents. It is well recommended to save your idea with all the required
                        information. You must also agree to the Non-Disclosure Agreement (NDA) as the receiving party (IDEA owner).
                    </p>
                    <p>The next step is to choose the action you want to take with your idea. There are four possible actions at the moment on SCIONS.</p>
                </div>
            </div>
            
            <div className="row mt-5">
                <div className="col-md-6">
                    <div className="text-center"><img src="/assets/images/sell-your-idea-img.jpg" /></div>
                    <h5 className="text-center"><strong>Action 1:</strong> Sell your IDEA</h5>
                    <p className="text-justify">
                        If you want to sell your idea, then, select this option <strong>"Sell it"</strong> and provide clear offer expectations for the interested users of SCIONS.
                        <strong>You can't select any other action if you decide to sell your idea.</strong>
                    </p>
                </div>

                <div className="col-md-6">
                    <div className="text-center"><img src="/assets/images/find-your-investor-img.jpg" /></div>
                    <h5 className="text-center"><strong>Action 2:</strong> Find an Investor for your IDEA</h5>
                    <p className="text-justify">
                        If you are looking for an investor for your idea, then, select this option <strong>"Find investors"</strong>. Provide clear information for the interested users of SCIONS. You may also choose to find
                        a co-founder, collaborator, and/or expertise for your IDEA along with this action.
                    </p>
                </div>
            </div>

            <div className="row mt-5">
                <div className="col-md-8 mar-top5">
                    <br /><br /><br /><br />
                    <h5 className=""><strong>Action 3:</strong> Find a Co-founder/Collaborator for your IDEA</h5>
                    <p className="text-justify">
                        If you are looking for a co-founder/collaborator for your IDEA, then, select this option <strong>"Find Co-founder"</strong>. Provide clear information for the interested users of SCIONS. You may also
                        choose to find an investor and/or expertise for your IDEA along with this action.
                    </p>
                </div>
                <div className="col-md-4"><img src="/assets/images/find-cofounder-img.jpg" width="500" height="450" className="img-fluid" /></div>
            </div>

            <div className="row mt-5">
                <div className="col-md-4 mar-top5 order-md-0"><img src="/assets/images/publish-idea-img.jpg" width="500" height="450" className="img-fluid" /></div>
                <div className="col-md-8 mar-top5 order-md-1">
                    <h3>Publish IDEA</h3>
                    <p className="text-justify">
                        Now, you are ready to publish your idea and share it with the users of SCIONS. Once the idea is published, then <strong>it cannot be edited anymore</strong>. The users can view your idea and make the
                        offer on your requested actions. The users are only allowed to view your idea when they agree to the mutual Non-Disclosure Agreement (NDA). If a user doesn't accept the NDA, then the user can only
                        view the Idea Title, Value Proposition, and your IDEA’s field.
                    </p>
                </div>
            </div>

            <div className="row mt-5">
                <div className="col-md-4 mar-top5 order-md-3"><img src="/assets/images/idea-response-img.jpg" width="500" height="450" className="img-fluid" /></div>
                <div className="col-md-8 mar-top5 order-md-2">
                    <h3>Idea Responses (Offers)</h3>
                    <p className="text-justify">
                        You can browse through the offers in the Idea Responses section. It is always advisable to chat with the responder if the offer is appealing to you. Make your agreements clear on the chat.
                        <strong>SCIONS store your chat for a longer duration to refer them back</strong>.
                    </p>
                    <p className="text-justify">Make sure that you have done your paperwork already before accepting the offer. Once the agreement is clear and ready to make a deal, then, you may Accept the offer.</p>
                    <p className="text-justify">If the offer is not appealing to you. It is your right to reject it.</p>
                </div>
            </div>

            <div className="row mt-5">
                <div className="col-md-4 mar-top5 order-md-4"><img src="/assets/images/delete-idea-img.jpg" width="500" height="450" className="img-fluid" /></div>
                <div className="col-md-8 mar-top5 order-md-5">
                    <h3>Delete IDEA</h3>
                    <p className="text-justify">In case you don't want to continue dealing with the idea, then, you can just delete it under "Edit Idea Details" section. You can't delete the idea after accepting an offer.</p>
                </div>
            </div>

            <div className="row mt-5">
                <div className="col-md-4 mar-top5 order-md-7"><img src="/assets/images/duplicate-idea-img.jpg" width="500" height="450" className="img-fluid" /></div>
                <div className="col-md-8 mar-top5 order-md-6">
                    <h3>Duplicate IDEA</h3>
                    <p className="text-justify">
                        In case you want to edit your idea (which is not possible after publishing), then you can go for duplication. The duplicate idea option under "Edit Idea Details" section will copy the details of the
                        existing idea and will allow you to make adjustments. You can't duplicate the idea after accepting an offer.
                    </p>
                </div>
            </div>

            <div className="row mt-5">
                <div className="col-md-4 mar-top5"><img src="/assets/images/complete-deal-img.jpg" width="500" height="450" className="img-fluid" /></div>
                <div className="col-md-8 mar-top5">
                    <h3>Complete Deal & Close IDEA Listing</h3>
                    <p className="text-justify">
                        Once the offer is accepted by you from Idea Responses within SCIONS, then, you can close the deal with this option under "Edit Idea Details". Make sure that you have done your paperwork already before
                        completing the deal. This information will be reflected on the IDEA portal and users will not be able to make the offer on this IDEA anymore.
                    </p>
                </div>
            </div>
        </div>
    )

}

export default HowItWorks