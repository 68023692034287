import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useLocation } from 'react-router-dom'
import Form from "react-validation/build/form";
import { logout } from "../actions/auth";

const Logout = (props) =>{

    const dispatch = useDispatch();
    
    dispatch(logout(true));

    return(
        <Navigate to="/login" />
    );
};

export default Logout;