import FooterInfo from "./FooterInfo"
import FooterLinks from "./FooterLinks"

const FooterHome = () =>{

    return(
        <footer>
            <div className="container">
                <div className="row mobile-footer">
                    <div className="col-md-2 mb-2">
                        <img style={{marginTop:"6px"}} src="/assets/images/sigma-tech.jpg" className="img-thumbnail logo-plan p-1" />
                    </div>
                    <div className="col-md-10 mt-2">
                        <FooterInfo /><br /><br />
                        <FooterLinks />
                    </div>
                </div>
            </div>
        </footer>
    )

}

export default FooterHome