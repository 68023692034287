import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, Outlet, useParams } from "react-router-dom";
import { API_GET_IDEA, API_URL } from "../../../services/ApiEndpoints";
import authHeader from "../../../services/auth-header";
import Popups from "../../libs/Popups";
import TransPopup from "../../libs/TransPopup";
import { print_idea_status } from "../Consts";
import HowItWorks from "./HowItWorks";

const OffersView = () =>{

    let {slug} = useParams();
    const [isLoading, setIsLoading] = useState(false)

    const [data, setData] = useState({})

    const getDetails = () =>{

        setIsLoading(true)

        axios
        .get(API_URL + API_GET_IDEA +"/"+ slug,{
            headers: authHeader()
        })
        .then((response)=>{
            
            if(response.status==200){

                const json = response.data;

                if(json.status=="RC200"){

                    var data = json.data;

                    setData(data)

                }

            }

            setIsLoading(false)

        })
        .catch((error)=>{
            setIsLoading(false)
        });

    }

    useEffect(()=>{
        getDetails()
    }, [])
    
    const popupHowItWork = useRef()

    return(
        <section>
            <div className="gap gray-bg">
                <div className="container">
                    
                    { isLoading ? (<p>Loading...</p>) : (
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="row merged20" id="page-contents">
                                    <div className="col-md-12">
                                        <div className="central-meta">
                                            <h3>{data.idea_title}</h3>
                                            <h6 style={{"color":"#f05a24"}} className="mb-3">{data.unique_id}</h6>
                                            <p className="m-0">
                                                { data.introduction && data.introduction.split("\n").map((str, index) => <p key={`px-${index}`}>{str}</p>)}
                                            </p>
                                            <em>Added: {data.date}</em>
                                            <br />
                                            Status: &nbsp; {print_idea_status(data)}

                                            <TransPopup
                                                ref={popupHowItWork}
                                                label="How it Works ?"
                                                className="pull-right"
                                                title=""
                                                dClassName="modal-lg modal-transp"
                                                btnCancel={false}
                                                btnCancelLabel="Close"
                                                onSaveClickListener={(e)=>{
                                                    popupHowItWork.current.close()
                                                }}
                                            >
                                                <HowItWorks hide={(e)=>{
                                                    popupHowItWork.current.close()
                                                }} />
                                            </TransPopup>

                                            <div className="frnds mt-4">
                                                <ul className="nav nav-tabs mb-2">
                                                    <li className="nav-item">
                                                        <NavLink to={"offers"} >My Offers</NavLink>
                                                    </li>
                                                    <li className="nav-item">
                                                        <NavLink to={"chat"} >Chat(s)</NavLink>
                                                    </li>
                                                    <li className="nav-item">
                                                        <NavLink to={"details"} >Idea Details</NavLink>
                                                    </li>
                                                </ul>

                                                <div className="gap gray-bg">
                                                    <div className="container">
                                                        <Outlet />
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </section>
    );
};


export default OffersView;