import { useState } from "react";

const PasswordField = (props) =>{

    let eyeIcon = "eye"
    const [showPwd, setShowPwd] = useState(false)

    return(
        <div className="form-group">
            <input type={showPwd ? "text" : "password"} value={props.value} onChange={props.onChange} id={`input-password-${props.keys}`} required="required" />
            <label className="control-label" htmlFor={`input-password-${props.keys}`}>{props.label || "Password"} *</label>
            <i className="mtrl-select"></i>
            <div onClick={ (e) =>{
                setShowPwd(!showPwd)
            }} className="pwd-eye">
                <i className={`fa fa-${showPwd ? "eye-slash" : "eye"}`}></i>
            </div>
        </div>
    )

}

export default PasswordField;