import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component'
import { API_FIELD_TREE, API_IDEA_LIST, API_SIGN_NDA, API_URL } from "../../../services/ApiEndpoints";
import axios from "axios";
import authHeader from "../../../services/auth-header";
import React, { useEffect, useRef, useState } from "react";
import LoaderWrapperView from "../../libs/LoaderWrapperView";
import TreeView from "../../libs/TreeView";
import { Button, Modal } from 'react-bootstrap';
import ExtButton from "../../libs/ExtButton";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import NDAText from "./NDAText";
import TransPopup from "../../libs/TransPopup";
import HowItWorks from "./HowItWorks";

const IdeaList = (props) => {

    const { userData } = useSelector((state) => state.userData);

    const queryString = require('query-string');

    const location = useLocation();
    
    const navigate = useNavigate()

    const query = queryString.parse(location.search)

  const [currentPage, setCurrentPage] = useState(1)
  const [list, setList] = useState([])
  const [links, setLinks] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const [filters, setFilters] = useState({
    field:"",
    discipline:"",
    subDiscipline:"",
    req_idea_with_jobs:0,
    req_idea_sell:0,
    req_collaborate: query["collaboration"]!=undefined ? 1 : 0,
    req_fund_investor: query["investments"]!=undefined ? 1 : 0,
    fields: query["field"]!=undefined ? [query["field"]] : []
  })


  const getData = () =>{
    
    setIsLoading(true)

    axios
        .post(API_URL + API_IDEA_LIST,{
                page: currentPage,
                field_id: filters.field,
                discipline_id: filters.discipline,
                sub_discipline_id: filters.subDiscipline,
                idea_with_jobs: filters.req_idea_with_jobs,
                idea_sell: filters.req_idea_sell,
                collaborate: filters.req_collaborate,
                fund_investor: filters.req_fund_investor,
                fields:filters.fields
            },
            {
              headers: authHeader()
            }
        )
        .then((response)=>{
            
            if(response.status==200){

                const json = response.data;

                if(json.status=="RC200"){

                    var resp = json.data;
                    
                    setLinks(resp.links)
                    setList(resp.data)

                }

                setIsLoading(false)

            }

        })
        .catch((error)=>{

            setIsLoading(false)

        });

  }

  const updateFilter = (e, key) =>{
    
        const obj = {...filters}

        obj[key] = e.target.checked ? 1 : 0

        setFilters(obj)
  }

  const handleLinkClick = (e, cPage) =>{
      e.preventDefault()
      setCurrentPage( cPage )
  }

  const printLinks = (link) =>{

        const isNullLink = link.url ? "" : "disabled" 

        if(link.label==="...") {
            return(<li key={link.label} className={`page-item ${isNullLink}`}>
                <a className="page-link" onClick={ (e) => e.preventDefault() } href="#a">{link.label}</a>
            </li>)
        }

        if(link.label==="&laquo; Previous") {
            return(
                <li key={link.label} className={`page-item ${isNullLink}`}>
                    <a onClick={ (e)=> handleLinkClick(e, currentPage-1)} className="page-link prev" href="#a">
                    Previous
                    </a>
                </li>
            )
        }
        
        if(link.label==="Next &raquo;") {
            return(
                <li key={link.label} className={`page-item ${isNullLink}`}>
                    <a onClick={ (e)=>handleLinkClick(e, currentPage+1) } className="page-link next" href="#n" aria-label="Next">
                    Next
                    </a>
                </li>
            )
        }
        
        const isActive = link.active ? "active" : ""

        return(
            <li key={link.label} className={`page-item ${isActive}`}>
                <a className="page-link" onClick={ (e) => handleLinkClick(e, parseInt(link.label,10)) } href="#a">{link.label}</a>
            </li>
        )
    }

    /*useEffect(()=>{
        if( query["investments"]!=undefined ){
            const obj = {...filters}
            obj["req_fund_investor"] = 1
            setFilters(obj)
        }
    
        if( query["collaboration"]!=undefined ){
            const obj = {...filters}
            obj["req_collaborate"] = 1
            setFilters(obj)
        }

        setCurrentPage(1)
        getData()

    }, [currentPage])*/

  useEffect(()=>{
    setCurrentPage(1)
    getData()

  }, [currentPage, filters])

  const [showNDA, setShowNDA] = useState(false)
  const [currentData, setCurrentData] = useState({})

  const [isNDALoading, setIsNDALoading] = useState(false)

  const submitNDA = () =>{

    setIsNDALoading(true)

    var formdata = new FormData()
    formdata.append("slug", currentData.slug);

    axios.post(API_URL + API_SIGN_NDA, formdata,{
        headers:authHeader()
    })
    .then((responsex) => {

        setIsNDALoading(false)

        if(responsex.data.status=="RC200"){

            toast.success(responsex.data.message)

            setShowNDA(false)

            navigate(`/ideas/view/${currentData.slug}`)

        }

        return responsex.data;
    }).error(()=>{
        setIsNDALoading(false)
    });

  }

  const popupHowItWork = useRef()

  return (
    <section>
        <div className="mt-4">
            <div className="container">
                
            <div className="row" id="page-contents">
                    <div className="col-md-3">
                        <aside className="sidebar stick-widget">
                            <div className="widget">
                                <h4 className="widget-title">Filter by Categories</h4>
        
                                <TreeView endpoint={API_FIELD_TREE} value={filters.fields} onCheckChange={(checked)=>{

                                    const obj = {...filters}
                                    obj["fields"] = checked
                                    setFilters(obj)

                                }} />

                            </div>

                            <div className="widget">
                                <h4 className="widget-title">Filter Options</h4>
                                <ul className="mb0">
                                    <li>
                                        <label>
                                            <input onChange={(e)=> updateFilter(e, "req_idea_with_jobs")} type="checkbox" /> Ideas with job opportunities
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input onChange={(e)=> updateFilter(e, "req_idea_sell")} type="checkbox" /> Ideas on Sale
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input defaultChecked={filters.req_collaborate==1} onChange={(e)=> updateFilter(e, "req_collaborate")} type="checkbox" /> Ideas requiring Collaborator(s)
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input defaultChecked={filters.req_fund_investor==1} onChange={(e)=> updateFilter(e, "req_fund_investor")} type="checkbox" /> Ideas requiring Investment
                                        </label>
                                    </li>
                                </ul>
                            </div>

                            {/* <div className="widget">
                                <h4 className="widget-title">How it Works</h4>
                                <div style={{cursor:"pointer"}} onClick={(e)=>{
                                    popupHowItWork.current.open()
                                }} className="text-center">
                                    <img src={require("../../../assets/images/how-it-works.jpg")} />
                                </div>

                                <TransPopup
                                    ref={popupHowItWork}
                                    label={""}
                                    hideButton={true}
                                    className="pull-right"
                                    title=""
                                    dClassName="modal-lg modal-transp"
                                    btnCancel={false}
                                    btnCancelLabel="Close"
                                    onSaveClickListener={(e)=>{
                                        popupHowItWork.current.close()
                                    }}
                                >
                                    <HowItWorks hide={(e)=>{
                                        popupHowItWork.current.close()
                                    }} />
                                </TransPopup>

                            </div> */}
                        </aside>
                    </div>

                        <LoaderWrapperView isLoading={isLoading}>
                            <div className="col-md-9">
                                <div className="row">
                                    
                                    {list && list.length==0 ? (
                                        <p>Idea not found</p>
                                    ) : list.map((idea, i) => (
                                        <SingleIdea checkNDA={(e)=>{

                                            e.preventDefault()

                                            if(userData == undefined){
                                                navigate(`/access-premium-feature`);
                                                return;
                                            }

                                            if(idea.opt_in_for_public==0 && userData.is_premium_user == 0){
                                                navigate(`/access-premium-feature`);
                                                return;
                                            }

                                            if(idea.is_nda_done==1)
                                                navigate(`/ideas/view/${idea.slug}`)
                                            else{
                                                setCurrentData(idea)
                                                setShowNDA(true)
                                            }

                                        }} key={idea.id} data={idea} />
                                    ))}

                                </div>

                                { links.length>3 && (
                                    <div className="col-md-12">
                                        <nav className="mt-4 mb-3">
                                            <ul className="pagination justify-content-center mb-0">

                                                { links.map((link)=>(

                                                    printLinks(link)
                                                    
                                                ))}
                                                
                                            </ul>
                                        </nav>
                                    </div>
                                )}

                            </div>
                        </LoaderWrapperView>
                    </div>

                    <Modal noMargin dialogClassName={"full-modal"} show={showNDA} aria-labelledby="contained-modal-title-vcenter" onHide={()=>setShowNDA(false)}>  
                    
                        <Modal.Body>
                            
                            <NDAText secondParty={currentData ? currentData.full_name : ""} secondPartyRole={currentData ? currentData.role_name : ""} ideaTitle={currentData ? currentData.idea_title : ""} />

                            <br /><br />


                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <ExtButton isLoading={isNDALoading} showLoading={false} type="button" className="btn btn-link mr-50" onClick={()=>setShowNDA(false)}><span>Cancel</span></ExtButton>

                                    <ExtButton isLoading={isNDALoading} showLoading={true} type="button" className="mtr-btn" onClick={(e)=>{
                                        submitNDA()
                                    }}><span>I understand and Agree</span></ExtButton>
                                </div>
                            </div>


                        </Modal.Body>

                    </Modal>

            </div>
        </div>
    </section>
  );
};

const SingleIdea = (props) => {

  const {data} = props

  return(
    <div className="col-md-4">
        
        <div className="g-post-classic idea-min-height">
            <figure>
                <a onClick={props.checkNDA} >
                    <img className="img-max-250" alt={data.idea_title} src={data.idea_image} />
                </a>
            </figure>
            <div className="g-post-meta">
                <div className="post-title">
                    <h4 style={{maxHeight:"50px"}} className="text-max-2lines">
                        <a className="sentence-case" href="" onClick={props.checkNDA}>{data.idea_title}</a>
                    </h4>
                    <p className="text-max-3lines">
                        {data.introduction}
                    </p>
                    <span className="p-date">
                        <Link className="text-max-1lines" to={`/./${data.public_url}`}>by {data.full_name} {data.org_id!=-1 && (` (${data.organization_name})`)}</Link> {data.date}
                    </span>
                </div>
                <div className="g-post-ranking">
                    <a title="" href="#" className="likes">{data.eng_field}</a>
                </div>
            </div>
        </div>
    </div>
  )

};

export default IdeaList;
