import axios from "axios";
import { useEffect, useRef, useState } from "react";
import Select2 from '../../../libs/Select2'
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { API_DELETE_PROBLEM, API_DUPLICATE_PROBLEM, API_GET_DISCIPLINE_BY_ID, API_GET_ENGINEERING_FIELDS, API_GET_PROBLEM, API_PROBLEM_DEAL_COMPLETED, API_PROBLEM_OFFER_ACCEPTED_USERS, API_UPDATE_PROBLEM, API_URL } from "../../../../services/ApiEndpoints";
import authHeader from "../../../../services/auth-header";
import ExtButton from "../../../libs/ExtButton";
import ExToolTip from "../../../libs/ExToolTip";
import Popups from "../../../libs/Popups";
import { toast } from "react-toastify";
import LoaderWrapperView from '../../../libs/LoaderWrapperView'
import WordLimiter from "../../../libs/WordLimiter";
import { DatePicker, df, mode } from "../../../../helpers/DatePicker";
import ImageCropperPopup from "../../../libs/image/ImageCropperPopup";

const EditProblem = (props) => {

    let {slug} = useParams();

    const select2PD = useRef(null)
    const select2PSD = useRef(null)

    const [listEngineeringField, setListEngineeringField] = useState([])
    const [listDiscipline, setListDiscipline] = useState([])
    const [listIPD, setListIPD] = useState([])
    
    const [isLoadingEngField, setIsLoadingEngField] = useState(true)
    const [isLoading, setIsLoading] = useState(false)

    const getEngFields = () =>{

        setIsLoadingEngField(true)
        setIsLoading(true)

        axios
        .get(API_URL + API_GET_ENGINEERING_FIELDS,{
            headers: authHeader()
        })
        .then((response)=>{
            
            setIsLoadingEngField(false)

            if(response.status==200){

                const json = response.data;

                setListEngineeringField(json.data.engineering_field)

            }

            getDetails()

        })
        .catch((error)=>{
            getDetails()
        });
    }

    const getDeciplinesById = (targetOne, targetTwo, id, e) =>{

        axios
         .get(API_URL + API_GET_DISCIPLINE_BY_ID +"/"+ id,{
             headers: authHeader()
         })
         .then((response)=>{
             
             if(response.status==200){
 
                 const json = response.data.data;
 
                 if(targetOne!=null)
                     targetOne.current.updateSelect(json)
                 
                 if(targetTwo!=null)
                     targetTwo.current.updateSelect([])
                 
             }
 
         })
         .catch((error)=>{
 
         });
 
     }
    
    // states for the fields start

    const [painpoint, setPainpoint] = useState("")

    const [engField, setEngField] = useState(-1)
    const [discipline, setDiscipline] = useState(-1)
    
    const [description, setDescription] = useState("")
    
    const [image, setImage] = useState(null)
    const [imageSrc, setImageSrc] = useState("")

    const [intellectualPropertyDocuments, setIntellectualPropertyDocuments] = useState([null])

    const [isPublished, setIsPublished] = useState(-1)
    const [isDealCompleted, setIsDealCompleted] = useState(-1)
    const [anyOfferAccepted, setAnyOfferAccepted] = useState(-1)
    const [id, setId] = useState(-1)

    const [proposalDate, setProposalDate] = useState("")
    const [proposalDueDate, setProposalDueDate] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [location, setLocation] = useState("")
    const [offeredPrice, setOfferedPrice] = useState("")
    const [physicalPresenceNeeded, setPhysicalPresenceNeeded] = useState(-1)

    // states for the fields end

    const getDetails = () =>{

        setIsLoading(true)

        axios
        .get(API_URL + API_GET_PROBLEM +"/"+ slug,{
            headers: authHeader()
        })
        .then((response)=>{
            
            if(response.status==200){

                const json = response.data;

                if(json.status=="RC200"){

                    var data = json.data;

                    setPainpoint(data.pain_point)
                    setId(data.id)
                    setIsPublish(data.is_published)
                    setIsDealCompleted(data.is_deal_completed)
                    setAnyOfferAccepted(data.has_any_offer_accepted)
                    
                    setEngField(data.field)
                    setDiscipline(data.discipline)
                    
                    setDescription(data.description)

                    setCompanyName(data.company_name)
                    setLocation(data.location)
                    setOfferedPrice(data.offered_price)
                    setProposalDate(data.proposal_date_deadline)
                    setProposalDueDate(data.proposal_date_due_deadline)
                    setPhysicalPresenceNeeded(data.require_physical_presence)

                    setImageSrc(data.image)

                    setListDiscipline(data.selected_disciplines)
                    setListIPD(data.upload_document)

                    setIsPublished(data.is_published)

                }

            }

            setIsLoading(false)

        })
        .catch((error)=>{
            setIsLoading(false)
        });

    }

    useEffect(()=>{
        getEngFields()
    }, [])

    const [isActuallyPublish, setIsActuallyPublish] = useState(false)
    const [isPublish, setIsPublish] = useState(false)
    const [clicked, setClicked] = useState(0)
    
    const [isUpdateLoading, setIsUpdateLoading] = useState(false)
    const [hasError, setHasError] = useState(false) 
    const [msg, setMsg] = useState("")
    const [allowReload, setAllowReload] = useOutletContext();

    const handleSubmit = (e) =>{
        e.preventDefault()
        handleSubmitPublish(false)
    }

    const handleSubmitPublish = (isPublishProblem) =>{
        
        setIsUpdateLoading(true)

        const formdata = new FormData()
        formdata.append("id", id);
        formdata.append("pain_point", painpoint);
        formdata.append("field", engField);
        formdata.append("discipline", discipline);

        if(image!==null)
            formdata.append("image", image)

        intellectualPropertyDocuments.length>0 && intellectualPropertyDocuments.map((val, index)=>{
            if(val!==null)
                formdata.append("upload_document["+ index +"]", val)
        })

        formdata.append("proposal_date_deadline", proposalDate);
        formdata.append("proposal_date_due_deadline", proposalDueDate);
        formdata.append("description", description);

        formdata.append("company_name", companyName);
        formdata.append("location", location);
        formdata.append("offered_price", offeredPrice);
        formdata.append("require_physical_presence", physicalPresenceNeeded);

        if(isPublished){
            formdata.append("is_published", 1)
        }else{
            formdata.append("is_published", isPublishProblem? 1 : 0)
        }
            

        axios.post(API_URL + API_UPDATE_PROBLEM, formdata,{
            headers:authHeader()
        })
        .then((responsex) => {

            setIsUpdateLoading(false)

            if(responsex.data.status=="RC200"){
                setHasError(false)

                setAllowReload(allowReload+1)

                toast.success(responsex.data.message)
                    
            }else{
                setHasError(true)
                toast.error(responsex.data.message)
            }

            return responsex.data;
        });

    }

    const [isLoadingDelete, setIsLoadingDelete] = useState(false)
    const [isLoadingDuplicate, setIsLoadingDuplicate] = useState(false)

    const popupDelete = useRef()
    const navigation = useNavigate()
    
    const deleteProblem = () =>{
        
        setIsLoadingDelete(true)

        const formdata = new FormData()
        formdata.append("id", slug)

        axios.post(API_URL + API_DELETE_PROBLEM, formdata,{
            headers:authHeader()
        })
        .then((responsex) => {

            setIsLoadingDelete(false)

            if(responsex.data.status=="RC200"){
                toast.success(responsex.data.message)
                navigation("/problemspace/my-problems")
            }else{
                setIsLoadingDelete(true)
                toast.error(responsex.data.message)
            }

            return responsex.data;
        });

    }
    
    const popupDuplicate = useRef()

    const duplicateProblem = () =>{
        
        setIsLoadingDuplicate(true)

        const formdata = new FormData()
        formdata.append("id", slug)

        axios.post(API_URL + API_DUPLICATE_PROBLEM, formdata,{
            headers:authHeader()
        })
        .then((responsex) => {

            setIsLoadingDuplicate(false)

            if(responsex.data.status=="RC200"){
                toast.success(responsex.data.message)
                navigation("/problemspace/my-problems")
            }else{
                setIsLoadingDuplicate(true)
                toast.error(responsex.data.message)
            }

            return responsex.data;
        });

    }

    const popupPublishProblem = useRef()

    const form = useRef()

    
    const popupDealCompleted = useRef()
    const [isLoadingDealComplete, setIsLoadingDealComplete] = useState(false)
    const [closeFrom, setCloseFrom] = useState(-1)
    const [dealCompletedComment, setDealCompletedComment] = useState("")

    const markAsDealCompleted = () =>{
        
        if(closeFrom==-1){
            alert("Where you closed the deal?")
            return
        }

        popupDealCompleted.current.close()

        setIsLoadingDealComplete(true)

        const formdata = new FormData()
        formdata.append("problem_id", id)
        formdata.append("deal_close_from", closeFrom)
        formdata.append("comments",dealCompletedComment)

        axios.post(API_URL + API_PROBLEM_DEAL_COMPLETED, formdata,{
            headers:authHeader()
        })
        .then((responsex) => {

            setIsLoadingDealComplete(false)

            if(responsex.data.status=="RC200"){
                toast.success(responsex.data.message)
                setIsDealCompleted(1)
            }else{
                setIsLoadingDealComplete(false)
                toast.error(responsex.data.message)
            }

            return responsex.data;
        });

    }

    const [isLoadingULI, setIsLoadingULI] = useState(false)
    const [userListforProblemClosed, setUserListforProblemClosed] = useState([])

    const getUserListForProblemClose = () =>{

        setIsLoadingULI(true)

        axios
        .get( `${API_URL}${API_PROBLEM_OFFER_ACCEPTED_USERS}?problem_id=${id}`,{
            params:{},
            headers: authHeader()
        })
        .then((response)=>{

            if(response.status==200){
    
                const json = response.data;
    
                if(json.status=="RC200"){
                    setUserListforProblemClosed(json.data)
                }

                setIsLoadingULI(false)
    
            }

        });
            
            

    }

  return (
                
    <>
        {isLoading ? (
            <p>Loading...</p>
        ) : (
            
            isPublished==1 ? (
                <>
                    <strong>You can not edit an Business Problem once it is published. You can only take the following actions based on the Business Problem Stage.</strong>
                    <p> For eg. if you are in talks with other SCIONS you can still <strong style={{"color":"#f05a24"}}>Delete</strong> and <strong style={{"color":"#f05a24"}}>Duplicate</strong> your problem to create a new one. However, if you have accepted an offer from a SCIONS, you can only use <strong style={{"color":"#f05a24"}}>Complete Deal and Close Business Problem</strong></p>
                    <br />

                    {anyOfferAccepted==0 && isDealCompleted==0 ? (
                        <div style={{display:"inline-block"}}>
                            <Popups
                                ref={popupDelete}
                                label="Delete Problem"
                                title="Delete Problem"
                                btnCancel={true}
                                btnSave={true}
                                onSaveClickListener={(e)=>{
                                    popupDelete.current.close()
                                    deleteProblem()
                                }}
                                className="btn btn-sm btn-success"
                                dClassName="modal-lg"
                            >
                                <p>Are you sure, do you want to delete Problem?</p>
                            </Popups>

                            <Popups
                                ref={popupDuplicate}
                                label="Duplicate Problem"
                                title="Duplicate Problem"
                                btnCancel={true}
                                btnSave={true}
                                onSaveClickListener={(e)=>{
                                    popupDuplicate.current.close()
                                    duplicateProblem()
                                }}
                                className="btn btn-sm btn-success"
                                dClassName="modal-lg"
                            >
                                <p>Are you sure, do you want to delete current problem and create new problem with current problem details?</p>
                            </Popups>
                        </div>
                    ) : ""}

                    {isDealCompleted==0 && (
                        <Popups
                            ref={popupDealCompleted}
                            label="Complete Deal &amp; Close Problem"
                            title="Complete Deal &amp; Close Problem"
                            btnCancel={true}
                            btnSave={true}
                            onSaveClickListener={(e)=>{
                                markAsDealCompleted()
                            }}
                            onButtonClick={(e)=>{
                                getUserListForProblemClose()
                            }}
                            className="btn btn-sm btn-success"
                            dClassName="modal-lg"
                        >
                            <LoaderWrapperView isLoading={isLoadingULI} >
                                <div className="row">
                                    <div className="col-md-12">
                                        Where you closed the deal?
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-radio">
                                            <div className="radio">
                                                <label><input value="0" type="radio" defaultChecked={closeFrom==0} onChange={ (e) => {
                                                    setCloseFrom(parseInt(e.target.value))
                                                } } name="gender" /><i className="check-box"></i>In SCIONS</label>
                                            </div>
                                            <div className="radio">
                                                <label><input value="1" type="radio" defaultChecked={closeFrom==1} onChange={ (e) => {
                                                    setCloseFrom(parseInt(e.target.value))
                                                } } name="gender" /><i className="check-box"></i>Outside SCIONS</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12">

                                        {closeFrom==0 && (
                                            <ul>
                                                {userListforProblemClosed.map((usr)=>(
                                                    <li>{usr.full_name}</li>
                                                ))}
                                            </ul>
                                        )}

                                        {closeFrom==1 && (
                                            <div className="form-group">
                                                <label for="input">Comments (Optional) </label>
                                                <textarea value={dealCompletedComment || ""} onChange={(e)=> setDealCompletedComment(e.target.value)} rows="5" id="textarea"  required="required"></textarea>
                                                <i className="mtrl-select"></i>
                                            </div>
                                        )}

                                    </div>
                                    
                                </div>
                            </LoaderWrapperView>
                            
                        </Popups>
                    )}
                    
                </>
            ) : (
                <form ref={form} onSubmit={handleSubmit} method="post">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="central-meta">
                                
                                    <div className="row">
                                    
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input type="text" value={painpoint} onChange={(e)=> setPainpoint(e.target.value)} required="required" />
                                                <label className="control-label">Pain Point *</label>
                                                <i className="mtrl-select"></i>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row">

                                        <div className="col-md-4">
                                            <div className="form-group ">

                                                {isLoadingEngField ? (
                                                    <p>loading...</p>
                                                ) : (
                                                    <Select2 value={engField} onSelect={
                                                        (e) => {
                                                            setEngField(e.target.value)
                                                            getDeciplinesById(select2PD, select2PSD, e.target.value,e)
                                                        }
                                                    } required={true} keys={"field_name"} directValues={false} placeholder={"Select your problem field"} data={listEngineeringField} />
                                                )}

                                                    
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group">
                                            <Select2 ref={select2PD} value={discipline} onSelect={ (e) => {
                                                setDiscipline(e.target.value)
                                            } } name="discipline" keys={"field_name"} data={listDiscipline} directValues={false} placeholder={"Which one best describe your problem discipline"} />
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <WordLimiter rows="4" maxWords="500" value={description || ""} onChange={(val)=>{
                                                setDescription(val)
                                            }} label="Description" />
                                            <br /><br />
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <input type="text" value={companyName} onChange={(e)=> setCompanyName(e.target.value)} required="required" />
                                                <label className="control-label">Company Name *</label>
                                                <i className="mtrl-select"></i>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <input type="text" value={location} onChange={(e)=> setLocation(e.target.value)} required="required" />
                                                <label className="control-label">Location *</label>
                                                <i className="mtrl-select"></i>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <input type="text" value={offeredPrice} onChange={(e)=> setOfferedPrice(e.target.value)} required="required" />
                                                <label className="control-label">Offer Price *</label>
                                                <i className="mtrl-select"></i>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <DatePicker format={df.DMY} mode={mode.day} onChange={ (e, date)=>{
                                                setProposalDate(date)
                                            }} value={proposalDate} required={true}>
                                                Proposal deadine date *
                                            </DatePicker><br />
                                        </div>

                                        <div className="col-md-4">
                                            <DatePicker format={df.DMY} mode={mode.day} onChange={ (e, date)=>{
                                                setProposalDueDate(date)
                                            }} value={proposalDueDate} required={true}>
                                                Proposal deadine due date *
                                            </DatePicker><br />
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-radio">
                                                Is physical presence needed? *&nbsp;&nbsp;&nbsp;
                                                <div className="radio">
                                                    <label>
                                                        <input type="radio" onChange={(e)=> setPhysicalPresenceNeeded(1)} value="1" checked={physicalPresenceNeeded===1} name="radio" />
                                                        <i className="check-box"></i>YES
                                                    </label>
                                                </div>
                                                <div className="radio">
                                                    <label>
                                                        <input type="radio" onChange={(e)=> setPhysicalPresenceNeeded(0)} checked={physicalPresenceNeeded===0} value="0" name="radio" />
                                                        <i className="check-box"></i>NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="col-md-12">
                                            <div className="form-group">Upload a suitable image for your Problem :<br /><small>(Note: Only PNG, JPG and JPEG allowed)</small></div>
                                        </div>

                                        <div className="col-md-4">
                                            <ImageCropperPopup width={250} height={250} title="Select Business Problem Image" className='form-control file-picker-theme' onImageCroped={
                                                (base64, blob) => {
                                                    setImage(blob)
                                                }
                                            } />
                                                {/* <input onClick={ (e) => e.target.value="" } onChange={ (e)=> {
                                                    var files = e.target.files;

                                                    if(files.length==0) return;

                                                    setImage(files[0])

                                                }} name="" className="form-control-file file-picker-theme" type="file" /> */}
                                        </div>

                                        <div className="col-md-2">
                                            <img src={imageSrc} alt="" />
                                        </div>

                                        <div className="col-md-12 mt-5">
                                            <h5>Upload all Problem related documents</h5>
                                            <small>(Note: Only PDF, JPEG, PNG images allowed)</small>
                                        </div>

                                        {/* Intellectual Property start */}

                                    </div>

                                    <div className="row">

                                        <div className="col-md-6">
                                            
                                            { intellectualPropertyDocuments.map((value, index)=>(
                                                <input key={index} onClick={ (e) => e.target.value="" } onChange={ (e)=> {
                                                    var files = e.target.files;

                                                    if(files.length==0) return;

                                                    let ipd = [...intellectualPropertyDocuments]

                                                    ipd[index] = files[0];

                                                    setIntellectualPropertyDocuments(ipd)

                                                }} name="ipd" type="file" className="form-control-file file-picker-theme" />
                                            ))}

                                        </div>

                                        <div className="col-md-1">
                                            <a href="#" onClick={ (e)=>{
                                                e.preventDefault();
                                                setIntellectualPropertyDocuments(intellectualPropertyDocuments.concat(null))

                                            }} data-toggle="tooltip" data-placement="top" title="Add more documents"><i className="fa fa-plus-circle"></i></a>
                                        </div>
                                        <div className="col-md-5">
                                            <p><strong>Documents List</strong></p>
                                            {listIPD.length>0 ? (
                                                listIPD.map((item, index)=>(
                                                    <a key={index} style={{display:"block"}} href={item} target="_blank" >{`View Document ${index+1}`}</a>
                                                ))
                                            ) : (
                                                <p>Documents not uploaded</p>
                                            )}
                                        </div>
                                    </div>

                                    {/* Intellectual Property end */}

                                
                            </div>
                        </div>

                        <div className="col-md-12 text-center">
                            
                            {msg && (
                                <div className={ hasError ? "alert alert-danger mt-10 text-left" : "alert alert-success mt-10 text-left" } role="alert">
                                    {msg}
                                </div>
                            )}

                            {isPublished ? (
                                <ExtButton name="save" onClick={ (e)=> setIsPublish(false) } isLoading={isUpdateLoading} type="submit" className="mtr-btn">
                                    <span>Save Problem</span>
                                </ExtButton>
                            ) : (
                                <>
                                    <ExtButton name="save" onClick={ (e)=> {
                                        setClicked(0)
                                        setIsPublish(false)
                                    } } isLoading={isUpdateLoading} showLoading={clicked==0} type="submit" className="mtr-btn">
                                        <span>Save Problem</span>
                                    </ExtButton> &nbsp; &nbsp; 
                                    
                                    <Popups
                                        ref={popupPublishProblem}
                                        label="Publish your problem"
                                        title="Publish Problem"
                                        btnSaveLabel="Publish your problem"
                                        span={true}
                                        isLoading={isUpdateLoading}
                                        type="button"
                                        btnCancel={true}
                                        btnSave={true}
                                        button={true}
                                        onSaveClickListener={(e)=>{
                                            popupPublishProblem.current.close()
                                            setClicked(1)
                                            setIsPublish(true)
                                            handleSubmitPublish(true)
                                            
                                        }}
                                        className="mtr-btn"
                                        dClassName="modal-lg"
                                    >
                                        <div className="text-center">
                                            <p>Are you completely ready with the problem? <br />Once the problem is published, you can't edit it anymore. You can still cancel and just save the problem in case you have doubts.</p>
                                        </div>
                                    </Popups>

                                </>
                            )}
                            <br /><br />
                        </div>

                    </div>
                </form>
            )

        )}
    </>
  );

};

export default EditProblem;