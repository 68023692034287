import React, { Component, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { API_GET_NOTIFICATIONS, API_URL } from "../../../services/ApiEndpoints";
import axios from "axios";
import authHeader from "../../../services/auth-header";
import LoaderWrapperView from "../../libs/LoaderWrapperView";
import jQuery from "jquery";
import { Link } from "react-router-dom";
import { UPDATE_PROFILE_STATE } from "../../../actions/types";

const NotificationPopup = () =>{

    const dispatch = useDispatch();

    const refNotifications = useRef()
    const {userData} = useSelector(state => state.userData )

    const [isLoading, setIsLoading] = useState(true)
    const [notificationList, setNotificationList] = useState([])

    useEffect(()=>{
        
        jQuery(refNotifications.current).on("click", function(){
            getNotifications();
        })

    }, [])

    const getNotifications = () => {

        /* update notification count start */
        
        userData.notifications_count=0;

        dispatch({
            type: UPDATE_PROFILE_STATE,
            userData: userData
        });
        
        /* update notification count end */

        setIsLoading(true)
        setNotificationList([])

        axios
        .get(API_URL + API_GET_NOTIFICATIONS,{
            headers: authHeader()
        })
        .then((response)=>{
            
            if(response.status==200){

                const json = response.data;

                if(json.status=="RC200"){

                    var data = json.data;

                    setNotificationList(data.notification_list)
                    
                }

                setIsLoading(false)

            }

        })
        .catch((error)=>{

        });

    }

    return(
        <>
           <a href="" ref={refNotifications} title="Notification" data-ripple="">
                <i className="ti-bell"></i>
                
                { userData.notifications_count>0 && (
                    <span>{userData.notifications_count}</span>
                )}
                
            </a>
                <div className="dropdowns">
                    <span>{userData.notifications_count} New Notifications</span>

                    <LoaderWrapperView isLoading={isLoading}>
                                   
                        { notificationList.length>0 ? (
                            <ul className="drops-menu">

                                { notificationList.map((item, index)=>{
                                    
                                    return(
                                        <FriendRequestNotification notification={item} />
                                    )

                                }) }
                                
                            </ul>    
                        ) : (
                            <p> You do not have any notifications</p>
                        ) }

                        <Link to="/notifications" title="" className="more-mesg">view all</Link>

                    </LoaderWrapperView>

                        

                </div> 
        </>
    );

};

const FriendRequestNotification = (props) => {

    const { notification } = props
    
    return(
        <li>

            <Link to={`/notifications`} title="">
                <img src={notification.profile_image} alt="" />
                <div className="mesg-meta">
                    <h6>{notification.full_name}</h6>
                    <span>{notification.message}</span>
                    <i>{notification.date}</i>
                </div>
            </Link>

            { notification.status==0 && (
                <span className="tag blue">Unread</span>
            )}

        </li>
    )


}

export default NotificationPopup;