import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { API_GET_STRIPE_CANCEL_SUBSCRIPTION, API_GET_STRIPE_CUSTOMER_PORTAL, API_GET_SUBSCRIPTION, API_URL } from "../../../services/ApiEndpoints";
import authHeader from "../../../services/auth-header";
import ExtButton from "../../libs/ExtButton";
import LoaderWrapperView from "../../libs/LoaderWrapperView";
import Popups from "../../libs/Popups";

const Subscription = () =>{

    const popup = useRef()

    const [isLoading, setIsLoading] = useState(true)
    const [isPortalLoading, setIsPortalLoading] = useState(false)
    const [isCancelLoading, setIsCancelLoading] = useState(false)
    const [data, setData] = useState({})

    const navigate = useNavigate()

    const getData = () => {

        setIsLoading(true)

        axios
        .get(API_URL + API_GET_SUBSCRIPTION,{
            headers: authHeader()
        })
        .then((response)=>{
            
            if(response.status==200){

                const json = response.data;

                if(json.status=="RC200"){

                    setData(json.data)
                }

            }
            
            setIsLoading(false)

        })
        .catch((error)=>{

            setIsLoading(false)

        });
    }

    const getPortalUrl = () => {

        setIsPortalLoading(true)

        axios
        .get(API_URL + API_GET_STRIPE_CUSTOMER_PORTAL,{
            headers: authHeader()
        })
        .then((response)=>{
            
            if(response.status==200){

                const json = response.data;

                if(json.status=="RC200"){
                    window.location.href= json.data
                }

            }
            
            setIsPortalLoading(false)

        })
        .catch((error)=>{

            setIsPortalLoading(false)

        });
    }

    const cancelSubscription = () => {

        setIsCancelLoading(true)

        axios
        .get(API_URL + API_GET_STRIPE_CANCEL_SUBSCRIPTION,{
            headers: authHeader()
        })
        .then((response)=>{
            
            if(response.status==200){

                const json = response.data;

                if(json.status=="RC200"){
                    
                    getData()
                    toast.success(json.message)
                }else{
                    toast.error(json.message)
                }

            }
            
            setIsCancelLoading(false)

        })
        .catch((error)=>{

            setIsCancelLoading(false)

        });
    }

    useEffect(()=>{
        getData()
    }, [])

    return(
        <section className="mb-5 mt-4">
            <div className="gap gray-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="mb-3">Package</h2>

                            <LoaderWrapperView isLoading={isLoading}>
                                <div>
                                    {data.status==1 ?  (
                                        <>
                                            <div className="central-meta">
                                                <div className="row">
                                                    <div className="col-md-8 mb-md-0 mb-5">
                                                        <strong>Current Plan: </strong> {data.current_plan}<br />

                                                        { !(data.subscription_type==1 || data.subscription_type==2) && (
                                                            <>
                                                                <strong>Organization: </strong>
                                                                {data.org_id==-1 ? (
                                                                    <Link to={`/organizations/${data.org_id}/view`}>Click here to create</Link>
                                                                ) : (
                                                                    <Link to={`/organizations/${data.org_id}/view`}>{data.org_name}</Link>
                                                                )}
                                                                <br />
                                                            </>
                                                        )}

                                                        <strong>Status: </strong> {data.status==1 ? (<span className="badge badge-success">Active</span>) : (<span className="badge badge-danger">Cancelled</span>)}<br />
                                                        <strong>Premium Expiry Date: {data.next_billing_date}</strong><br />

                                                        <br />
                                                        
                                                        <Link type="link" to="/upgrade" className="mtr-btn">
                                                            <span>Buy SCIONScoin(s)</span>
                                                        </Link>
                                                    </div>
                                                    <div className="col-md-4 text-center">
                                                        My SCIONScoin(s)
                                                        <h1 className="text-primary">{data.coins}</h1>
                                                    </div>
                                                </div>

                                                {/* <Popups
                                                    key={`ip`}
                                                    ref={popup}
                                                    label={[" Cancel Subscription"]}
                                                    title="Cancel Subscription"
                                                    dClassName="modal-lg"
                                                    span={true}
                                                    button={true}
                                                    className="mtr-btn"
                                                    onOpen={(e)=>{
                                                        
                                                    }}
                                                >
                                                    <h4 className="text-danger text-center">Are you really sure you want to cancel your subscription?</h4><br />

                                                    <ol>
                                                        <li>You will not be able to access all paid features after {data.next_billing_date}</li>
                                                        <li>This will cancel your subscription now and you won't be charged on next billing date</li>
                                                        <li>No refunds</li>
                                                    </ol>

                                                    <ExtButton onClick={cancelSubscription} isLoading={isCancelLoading} className="mtr-btn" type="button">
                                                        <span>Yes, Cancel Subscription</span>
                                                    </ExtButton>
                                                </Popups> */}

                                                {/* {data.is_manual==0 && (
                                                    <>
                                                        &nbsp; 
                                                        <ExtButton isLoading={isPortalLoading} onClick={getPortalUrl} className="mtr-btn" type="button">
                                                            <span>Update Card Details</span>
                                                        </ExtButton>
                                                    </>
                                                )} */}
                                            
                                            </div>

                                            <div className="central-meta">
                                                <h4 class="f-title">SCIONScoins History</h4>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="table-responsive">
                                                            <table className="table table-bordered">
                                                                <tr>
                                                                    <th width="15%">Date</th>
                                                                    <th width="10%">No. of SCIONScoin(s)</th>
                                                                    <th width="10%">Credit/ Debit</th>
                                                                    <th width="65%">Description</th>
                                                                </tr>

                                                                {data.coins_history && data.coins_history.map((item, index)=>(
                                                                    <tr key={`coinhr-${index}`}>
                                                                        <td>{item.date}</td>
                                                                        <td>{item.coin}</td>
                                                                        <td>{item.is_debit==1 ? "Credit" : "Debit"}</td>
                                                                        <td>{item.description}</td>
                                                                    </tr>
                                                                ))}
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </>
                                    ): (
                                        <>
                                            <h4>You do not have any premium plan.</h4>
                                            {/* <p>You will still get all premium feature till <strong>{data.next_billing_date}</strong> but you won't be charged by us.</p> */}
                                            
                                            
                                            <br />
                                            <div className="">
                                                <strong>What’s included in Premium access?</strong>
                                                <ul>
                                                    <li>Utilize SCIONS AI hub to identify the ideal investor, company, and experts.</li>
                                                    <li>Social Networking with all SCIONS</li>
                                                    <li>Chat with fellow SCIONS</li>
                                                    <li>Full access to idea marketplace</li>
                                                    <li>Access to investment opportunities</li>
                                                    <li>Access to collaboration opportunities</li>
                                                    <li>Create sub-admins for task sharing (Organizations users only)</li>
                                                    <li>Invite your staff/ student and make them as paid users (Organizations users only)</li>
                                                </ul>
                                            </div>

                                            <br />
                                            <Link type="link" to="/upgrade" className="mtr-btn">
                                                <span>Buy Coins</span>
                                            </Link>

                                        </>
                                    )}
                                </div>
                            </LoaderWrapperView>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default Subscription;