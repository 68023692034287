import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { API_CREATE_IDEA, API_CREATE_PROBLEM, API_GET_DISCIPLINE_BY_ID, API_GET_ENGINEERING_FIELDS, API_URL } from "../../../services/ApiEndpoints";
import authHeader from "../../../services/auth-header";
import ExtButton from "../../libs/ExtButton";
import Select2 from '../../libs/Select2'
import { Button, Modal } from 'react-bootstrap';
import NDAText from "../ideas/NDAText";
import WordLimiter from "../../libs/WordLimiter";
import { DatePicker, df, mode } from "../../../helpers/DatePicker";
import ImageCropperPopup from "../../libs/image/ImageCropperPopup";

const CreateProblem = (props) => {

    const select2PD = useRef()
    const select2PSD = useRef()

    // Predefined values or from first time api calls
    const [listPatientedWith] = useState([
        {isChecked:false, name:"EU Body", id:0},
        {isChecked:false, name:"USA Body", id:1},
        {isChecked:false, name:"Asian Organization", id:2}
    ])

    const [listEngineeringField, setListEngineeringField] = useState([])
    
    const [isLoadingEngField, setIsLoadingEngField] = useState(true)

    const getEngFields = () =>{

        setIsLoadingEngField(true)

        axios
        .get(API_URL + API_GET_ENGINEERING_FIELDS,{
            headers: authHeader()
        })
        .then((response)=>{
            
            setIsLoadingEngField(false)

            if(response.status==200){

                const json = response.data;

                setListEngineeringField(json.data.engineering_field)

            }

        })
        .catch((error)=>{

        });
    }

    const getDeciplinesById = (targetOne, targetTwo, id, e) =>{

        axios
         .get(API_URL + API_GET_DISCIPLINE_BY_ID +"/"+ id,{
             headers: authHeader()
         })
         .then((response)=>{
             
             if(response.status==200){
 
                 const json = response.data.data;
 
                 if(targetOne!=null)
                     targetOne.current.updateSelect(json)
                 
                 if(targetTwo!=null)
                     targetTwo.current.updateSelect([])
                 
             }
 
         })
         .catch((error)=>{
 
         });
 
     }

    useEffect(()=>{
        getEngFields();
    }, [])

    // states for the fields start

    const [painpoint, setPainpoint] = useState("")

    const [engField, setEngField] = useState(-1)
    const [discipline, setDiscipline] = useState(-1)
    
    const [description, setDescription] = useState("")
    
    const [image, setImage] = useState(null)

    const [proposalDate, setProposalDate] = useState("")
    const [proposalDueDate, setProposalDueDate] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [location, setLocation] = useState("")
    const [offeredPrice, setOfferedPrice] = useState("")
    const [physicalPresenceNeeded, setPhysicalPresenceNeeded] = useState(-1)

    const [intellectualPropertyDocuments, setIntellectualPropertyDocuments] = useState([null])

    const [acceptNDA, setAcceptNDA] = useState()

    // states for the fields end
    
    const [isLoading, setIsLoading] = useState(false)
    const [hasError, setHasError] = useState(false) 
    const [msg, setMsg] = useState("")

    const navigation = useNavigate()

    const handleSubmit = (e) =>{
        e.preventDefault()

        setIsLoading(true)

        var formdata = new FormData()
        formdata.append("pain_point", painpoint);
        formdata.append("field", engField);
        formdata.append("discipline", discipline);
        formdata.append("accept_nda", acceptNDA ? 1 : 0);

        if(image!==null)
            formdata.append("image", image)

        intellectualPropertyDocuments.length>0 && intellectualPropertyDocuments.map((val, index)=>{
            if(val!==null)
                formdata.append("upload_document["+ index +"]", val)
        })

        formdata.append("proposal_date_deadline", proposalDate);
        formdata.append("proposal_date_due_deadline", proposalDueDate);
        formdata.append("description", description);

        formdata.append("company_name", companyName);
        formdata.append("location", location);
        formdata.append("offered_price", offeredPrice);
        formdata.append("require_physical_presence", physicalPresenceNeeded);
        formdata.append("is_published", 0);


        axios.post(API_URL + API_CREATE_PROBLEM, formdata,{
            headers:authHeader()
        })
        .then((responsex) => {

            setIsLoading(false)

            if(responsex.data.status=="RC200"){
                setHasError(false)

                const id = responsex.data.data.id

                toast.success(responsex.data.message)

                navigation(`/problemspace/details/${id}/edit`)


            }else{
                setHasError(true)
            }

            setMsg(responsex.data.message)

            return responsex.data;
        }).error(()=>{
            setIsLoading(false)
        });

    }

    const [showNDA, setShowNDA] = useState(false)

    return (
        <section>
            <div className="gap gray-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="central-meta">
                                
                                <div className="row">
                                    <div className="col-md-9">
                                        <h3>List your business problem on SCIONS</h3>
                                    </div>
                                    <div className="col-md-3">
                                        
                                    </div>
                                </div>

                                <form onSubmit={handleSubmit} method="post">
                                    <div className="row">

                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input type="text" value={painpoint} onChange={(e)=> setPainpoint(e.target.value)} required="required" />
                                                <label className="control-label">Pain Point *</label>
                                                <i className="mtrl-select"></i>
                                            </div>
                                        </div>
                                        
                                    </div>

                                    <div className="row">

                                        <div className="col-md-4">
                                            <div className="form-group ">

                                                {isLoadingEngField ? (
                                                    <p>loading...</p>
                                                ) : (
                                                    <>
                                                        <label>Select your idea field *</label>
                                                        <Select2 value={engField} onSelect={
                                                            (e) => {
                                                                setEngField(e.target.value)
                                                                getDeciplinesById(select2PD, select2PSD, e.target.value,e)
                                                            }
                                                        } required={true} keys={"field_name"} placeholder={"Select"} data={listEngineeringField} />
                                                    </>
                                                )}

                                                    
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Which one best describe your idea discipline *</label>
                                                <Select2 ref={select2PD} value={discipline} onSelect={ (e) => {
                                                    setDiscipline(e.target.value)
                                                    //getDeciplinesById(select2PSD, null, e.target.value,e)
                                                } } name="discipline" keys={"field_name"} placeholder={"Select"} />
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <WordLimiter rows="4" maxWords="500" value={description} onChange={(val)=>{
                                                setDescription(val)
                                            }} label="Description" />
                                            <br />
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <input type="text" value={companyName} onChange={(e)=> setCompanyName(e.target.value)} required="required" />
                                                <label className="control-label">Company Name *</label>
                                                <i className="mtrl-select"></i>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <input type="text" value={location} onChange={(e)=> setLocation(e.target.value)} required="required" />
                                                <label className="control-label">Location *</label>
                                                <i className="mtrl-select"></i>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <input type="text" value={offeredPrice} onChange={(e)=> setOfferedPrice(e.target.value)} required="required" />
                                                <label className="control-label">Offer Price *</label>
                                                <i className="mtrl-select"></i>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <DatePicker format={df.DMY} mode={mode.day} onChange={ (e, date)=>{
                                                setProposalDate(date)
                                            }} value={proposalDate} required={true}>
                                                Proposal deadine date *
                                            </DatePicker><br />
                                        </div>

                                        <div className="col-md-4">
                                            <DatePicker format={df.DMY} mode={mode.day} onChange={ (e, date)=>{
                                                setProposalDueDate(date)
                                            }} value={proposalDueDate} required={true}>
                                                Proposal deadine due date *
                                            </DatePicker><br />
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-radio">
                                                Is physical presence needed? *&nbsp;&nbsp;&nbsp;
                                                <div className="radio">
                                                    <label>
                                                        <input type="radio" onChange={(e)=> setPhysicalPresenceNeeded(1)} value="1" checked={physicalPresenceNeeded===1} name="radio" />
                                                        <i className="check-box"></i>YES
                                                    </label>
                                                </div>
                                                <div className="radio">
                                                    <label>
                                                        <input type="radio" onChange={(e)=> setPhysicalPresenceNeeded(0)} checked={physicalPresenceNeeded===0} value="0" name="radio" />
                                                        <i className="check-box"></i>NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-group">Upload a suitable image for your Problem * <br /><small>(Note: Only PNG, JPG and JPEG allowed)</small></div>
                                        </div>

                                        <div className="col-md-6">
                                            <ImageCropperPopup width={250} height={250} title="Select Business Problem Image" className='form-control file-picker-theme' onImageCroped={
                                                (base64, blob) => {
                                                    setImage(blob)
                                                }
                                            } />
                                                {/* <input onClick={ (e) => e.target.value="" } onChange={ (e)=> {
                                                    var files = e.target.files;

                                                    if(files.length==0) return;

                                                    setImage(files[0])

                                                }} name="" className="form-control-file file-picker-theme" type="file" /> */}
                                        </div>

                                        <div className="col-md-12 mt-5">
                                            <h5>Upload all Problem related documents</h5>
                                            <small>(Note: Only PDF, JPEG, PNG images allowed)</small>
                                        </div>

                                        {/* Documents start */}
                                        <div className="col-md-6">
                                            
                                            { intellectualPropertyDocuments.map((value, index)=>(
                                                <input key={index} onClick={ (e) => e.target.value="" } onChange={ (e)=> {
                                                    var files = e.target.files;

                                                    if(files.length==0) return;

                                                    let ipd = [...intellectualPropertyDocuments]

                                                    ipd[index] = files[0];

                                                    setIntellectualPropertyDocuments(ipd)

                                                }} name="ipd" type="file" className="form-control-file file-picker-theme" />
                                            ))}

                                        </div>

                                        <div className="col-md-6">
                                            <a href="#" onClick={ (e)=>{
                                                    e.preventDefault();
                                                    setIntellectualPropertyDocuments(intellectualPropertyDocuments.concat(null))
                                                }} data-toggle="tooltip" data-placement="top" title="Add more documents"><i className="fa fa-plus-circle"></i></a>
                                        </div>
                                        
                                        {/* Documents Property end */}

                                        <div className="col-md-12"><hr /></div>
                                        
                                        <div className="col-md-12">
                                            <br />
                                            <input defaultChecked={acceptNDA} onChange={(e)=>{
                                                setAcceptNDA(e.target.checked)
                                            }} type="checkbox" value="1" /> I acknowledge that I have read and agreed to the <a className="links" onClick={(e)=>{
                                                e.preventDefault()
                                                setShowNDA(true)
                                            }}>NDA</a>. 

                                            
                                        </div>

                                        {msg && (
                                            <div className="col-md-12"><br />
                                                <div className={ hasError ? "alert alert-danger mt-10" : "alert alert-success mt-10" } role="alert">
                                                    {msg}
                                                </div>
                                            </div>
                                        )}

                                        <div className="col-md-12">
                                            <div className="submit-btns mb-2">
                                                <ExtButton isLoading={isLoading} type="submit" className="mtr-btn">
                                                    <span>Save Problem</span>
                                                </ExtButton>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

            <Modal noMargin dialogClassName={"full-modal"} show={showNDA} aria-labelledby="contained-modal-title-vcenter" onHide={()=>setShowNDA(false)}>  
                <Modal.Body>

                    <NDAText readOnly={true} secondParty="" secondPartyRole="" ideaTitle="" />

                    <br /><br />

                    <div className="row">
                        <div className="col-md-12 text-right">
                            <ExtButton type="button" className="btn btn-link mr-50" onClick={()=>{
                                setShowNDA(false)
                                //setAcceptNDA(false)
                            }}><span>Close</span></ExtButton>

                            {/* <ExtButton type="button" className="mtr-btn" onClick={(e)=>{
                                setShowNDA(false)
                                setAcceptNDA(true)
                            }}><span>I understand and Agree</span></ExtButton> */}
                        </div>
                    </div>

                </Modal.Body>
            </Modal>

        </section>
    );

};

export default CreateProblem;
