import { SET_MESSAGE, CLEAR_MESSAGE } from "./types";

export const setMessage = (message, msgTypes) => ({
    type: SET_MESSAGE,
    payload: message,
    msgType: msgTypes
});

export const clearMessage = () => ({
    type: CLEAR_MESSAGE,
    payload: ""
});
  