import { useSelector } from "react-redux";
import moment from 'moment';

const NDAText =  (props) =>{

    const {user:currentUser} = useSelector((state) => state.auth);

    const currentDate = () =>{
        
        return moment().toString();
        return moment().format('DD-MM-YYYY hh:mm A z')
      }
    
      const currentUserName = () =>{
        return `${currentUser.first_name} ${currentUser.last_name}`
      }

      const currentUserRole = () =>{
        return currentUser.role
      }

    return(
        <div className="sign-nda">
            <h4 className="text-center">MUTUAL NON-DISCLOSURE AGREEMENT</h4><br />

            {props.readOnly ? (
              <>
                <p>This Non-Disclosure and confidentiality agreement (“this agreement”) is entered into as of the _____________ (the "effective date”) by and between:</p>

                <strong>Disclosing party:</strong> _____________ as a(n) _____________<br /><br />
                <p>AND</p>
                <strong>Receiving party:</strong> _____________ as a(n) _____________<br /><br />
              </>
            ) : (
                <>
                  <p>This Non-Disclosure and confidentiality agreement (“this agreement”) is entered into as of the {currentDate()} (the "effective date”) by and between:</p>
                  <br />
                  <strong>Disclosing party:</strong> <u>{currentUserName()}</u> as a(n) <u>{currentUserRole()}</u><br />
                  <p>AND</p>
                  <strong>Receiving party:</strong> <u>{props.secondParty || ""}</u> as a(n) <u>{props.secondPartyRole || ""}</u><br /><br />
                </>
            )}
            

            <p><strong>1 . Purpose</strong></p>

            <p className="para">The parties wish to enter into negotiations about <u>{props.ideaTitle || ""}</u>. In connection with the Purpose, each party may disclose or have already disclosed to the other certain confidential technical and/or business information which the disclosing party desires the receiving party to treat as confidential.</p>

            <p><strong>2. Confidential Information</strong></p>

            <p className="para">"Confidential Information" means any information disclosed in connection with the Purpose by either party to the other party, either directly or indirectly, in writing, orally, or by inspection of tangible objects (including, without limitation, all documents containing any kind of business data and business information, invention descriptions, technical information relating to proprietary ideas and inventions, patentable ideas, trade secrets, drawing and/or illustrations, research and development, financial information, customers, clients, current and/or future business plans and models), regardless of its designation as "Confidential," "Proprietary", or some similar designation. Confidential Information also includes any information disclosed to the disclosing party by third parties and includes the terms of this agreement, including, but not limited to, the fact that any investigations, discussions, or negotiations are taking place concerning the Purpose or the status thereof. Confidential Information shall not, however, include any information which:</p>

            <ul>
                <li>was publicly known and made generally available in the public domain prior to the time of disclosure by the disclosing party;</li>
                <li>becomes publicly known and made generally available after disclosure by the disclosing party to the receiving party through no action or inaction of the receiving party;</li>
                <li>is already in the possession of the receiving party at the time of disclosure by the disclosing party as shown by the receiving party's files and records immediately prior to the time of disclosure;</li>
                <li>is obtained by the receiving party from a third party without a breach of such third party's obligations of confidentiality;</li>
                <li>is independently developed by the receiving party without use of or reference to the disclosing party's Confidential Information, as shown by documents and other competent evidence in the receiving party's possession; or</li>
                <li>is disclosed by the receiving party with prior written approval by the disclosing party.</li>
            </ul>

            <p><strong>3. Representatives and Affiliates</strong></p>

            <p className="para">"Representatives" means: (i) with respect to the receiving party, the receiving party's Affiliates, agents, directors, officers, employees, representatives, consultants and advisers; and (ii) with respect to the disclosing party, the disclosing party's Affiliates, agents, directors, officers, employees, representatives, consultants and advisers and the agents, directors, officers, employees, representatives, consultants and advisers of the disclosing party's Affiliates. The term "Affiliates", with respect to a party, means: (i) any person, directly or indirectly, through one or more intermediaries, controlled by or under common control with such party, and (ii) anybody corporate of which such Party beneficially owns, directly or indirectly, voting securities carrying more than fifty percent (50%) of the voting rights attached to all voting securities of the body corporate for the time being outstanding. Each party shall ensure that its Representatives adhere to the obligations set forth in this Agreement and in case of non-compliance this Agreement may be directly enforced by the disclosing party against such Representatives. For the purposes of this Agreement only, "employees" include individual</p>

            <p><strong>4. Non-use and Non-disclosure</strong></p>

            <p className="para">Each party agrees not to use any Confidential Information of the other party for any reason except to fulfill its obligations in connection with the Purpose. The receiving party agrees not to disclose any Confidential Information of the disclosing party to third parties or to the receiving party's employees, except to those employees or advisors of the receiving party who are required to have such Confidential Information in order to fulfill its obligations in connection with the Purpose and who are obligated to keep such Confidential Information confidential. The receiving party agrees that it will not copy, reproduce or store in an unprotected retrieval system or data base the Confidential Information, except as may be required in connection with the Purpose. The receiving party agrees that it will not (try to) access any systems and/or data outside the scope of the Purpose. The receiving party agrees that it will be responsible for any breach of this Agreement by its employees and advisors. The receiving party shall monitor, enforce and be responsible for, compliance with this provision by permitted persons and the receiving party will be liable and responsible for any breach of this Agreement by itself and/or any of the permitted persons.</p>

            <p><strong>5. Maintenance of Confidentiality.</strong></p>

            <p className="para">Each party agrees that it shall take reasonable measures to protect the secrecy of and avoid disclosure and unauthorized use of the Confidential Information of the other party. Without limiting the foregoing, each party shall take at least those measures that it takes to protect its own confidential information. Neither party shall make any copies of the Confidential Information of the other party unless reasonably necessary for the Purpose or previously approved in writing by the other party. Each party shall reproduce the other party's proprietary rights notices on any such approved copies, in the same manner in which such notices were set forth in or on the original.</p>

            <p><strong>6. Personal Data</strong></p>

            <p className="para">The parties acknowledge that the Confidential Information may contain personal data and that processing of such data may be regulated by applicable law. Parties are aware of and shall observe the rules and regulations with regard to personal data and parties acknowledge their own responsibility to comply with those rules and regulations. Each party acknowledges and agrees that some or all of the personal data it sends to the other party may be identical or substantially similar to data obtained, owned or licensed by such party and nothing in this Agreement shall restrict the receiving party from using the personal data it has in its possession in any manner.</p>

            <p><strong>7. Damages</strong></p>

            <p className="para">The parties acknowledge that the unauthorized use or disclosure of the Confidential Information could cause irreparable harm to disclosing party. Accordingly, the parties  agree that the disclosing party has the right to seek an immediate injunction, without bond or other security, against any breach or threatened breach of this Agreement as well as the right to pursue any and all other rights and remedies available at law or in equity for such breach or threatened breach.</p>

            <p><strong>8. No Obligation</strong></p>

            <p className="para">Nothing herein shall obligate either party to proceed with any negotiation between them, and each party reserves the right, in its sole discretion, to terminate the discussions contemplated by this Agreement concerning the Purpose without any payment or other obligation, except for possible claims arising from this Agreement.</p>

            <p><strong>9. No Warranty</strong></p>

            <p className="para">All confidential information is provided "as is". Each party makes no warranties, express, implied or otherwise, regarding its accuracy, completeness, or performance.</p>

            <p><strong>10. Return of Materials</strong></p>

            <p className="para">All documents and other tangible objects containing or representing Confidential Information which have been disclosed by either party to the other party, and all copies thereof which are in the possession of the other party, shall be and remain the property of the disclosing party and shall be promptly returned to the disclosing party or destroyed by the receiving party upon the disclosing party's written request, except for copies retained for legal or regulatory purposes.</p>

            <p><strong>11. No License</strong></p>

            <p className="para">Nothing in this Agreement is intended to grant any rights to either party under any patent, mask work right, or copyright of the other party, nor shall this Agreement grant any party any rights in or to the Confidential Information of the other party except as expressly set forth herein.</p>

            <p><strong>12. Term</strong></p>

            <p className="para">This Agreement shall commence on the date hereof and shall remain in force for 5 years unless terminated by either Party in accordance with the following sentence. Either Party may terminate this Agreement at any time for any reason by giving to the other Party no less than 14 days written notice. Notwithstanding the provisions of the aforesaid, the obligations in Clause 2, 4, 5 and 6 shall remain in force and binding for a further period of five (5) years following the date of termination or expiration of this Agreement (except that with respect to any trade secrets the obligations shall be perpetual).</p>

            <p><strong>13. Miscellaneous</strong></p>

            <p className="para">Neither party may assign its rights and obligations under this Agreement without the prior written consent of the other party, except in the event of an assignment in the event of a merger or acquisition. It is further understood and agreed that the obligations in this Agreement shall bind and inure to the benefit of the parties hereto and their permitted successors and assigns. This Agreement is governed by, and shall be construed in accordance with, the law of the Netherlands and any dispute arising out of or in connection with this Agreement or further agreements resulting from this Agreement shall be exclusively resolved by and shall be submitted to the competent courts of this jurisdiction. This Agreement shall be the entire agreement between the parties with respect to the subject matter hereof, and neither party shall have any obligation, express or implied by law, with respect to trade secret or proprietary information of the other party except as set forth herein. Any failure to enforce any provision of this Agreement shall not constitute a waiver thereof or of any other provision. This Agreement may not be amended, nor any obligation waived, except by a writing signed by both parties hereto. The parties acknowledge and agree that this Agreement is executed in the English language.</p>

        </div>
    )

}

export default NDAText;