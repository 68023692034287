import React, { Component } from "react";

import InfiniteScroll from 'react-infinite-scroll-component'
import { ApiServices } from "../../../services/ApiServices";
import { API_MY_TIMELINE, API_TIMELINE, API_URL } from "../../../services/ApiEndpoints";
import axios from "axios";
import authHeader from "../../../services/auth-header";
import SinglePost from "../feed/SinglePost";
import CreatePost from "../feed/CreatePost";

class MyFeedList extends Component{

    state = {
        currentPage:1,
        canLoadMore:true,
        posts: []
    }

    componentDidMount(){
        this.getPost();
    }

    getPost = () =>{

        axios
        .get(API_URL + API_MY_TIMELINE,{
            params:{
                page: this.state.currentPage
            },
            headers: authHeader()
        })
        .then((response)=>{
            
            if(response.status==200){

                const json = response.data;

                if(json.status=="RC200"){

                    var resp = json.data;

                    var postList = resp.data;

                    this.setState({
                        canLoadMore: !(this.state.currentPage == resp.last_page),
                        posts: this.state.posts.concat(postList)
                    });

                }

            }

        })
        .catch((error)=>{

        });

    };

    loadFunc = () => {
        
        this.state.currentPage++;

        this.getPost();

    }

    reloadFeed = () =>{

        this.setState({
            posts: [],
            currentPage:1
        });

        this.getPost();

        window.clearOverlay();

    }

    render(){

        return(
            
            <div>

                <CreatePost reloadFeed={this.reloadFeed} />
                
                <div className="loadMore">

                    <InfiniteScroll
                        dataLength={this.state.posts.length}
                        next={this.loadFunc}
                        hasMore={this.state.canLoadMore}
                        endMessage={<div className="text-center">That's all for now</div>}
                        loader={<div className="loader text-center">Loading ...</div>}>
                        
                        {this.state.posts.map((post, i) => <SinglePost onRemoved={(pid)=>{
                            
                            this.setState({
                                posts: this.state.posts.filter((p)=>{
                                    return p.id!=pid
                                })
                            });
                            
                        }} key={"p-"+post.id} post={post} />)}

                    </InfiniteScroll>

                </div>
            </div>
        );
    }
};

export default MyFeedList;