import React, { useRef } from "react";
import {Link, Outlet, Route, Routes} from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import axios from "axios";
import { API_DELETE_ACCOUNT, API_GET_ACCOUNT_SETTINGS, API_UPDATE_ACCOUNT_SETTINGS, API_URL } from "../../../services/ApiEndpoints";
import authHeader from "../../../services/auth-header";
import { useEffect } from "react";
import LoaderWrapperView from "../../libs/LoaderWrapperView";
import { toast } from 'react-toastify';
import ExtButton from '../../libs/ExtButton'
import Popups from "../../libs/Popups";
import { logout } from "../../../actions/auth";
import { LOGIN_FAIL, SET_MESSAGE } from "../../../actions/types";

const AccountSettings = () =>{
    
    const {user:currentUser} = useSelector((state) => state.auth);

    const [isLoading, setIsLoading] = useState(false)

    const [phoneNumberStatus, setPhoneNumberStatus] = useState(0)
    const [emailStatus, setEmailStatus] = useState(0)
    const [locationStatus, setLocationStatus] = useState(0)
    const [allowPublic, setAllowPublic] = useState(0)

    const getDetails = () =>{

        setIsLoading(true)

        axios
        .get(API_URL + API_GET_ACCOUNT_SETTINGS,{
            headers: authHeader()
        })
        .then((response)=>{
            
            setIsLoading(false)

            if(response.status==200){

                const json = response.data;

                if(json.status=="RC200"){

                    var data = json.data;

                    setPhoneNumberStatus(data.setting_phone_show)
                    setEmailStatus(data.setting_email_show)
                    setLocationStatus(data.setting_location_show)
                    setAllowPublic(data.setting_public_view)

                }

            }

        })
        .catch((error)=>{
            setIsLoading(false)
        });
    }

    const updateDetails = (e, type) =>{

        var formdata = new FormData()
        formdata.append("type", type)
        formdata.append("value", e.target.checked ? 1 : 0)

        axios.post(API_URL + API_UPDATE_ACCOUNT_SETTINGS, formdata,{
            headers:authHeader()
        })
        .then((responsex) => {

            const msg = responsex.data.message

            if(responsex.data.status=="RC200"){
                toast.success(msg)
            }else{
                toast.error(msg)
            }

            return responsex.data;
        });

    }

    const deleteAccountPopup = useRef()
    const [isDeleteAccLoading, setIsDeleteAccLoading] = useState(false)

    const dispatch = useDispatch();

    const deleteAccount = () =>{
        
        setIsDeleteAccLoading(true)

        axios
        .get(API_URL + API_DELETE_ACCOUNT,{
            headers: authHeader()
        })
        .then((response)=>{
            
            setIsDeleteAccLoading(false)

            if(response.status==200){
                const json = response.data;

                deleteAccountPopup.current.close()

                if(json.status=="RC200"){
                    dispatch({
                        type: LOGIN_FAIL
                    });
                    dispatch({
                        type: SET_MESSAGE,
                        payload: json.message
                    });

                    dispatch(logout(false));
                }else{
                    toast.error(json.message)
                }
            }

        })
        .catch((error)=>{
            setIsDeleteAccLoading(false)
        });

    }

    useEffect(()=>{
        getDetails()
    }, [])

    return(
        <section>
            <div className="gap2 gray-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                
                                <div className="central-meta" id="account-setting">
                                    <div className="onoff-options">
                                        <h5 className="f-title"><i className="ti-settings"></i>account setting</h5>

                                            <LoaderWrapperView isLoading={isLoading}>
                                                <>
                                                    <div className="setting-row">
                                                        <span>Phone Number Visibility</span>
                                                        <p>Show my phone number to public in profile page</p>
                                                        <input type="checkbox" defaultChecked={phoneNumberStatus==1} onChange={(e)=> {
                                                            setPhoneNumberStatus(e.target.checked ? 1 : 0)
                                                            updateDetails(e, "phone")
                                                        } } id="chk_enable_phone" />
                                                        <label htmlFor="chk_enable_phone" data-on-label="ON" data-off-label="OFF"></label>
                                                    </div>

                                                    <div className="setting-row">
                                                        <span>Email Address Visibility</span>
                                                        <p>Show my email address to public in profile page</p>
                                                        <input type="checkbox" defaultChecked={emailStatus==1} onChange={(e)=> {
                                                            setEmailStatus(e.target.checked ? 1 : 0)
                                                            updateDetails(e, "email")
                                                        } } id="chk_enable_email" />
                                                        <label htmlFor="chk_enable_email" data-on-label="ON" data-off-label="OFF"></label>
                                                    </div>

                                                    <div className="setting-row">
                                                        <span>Address Visibility</span>
                                                        <p>Show my city/country to public in profile page</p>
                                                        <input type="checkbox" defaultChecked={locationStatus==1} onChange={(e)=> {
                                                            setLocationStatus(e.target.checked ? 1 : 0)
                                                            updateDetails(e, "location")
                                                        } } id="chk_enable_loc" />
                                                        <label htmlFor="chk_enable_loc" data-on-label="ON" data-off-label="OFF"></label>
                                                    </div>

                                                    <div className="setting-row">
                                                        <span>Allow Publicly</span>
                                                        <p>Allow my profile to view non registered users</p>
                                                        <input type="checkbox" defaultChecked={allowPublic==1} onChange={(e)=> {
                                                            setAllowPublic(e.target.checked ? 1 : 0)
                                                            updateDetails(e, "public_view")
                                                        } } id="chk_enable_pv" />
                                                        <label htmlFor="chk_enable_pv" data-on-label="ON" data-off-label="OFF"></label>
                                                    </div>

                                                    <Popups
                                                        key={`deleteaccount`}
                                                        ref={deleteAccountPopup}
                                                        label={[" Delete My Account"]}
                                                        title="Delete My Account"
                                                        dClassName="modal-lg"
                                                        span={true}
                                                        isLoading={isDeleteAccLoading}
                                                        button={true}
                                                        className="mtr-btn"
                                                        onOpen={(e)=>{
                                                            
                                                        }}
                                                    >
                                                        <h4 className="text-danger text-center">Are you sure you want to delete your SCIONS account?</h4><br />

                                                        <ol>
                                                            <li>You cannot go back once you press "Yes, Delete My Account" button and will loose your paid benefits (if taken - We do not refund any amount in this case.)</li>
                                                            <li>However, as a responsible portal, we will save all your activities in our database for future.</li>
                                                            <li>If you wish to rejoin SCIONS, please use the same email ID for re-verification of your account and you will again enjoy the same benefits.</li>
                                                        </ol>

                                                        <ExtButton onClick={(e)=>{
                                                            deleteAccount()
                                                        }} isLoading={isDeleteAccLoading} className="mtr-btn" type="button">
                                                            <span>Yes, Delete My Account</span>
                                                        </ExtButton>
                                                    </Popups>

                                                </>
                                            </LoaderWrapperView>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>

    );
};

export default AccountSettings;