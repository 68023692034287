import { Link, NavLink, Outlet, useParams } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component'
import { API_GET_ORG, API_GET_ORG_DETAILS, API_IDEA_LIST, API_URL } from "../../../services/ApiEndpoints";
import axios from "axios";
import authHeader from "../../../services/auth-header";
import { useEffect, useState } from "react";
import { printPhoneNumber } from "../../../helpers/configs";
import ExCarousel from "../../libs/ExCarousel";

const ViewOrganization = () => {

    let {id} = useParams()
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState({})

    const getData = () =>{

        setIsLoading(true)

        axios
            .get(API_URL + API_GET_ORG_DETAILS +"/"+ id,{
                    headers: authHeader()
                }
            )
            .then((response)=>{
                
                if(response.status==200){

                    const json = response.data;

                    if(json.status=="RC200"){

                        var resp = json.data;

                        setData(resp)
                    }

                }

                setIsLoading(false)

            })
            .catch((error)=>{
                setIsLoading(false)
            });

    }

    useEffect(()=>{
        getData()
    }, [])

  return (
    <>
            { isLoading ? (<p>Loading...</p>) : (
                
                <div className="central-meta">
                    <div className="editing-info">
                        <div className="friend-info">
                            <figure>
                                <img className="profile-img" src={data.company_logo} alt="" />
                            </figure>
                            <div className="friend-name">
                                <ins><a title="">{data.company_name}</a></ins>
                                <span>Phone: {printPhoneNumber(data.phone_code, data.phone)}</span>
                                <span>Website: <a href={data.website} target="_blank">{data.website}</a></span>
                            </div>
                            <div className="post-meta">
                                <div className="description">
                                    <h5 className="f-title">About {data.company_name}</h5>
                                    { data.about_company && data.about_company.split("\n").map((str, index) => <p key={`px-${index}`}>{str}</p>)}

                                    {data.analytical_test_facilities && data.analytical_test_facilities.length>0 && (
                                        <div className="main-wraper mt-0">
                                            <h4 className="main-title mb-0 mt-5 text-left">Analytical Test Facilities</h4>
                                            <hr />

                                            <div className="post-content">

                                                <div className="pst-carousel">

                                                    <ExCarousel >
                                                        {data.analytical_test_facilities.map((val)=>(
                                                            <div className="pst-frmt">
                                                                <a onClick={val.document}>
                                                                    <img src={val.document} alt="" />
                                                                </a>
                                                                <div className="pst-inf mt-2">
                                                                    <h6>
                                                                        <a onClick={val.document}>
                                                                            {val.name}
                                                                        </a>
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </ExCarousel>

                                                </div>
                                            </div>
                                            
                                        </div>
                                    )}

                                    {data.laboratory_equipments && data.laboratory_equipments.length>0 && (
                                        <div className="main-wraper mt-0">
                                            <h4 className="main-title mb-0 mt-5 text-left">Laboratory Equipments</h4>
                                            <hr />

                                            <div className="post-content">

                                                <div className="pst-carousel">

                                                    <ExCarousel >
                                                        {data.laboratory_equipments.map((val)=>(
                                                            <div className="pst-frmt">
                                                                <a onClick={val.document}>
                                                                    <img src={val.document} alt="" />
                                                                </a>
                                                                <div className="pst-inf mt-2">
                                                                    <h6>
                                                                        <a onClick={val.document}>
                                                                            {val.name}
                                                                        </a>
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </ExCarousel>

                                                </div>
                                            </div>
                                            
                                        </div>
                                    )}

                                    {data.it_facilities && data.it_facilities.length>0 && (
                                        <div className="main-wraper mt-0">
                                            <h4 className="main-title mb-0 mt-5 text-left">IT Facilities</h4>
                                            <hr />

                                            <div className="post-content">

                                                <div className="pst-carousel">

                                                    <ExCarousel >
                                                        {data.it_facilities.map((val)=>(
                                                            <div className="pst-frmt">
                                                                <a onClick={val.document}>
                                                                    <img src={val.document} alt="" />
                                                                </a>
                                                                <div className="pst-inf mt-2">
                                                                    <h6>
                                                                        <a onClick={val.document}>
                                                                            {val.name}
                                                                        </a>
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </ExCarousel>

                                                </div>
                                            </div>
                                            
                                        </div>
                                    )}

                                    {/* {data.analytical_test_facilities && (
                                        <>
                                            <h5 className="f-title">Analytical Test Facilities</h5>
                                            { data.analytical_test_facilities && data.analytical_test_facilities.split("\n").map((str, index) => <p key={`px-${index}`}>{str}</p>)}
                                        </>
                                    )}

                                    {data.laboratory_equipments && (
                                        <>
                                            <h5 className="f-title">Laboratory Equipments</h5>
                                            { data.laboratory_equipments && data.laboratory_equipments.split("\n").map((str, index) => <p key={`px-${index}`}>{str}</p>)}
                                        </>
                                    )}

                                    {data.it_facilities && (
                                        <>
                                            <h5 className="f-title">IT Facilities</h5>
                                            { data.it_facilities && data.it_facilities.split("\n").map((str, index) => <p key={`px-${index}`}>{str}</p>)}
                                        </>
                                    )} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

    </>
  );
};


export default ViewOrganization;