import React, { useEffect, useRef, useState } from "react";
import PersonalInfo from "./PersonalInfo";
import Education from "./Education";
import Interests from "./Interests";
import Languages from "./Languages";
import Publications from "./Publications";
import ChangePassword from "./ChangePassword";
import Experience from "./Experience";
import Skills from "./Skills"
import { API_GET_PROFILE, API_URL } from "../../../services/ApiEndpoints";
import { useSelector } from "react-redux";
import authHeader from "../../../services/auth-header";
import axios from "axios";

const EditProfile = () => {
    
    const [isLoading, setIsLoading] = useState(true)
    const {userData} = useSelector(state => state.userData )

    const getData = () =>{
        
      axios
      .get(API_URL + API_GET_PROFILE,{
          headers: authHeader()
      })
      .then((response)=>{
          
          if(response.status==200){

              const json = response.data;

              if(json.status=="RC200"){
                
                setProfileDetails(json.data)

              }

          }

          setIsLoading(false)

      })
      .catch((error)=>{
        setIsLoading(false)
      });

  };
    
    const [profileDetails, setProfileDetails] = useState({})

    useEffect(()=>{
      
        getData()

    }, []);

    const refPersonalInfo = useRef();

    const scrollToTarget = (id) => {
        const tag = document.getElementById(id);
        tag.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    }

    const [modalStatus, setModalStatus] = useState(true)

  return (
    <section>
      <div className="gap gray-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row merged20" id="page-contents">
                
                {/* Left side content start */}
                <div className="col-lg-3">

                    <aside className="sidebar static">
                        <div className="widget stick-widget">
                            <h4 className="widget-title">Edit your profile</h4>
                            <ul className="naves">
                                <li><i className="ti-user"></i><a onClick={(e)=>scrollToTarget("personal-info")}>Personal Information</a></li>
                                <li><i className="ti-pencil"></i><a onClick={(e)=>scrollToTarget("education")} title="">Education</a></li>
                                <li><i className="ti-briefcase"></i><a onClick={(e)=>scrollToTarget("experience")} title="">Experience</a></li>
                                <li><i className="ti-book"></i><a onClick={(e)=>scrollToTarget("publications")}title="">Publications</a></li>
                                <li><i className="ti-star"></i><a onClick={(e)=>scrollToTarget("skills")} title="">Skills</a></li>
                                <li><i className="ti-info-alt"></i><a onClick={(e)=>scrollToTarget("languages")} title="">Languages</a></li>
                                <li><i className="ti-heart"></i><a onClick={(e)=>scrollToTarget("interests")} title="">Interests</a></li>
                                <li><i className="ti-lock"></i><a onClick={(e)=>scrollToTarget("change-password")} title="">Change Password</a></li>
                            </ul>
                        </div>
                    </aside>

                </div>
                {/* Left side content end */}

                {/* Profile content start */}
                <div className="col-lg-9">

                  {userData.force_redirect_to_profile==0 && (
                    <>
                        <div className="alert alert-danger">
                          <p>Please update the required profile details in the highlighted section to explore SCIONS completely.</p>
                        </div>
                    </>
                  )}

                {isLoading ? (
                    <p>loading...</p>
                ) : (
                    Object.keys(profileDetails).length===0 ? (
                        <p>Error</p>
                    ) : (
                            <div>
    
                                <PersonalInfo
                                    engFieldList={profileDetails.engineering_fields}
                                    pSelectedDisciplines={profileDetails.p_selected_disciplines}
                                    pSelectedSubDisciplines={profileDetails.p_selected_sub_disciplines}
                                    sSelectedDisciplines={profileDetails.s_selected_disciplines}
                                    sSelectedSubDisciplines={profileDetails.s_selected_sub_disciplines}
                                    profile={profileDetails.profile_details}
                                    type={profileDetails.types.filter((v)=>v.is_org_type== profileDetails.profile_details.register_as )}
                                    countries={profileDetails.countries}
                                    data={profileDetails.profile_details}  />
                                
                                <Education 
                                    profile={profileDetails.profile_details}
                                    countries={profileDetails.countries}
                                    masters={profileDetails.education.masters}
                                    graduation={profileDetails.education.graduation} />
    
                                <Experience experience={profileDetails.experience}  />
    
                                <Publications publications={profileDetails.publications} />
    
                                <Skills soft_skills={profileDetails.soft_skills} technical_skills={profileDetails.technical_skills} />
    
                                {/* pending update profile */}
                                <Languages data={profileDetails.languages} />
    
                                <Interests interests={profileDetails.interests} />
    
                                <ChangePassword />
                            </div>
                    )
                )}

                </div>
                {/* Profile content end */}

              </div>
            </div>
          </div>
        </div>
      </div>

    </section>

  );
};

export default EditProfile;
