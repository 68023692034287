import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API_MY_PROBLEMS, API_URL } from "../../../../services/ApiEndpoints";
import authHeader from "../../../../services/auth-header";
import { print_idea_status, print_problem_status } from "../../Consts";

const MyProblems = () => {

    const [isLoading, setIsLoading] = useState(false)
    const [list, setList] = useState([])
    const [offeres, setOffers] = useState([])

    const getDetails = () =>{

        setIsLoading(true)

        axios
        .get(API_URL + API_MY_PROBLEMS,{
            headers: authHeader()
        })
        .then((response)=>{
            
            setIsLoading(false)

            if(response.status==200){

                const json = response.data;

                setList(json.data.problem)
                setOffers(json.data.offers)

            }

        })
        .catch((error)=>{
            setIsLoading(false)
        });
    }

    useEffect(()=>{
        getDetails()
    }, [])

  return (
        <section>
            <div className="gap gray-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="central-meta">
                                <div className="frnds">
                                    <div className="row">
                                        <div className="col-8 col-md-6">
                                            <h3>Eureka Space</h3>
                                        </div>

                                        <div className="col-4 col-md-6">
                                            <Link style={{top:0}} to="/problemspace/create" className="add-butn">Create Eureka</Link>
                                        </div>
                                    </div>

                                    { isLoading ? (
                                        <p>Loading...</p>
                                    ) : (
                                        
                                        list.length==0 ? (
                                            <p className="text-center"><strong>You have not shared any problems.</strong></p>
                                        ) : (
                                            <ul className="nearby-contct">
                                                
                                                {list.map((item, index)=>(
                                                    <li key={item.id}>
                                                        <Link to={`/problemspace/details/${item.id}/responses`}>
                                                            <div className="row">
                                                                <div className="col-md-2 text-center">
                                                                    <figure>
                                                                        <Link to={`/problemspace/details/${item.id}/responses`} title="" style={{verticalAlign:"top"}}><img className="img-fluid" src={item.image} alt={item.pain_point} /></Link>
                                                                    </figure>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <h4><Link to={`/problemspace/details/${item.id}/responses`} title="">{item.pain_point}</Link></h4>
                                                                    
                                                                    {/* <h6 style={{"color":"#f05a24"}}>ID: {item.unique_id}</h6> */}
                                                                    
                                                                    <small>Created at: {item.date}</small>
                                                                    <br />
                                                                    {print_problem_status(item)}

                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </li>
                                                ))}

                                            </ul>  
                                        )

                                        
                                    )}
                                    
                                </div>
                            </div>

                            <div className="central-meta">
                                <div className="frnds">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h3>My Activities</h3>
                                        </div>
                                    </div>

                                    { isLoading ? (
                                        <p>Loading...</p>
                                    ) : (
                                        
                                        offeres.length==0 ? (
                                            <p className="text-center"><strong>You have not reacted to any problems.</strong></p>
                                        ) : (
                                            <ul className="nearby-contct">
                                                
                                                {offeres.map((item, index)=>(
                                                    <li key={`offers-my-activity-${item.id}`}>
                                                        <Link to={`/problemspace/my-offers/${item.problemspace_id}/offers`}>
                                                            <div className="row">
                                                                <div className="col-md-2 text-center">
                                                                    <figure>
                                                                        <Link to={`/problemspace/my-offers/${item.problemspace_id}/offers`} title="" style={{verticalAlign:"top"}}><img className="img-fluid" src={item.image} alt={item.pain_point} /></Link>
                                                                    </figure>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <h4><Link to={`/problemspace/my-offers/${item.problemspace_id}/offers`} title="">{item.pain_point}</Link></h4>
                                                                    <h6 style={{"color":"#f05a24"}}>{item.unique_id}</h6>
                                                                    <p><small>Published on: {item.date}</small></p>
                                                                    {print_idea_status(item)}
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </li>
                                                ))}

                                            </ul>  
                                        )

                                        
                                    )}
                                    
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>

  );

};

export default MyProblems;
