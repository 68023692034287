import axios from "axios";
import { data } from "jquery";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, NavLink, useLocation } from "react-router-dom";
import {
  API_FIELD_TREE,
  API_GET_INVESTOR_LIST,
  API_JOB_LIST,
  API_URL,
} from "../../../services/ApiEndpoints";
import authHeader from "../../../services/auth-header";
import LoaderWrapperView from "../../libs/LoaderWrapperView";
import TreeView from "../../libs/TreeView";
import linkdinlogo from "../../../assets/images/ic-linkedin-logo.png";
import scionlogo from "../../../assets/images/ic-scion-logo.png";

const AngelInvestor = () => {
  const queryString = require("query-string");
  const location = useLocation();
  const query = queryString.parse(location.search);

  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [filters, setFilters] = useState({
    type_full_time: 0,
    type_part_time: 0,
    type_freelancer: 0,
    fields: query["field"] != undefined ? [query["field"]] : [],
  });

  const updateFilter = (e, key) => {
    const obj = { ...filters };

    obj[key] = e.target.checked ? 1 : 0;

    setFilters(obj);
  };

  const getData = () => {
    setIsLoading(true);

    axios
      .get(API_URL + API_GET_INVESTOR_LIST, {
        params: {
          page: currentPage,
          type_full_time: filters.type_full_time,
          type_part_time: filters.type_part_time,
          type_freelancer: filters.type_freelancer,
          fields: filters.fields,
        },
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          const { status } = response.data;

          if (status == "RC200") {
            const { data, last_page } = response.data.data;
            setCanLoadMore(!(currentPage == last_page));
            if (currentPage == 1) {
              setList(data);
            } else {
              const old = [...list];
              setList(old.concat(data));
            }
          }
        }

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const loadFunc = () => {
    setCurrentPage(currentPage + 1);
    //getData()
  };

  useEffect(() => {
    getData();
  }, [currentPage]);

  useEffect(() => {
    setList([]);
    setCurrentPage(1);
    getData();
  }, [filters]);

  const jobTypeList = ["Full Time", "Part Time", "Freelancer"];

  return (
    <section>
      <div className="gap gray-bg ">
        <div className="container ">
          <div className="row ">
            <div className="col-lg-12">
              <div className="row ">
                <div className="col-lg-9 m-auto">
                  <div className="central-meta">
                    <div className="frnds">
                      <h3>Angel Investors</h3>

                      <LoaderWrapperView
                        isLoading={currentPage == 1 && isLoading}
                      >
                        <InfiniteScroll
                          dataLength={list.length}
                          next={loadFunc}
                          hasMore={canLoadMore}
                          endMessage={
                            <div className="text-center">
                              No more Angel Investor found
                            </div>
                          }
                          loader={
                            <div className="loader text-center">
                              Loading ...
                            </div>
                          }
                        >
                          <ul className="nearby-contct">
                            {list.map((item, index) => (
                              <li key={index}>
                                <div className="nearly-pepls">
                                  <figure>
                                    <div style={{ verticalAlign: "top" }}>
                                      <img
                                        style={{width: "64px", height: "64px", objectFit: "cover"}}
                                        src={item.profile_image}
                                        alt={item.title}
                                      />
                                    </div>
                                  </figure>
                                  <div className="pepl-info">
                                    <h4>
                                      <div>{item.first_name} {item.last_name}</div>
                                    </h4>
                                    {item.city && (
                                      <>
                                        <span>
                                          <strong>Location: </strong>
                                          {item.city}
                                        </span>
                                      </>
                                    )}
                                    <br />
                                    <small>
                                      {item.specialities && (
                                        <span>
                                          {item.specialities}
                                        </span>
                                      )}
                                      <br />
                                    </small>
                                    {item.linked_in_url ? (
                                      <a
                                        href={item.linked_in_url}
                                        target="_blank"
                                      >
                                        <img
                                          src={linkdinlogo}
                                          className="img-fluid img-thumbnail px-1"
                                          style={{ width: "100px", marginTop:"10px", marginRight:"10px"  }}
                                        />
                                      </a>
                                    ) : (
                                      ""
                                    )}

                                    {item.public_url ? (
                                      <NavLink
                                        to={`/${item.public_url}`}
                                        target="_blank"
                                      >
                                        <img
                                          src={scionlogo}
                                          className="img-fluid img-thumbnail px-1"
                                          style={{ width: "100px", marginTop:"10px"}}
                                        />
                                      </NavLink>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </InfiniteScroll>
                      </LoaderWrapperView>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AngelInvestor;
