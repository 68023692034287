import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";

import { login } from "../actions/auth";
import { clearMessage } from "../actions/message";

import {
  LOGIN_FAIL,
  LOGIN_FAIL_201,
  LOGIN_FAIL_REACTIVATE_ACCOUNT,
  LOGIN_SUCCESS,
  SET_MESSAGE,
} from "../actions/types";
import {
  API_LOGIN,
  API_URL,
  RE_CAPTCHA_SITE_KEY,
} from "../services/ApiEndpoints";
import ExtButton from "./libs/ExtButton";
import FormReCaptchaV3 from "./libs/FormReCaptchaV3";
import PasswordField from "./libs/PasswordField";
import ReCaptchaV3 from "./libs/ReCaptchaV3";

const Login = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { msgType } = useSelector((state) => state.msgType);
  const { message } = useSelector((state) => state.message);
  const [isError, setisError] = useState(false);
  const [msg, setMsg] = useState("");

  const { isLoggedIn } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const dispatch = useDispatch();

  const handleValidation = () => {
    let formIsValid = true;

    if (!username) {
      formIsValid = false;
    } else if (!password) {
      formIsValid = false;
    }

    return formIsValid;
  };

  const handleLogin = (captchaToken) => {
    setLoading(true);

    /*dispatch(login(username, password, captchaToken))
                .then(()=>{
                    //props.history.push("/scionsline");
                    //window.location.reload();
                    setIsSuccess(true)
                }).catch(() => {
                    setLoading(false);
            });*/

    axios
      .post(API_URL + API_LOGIN, {
        email: username,
        password: password,
        recaptcha_token: captchaToken,
      })
      .then((response) => {
        setLoading(false);

        let data = response.data;

        if (data.status == "RC200") {
          localStorage.setItem("user", JSON.stringify(data.data));

          dispatch({
            type: LOGIN_SUCCESS,
            payload: { user: data.data },
          });
        } else if (data.status == LOGIN_FAIL_REACTIVATE_ACCOUNT) {
          dispatch({
            type: LOGIN_FAIL,
          });
          dispatch({
            type: SET_MESSAGE,
            payload: `${data.message}`,
            msgType: LOGIN_FAIL_REACTIVATE_ACCOUNT,
          });
        } else {
          dispatch({
            type: LOGIN_FAIL,
          });
          dispatch({
            type: SET_MESSAGE,
            payload: data.message,
          });
        }
      });
  };

  if (isLoggedIn) {
    return <Navigate to="/overview" />;
  }

  if (isSuccess) {
    return <Navigate to="/overview" />;
  }

  return (
    <div className="theme-layout">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 text-center mt-3">
            <a href="/">
              <img width="240px" src={require("../assets/images/logo-big.png")} alt="" />
            </a>
          </div>

          <div className="login-reg-bg" style={{ margin: "0 auto 100px auto" }}>
            <div className="col-md-12">
              <div className="log-reg-area sign mt-5">
                <h3 className="log-title text-center">Login</h3>

                <h6 className="text-center">Already a SCION?</h6>

                <br />
                <FormReCaptchaV3
                  onLoadStatusUpdate={(status) => {
                    setLoading(status);
                  }}
                  action="login"
                  onSubmit={handleLogin}
                >
                  <div className="form-group">
                    <input
                      type="text"
                      id="input-username"
                      onChange={(e) => setUsername(e.target.value)}
                      required="required"
                    />
                    <label className="control-label" htmlFor="input-username">
                      Username *
                    </label>
                    <i className="mtrl-select"></i>
                  </div>

                  <PasswordField
                    value={password}
                    label="Password"
                    keys="pwd"
                    onChange={(e) => setPassword(e.target.value)}
                  />

                  {/* <div className="checkbox">
                                        <label>
                                            <input type="checkbox" defaultChecked />
                                            <i className="check-box"></i>
                                            Remember Me
                                        </label>
                                    </div> */}

                  <Link to="/forgot-password" className="forgot-pwd">
                    Forgot Password?
                  </Link>

                  <div className="submit-btns">
                    {message && (
                      <div className="alert alert-danger" role="alert">
                        {msgType == LOGIN_FAIL_REACTIVATE_ACCOUNT ? (
                          <>
                            Your account is deactivated.{" "}
                            <Link className="err-link" to="/recover-account">
                              Click here
                            </Link>{" "}
                            to verify your account and reactivate it to access
                            SCIONS.
                          </>
                        ) : (
                          message
                        )}
                      </div>
                    )}
                    {/* <button className="mtr-btn signin" disabled={loading} type="submit">
                                            {loading && (
                                                <span className="spinner-border spinner-border-sm"></span>
                                            )}
                                            <span>Login</span>
                                        </button>
                                        <Link to="/register" className="mtr-btn ml-10 signup">
                                            {loading && (
                                                <span className="spinner-border spinner-border-sm"></span>
                                            )}
                                            <span>New User Registration</span>
                                        </Link> */}
                    <ExtButton
                      isLoading={loading}
                      className="mtr-btn"
                      type="submit"
                    >
                      <span>Login</span>
                    </ExtButton>
                    <Link to="/register" className="mtr-btn ml-10 signup">
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>New User Registration</span>
                    </Link>
                    <br />
                    <br />
                    Verification link missing?{" "}
                    <Link
                      to="/resend-email-verification-link"
                      className="already-have"
                    >
                      Get the new link
                    </Link>
                  </div>
                </FormReCaptchaV3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
