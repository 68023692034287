import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import AddComment from "./AddComment";
import SingleComment from "./SingleComment";
import axios from "axios";
import {
  API_GET_COMMENTS_BY_POST,
  API_URL,
} from "../../../services/ApiEndpoints";
import authHeader from "../../../services/auth-header";
import LoaderWrapperView from "../../libs/LoaderWrapperView";

const CommentList = (post) => {
  const [comments, setComments] = useState(post.comments);
  // const [replyId, setReplyId] = useState(-1)

  const [currentPage, setCurrentPage] = useState(2);
  const [isLoading, setIsLoading] = useState(false);
  const [allowLoadMore, setAllowLoadMore] = useState(1);

  const onCommentAdded = (comment) => {
    console.log(...comments);
    console.log(comment);

    const newComments = [...comments].concat(comment);

    setComments(newComments);
    //setReplyId(-1);
  };

  const onCommentRepliedClicked = (commmentId) => {
    //setReplyId(commmentId)
  };

  const getComments = (e) => {
    e.preventDefault();

    setIsLoading(true);

    axios
      .get(
        `${API_URL}${API_GET_COMMENTS_BY_POST}/${post.id}?page=${currentPage}`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status == 200) {
          const json = response.data;

          if (json.status == "RC200") {
            var resp = json.data;

            let com = [...comments];

            setComments(com.concat(resp.data));

            setAllowLoadMore(true);

            setCurrentPage(currentPage + 1);

            setAllowLoadMore(currentPage < resp.last_page);
          }

          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (post.comments.length < 10) {
      setAllowLoadMore(false);
    }
  }, []);

  return (
    <ul className="we-comet">
      {comments.map((comment, i) => (
        <SingleComment
          postId={post.id}
          onCommentRepliedClicked={onCommentRepliedClicked}
          key={"comment-" + post.id + "-" + comment.id}
          comment={comment}
        />
      ))}

      {allowLoadMore && (
        <li>
          <LoaderWrapperView isLoading={isLoading}>
            <a href="" onClick={getComments} className="showmore underline">
              more comments
            </a>
          </LoaderWrapperView>
        </li>
      )}

      <AddComment
        replyId={-1}
        parentCallback={onCommentAdded}
        key={"add-comment-" + post.id}
        postId={post.id}
      />
    </ul>
  );
};

export default CommentList;
