import '../../assets/js/tooltip'
import "bootstrap"
import { Component, forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const TransPopup = forwardRef((props, ref) => {

    const {className, button, label, title, btnCancel, btnSave, btnCancelLabel, btnSaveLabel, onSaveClickListener, dClassName} = props

    const [show, setShow] = useState(props.show || false)

    const close = () =>{
        setShow(false)
    }

    const open = () =>{
        setShow(true)
    }

    useImperativeHandle(ref, () => ({
        close, open
    }));

    return(
        <>
            {props.hideButton ? "" : ((button && button===true) ? (
                <button disabled={props.isLoading || false} type={props.type || "button"} className={className || ""} onClick={ (e)=>{
                    e.preventDefault()
                    setShow(!show)
                    props.onButtonClick && props.onButtonClick()
                }} > {props.span ? <span>{label || ""}</span> : (label || "")}</button>
            ) : (
                <a href="" className={className || ""} onClick={ (e)=>{
                        e.preventDefault()
                        setShow(!show)
                        props.onButtonClick && props.onButtonClick()
                    }} >{label || ""}</a>
            ))}

            <Modal onShow={(e)=>{
                if(props.onOpen)
                    props.onOpen(e)
            }} dialogClassName={dClassName || ""} show={show} centered aria-labelledby="contained-modal-title-vcenter" onHide={()=>setShow(false)}>  
                
                <Modal.Body>
                    {props.children}
                </Modal.Body>

                { (btnCancel || btnSave) && (
                    <Modal.Footer>
                        {btnCancel && (
                            <button type="button" className="btn btn-link btn-bordered" onClick={()=>setShow(false)}>{btnCancelLabel || "Cancel"}</button>
                        )}

                        {btnSave && (
                            <Button className="btn btn-success" onClick={(e)=>{
                                onSaveClickListener && (
                                    onSaveClickListener(e)
                                )
                            }}>{ btnSaveLabel || "Save"}</Button>  
                        )}

                    </Modal.Footer>
                )}

            </Modal>
        </>
    )
    
})

export default TransPopup;