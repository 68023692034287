import React, { useEffect, useRef, useState } from "react";
import {Link} from 'react-router-dom'
import { useSelector } from "react-redux";
import Form from "react-validation/build/form";
import { API_TIMELINE_ADD_COMMENT, API_URL } from "../../../services/ApiEndpoints";
import axios from "axios";
import authHeader from "../../../services/auth-header";
import { toast } from "react-toastify";

const AddComment = (props) =>{
    
    const form = useRef();

    const { user: currentUser } = useSelector((state) => state.auth);

    const [commentText, setCommentText] = useState("")

    const handleSubmit = (e) => {
        e.preventDefault();
        
        if(!commentText){
            toast.error("Please enter opinion to share.")
            return;
        }

        axios
        .post(API_URL + API_TIMELINE_ADD_COMMENT,{
            feed_id: props.postId,
            comment: commentText,
            replied_to: props.replyId
        },{
            headers: authHeader()
        })
        .then((response)=>{
            
            if(response.status==200){

                const json = response.data;

                if(json.status=="RC200"){

                    setCommentText("")

                    props.parentCallback({
                        comment: commentText,
                        id: json.data.id,
                        created_at: "Now",
                        full_name: `${currentUser.first_name} ${currentUser.last_name}`,
                        profile_image: currentUser.profile_image,
                        public_url: currentUser.public_url,
                        replied_comments: []
                    })

                }

            }

        })
        .catch((error)=>{

        });

    }

    return(
        
        <li className="post-comment">
            <div className="comet-avatar">
                <img src={currentUser.profile_image} alt="" />
            </div>
            <div className="post-comt-box">
                <Form onSubmit={handleSubmit} ref={form} >
                    <textarea value={commentText} onChange={(e)=>setCommentText(e.target.value)} placeholder={props.placeholder ? props.placeholder : "Share your opinion"}></textarea>
                    {/* <div className="add-smiles">
                        <span className="em em-expressionless" title="add icon"></span>
                    </div>
                    <div className="smiles-bunch">
                        <i className="em em---1"></i>
                        <i className="em em-smiley"></i>
                        <i className="em em-anguished"></i>
                        <i className="em em-laughing"></i>
                        <i className="em em-angry"></i>
                        <i className="em em-astonished"></i>
                        <i className="em em-blush"></i>
                        <i className="em em-disappointed"></i>
                        <i className="em em-worried"></i>
                        <i className="em em-kissing_heart"></i>
                        <i className="em em-rage"></i>
                        <i className="em em-stuck_out_tongue"></i>
                    </div> */}
                    <div className="add-smiles mt-3">
                        <button className="btn btn-sm bg-theme" type="submit">Submit</button>
                    </div>
                </Form>
            </div>
        </li>
        
    );
};

export default AddComment;