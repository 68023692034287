import { Link, NavLink, Outlet, useParams } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component'
import { API_GET_ORG, API_GET_ORG_DETAILS, API_IDEA_LIST, API_URL } from "../../../../services/ApiEndpoints";
import axios from "axios";
import authHeader from "../../../../services/auth-header";
import { useEffect, useState } from "react";
import { organizationSize, typeOfOrganization } from "../../Consts";
import { printPhoneNumber } from "../../../../helpers/configs";
import ExCarousel from "../../../libs/ExCarousel";

const OrganizationDetails = () => {

    let {slug:id} = useParams()
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState({})

    const getData = () =>{

        setIsLoading(true)

        axios
            .get(API_URL + API_GET_ORG_DETAILS +"/"+ id,{
                    headers: authHeader()
                }
            )
            .then((response)=>{
                
                if(response.status==200){

                    const json = response.data;

                    if(json.status=="RC200"){

                        var resp = json.data;

                        setData(resp)
                    }

                }

                setIsLoading(false)

            })
            .catch((error)=>{
                setIsLoading(false)
            });

    }

    useEffect(()=>{
        getData()
    }, [])

  return (
    <section>
        <div className="gap gray-bg">
            <div className="container">
                
                { isLoading ? (<p>Loading...</p>) : (
                    
                    <div className="central-meta">
                        <div className="editing-info">
                            <div className="friend-info">
                                <div className="d-md-inline-block d-flex text-center">
                                    <figure className="d-inline-block" style={{"box-shadow": "0px 0px 5px #DDD"}}>
                                        <img style={{"min-height": "160px", "width": "160px", "border-radius":"0px"}} src={data.company_logo} alt="" />
                                    </figure>
                                </div>
                                <div style={{"width":"83%", "margin-left":"10px"}} className="friend-name">
                                    <h4 style={{"color": "#f05a24", "text-transform": "capitalize"}}>{data.company_name}</h4>
                                    {data.location && (
                                        <span><i style={{fontSize:"16px"}} className="fa fa-map-marker"></i> {data.location}</span>
                                    )}

                                    {data.phone && (
                                        <span><i className="fa fa-phone"></i> {printPhoneNumber(data.phone_code, data.phone)}</span>
                                    )}

                                    {data.website && (
                                        <span><i className="fa fa-globe"></i> <a href={data.website} target="_blank">{data.website}</a></span>
                                    )}

                                    {typeOfOrganization[data.type_of_organization] && (
                                        <span><i className="fa fa-tags"></i> {typeOfOrganization[data.type_of_organization].name}</span>
                                    )}

                                    {organizationSize[data.company_size] && (
                                      <span><i className="fa fa-users"></i> {organizationSize[data.company_size].name} Employees</span>  
                                    )}

                                    {data.number_of_patented_ideas!=0 && (
                                        <span>*{data.number_of_patented_ideas} Total active number of patented ideas</span>
                                    )}

                                    {data.number_of_patented_ideas_last_one_year!=0 && (
                                        <span>*{data.number_of_patented_ideas_last_one_year} Number of patented ideas in last one year</span>
                                    )}

                                </div>
                                <div className="post-meta">
                                    <div className="description">
                                        <h5 className="f-title">About {data.company_name}</h5>
                                        { data.about_company && data.about_company.split("\n").map((str, index) => <p key={`px-${index}`}>{str}</p>)}

                                        {data.analytical_test_facilities && data.analytical_test_facilities.length>0 && (
                                            <div className="main-wraper mt-0">
                                                <h4 className="main-title mb-0 mt-5 text-left">Analytical Test Facilities</h4>
                                                <hr />

                                                <div className="post-content">

                                                    <div className="pst-carousel">

                                                        <ExCarousel >
                                                            {data.analytical_test_facilities.map((val)=>(
                                                                <div className="pst-frmt">
                                                                    <a onClick={val.document}>
                                                                        <img src={val.document} alt="" />
                                                                    </a>
                                                                    <div className="pst-inf mt-2">
                                                                        <h6>
                                                                            <a onClick={val.document}>
                                                                                {val.name}
                                                                            </a>
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </ExCarousel>

                                                    </div>
                                                </div>
                                                
                                            </div>
                                        )}

                                        {data.laboratory_equipments && data.laboratory_equipments.length>0 && (
                                            <div className="main-wraper mt-0">
                                                <h4 className="main-title mb-0 mt-5 text-left">Laboratory Equipments</h4>
                                                <hr />

                                                <div className="post-content">

                                                    <div className="pst-carousel">

                                                        <ExCarousel >
                                                            {data.laboratory_equipments.map((val)=>(
                                                                <div className="pst-frmt">
                                                                    <a onClick={val.document}>
                                                                        <img src={val.document} alt="" />
                                                                    </a>
                                                                    <div className="pst-inf mt-2">
                                                                        <h6>
                                                                            <a onClick={val.document}>
                                                                                {val.name}
                                                                            </a>
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </ExCarousel>

                                                    </div>
                                                </div>
                                                
                                            </div>
                                        )}

                                        {data.it_facilities && data.it_facilities.length>0 && (
                                            <div className="main-wraper mt-0">
                                                <h4 className="main-title mb-0 mt-5 text-left">IT Facilities</h4>
                                                <hr />

                                                <div className="post-content">

                                                    <div className="pst-carousel">

                                                        <ExCarousel >
                                                            {data.it_facilities.map((val)=>(
                                                                <div className="pst-frmt">
                                                                    <a onClick={val.document}>
                                                                        <img src={val.document} alt="" />
                                                                    </a>
                                                                    <div className="pst-inf mt-2">
                                                                        <h6>
                                                                            <a onClick={val.document}>
                                                                                {val.name}
                                                                            </a>
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </ExCarousel>

                                                    </div>
                                                </div>
                                                
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </div>
    </section>
  );
};


export default OrganizationDetails;