import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  API_UPDATE_PROFILE_IMAGE,
  API_URL,
} from "../../../services/ApiEndpoints";
import authHeader from "../../../services/auth-header";
import ExtButton from "../ExtButton";
import ImageCropper from "./ImageCropper";

const ImageCropperPopup = (props) => {
  const { className } = props;

  const [modalBack, setModalBack] = useState(false);

  const [imageToCrop, setImageToCrop] = useState(undefined);
  const [croppedImage, setCroppedImage] = useState(undefined);
  const [imageBlob, setImageBlob] = useState(undefined);

  const onUploadFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      var _URL = window.URL || window.webkitURL;
      const img = new Image();
      var objectUrl = _URL.createObjectURL(event.target.files[0]);
      img.onload = function () {
        if (this.width <= 300 || this.height <= 300) {
          alert("Image dimentions must be greateer then 300x300");
          event.target.value = "";
          _URL.revokeObjectURL(objectUrl);
          return;
        }

        _URL.revokeObjectURL(objectUrl);

        const reader = new FileReader();

        reader.addEventListener("load", () => {
          setImageToCrop(reader.result);
          setModalBack(true);
        });

        reader.readAsDataURL(event.target.files[0]);
      };
      img.src = objectUrl;
    }
  };

  useEffect(() => {
    setCroppedImage(undefined);
  }, [imageToCrop]);

  async function convToBlob() {
    if (croppedImage) {
      const blob = await fetch(croppedImage)
        .then((res) => res.blob())
        .then((blob) => {
          setImageBlob(blob);
        });
    }
  }

  useEffect(() => {
    convToBlob();
  }, [croppedImage]);

  const refInput = useRef();

  return (
    <>
      <input
        type="file"
        ref={refInput}
        accept="image/*"
        className={className || ""}
        onChange={onUploadFile}
      />

      <Modal
        show={modalBack}
        toggle={() => setModalBack(!modalBack)}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}
        size="lg"
      >
        <ModalHeader style={{ display: "block" }}>
          <ModalTitle>
            <div
              style={{ width: "100%", display: "block" }}
              className="text-center"
            >
              {props.title || ""}
            </div>
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-6 text-center">
              <h6>Crop Image</h6>
              <ImageCropper
                {...props}
                imageToCrop={imageToCrop}
                onImageCropped={(croppedImagex) => {
                  setCroppedImage(croppedImagex);
                }}
              />
            </div>

            <div className="col-md-6 text-center">
              <h6>Result</h6>
              {!croppedImage ? (
                <div className="cls-empty-crop-result">Please crop image</div>
              ) : (
                <div>
                  <img alt="Cropped result" src={croppedImage} />
                </div>
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Link
            style={{ fontWeight: "bold" }}
            to="/"
            onClick={(e) => {
              e.preventDefault();
              refInput.current.value = "";
              setModalBack(false);
            }}
            className="already-have"
          >
            Cancel
          </Link>{" "}
          &nbsp; &nbsp; &nbsp;
          <ExtButton
            isLoading={false}
            onClick={() => {
              setModalBack(false);

              // console.log(imageBlob)

              if (props.onImageCroped) {
                props.onImageCroped(croppedImage, imageBlob);
              }
            }}
            className="mtr-btn no-margin"
            type="submit"
          >
            <span>Crop</span>
          </ExtButton>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ImageCropperPopup;
