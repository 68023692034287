import { useEffect, useState } from "react";
import { RE_CAPTCHA_SITE_KEY } from "../../services/ApiEndpoints";

const FormReCaptchaV3 = (props) =>{

    const [allowCaptcha, setAllowCaptcha] = useState(props.allowCaptcha || true)

    const [noForm, setNoForm] = useState(props.noForm || false)

    let recaptchaScript = null;
    const [action, setAction] = useState(props.action || "scions_forms")

    useEffect(()=>{
        
        recaptchaScript = document.createElement("script")
        recaptchaScript.onerror = function(){};
        recaptchaScript.id = "recaptcha-script";
        recaptchaScript.async = true;
        recaptchaScript.type = "text/javascript";
        document.body.appendChild(recaptchaScript);
        recaptchaScript.src = "https://www.google.com/recaptcha/api.js?render="+ RE_CAPTCHA_SITE_KEY

        recaptchaScript.addEventListener("load", ()=>{
            if(noForm){
                generate()
            }
        })

        return() =>{
            if(recaptchaScript!=null){
                document.body.removeChild(recaptchaScript);
                if(document.getElementsByClassName("grecaptcha-badge").length!=0){
                    document.getElementsByClassName("grecaptcha-badge")[0].remove()
                }
            }
        }

    }, [])

    const handleSubmit = (e) =>{
        e.preventDefault();
        
        generate()

    }

    const generate = () =>{
        props.onLoadStatusUpdate && props.onLoadStatusUpdate(true)

        if(!allowCaptcha){
            props.onSubmit && props.onSubmit("")
            return
        }

        window.grecaptcha.ready(_ => {
            window.grecaptcha
                .execute(RE_CAPTCHA_SITE_KEY, { action: action })
                .then(token => {
                    props.onSubmit && props.onSubmit(token)
                })
        })
    }

    return(
        !noForm && (
            <form onSubmit={handleSubmit} >
                {props.children}
            </form>
        )
    )

}

export default FormReCaptchaV3