import { useEffect, useLayoutEffect, useRef, useState } from "react"
import { useSelector } from "react-redux";
import { Link, NavLink, useParams } from "react-router-dom";
import CanvasJSReact from '@canvasjs/react-charts';
import axios from "axios";
import { API_GET_INVESTOR_DETAILS_AIHUB, API_RUN_AI, API_RUN_AI_CHECK, API_RUN_AI_NEXT, API_URL } from "../../../services/ApiEndpoints";
import authHeader from "../../../services/auth-header";
import ExtButton from "../../libs/ExtButton";
import linkdinlogo from "../../../assets/images/ic-linkedin-logo.png";
import { toast } from "react-toastify";
import LoaderWrapperView from "../../libs/LoaderWrapperView";

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const AIHub = () => {

    let {slug} = useParams();

    const { user: currentUser } = useSelector((state) => state.auth);

    const [isLoading, setIsLoading] = useState(true)

    const [isAIstarted, setIsAIstarted] = useState(false)
    const [status, setStatus] = useState(0)

    const [collabList, setCollabList] = useState([])
    const [orgList, setOrgList] = useState([])
    const [angelList, setAngelList] = useState([])

    const getDetails = () =>{

        setIsLoading(true)

        axios
        .get(API_URL + API_RUN_AI_CHECK + "?id="+ slug,{
            headers: authHeader()
        })
        .then((response)=>{

            if(response.status==200){

                const json = response.data;
                // setData(json.data)
                setIsLoading(false)
                
                if(json.status=="RC200"){
                    // this means ai is running so call second api
                    setIsAIstarted(true)
                    runAI()
                }else{
                    // this means ai is not running show button
                    setIsAIstarted(false)
                }
                
            }

        })
        .catch((error)=>{
            setIsLoading(false)
        });
    }

    useEffect(()=>{
        getDetails()

    }, [])

    let nIntervId = null;

    useEffect(()=>{
        
        console.log("interval useEffect", isAIstarted, status)

        if(nIntervId==null){
            console.log("interval started")
            nIntervId = setInterval(()=>{
                if(isAIstarted && status==0){
                    console.log("AI api call")
                }
            }, 5000);
        }

        if(isAIstarted && status==2){
            if(nIntervId){
                console.log("clear interval");
                clearInterval(nIntervId)
            }
        }

        // console.log(json.message)

        /*if(isAIstarted && status==0){
            if(nIntervId!=null){
                console.log("interval started")
                nIntervId = setInterval(()=>{
                    if(isAIstarted && status==0){
                        runAIAsync()
                    }
                }, 5000);
            }
        }

        if(isAIstarted && status==2){
            console.log("interval cleared")
            clearInterval(nIntervId)
        }*/

        return 

    }, [isAIstarted, status])

    const [runIsLoading, setRunIsLoading] = useState(false)
    const [data, setData] = useState({})
    const [msg, setMsg] = useState("")

    const runAIAsync = async () =>{

        axios
        .get(API_URL + API_RUN_AI + "?id="+ slug,{
            headers: authHeader()
        })
        .then((response)=>{

            if(response.status==200){

                const json = response.data;
                
                if(json.status=="RC200"){
                   
                    setIsAIstarted(true)
                    setMsg(json.message)
                    setStatus(json.data?.ai_result ?? 0)

                    setData(json.data)

                }
                
            }

        })
        .catch((error)=>{
            
        });
    }

    const runAI = () =>{

        setRunIsLoading(true)

        axios
        .get(API_URL + API_RUN_AI + "?id="+ slug,{
            headers: authHeader()
        })
        .then((response)=>{

            if(response.status==200){

                const json = response.data;
                
                if(json.status=="RC200"){
                   
                    setIsAIstarted(true)

                    setCollabList(json.data.collaborators)
                    setOrgList(json.data.organizations)
                    setAngelList(json.data.angel_investors)

                    setMsg(json.message)
                    setStatus(json.data?.ai_result ?? 0)

                    setData(json.data)

                }else{
                    // this means ai is not running show button
                    toast.error(json.message)
                }

                setRunIsLoading(false)
                
            }

        })
        .catch((error)=>{
            setRunIsLoading(false)
        });
    }

    const loadMoreData = (type) =>{

        axios
        .get(API_URL + API_RUN_AI_NEXT + "?id="+ slug + "&type="+ type,{
            headers: authHeader()
        })
        .then((response)=>{

            if(response.status==200){

                const json = response.data;
                
                if(json.status=="RC200"){

                    if(type==0){
                        let d = [...collabList]
                        setCollabList(d.concat(json.data))
                    }else if(type==1){
                        let d = [...orgList]
                        setOrgList(d.concat(json.data))
                    }else if(type==2){
                        let d = [...angelList]
                        setAngelList(d.concat(json.data))
                    }

                }else{
                    // this means ai is not running show button
                    toast.error(json.message)
                }
                
            }

        })
        .catch((error)=>{
            
        });
    }

    const [popupAngelInvestor, setPopupAngelInvestor] = useState(false)
    const [popupOrgInvestor, setPopupOrgInvestor] = useState(false)

    const viewDetailsToggle = (e, type, uuid) => {
        e.preventDefault()

        getInvestorDetails(type, uuid)

    }

    let loadingDetailsAPI = useRef(null);
    const [investorDetails, setInvestorDetails] = useState({})

    const getInvestorDetails = (type, uuid) =>{

        loadingDetailsAPI = toast.loading("Loading data...", {
            closeOnClick: true,
        });
        
        axios
        .get(`${API_URL}${API_GET_INVESTOR_DETAILS_AIHUB}/${type}/${uuid}`,{
            headers: authHeader()
        })
        .then((response)=>{
            
            if(response.status==200){
  
                const json = response.data;
  
                if(json.status=="RC200"){

                    toast.dismiss(loadingDetailsAPI);

                    if(type=="P"){
                        setPopupOrgInvestor(false)
                        setPopupAngelInvestor(true)
                    }else if(type=="O"){
                        setPopupAngelInvestor(false)
                        setPopupOrgInvestor(true)
                    }

                    setInvestorDetails(json.data)
  
                }else{
                    toast.update(loadingDetailsAPI, {
                        render: json.message,
                        type: "error",
                        isLoading: false,
                        autoClose: 5000,
                    });
                }
  
            }
  
        })
        .catch((error)=>{
            toast.update(loadingDetailsAPI, {
                render: "Error, Please try again...",
                type: "error",
                isLoading: false,
                autoClose: 5000,
            });
        });
  
    };

    return(
        <LoaderWrapperView isLoading={isLoading} >

            <>
                {!isAIstarted ? (
                    <div style={{margin:"-10px 10px 0px 10px"}} className="">
                        <div className="row merged20" id="page-contents">
                            <div className="col-md-12">
                                <div className="central-meta">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h3 style={{ color: "#f05a24" }}>Hello and welcome to SCIONS Hub!</h3>

                                            <p>Our platform leverages advanced communication technologies and smart algorithms to connect visionaries with networks of researchers, inventors, entrepreneurs, investors, collaborators and experts worldwide. By using our system, you can access a wide range of resources and opportunities to boost your ideas to the next level.</p>
                                            {/* <p>By using our system, you can access a wide range of resources and opportunities to boost your ideas to the next level. Tap into the power of our hub to discover the perfect partners for your concept and bring your ideas to life.</p> */}
                                            <p>Tap into the power of our hub to discover the perfect partners for your concept and bring your ideas to life.</p>
                                            <p><strong>Let’s revolutionize the future together.</strong></p>

                                            <ExtButton
                                                isLoading={runIsLoading}
                                                type="submit"
                                                className="mtr-btn large"
                                                onClick={(e)=>{
                                                    runAI()
                                                }}
                                                >
                                                <span>Run SCIONS AI</span>
                                            </ExtButton>
                                        </div>
                                        <div className="col-md-6">
                                            <img src="/assets/images/run-ai-img.jpg" />
                                        </div>
                                    </div>    
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div style={{margin:"-10px 10px 0px 10px"}} className="">
                        
                        {status==0 ? (
                            <div style={{margin:"-10px 10px 0px 10px"}} className="">
                                <div className="row merged20" id="page-contents">
                                    <div className="col-md-12">
                                        <div className="central-meta">
                                            <div className="text-center">
                                                <h5 style={{ color: "#f05a24" }} dangerouslySetInnerHTML={{__html: msg}} ></h5>
                                                <br />
                                                <iframe frameBorder={0} height={500} src="https://lottie.host/embed/85a46583-822b-41d1-95b0-3d3905974a6f/NLRW4Cw9cm.json"></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                                <p>The data provided below comes from our AI Engine and is directly related to your Idea's information. We strive to present results that closely align with your core Idea and base country as indicated in your personal profile. However, please note that not all results will be from the same location. Therefore, we present a diverse range of results for investors, collaborators, and organizations worldwide.</p>
                                <p style={{fontSize: "18px", textAlign:"center", color: "#ea663a"}}><strong>We continually enhance our system to ensure ongoing improvements.</strong></p>
                                <div className="row merged20" id="page-contents">
                                    <div className="col-md-6">
                                        <div className="central-meta" style={{padding: "0px"}}>
                                            <div className="chart-heading" style={{margin:"15px", padding: "0px"}}>
                                                <h5 style={{margin:"0px", textTransform:"none"}}>Investors for your Idea category</h5>
                                            </div>

                                            <InvestorsChart data={{				
                                                type: "column",
                                                dataPoints: data.graph_investors ?? []
                                            }} />
                                        </div>
                                    </div>
                                    
                                    <div className="col-md-6">
                                        <div className="central-meta" style={{padding: "0px"}}>
                                            <div className="chart-heading" style={{margin:"15px",padding: "0px"}}>
                                                <h5 style={{margin:"0px", textTransform:"none"}}>Collaborators for your Idea category</h5>
                                            </div>
                                            <InvestorsChart data={{				
                                                type: "column",
                                                dataPoints: data.graph_collaborators ?? []
                                            }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row merged20" id="page-contents">
                                    <div className="col-md-6">
                                        <div className="card card-with-title">
                                            <div className="title">
                                                <h5>Angel Investors proposed for your Idea</h5>
                                            </div>
                                            <table className="table">
                                                <tbody>
                                                    {angelList.length==0 && (
                                                        <tr>
                                                            <td>Angel Investor(s) not found</td>
                                                        </tr>
                                                    )}
                                                    {angelList?.map((val, index)=>(
                                                        <tr key={`aip${index}`}>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <div style={{marginRight: "10px", textAlign:"center", minWidth: "70px"}}>
                                                                        <img className="img-circle-40" src={val.profile_image} /><br />
                                                                        <strong className="angel-rank">Rank {index+1}</strong>
                                                                    </div>
                                                                    <div>
                                                                        <p className="m-0"><strong style={{ color: "#f05a24" }}>{val.first_name} {val.last_name}</strong> ({val.country_name ?? "India"})</p>
                                                                        <p>{val.job_position} ({val.total_investments} Investments)</p>
                                                                        <p><a onClick={(e)=>viewDetailsToggle(e, "P", val.uuid)} href="" style={{ color: "#f05a24" }} >View Details</a></p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}

                                                    {(data.angel_investors_total>5 && angelList.length<=5) && (
                                                        <tr>
                                                            <td className="text-right">
                                                                <p><a onClick={(e)=>{
                                                                    e.preventDefault()
                                                                    loadMoreData(2)
                                                                }} href="" style={{ color: "#f05a24" }} >View more</a></p>
                                                            </td>
                                                        </tr>
                                                    )}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="card card-with-title">
                                            <div className="title">
                                                <h5>Collaborators proposed for your Idea</h5>
                                            </div>
                                            <table className="table">
                                                <tbody>
                                                    {collabList?.length==0 && (
                                                        <tr>
                                                            <td>Collaborators not found</td>
                                                        </tr>
                                                    )}
                                                    {collabList?.map((val, index)=>(
                                                        <tr key={`c${index}`}>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <div style={{marginRight: "10px", textAlign:"center", minWidth: "70px"}}>
                                                                        <img className="img-rect-100" src={val.company_logo} /><br />
                                                                        <strong className="angel-rank">Rank {index+1}</strong>
                                                                    </div>
                                                                    <div>
                                                                        <p className="m-0"><a style={{ color: "#f05a24" }} href={val.website} target="_blank">{val.company_name}</a></p>
                                                                        <p className="line-clamp-1"><strong>Location:</strong> {val.location}</p>
                                                                        <p><a onClick={(e)=>{
                                                                            viewDetailsToggle(e, "O", val.uuid)
                                                                        }} href="" style={{ color: "#f05a24" }} >View Details</a></p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}

                                                    {(data.collaborators_total>5 && collabList.length<=5) && (
                                                        <tr>
                                                            <td className="text-right">
                                                                <p><a onClick={(e)=>{
                                                                    e.preventDefault()
                                                                    loadMoreData(0)
                                                                }} href="" style={{ color: "#f05a24" }} >View more</a></p>
                                                            </td>
                                                        </tr>
                                                    )}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="row merged20" id="page-contents">
                                    <div className="col-md-12">
                                        <div className="card card-with-title">
                                            <div className="title">
                                                <h5>Investment Organizations proposed for your Idea</h5>
                                            </div>
                                            <table className="table">
                                                <tbody>
                                                    {orgList?.length==0 && (
                                                        <tr>
                                                            <td>Organization(s) not found</td>
                                                        </tr>
                                                    )}
                                                    {orgList?.map((val, index)=>(
                                                        <tr key={`o${index}`}>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <div style={{marginRight: "10px", textAlign:"center", minWidth: "70px"}}>
                                                                        <img className="img-rect-100" src={val.company_logo} /><br />
                                                                        <strong className="angel-rank">Rank {index+1}</strong>
                                                                    </div>
                                                                    <div>
                                                                        <p className="m-0"><a style={{ color: "#f05a24" }} href={val.website} target="_blank">{val.company_name}</a></p>
                                                                        <p className="line-clamp-1"><strong>Location:</strong> {val.location}</p>
                                                                        <p><a onClick={(e)=>viewDetailsToggle(e, "O", val.uuid)} href="" style={{ color: "#f05a24" }} >View Details</a></p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}

                                                    {(data.organizations_total>5 && orgList.length<=5) && (
                                                        <tr>
                                                            <td className="text-right">
                                                                <p><a onClick={(e)=>{
                                                                    e.preventDefault()
                                                                    loadMoreData(1)
                                                                }} href="" style={{ color: "#f05a24" }} >View more</a></p>
                                                            </td>
                                                        </tr>
                                                    )}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="text-center">
                                    <br />
                                    <a
                                        type="link"
                                        href="https://forms.gle/F4NwdMmR9jx5aVJs6"
                                        target="_blank"
                                        directLink
                                        className="mtr-btn"
                                        >
                                        <span>Share your Feedback</span>
                                    </a>
                                </div>

                                <AngelInvestorPopup data={investorDetails} onClose={()=>{
                                    setPopupAngelInvestor(false)
                                }} show={popupAngelInvestor} />

                                <OrgInvestorPopup data={investorDetails} onClose={()=>{
                                    setPopupOrgInvestor(false)
                                }} show={popupOrgInvestor} />
                            </>
                        )}

                    </div>
                )}
            </>

        </LoaderWrapperView>
    )

}

const AngelInvestorPopup = (props) => {

    const [modalStatus, setModalStatus] = useState(props.show || false)
    const [data, setData] = useState(props.data || {})

    useEffect(()=>{
        setModalStatus(props.show || false)
        setData(props.data || {})
        setIsReadingMore(false)
        setIsTruncated(false)
    }, [props])

    const ref = useRef(null);

    const [isTruncated, setIsTruncated] = useState(false);
    const [isReadingMore, setIsReadingMore] = useState(false);
    
    const checkLayoutChange = () => {
        if(!modalStatus) return

        const { offsetHeight, scrollHeight } = ref.current || {};

        console.log(offsetHeight, scrollHeight)
    
        if (offsetHeight && scrollHeight && (offsetHeight+20) < scrollHeight) {
            setIsTruncated(true);
        } else {
            setIsTruncated(false);
        }
    }

    useEffect(()=>{
        checkLayoutChange()
    }, [modalStatus])

    useLayoutEffect(() => {
        checkLayoutChange()
    }, [ref]);

    
    return(
        <div className={`custom-modal ${modalStatus ? "show" : ""}`}>
            <div className="custom-modal-cnt text-center" style={{width: "60%", padding:"0px"}}>
                
                <div className="custom-modal-header">
                    <h5>Angel Investors proposed for your Idea</h5>
                </div>

                <div className="custom-modal-content">
 
                    <div className="row profile-details">
                        <div className="col-md-2 image-sec">
                            <img src={data.image_url} className="u-profile" />
                            <div style={{display: "flex", justifyContent:"center"}}>
                                {data.website && (
                                    <a target="_blank" href={data.website}><img src="/ic-web.png" className="ic" /></a>
                                )}

                                {data.twitter && (
                                    <a target="_blank" href={data.twitter}><img src="/ic-x.png" className="ic" /></a>
                                )}
                            </div>
                        </div>
                        <div className="col-md-10 details-sec">
                            <strong>{data.name} {data.country && (<>({data.country})</>)}</strong>
                            <p>{data.primary_organization} {data.primary_job_title && (<>({data.primary_job_title})</>)}</p>
                            {data.location_identifiers && (<><i className="fa fa-location-pin" /> {data.location_identifiers}</>)}
                            <p ref={ref} className={`${!isReadingMore && 'line-clamp-3'}`} style={{marginTop:"10px", fontSize:"14px", color:"#666", lineHeight: "16px"}}>{data.description}</p>
                            {isTruncated && (
                                isReadingMore ? (
                                    <a style={{color:"#ed6031"}} onClick={() => setIsReadingMore(false)}>Read less</a>
                                ) : (
                                    <a style={{color:"#ed6031"}} onClick={() => setIsReadingMore(true)}>Read more</a>
                                )
                            )}
                        </div>
                    </div>
                    <br />
                    <div className="pd-boxes">
                        <div className="pdb">
                            <div className="rnd">
                                <h1>{data.num_founded_organizations}</h1>
                            </div>
                            <strong>Companies founded</strong>
                        </div>
                        <div className="pdb">
                            <div className="rnd">
                                <h1>{data.num_investments}</h1>
                            </div>
                            <strong>Investment done</strong>
                        </div>
                        <div className="pdb">
                            <div className="rnd">
                                <h1>{data.num_event_appearances}</h1>
                            </div>
                            <strong>Number of Event Appearances</strong>
                        </div>
                        <div className="pdb">
                            <div className="rnd">
                                <h1>{data.num_current_jobs}</h1>
                            </div>
                            <strong>Current Jobs</strong>
                        </div>
                        <div className="pdb">
                            <div className="rnd">
                                <h1>{data.num_current_advisor_jobs}</h1>
                            </div>
                            <strong>Current Advisor Jobs</strong>
                        </div>
                        <div className="pdb">
                            <div className="rnd">
                                <h1>{data.num_portfolio_organizations}</h1>
                            </div>
                            <strong>Portfolio Organizations</strong>
                        </div>
                    </div>

                    <button onClick={(e) => {
                        setModalStatus(false)
                        props.onClose && props.onClose()
                    }} className="mtr-btn" type="button">
                        <span>Close</span>
                    </button>
                </div>

                
            </div>
        </div>
    )

}

const OrgInvestorPopup = (props) => {

    const [modalStatus, setModalStatus] = useState(props.show || false)
    const [data, setData] = useState(props.data || {})

    useEffect(()=>{
        setModalStatus(props.show || false)
        setData(props.data || {})
        setIsReadingMore(false)
        setIsTruncated(false)
        
        setTimeout(()=>{
            mcontent.current && mcontent.current.scrollTo({
                top:0,
                behavior: 'smooth'
            })
        }, 100)

    }, [props])

    const ref = useRef(null);
    const mcontent = useRef(null)

    const [isTruncated, setIsTruncated] = useState(false);
    const [isReadingMore, setIsReadingMore] = useState(false);
    
    const checkLayoutChange = () => {
        if(!modalStatus) return

        const { offsetHeight, scrollHeight } = ref.current || {};

        console.log(offsetHeight, scrollHeight)
    
        if (offsetHeight && scrollHeight && (offsetHeight+20) < scrollHeight) {
            setIsTruncated(true);
        } else {
            setIsTruncated(false);
        }
    }

    useEffect(()=>{
        checkLayoutChange()
    }, [modalStatus])

    useLayoutEffect(() => {
        checkLayoutChange()
    }, [ref]);

    return(
        <div className={`custom-modal ${modalStatus ? "show" : ""}`}>
            <div className="custom-modal-cnt text-center" style={{width: "60%", padding:"0px"}}>
                
                <div className="custom-modal-header">
                    <h5>Investment Organizations/Collaborators proposed for your Idea</h5>
                </div>

                <div ref={mcontent} className="custom-modal-content custom-modal-content-org">
 
                    <div className="row profile-details">
                        <div className="col-md-2 image-sec">
                            <img style={{objectFit: "contain"}} src={data.image_url} className="u-profile" />
                            <div style={{display: "flex", justifyContent:"center"}}>
                                {data.website && (
                                    <a target="_blank" href={data.website}><img src="/ic-web.png" className="ic" /></a>
                                )}

                                {data.linkedin && (
                                    <a target="_blank" href={data.linkedin}><img src="/ic-linkedin.png" className="ic" /></a>
                                )}
                            </div>
                        </div>
                        <div className="col-md-10 details-sec">
                            <strong>{data.name}</strong>
                            <br />
                            {data.location_identifiers && (<><small><i className="fa fa-location-pin" /> {data.location_identifiers}</small></>)}

                            <p>
                                {data.phone_number && (<><small><i className="fa fa-phone" /> {data.phone_number}</small> &nbsp; &nbsp; &nbsp;</>)} 
                                {data.contact_email && (<><small><i className="fa fa-envelope" /> {data.contact_email}</small></>)}
                            </p>
                            <p ref={ref} className={`${!isReadingMore && 'line-clamp-3'}`} style={{marginTop:"10px", fontSize:"14px", color:"#666", lineHeight: "16px"}}>{data.description}</p>
                            {isTruncated && (
                                isReadingMore ? (
                                    <a style={{color:"#ed6031"}} onClick={() => setIsReadingMore(false)}>Read less</a>
                                ) : (
                                    <a style={{color:"#ed6031"}} onClick={() => setIsReadingMore(true)}>Read more</a>
                                )
                            )}
                        </div>
                    </div>
                    <br />
                    <h5>Company Details</h5>
                    <div className="pd-boxes-org">
                        <div className="pdb">
                            <div>
                                <div>Type of Company</div>
                                <div><strong>{data.company_type}</strong></div>
                            </div>
                        </div>
                        <div className="pdb">
                            <div>
                                <div>Area of Interest</div>
                                <div><strong>{data.category_groups}</strong></div>
                            </div>
                        </div>
                        <div className="pdb">
                            <div>
                                <div>Legal name of Organizaation</div>
                                <div><strong>{data.legal_name}</strong></div>
                            </div>
                        </div>
                        <div className="pdb">
                            <div>
                                <div>Revenue Range</div>
                                <div><strong>{data.revenue_range}</strong></div>
                            </div>
                        </div>
                    </div>

                    <br />
                    <h5>Founder / Investor Details</h5>
                    <div className="pd-boxes-org">
                        <div className="pdb">
                            <div>
                                <div>Founded on</div>
                                <div><strong>{data.founded_on}</strong></div>
                            </div>
                        </div>
                        <div className="pdb">
                            <div>
                                <div>Number of Founders</div>
                                <div><strong>{data.num_founders}</strong></div>
                            </div>
                        </div>
                        <div className="pdb">
                            <div>
                                <div>Investments Stage</div>
                                <div><strong>{data.investor_stage}</strong></div>
                            </div>
                        </div>
                        <div className="pdb">
                            <div>
                                <div>Investor Type</div>
                                <div><strong>{data.investor_type}</strong></div>
                            </div>
                        </div>
                    </div>

                    <br />
                    <h5>Funding Details</h5>
                    <div className="pd-boxes-org">
                        <div className="pdb">
                            <div>
                                <div>Last Equity Funding</div>
                                <div><strong>{data.last_equity_funding_total}</strong></div>
                            </div>
                        </div>
                        <div className="pdb">
                            <div>
                                <div>Total funding amt. raised</div>
                                <div><strong>{data.funds_total}</strong></div>
                            </div>
                        </div>
                        <div className="pdb">
                            <div>
                                <div>Funding Stage</div>
                                <div><strong>{data.funding_stage}</strong></div>
                            </div>
                        </div>
                        <div className="pdb">
                            <div>
                                <div>Last Equity Funding Type</div>
                                <div><strong>{data.last_equity_funding_type}</strong></div>
                            </div>
                        </div>
                    </div>
                    <div className="pd-boxes-org">
                        <div className="pdb">
                            <div>
                                <div>Last Funding at</div>
                                <div><strong>{data.last_funding_at}</strong></div>
                            </div>
                        </div>
                        <div className="pdb">
                            <div>
                                <div>Last Funding Type</div>
                                <div><strong>{data.last_funding_type}</strong></div>
                            </div>
                        </div>
                        <div className="pdb">
                            <div>
                                <div>Program Type</div>
                                <div><strong>{data.program_type}</strong></div>
                            </div>
                        </div>
                        <div className="pdb">
                            <div>
                                <div>Most Recent Funding Round</div>
                                <div><strong>{data.last_funding_total}</strong></div>
                            </div>
                        </div>
                    </div>

                    <button onClick={(e) => {
                        setModalStatus(false)
                        props.onClose && props.onClose()
                    }} className="mtr-btn" type="button">
                        <span>Close</span>
                    </button>
                </div>

                
            </div>
        </div>
    )

}

const InvestorsChart = (props) => {

    const [options, setOptions] = useState({})

    useEffect(()=>{
        
        setOptions({
            backgroundColor:"#fff3ef",
            axisX:{
                tickColor: "#c04c23",
                tickLength: 5,
                tickThickness: 2
              },
              axisY:{
                tickColor: "#c04c23",
                tickThickness: 1,
                gridColor: "#c04c23" ,
                gridThickness: 1        
              },
            title: {
                text: ""
              },
              dataPointBackground: "linear-gradient(#e66465, #9198e5)",
              animationEnabled: true,
              data: [props.data]
        })
        
    },[props])

    return(
        options && <div style={{padding: "10px", background: "#fff3ef"}} ><CanvasJSChart options = {options} /></div>
    )

}

export default AIHub;