import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { API_FIELD_TREE, API_PROBLEM_LIST, API_SIGN_PROBLEM_NDA, API_URL } from "../../../../services/ApiEndpoints";
import axios from "axios";
import authHeader from "../../../../services/auth-header";
import React, { useEffect, useRef, useState } from "react";
import LoaderWrapperView from "../../../libs/LoaderWrapperView";
import TreeView from "../../../libs/TreeView";
import { Button, Modal } from 'react-bootstrap';
import ExtButton from "../../../libs/ExtButton";
import { toast } from "react-toastify";
import NDAText from "../../ideas/NDAText";

const ProblemList = (props) => {

    const queryString = require('query-string');

    const location = useLocation();
    
    const navigate = useNavigate()

    const query = queryString.parse(location.search)

    const [currentPage, setCurrentPage] = useState(1)
    const [list, setList] = useState([])
    const [links, setLinks] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const [filters, setFilters] = useState({
        fields: query["field"]!=undefined ? [query["field"]] : []
    })

  const getData = () =>{
    
    setIsLoading(true)

    axios
        .post(API_URL + API_PROBLEM_LIST,{
                page: currentPage,
                fields:filters.fields
            },
            {
              headers: authHeader()
            }
        )
        .then((response)=>{
            
            if(response.status==200){

                const json = response.data;

                if(json.status=="RC200"){

                    var resp = json.data;
                    
                    setLinks(resp.links)
                    setList(resp.data)

                }

                setIsLoading(false)

            }

        })
        .catch((error)=>{

            setIsLoading(false)

        });

  }

  const handleLinkClick = (e, cPage) =>{
      e.preventDefault()
      setCurrentPage( cPage )
  }

  const printLinks = (link) =>{

        const isNullLink = link.url ? "" : "disabled" 

        if(link.label==="...") {
            return(<li key={link.label} className={`page-item ${isNullLink}`}>
                <a className="page-link" onClick={ (e) => e.preventDefault() } href="#a">{link.label}</a>
            </li>)
        }

        if(link.label==="&laquo; Previous") {
            return(
                <li key={link.label} className={`page-item ${isNullLink}`}>
                    <a onClick={ (e)=> handleLinkClick(e, currentPage-1)} className="page-link prev" href="#a">
                    Previous
                    </a>
                </li>
            )
        }
        
        if(link.label==="Next &raquo;") {
            return(
                <li key={link.label} className={`page-item ${isNullLink}`}>
                    <a onClick={ (e)=>handleLinkClick(e, currentPage+1) } className="page-link next" href="#n" aria-label="Next">
                    Next
                    </a>
                </li>
            )
        }
        
        const isActive = link.active ? "active" : ""

        return(
            <li key={link.label} className={`page-item ${isActive}`}>
                <a className="page-link" onClick={ (e) => handleLinkClick(e, parseInt(link.label,10)) } href="#a">{link.label}</a>
            </li>
        )
    }

  useEffect(()=>{
    setCurrentPage(1)
    getData()

  }, [currentPage, filters])

  const [showNDA, setShowNDA] = useState(false)
  const [currentData, setCurrentData] = useState({})

  const [isNDALoading, setIsNDALoading] = useState(false)

  const submitNDA = () =>{

    setIsNDALoading(true)

    var formdata = new FormData()
    formdata.append("slug", currentData.slug);

    axios.post(API_URL + API_SIGN_PROBLEM_NDA, formdata,{
        headers:authHeader()
    })
    .then((responsex) => {

        setIsNDALoading(false)

        if(responsex.data.status=="RC200"){

            toast.success(responsex.data.message)

            setShowNDA(false)

            navigate(`/problems/${currentData.slug}`)

        }

        return responsex.data;
    }).error(()=>{
        setIsNDALoading(false)
    });

  }

  const popupHowItWork = useRef()

  return (
    <section>
        <div className="mt-4">
            <div className="container">
                
            <div className="row" id="page-contents">
                    <div className="col-md-3">
                        <aside className="sidebar stick-widget">
                            <div className="widget">
                                <h4 className="widget-title">Filter by Categories</h4>
        
                                <TreeView endpoint={API_FIELD_TREE} value={filters.fields} onCheckChange={(checked)=>{

                                    const obj = {...filters}
                                    obj["fields"] = checked
                                    setFilters(obj)

                                }} />

                            </div>

                        </aside>
                    </div>

                        <LoaderWrapperView isLoading={isLoading}>
                            <div className="col-md-9">
                                <div className="row">
                                    
                                    {list && list.length==0 ? (
                                        <p>Problems not found</p>
                                    ) : list.map((val, i) => (
                                        <SingleProblem checkNDA={(e)=>{

                                            e.preventDefault()

                                            if(val.is_nda_done==1)
                                                navigate(`/problems/${val.slug}`)
                                            else{
                                                setCurrentData(val)
                                                setShowNDA(true)
                                            }

                                        }} key={val.id} data={val} />
                                    ))}

                                </div>

                                { links.length>3 && (
                                    <div className="col-md-12">
                                        <nav className="mt-4 mb-3">
                                            <ul className="pagination justify-content-center mb-0">

                                                { links.map((link)=>(

                                                    printLinks(link)
                                                    
                                                ))}
                                                
                                            </ul>
                                        </nav>
                                    </div>
                                )}

                            </div>
                        </LoaderWrapperView>
                    </div>

                    <Modal noMargin dialogClassName={"full-modal"} show={showNDA} aria-labelledby="contained-modal-title-vcenter" onHide={()=>setShowNDA(false)}>  
                    
                        <Modal.Body>
                            
                            <NDAText secondParty={currentData ? currentData.full_name : ""} secondPartyRole={currentData ? currentData.role_name : ""} ideaTitle={currentData ? currentData.idea_title : ""} />

                            <br /><br />


                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <ExtButton isLoading={isNDALoading} showLoading={false} type="button" className="btn btn-link mr-50" onClick={()=>setShowNDA(false)}><span>Cancel</span></ExtButton>

                                    <ExtButton isLoading={isNDALoading} showLoading={true} type="button" className="mtr-btn" onClick={(e)=>{
                                        submitNDA()
                                    }}><span>I understand and Agree</span></ExtButton>
                                </div>
                            </div>


                        </Modal.Body>

                    </Modal>

            </div>
        </div>
    </section>
  );
};

const SingleProblem = (props) => {

  const {data} = props

  return(
    <div className="col-md-4">
        
        <div className="g-post-classic idea-min-height">
            <figure>
                <a onClick={props.checkNDA} >
                    <img className="img-max-250" alt={data.pain_point} src={data.image} />
                </a>
            </figure>
            <div className="g-post-meta">
                <div className="post-title">
                    <h4>
                        <a href="" onClick={props.checkNDA}>{data.pain_point}</a>
                    </h4>
                    <span className="p-date">
                        by <Link to={`/./${data.public_url}`}>{data.full_name}</Link><br />{data.date}
                    </span>
                </div>
                <div className="g-post-ranking">
                    <a title="" href="#" className="likes">{data.eng_field}</a>
                </div>
            </div>
        </div>
    </div>
  )

};

export default ProblemList;
