
const NotFound = () =>{

    return(
        <section>
            <div className="mt-4">
                <div style={{marginTop:"60px"}} className="container">
                    <h2 className="text-center">Page not found.</h2>
                </div>
            </div>
        </section>
    )
    
}

export default NotFound;