import { useEffect, useState } from "react"

const IdeaTLRV4 = (props) =>{
    
    const [TLR1, setTLR1] = useState(false)
    const [TLR2, setTLR2] = useState(false)
    const [TLR3, setTLR3] = useState(false)
    const [TLR4, setTLR4] = useState(false)
    const [TLR5, setTLR5] = useState(false)
    const [TLR6, setTLR6] = useState(false)
    const [TLR7, setTLR7] = useState(false)
    const [TLR8, setTLR8] = useState(false)
    const [TLR9, setTLR9] = useState(false)

    const [current, setCurrent] = useState(-1)

    useEffect(()=>{

        setCurrent(props.selected ? props.selected : -1)

        if(props.selected==1)
            setTLR1(true)
        else if(props.selected==2){
            setTLR1(true)
            setTLR2(true)
        }else if(props.selected==3){
            setTLR1(true)
            setTLR2(true)
            setTLR3(true)
        }else if(props.selected==4){
            setTLR1(true)
            setTLR2(true)
            setTLR3(true)
            setTLR4(true)
        }else if(props.selected==5){
            setTLR1(true)
            setTLR2(true)
            setTLR3(true)
            setTLR4(true)
            setTLR5(true)
        }else if(props.selected==6){
            setTLR1(true)
            setTLR2(true)
            setTLR3(true)
            setTLR4(true)
            setTLR5(true)
            setTLR6(true)
        }else if(props.selected==7){
            setTLR1(true)
            setTLR2(true)
            setTLR3(true)
            setTLR4(true)
            setTLR5(true)
            setTLR6(true)
            setTLR7(true)
        }else if(props.selected==8){
            setTLR1(true)
            setTLR2(true)
            setTLR3(true)
            setTLR4(true)
            setTLR5(true)
            setTLR6(true)
            setTLR7(true)
            setTLR8(true)
        }else if(props.selected==9){
            setTLR1(true)
            setTLR2(true)
            setTLR3(true)
            setTLR4(true)
            setTLR5(true)
            setTLR6(true)
            setTLR7(true)
            setTLR8(true)
            setTLR9(true)
        }

    }, [])

    return(
        <>
            <br />
            <h5>Select your Technology Readiness Level (TRL)</h5>
            <div className="tlr-content">
                <div className="d-md-none d-lg-none">
                    <table className="table mt-3" style={{color: "#FFF", width:"100%"}}>
                        <tbody>
                            <tr className="from-1-3">
                                <td style={{fontSize:"20px", fontWeight:"700", textAlign:"center"}} colSpan="2">RESEARCH</td>
                            </tr>
                            <tr className="from-1-3">
                                <td width="2%">1.</td>
                                <td>
                                    <label>
                                        <input checked={TLR1} onChange={(e)=>{
                                            setTLR1(e.target.checked)

                                            props.onSelected && props.onSelected( e.target.checked ? e.target.value : -1 )

                                            if(!e.target.checked){
                                                setTLR2(false)
                                                setTLR3(false)
                                                setTLR4(false)
                                                setTLR5(false)
                                                setTLR6(false)
                                                setTLR7(false)
                                                setTLR8(false)
                                                setTLR9(false)
                                            }

                                        }} type="checkbox" value="1" /> Basic Principles observed
                                    </label>
                                </td>
                            </tr>
                            <tr className="from-1-3">
                                <td>2.</td>
                                <td>
                                    <label>
                                        <input disabled={!TLR1} checked={TLR2} onChange={(e)=>{
                                            setTLR2(e.target.checked)
                                            
                                            props.onSelected && props.onSelected( e.target.checked ? e.target.value : e.target.value-1 )

                                            if(!e.target.checked){
                                                setTLR3(false)
                                                setTLR4(false)
                                                setTLR5(false)
                                                setTLR6(false)
                                                setTLR7(false)
                                                setTLR8(false)
                                                setTLR9(false)
                                            }

                                        }} type="checkbox" value="2" /> Technology Concept Formulated
                                    </label>
                                </td>
                            </tr>
                            <tr className="from-1-3">
                                <td>3.</td>
                                <td>
                                    <label>
                                        <input disabled={!TLR2} checked={TLR3} onChange={(e)=>{
                                            setTLR3(e.target.checked)
                                            
                                            props.onSelected && props.onSelected( e.target.checked ? e.target.value : e.target.value-1 )

                                            if(!e.target.checked){
                                                setTLR4(false)
                                                setTLR5(false)
                                                setTLR6(false)
                                                setTLR7(false)
                                                setTLR8(false)
                                                setTLR9(false)
                                            }

                                        }} type="checkbox" value="3" /> Experimental proof of concept
                                    </label>
                                </td>
                            </tr>

                            <tr className="from-4-6">
                                <td style={{fontSize:"20px", fontWeight:"700", textAlign:"center"}} colSpan="2">DEVELOPMENT</td>
                            </tr>
                            <tr className="from-4-6">
                                <td>4.</td>
                                <td>
                                    <label>
                                        <input disabled={!TLR3} checked={TLR4} onChange={(e)=>{
                                            setTLR4(e.target.checked)
                                            
                                            props.onSelected && props.onSelected( e.target.checked ? e.target.value : e.target.value-1 )

                                            if(!e.target.checked){
                                                setTLR5(false)
                                                setTLR6(false)
                                                setTLR7(false)
                                                setTLR8(false)
                                                setTLR9(false)
                                            }

                                        }} type="checkbox" value="4" /> Technology validated in LAB
                                    </label>
                                </td>
                            </tr>
                            <tr className="from-4-6">
                                <td>5.</td>
                                <td>
                                    <label>
                                        <input disabled={!TLR4} checked={TLR5} onChange={(e)=>{
                                            setTLR5(e.target.checked)
                                            
                                            props.onSelected && props.onSelected( e.target.checked ? e.target.value : e.target.value-1 )

                                            if(!e.target.checked){
                                                setTLR6(false)
                                                setTLR7(false)
                                                setTLR8(false)
                                                setTLR9(false)
                                            }

                                        }} type="checkbox" value="5" /> Technology validated in relevant environment
                                    </label>
                                </td>
                            </tr>
                            <tr className="from-4-6">
                                <td>6.</td>
                                <td>
                                    <label>
                                        <input disabled={!TLR5} checked={TLR6} onChange={(e)=>{
                                            setTLR6(e.target.checked)
                                            
                                            props.onSelected && props.onSelected( e.target.checked ? e.target.value : e.target.value-1 )

                                            if(!e.target.checked){
                                                setTLR7(false)
                                                setTLR8(false)
                                                setTLR9(false)
                                            }

                                        }} type="checkbox" value="6" /> Technology demonstrated in relevant environment
                                    </label>
                                </td>
                            </tr>

                            <tr className="from-7-9">
                                <td style={{fontSize:"20px", fontWeight:"700", textAlign:"center"}} colSpan="2">DEPLOYMENT</td>
                            </tr>
                            <tr className="from-7-9">
                                <td>7.</td>
                                <td>
                                    <label>
                                        <input disabled={!TLR6} checked={TLR7} onChange={(e)=>{
                                            setTLR7(e.target.checked)
                                            
                                            props.onSelected && props.onSelected( e.target.checked ? e.target.value : e.target.value-1 )

                                            if(!e.target.checked){
                                                setTLR8(false)
                                                setTLR9(false)
                                            }

                                        }} type="checkbox" value="7" /> Actual system proven in operational environment
                                    </label>
                                </td>
                            </tr>
                            <tr className="from-7-9">
                                <td>8.</td>
                                <td>
                                    <label>
                                        <input disabled={!TLR7} checked={TLR8} onChange={(e)=>{
                                            setTLR8(e.target.checked)
                                            
                                            props.onSelected && props.onSelected( e.target.checked ? e.target.value : e.target.value-1 )

                                            if(!e.target.checked){
                                                setTLR9(false)
                                            }

                                        }} type="checkbox" value="8" /> System complete and qualified
                                    </label>
                                </td>
                            </tr>
                            <tr className="from-7-9">
                                <td>9.</td>
                                <td>
                                    <label>
                                        <input disabled={!TLR8} checked={TLR9} onChange={(e)=>{
                                            setTLR9(e.target.checked)

                                            props.onSelected && props.onSelected( e.target.checked ? e.target.value : e.target.value-1 )

                                        }} type="checkbox" value="9" /> System prototype demonstration in operational environment
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="d-none d-md-block">
                    <table className="table mt-3" style={{color:"#FFF", width:"100%"}}>
                        <tbody>
                            <tr className="from-1-3">
                                <td width="15%" rowSpan="3" style={{verticalAlign:"middle", fontSize:"20px", fontWeight:"700"}}>RESEARCH</td>
                                <td width="2%">1.</td>
                                <td>
                                    <label>
                                        <input disabled={current>=2} checked={TLR1} onChange={(e)=>{
                                            setTLR1(e.target.checked)

                                            props.onSelected && props.onSelected( e.target.checked ? e.target.value : -1 )
                                            
                                            setCurrent( e.target.checked ? 1 : -1 )

                                        }} type="checkbox" value="1" /> Basic Principles observed
                                    </label>
                                </td>
                            </tr>
                            <tr className="from-1-3">
                                <td>2.</td>
                                <td>
                                    <label>
                                        <input disabled={current>=3} checked={TLR2} onChange={(e)=>{
                                            setTLR2(e.target.checked)
                                            
                                            props.onSelected && props.onSelected( e.target.checked ? e.target.value : e.target.value-1 )

                                            setCurrent( e.target.checked ? 2 : -1 )

                                            setTLR1(e.target.checked)

                                            // if(!e.target.checked){
                                            //     setTLR3(false)
                                            //     setTLR4(false)
                                            //     setTLR5(false)
                                            //     setTLR6(false)
                                            //     setTLR7(false)
                                            //     setTLR8(false)
                                            //     setTLR9(false)
                                            // }

                                        }} type="checkbox" value="2" /> Technology Concept Formulated
                                    </label>
                                </td>
                            </tr>
                            <tr className="from-1-3">
                                <td>3.</td>
                                <td>
                                    <label>
                                        <input disabled={current>=4} checked={TLR3} onChange={(e)=>{
                                            setTLR3(e.target.checked)
                                            
                                            props.onSelected && props.onSelected( e.target.checked ? e.target.value : e.target.value-1 )

                                            setCurrent( e.target.checked ? 3 : -1 )

                                            setTLR1(e.target.checked)
                                            setTLR2(e.target.checked)

                                            // if(!e.target.checked){
                                            //     setTLR4(false)
                                            //     setTLR5(false)
                                            //     setTLR6(false)
                                            //     setTLR7(false)
                                            //     setTLR8(false)
                                            //     setTLR9(false)
                                            // }

                                        }} type="checkbox" value="3" /> Experimental proof of concept
                                    </label>
                                </td>
                            </tr>

                            <tr className="from-4-6">
                                <td rowSpan="3" style={{verticalAlign:"middle", fontSize:"20px", fontWeight:"700"}}>DEVELOPMENT</td>
                                <td>4.</td>
                                <td>
                                    <label>
                                        <input disabled={current>=5} checked={TLR4} onChange={(e)=>{
                                            setTLR4(e.target.checked)
                                            
                                            props.onSelected && props.onSelected( e.target.checked ? e.target.value : e.target.value-1 )

                                            setCurrent( e.target.checked ? 4 : -1 )

                                            setTLR1(e.target.checked)
                                            setTLR2(e.target.checked)
                                            setTLR3(e.target.checked)

                                            // if(!e.target.checked){
                                            //     setTLR5(false)
                                            //     setTLR6(false)
                                            //     setTLR7(false)
                                            //     setTLR8(false)
                                            //     setTLR9(false)
                                            // }

                                        }} type="checkbox" value="4" /> Technology validated in LAB
                                    </label>
                                </td>
                            </tr>
                            <tr className="from-4-6">
                                <td>5.</td>
                                <td>
                                    <label>
                                        <input disabled={current>=6} checked={TLR5} onChange={(e)=>{
                                            setTLR5(e.target.checked)
                                            
                                            props.onSelected && props.onSelected( e.target.checked ? e.target.value : e.target.value-1 )

                                            setCurrent( e.target.checked ? 5 : -1 )

                                            setTLR1(e.target.checked)
                                            setTLR2(e.target.checked)
                                            setTLR3(e.target.checked)
                                            setTLR4(e.target.checked)

                                            // if(!e.target.checked){
                                            //     setTLR6(false)
                                            //     setTLR7(false)
                                            //     setTLR8(false)
                                            //     setTLR9(false)
                                            // }

                                        }} type="checkbox" value="5" /> Technology validated in relevant environment
                                    </label>
                                </td>
                            </tr>
                            <tr className="from-4-6">
                                <td>6.</td>
                                <td>
                                    <label>
                                        <input disabled={current>=7} checked={TLR6} onChange={(e)=>{
                                            setTLR6(e.target.checked)
                                            
                                            props.onSelected && props.onSelected( e.target.checked ? e.target.value : e.target.value-1 )

                                            setCurrent( e.target.checked ? 6 : -1 )

                                            setTLR1(e.target.checked)
                                            setTLR2(e.target.checked)
                                            setTLR3(e.target.checked)
                                            setTLR4(e.target.checked)
                                            setTLR5(e.target.checked)

                                            // if(!e.target.checked){
                                            //     setTLR7(false)
                                            //     setTLR8(false)
                                            //     setTLR9(false)
                                            // }

                                        }} type="checkbox" value="6" /> Technology demonstrated in relevant environment
                                    </label>
                                </td>
                            </tr>

                            <tr className="from-7-9">
                                <td rowSpan="3" style={{verticalAlign:"middle", fontSize:"20px", fontWeight:"700"}}>DEPLOYMENT</td>
                                <td>7.</td>
                                <td>
                                    <label>
                                        <input disabled={current>=8} checked={TLR7} onChange={(e)=>{
                                            setTLR7(e.target.checked)
                                            
                                            props.onSelected && props.onSelected( e.target.checked ? e.target.value : e.target.value-1 )
                                            
                                            setCurrent( e.target.checked ? 7 : -1 )

                                            setTLR1(e.target.checked)
                                            setTLR2(e.target.checked)
                                            setTLR3(e.target.checked)
                                            setTLR4(e.target.checked)
                                            setTLR5(e.target.checked)
                                            setTLR6(e.target.checked)

                                            // if(!e.target.checked){
                                            //     setTLR8(false)
                                            //     setTLR9(false)
                                            // }

                                        }} type="checkbox" value="7" /> Actual system proven in operational environment
                                    </label>
                                </td>
                            </tr>
                            <tr className="from-7-9">
                                <td>8.</td>
                                <td>
                                    <label>
                                        <input disabled={current>=9} checked={TLR8} onChange={(e)=>{
                                            setTLR8(e.target.checked)
                                            
                                            props.onSelected && props.onSelected( e.target.checked ? e.target.value : e.target.value-1 )

                                            setCurrent( e.target.checked ? 8 : -1 )

                                            setTLR1(e.target.checked)
                                            setTLR2(e.target.checked)
                                            setTLR3(e.target.checked)
                                            setTLR4(e.target.checked)
                                            setTLR5(e.target.checked)
                                            setTLR6(e.target.checked)
                                            setTLR7(e.target.checked)

                                            // if(!e.target.checked){
                                            //     setTLR9(false)
                                            // }

                                        }} type="checkbox" value="8" /> System complete and qualified
                                    </label>
                                </td>
                            </tr>
                            <tr className="from-7-9">
                                <td>9.</td>
                                <td>
                                    <label>
                                        <input checked={TLR9} onChange={(e)=>{
                                            setTLR9(e.target.checked)
                                            
                                            props.onSelected && props.onSelected( e.target.checked ? e.target.value : e.target.value-1 )

                                            setCurrent( e.target.checked ? 9 : -1 )

                                            setTLR1(e.target.checked)
                                            setTLR2(e.target.checked)
                                            setTLR3(e.target.checked)
                                            setTLR4(e.target.checked)
                                            setTLR5(e.target.checked)
                                            setTLR6(e.target.checked)
                                            setTLR7(e.target.checked)
                                            setTLR8(e.target.checked)

                                        }} type="checkbox" value="9" /> System prototype demonstration in operational environment
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>


            </div>
        </>
    )

}

export default IdeaTLRV4;