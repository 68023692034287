import { SET_MESSAGE, CLEAR_MESSAGE, UPDATE_PROFILE_STATE, OPEN_CHAT } from "../actions/types";

const initialState = {};

export default function (state = initialState, action) {
    const { type, payload, msgType, userData } = action;
    switch (type) {
      case SET_MESSAGE:
        return { message: payload, msgType:msgType };
      case CLEAR_MESSAGE:
        return { message: "", msgType:null };
      case UPDATE_PROFILE_STATE:
          return { message: "", userData:userData };
      case OPEN_CHAT:
          return { message: "", userData:userData, isChatStatus:true };
      default:
        return state;
    }
  }