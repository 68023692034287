import React, { useEffect, useState } from "react";
import { API_UPDATE_PUBLICATIONS, API_URL } from "../../../services/ApiEndpoints";
import axios from "axios";
import authHeader from "../../../services/auth-header";
import ExtButton from "../../libs/ExtButton";
import {DatePicker, df, mode} from "../../../helpers/DatePicker";
import { useOutletContext } from "react-router-dom";

const Publications = (props) =>{
    
    const [allowReload, setAllowReload] = useOutletContext();

    const {publications} = props

    const [data, setData] = useState(publications)

    useEffect(() => {
        setData(publications);
      }, [publications]);

    const [isLoading, setIsLoading] = useState(false)
    const [hasError, setHasError] = useState(false) 
    const [msg, setMsg] = useState("")

    const addMorePublication = (e) =>{
        e.preventDefault()

        setData( data.concat({
            id:-1,
            title:"",
            author_name:"",
            publisher_name:"",
            publication_date:"",
            publication_website:"",
            description:"",
        }) )
    }

    const handleChange = (index, e) => {

        var publications = [...data]
        publications[index][e.target.name] = e.target.value

        setData(publications)

    }

    const handleDateChange = (index, date) => {
        var publications = [...data]
        publications[index]["publication_date"] = date

        setData(publications)
    }

    const handleRemove = (index, e) => {
        e.preventDefault()

        var publications = [...data]
        publications.splice(index, 1)

        setData(publications)

    }

    const handleSubmit = (e) =>{

        e.preventDefault()

        setHasError(false)
        setMsg("")
        setIsLoading(true)

        var formdata = new FormData()

        data.map((item, index) => {
            formdata.append("title["+ index +"]", item.title)
            formdata.append("author_name["+ index +"]", item.author_name)
            formdata.append("publisher_name["+ index +"]", item.publisher_name)
            formdata.append("publication_date["+ index +"]", item.publication_date)
            formdata.append("publication_website["+ index +"]", item.publication_website)
            formdata.append("description["+ index +"]", item.description)
            formdata.append("id["+ index +"]", item.id)
        })

        axios.post(API_URL + API_UPDATE_PUBLICATIONS, formdata,{
            headers:authHeader()
        })
        .then((responsex) => {

            setIsLoading(false)

            if(responsex.data.status=="RC200"){
                setHasError(false)

                /* update profile completeness */
                setAllowReload(allowReload+1)
            }else{
                setHasError(true)
            }

            setMsg(responsex.data.message)

            return responsex.data;
        });

    }

    return(
        <div className="central-meta" id="publications">
            <div className="editing-info">
                <h5 className="f-title">Publications</h5>

                <form onSubmit={handleSubmit} method="post">

                    { data.length>0 && (
                        data.map((item, index)=>{
                            return(
                                <div key={index} className="row">
                                    <div className="col-md-10">
                                        <div className="form-group">
                                            <input name="title" value={item.title || ""} onChange={ (e) => handleChange(index, e) } type="text" required="required" />
                                            <label className="control-label" >Publication Title *</label>
                                            <i className="mtrl-select"></i>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="text-center form-group ">
                                            <a onClick={ (e) =>{ handleRemove(index, e) } } className="btn btn-link text-danger"><i className="fa fa-close"></i></a>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input name="author_name" value={item.author_name || ""} onChange={ (e) => handleChange(index, e) } type="text" required="required" />
                                            <label className="control-label" >Author Name(s) *</label>
                                            <i className="mtrl-select"></i>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input name="publisher_name" value={item.publisher_name || ""} onChange={ (e) => handleChange(index, e) } type="text" required="required" />
                                            <label className="control-label" >Publisher Name *</label>
                                            <i className="mtrl-select"></i>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <DatePicker format={df.MY} mode={mode.month} onChange={ (e, date)=>{
                                            handleDateChange(index, date)
                                        }} value={item.publication_date} required={true}>
                                            Publication Date *
                                        </DatePicker>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input name="publication_website" value={item.publication_website || ""} onChange={ (e) => handleChange(index, e) } type="text" />
                                            <label className="control-label" >DOI Link</label>
                                            <i className="mtrl-select"></i>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-12">
                                        <div className="form-group">
                                            <textarea name="description" value={item.description || ""} onChange={ (e) => handleChange(index, e) } rows="4" required="required"></textarea>
                                            <label className="control-label">Publication Description</label>
                                            <i className="mtrl-select"></i>
                                        </div>
                                    </div> */}
                                    
                                </div>
                            )
                        })
                    )}

                    <div className="submit-btns col-md-12">
                        <a onClick={addMorePublication}><i className="fa fa-plus-circle"></i> Add publication</a>
                    </div>

                    {msg && (
                        <div className={ hasError ? "alert alert-danger mt-10" : "alert alert-success mt-10" } role="alert">
                            {msg}
                        </div>
                    )}

                    <div className="submit-btns">
                        <ExtButton isLoading={isLoading} className="mtr-btn" type="submit">
                            <span>Update</span>
                        </ExtButton>
                    </div>
                </form>
            </div>
        </div>

    );
};

export default Publications;