import axios from 'axios';
import React, { Suspense, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import './assets/js/tooltip'
import CookieConsent from './components/libs/CookieConsent';
import { API_CHECK_USER_COUNTRY_STATUS, API_URL } from './services/ApiEndpoints';
import authHeader from './services/auth-header';

const PublicRoutes = () => {

    const { isLoggedIn } = useSelector(state => state.auth);
    const auth = isLoggedIn;

    const navigation = useNavigate()

    const checkCountry = () =>{
        
        axios
        .get(API_URL + API_CHECK_USER_COUNTRY_STATUS,{
            headers: authHeader()
        })
        .then((response)=>{
            
            if(response.status==200){

                const json = response.data;

                if(json.status=="RC400"){
                    navigation(`/location-access-denied`, {state:{msg: json.message }})
                }

            }

        })
        .catch((error)=>{
           
        });
    }

    useEffect(()=>{
        checkCountry()
    }, [])

    return auth ? <Navigate to="/overview" /> : <> <Outlet /> <CookieConsent /></>;
}


export default PublicRoutes;