import React, { Component, useEffect, useState } from "react";
import { Rating } from "react-simple-star-rating";
import { API_UPDATE_SKILLS, API_URL } from "../../../services/ApiEndpoints";
import axios from "axios";
import authHeader from "../../../services/auth-header";
import ExtButton from "../../libs/ExtButton";
import { useOutletContext } from "react-router-dom";

const Skills = (props) => {

    const [allowReload, setAllowReload] = useOutletContext();

    const {soft_skills, technical_skills} = props

    const [data, setData] = useState(technical_skills)
    const [data2, setData2] = useState(soft_skills)

    useEffect(() => {
        setData(technical_skills);
        setData2(soft_skills);
      }, [soft_skills, technical_skills]);

    const [isLoading, setIsLoading] = useState(false)
    const [hasError, setHasError] = useState(false) 
    const [msg, setMsg] = useState("")

    const addMoreSkills = (e) =>{
        e.preventDefault()

        setData( data.concat({id:-1, name:"", rating:0}) )
    }

    const addMoreSkills2 = (e) =>{
        e.preventDefault()

        setData2( data2.concat({id:-1, name:"", rating:0}) )
    }

    const handleChange = (index, e) => {

        var skills = [...data]
        skills[index][e.target.name] = e.target.value

        setData(skills)

    }

    const handleChange2 = (index, e) => {

        var skills = [...data2]
        skills[index][e.target.name] = e.target.value

        setData2(skills)

    }

    const handleRating = (index, rate) => {
        var skills = [...data]
        skills[index]["rating"] = rate/20

        setData(skills)
    }

    const handleRating2 = (index, rate) => {
        var skills = [...data2]
        skills[index]["rating"] = rate/20

        setData2(skills)
    }

    const handleRemove = (index, e) => {
        e.preventDefault()

        var skills = [...data]
        skills.splice(index, 1)

        setData(skills)

    }

    const handleRemove2 = (index, e) => {
        e.preventDefault()

        var skills = [...data2]
        skills.splice(index, 1)

        setData2(skills)

    }

    const handleSubmit = (e) =>{

        e.preventDefault()

        var error = 0

        data.map( (item, index) => {
            if(item.rating==0)
                error++
        } )

        if(error>0){
            setHasError(true)
            setMsg("Please select rating")
            return
        }

        setHasError(false)
        setMsg("")
        setIsLoading(true)

        var formdata = new FormData()

        let ind = 0;

        data.map((item, index) => {
            formdata.append("name["+ ind +"]", item.name)
            formdata.append("rating["+ ind +"]", item.rating)
            formdata.append("type["+ ind +"]", 1)
            formdata.append("id["+ ind +"]", item.id)

            ind++;
        })

        data2.map((item, index) => {
            formdata.append("name["+ ind +"]", item.name)
            formdata.append("rating["+ ind +"]", item.rating)
            formdata.append("type["+ ind +"]", 0)
            formdata.append("id["+ ind +"]", item.id)

            ind++;
        })

        axios.post(API_URL + API_UPDATE_SKILLS, formdata,{
            headers:authHeader()
        })
        .then((responsex) => {

            setIsLoading(false)

            if(responsex.data.status=="RC200"){
                setHasError(false)

                /* update profile completeness */
                setAllowReload(allowReload+1)
            }else{
                setHasError(true)
            }

            setMsg(responsex.data.message)

            return responsex.data;
        });

    }

    return(
        <div className="central-meta" id="skills">
            <div className="editing-info">
                <h5 className="f-title">Skills</h5>

                <form onSubmit={handleSubmit} method="post">

                    <h6>Technical Skills</h6><hr />
                    
                    { data.length>0 ? (
                        data.map( (item, index)=> {
                            return(
                                <div className="row" key={index}>
                                    <div className="col-6 col-md-6">
                                        <div className="form-group ">
                                            <input name="name" value={item.name || ""} onChange={
                                                (e) => handleChange(index, e)
                                            } type="text" id={"skill-name-"+ index} required="required" />
                                            <label className="control-label" htmlFor={"skill-name-"+ index}>Add your skill *</label>
                                            <i className="mtrl-select"></i>
                                        </div>
                                    </div>
                                    
                                    <div className="col-6 col-md-4">
                                        Self Rating *<br />
                                        <Rating name="rating" onClick={
                                            (e) => handleRating(index, e)
                                        } stars={5} ratingValue={item.rating*20} size={26} transition fillColor='orange' emptyColor='gray' />
                                    </div>

                                    <div className="col-md-2">
                                        <div className="text-center form-group ">
                                            <a onClick={ (e) =>{ handleRemove(index, e) } } className="btn btn-link text-danger"><i className="fa fa-close"></i></a>
                                        </div>
                                    </div>

                                </div>
                            )
                        })
                    ) : (
                        <p>Please select your skillsets</p>
                    ) }

                    <div className="submit-btns col-md-12 text-right">
                        <a onClick={addMoreSkills}><i className="fa fa-plus-circle"></i> Add</a>
                    </div>

                    <br /><br />
                    <h6>Soft Skills</h6><hr />
                    
                    { data2.length>0 ? (
                        data2.map( (item, index)=> {
                            return(
                                <div className="row" key={index}>
                                    <div className="col-6 col-md-6">
                                        <div className="form-group ">
                                            <input name="name" value={item.name || ""} onChange={
                                                (e) => handleChange2(index, e)
                                            } type="text" id={"skill-name-"+ index} required="required" />
                                            <label className="control-label" htmlFor={"skill-name-"+ index}>Add your skill *</label>
                                            <i className="mtrl-select"></i>
                                        </div>
                                    </div>
                                    
                                    <div className="col-6 col-md-4">
                                        Self Rating *<br />
                                        <Rating name="rating" onClick={
                                            (e) => handleRating2(index, e)
                                        } stars={5} ratingValue={item.rating*20} size={26} transition fillColor='orange' emptyColor='gray' />
                                    </div>

                                    <div className="col-md-2">
                                        <div className="text-center form-group ">
                                            <a onClick={ (e) =>{ handleRemove2(index, e) } } className="btn btn-link text-danger"><i className="fa fa-close"></i></a>
                                        </div>
                                    </div>

                                </div>
                            )
                        })
                    ) : (
                        <p>Please select your skillsets</p>
                    ) }

                    <div className="submit-btns col-md-12 text-right">
                        <a onClick={addMoreSkills2}><i className="fa fa-plus-circle"></i> Add</a>
                    </div>

                    {msg && (
                        <div className={ hasError ? "alert alert-danger mt-10" : "alert alert-success mt-10" } role="alert">
                            {msg}
                        </div>
                    )}

                    <div className="submit-btns">
                        <ExtButton isLoading={isLoading} className="mtr-btn" type="submit">
                            <span>Update</span>
                        </ExtButton>
                    </div>
                </form>
            </div>
        </div>

    );
};

export default Skills;