import axios from "axios";
import { useState } from "react";
import { API_CONTACT_US, API_URL } from "../../services/ApiEndpoints";
import authHeader from "../../services/auth-header";
import Footer from "../layouts/Footer"
import ExtButton from "../libs/ExtButton";
import FormReCaptchaV3 from "../libs/FormReCaptchaV3";
import CommonPagesFooter from "./CommonPagesFooter"
import Select2 from "../libs/Select2";

const ContactUs = () =>{

    const [isLoading, setIsLoading] = useState(false)
    const [msg, setMsg] = useState("");
    const [hasError, setHasError] = useState(false);

    const [email, setEmail] = useState("")
    const [subject, setSubject] = useState("")
    const [name, setName] = useState("")
    const [message, setMessage] = useState("")

    const handleSubmit = (captchaToken) =>{

        setHasError(false)
        setMsg("")
        setIsLoading(true)

        var formdata = new FormData()
        formdata.append("name", name)
        formdata.append("email", email)
        formdata.append("subject", subject)
        formdata.append("message", message)
        formdata.append("recaptcha_token", captchaToken)

        axios.post(API_URL + API_CONTACT_US, formdata,{
            headers:authHeader()
        })
        .then((responsex) => {

            setIsLoading(false)

            if(responsex.data.status=="RC200"){
                setHasError(false)

                setEmail("")
                setName("")
                setMessage("")
                setSubject("")
            }else{
                setHasError(true)
            }

            setMsg(responsex.data.message)

            return responsex.data;
        });

    }

    const contactTypeList = ["Contact Us via Email", "Schedule a Meeting"]

    const [contactType, setContactType] = useState("")

    return(
        <div className="theme-layout">

            <CommonPagesFooter />

            <div style={{marginTop:"60px"}}>

                <div className="container">
                    <br />
                            
                    <div className="central-meta text-justify">
                        <h1>Contact Us</h1>

                        <FormReCaptchaV3 onLoadStatusUpdate={(status)=>{
                            setIsLoading(status)
                        }} action="contact" onSubmit={handleSubmit}>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <Select2 required={true} directValues={true} value={contactType} onSelect={ (e) => setContactType(e.target.value) } data={contactTypeList} keys={"nmsss"} placeholder={"Select Option*"} />
                                    </div>
                                </div>
                                
                                {contactType=="Schedule a Meeting" ? (
                                    <>
                                        <iframe frameBorder="0" src="https://calendly.com/sattar-emamian/30min" height="800px" width="100%"/>
                                    </>
                                ) : (
                                    <>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="text" id="inp_fname" onChange={(e)=> setName(e.target.value) } value={name} name="name" required="required" />
                                            <label className="control-label" htmlFor="inp_fname">Full Name *</label>
                                            <i className="mtrl-select"></i>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="text" id="inp_email" onChange={(e)=> setEmail(e.target.value) } value={email} name="email" required="required" />
                                            <label className="control-label" htmlFor="inp_email">Email *</label>
                                            <i className="mtrl-select"></i>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="text" id="inp_subject" onChange={(e)=> setSubject(e.target.value) } value={subject} name="subject" required="required" />
                                            <label className="control-label" htmlFor="inp_subject">Subject *</label>
                                            <i className="mtrl-select"></i>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <textarea rows="4" name="message" onChange={(e)=> setMessage(e.target.value) } value={message || ""} required="required"></textarea>
                                            <label className="control-label" htmlFor="textarea">Message *</label><i className="mtrl-select"></i>
                                        </div>
                                        <br /><br />

                                        {msg && (
                                            <div className={ hasError ? "alert alert-danger mt-10" : "alert alert-success mt-10" } role="alert">
                                                {msg}
                                            </div>
                                        )}

                                        <div className="submit-btns">
                                            <ExtButton isLoading={isLoading} className="mtr-btn" type="submit">
                                                <span>Submit</span>
                                            </ExtButton>
                                        </div>
                                    </div>
                                    </>
                                )}

                            </div>
                            
                        </FormReCaptchaV3>
                    </div>
                </div>

            </div>
            
            <Footer />
        </div>
    )

}

export default ContactUs