import { useEffect, useState } from "react";
import SinglePlan from "./SinglePlan";
import Switch from "./Switch";
import ExtButton from "../../libs/ExtButton";
import { ExCounter } from "../../libs/ExCounter";
import axios from "axios";
import { API_GET_DISCOUNT, API_MAKE_PAYMENT, API_URL } from "../../../services/ApiEndpoints";
import authHeader from "../../../services/auth-header";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const PaymentPlansV2 = () =>{

    const {userData} = useSelector(state => state.userData )

    const [isLoading, setIsLoading] = useState(true)
    const [discount, setDiscount] = useState(0)

    const getData = () => {

        setIsLoading(true)

        axios
        .get(API_URL + API_GET_DISCOUNT,{
            headers: authHeader()
        })
        .then((response)=>{
            
            if(response.status==200){

                const json = response.data;

                setDiscount(json)

            }
            
            setIsLoading(false)

        })
        .catch((error)=>{

            setIsLoading(false)

        });
    }

    useEffect(()=>{
        getData()
    },[])

    return(
        <section className="mb-5 mt-4">
            <div className="gap gray-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="text-center mb-3">Choose the best plan from the following</h2><br />

                            {/* <div className="text-center">
                                <h3 className="text-center mb-5 text-theme">For Individuals</h3>
                            </div> */}

                            {userData.register_as==0 ? (
                                <section className="mb-5">

                                    <div className="row">

                                        <div className="col-md-12">
                                            <div className={`card mb-5 mb-lg-0`}>
                                                <div className="card-body">

                                                    <div className="table-responsive">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th width="15%">Packages</th>
                                                                    <th width="10%">SCIONScoin(s)</th>
                                                                    <th width="10%">Price (€)</th>
                                                                    <th style={{minWidth: "150px"}} width="20%">Buy Now</th>
                                                                    <th width="45%"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <Single
                                                                    name="Individual"
                                                                    value="50"
                                                                    plan="1"
                                                                    discount={`${userData.is_student ? discount : 0}`}
                                                                    coin="1" />
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    
                                                    <div className="">
                                                        <p>* Coins will never expire. Nevertheless, please note that your premium access is valid for one year, and renewal requires the use of only one coin. <br />After renewal, you can continue utilizing the remaining coins as usual.</p>
                                                        {/* <p>You will be offered premium access to SCIONS platform for 1 year with the purchase of any package.</p> */}
                                                        <strong>What’s included in Premium access?</strong>
                                                        <ul>
                                                            <li>Utilize SCIONS AI hub to identify the ideal investor, company, and experts.</li>
                                                            <li>Social Networking with all SCIONS</li>
                                                            <li>Chat with fellow SCIONS</li>
                                                            <li>Full access to idea marketplace</li>
                                                            <li>Access to investment opportunities</li>
                                                            <li>Access to collaboration opportunities</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </section>
                            ) : (
                                <section className=" mb-5">

                                    <div className="row">

                                        <div className="col-md-12">
                                                <div className={`card mb-5 mb-lg-0`}>
                                                    <div className="card-body">

                                                        <div className="table-responsive">
                                                            <table className="table table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th width="15%">Packages</th>
                                                                        <th width="10%">SCIONScoin(s)</th>
                                                                        <th width="10%">Price (€)</th>
                                                                        <th style={{minWidth: "150px"}} width="20%">Buy Now</th>
                                                                        <th width="45%"></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <Single
                                                                        name="Individual"
                                                                        value="50"
                                                                        discount="0"
                                                                        plan="21"
                                                                        coin="1" />

                                                                    <Single
                                                                        name="Small/Bronze"
                                                                        value="2200"
                                                                        plan="22"
                                                                        discount="0"
                                                                        coin="50" />

                                                                    <Single
                                                                        name="Medium/Silver"
                                                                        value="4000"
                                                                        plan="23"
                                                                        discount="0"
                                                                        coin="100" />

                                                                    <Single
                                                                        name="Large/Golden"
                                                                        value="20000"
                                                                        discount="0"
                                                                        plan="24"
                                                                        coin="700" />
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        
                                                        <div className="">
                                                            <p>* Coins will never expire. Nevertheless, please note that your premium access is valid for one year, and renewal requires the use of only one coin. <br />After renewal, you can continue utilizing the remaining coins as usual.</p>
                                                            {/* <p>You will be offered premium access to SCIONS platform for 1 year with the purchase of any package.</p> */}
                                                            <strong>What’s included in Premium access?</strong>
                                                            <ul>
                                                                <li>Utilize SCIONS AI hub to identify the ideal investor, company, and experts.</li>
                                                                <li>Social Networking with all SCIONS</li>
                                                                <li>Chat with fellow SCIONS</li>
                                                                <li>Full access to idea marketplace</li>
                                                                <li>Access to investment opportunities</li>
                                                                <li>Access to collaboration opportunities</li>
                                                                <li>Create sub-admins for task sharing</li>
                                                                <li>Invite your staff/ student and make them as paid users</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                    </div>
                                    </section>
                            )}
                            
                            
                            {/* <div className="text-center">
                                <h3 className="text-center mb-5 text-theme">For Organizations</h3>
                            </div> */}

                            
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )

}

const Single = (props) => {

    const [no, setNo] = useState(1)

    const [isLoading, setIsLoading] = useState(false)

    const submit = (qty, amount, plan) => {
        setIsLoading(true)

        axios
        .get(API_URL + API_MAKE_PAYMENT + `?plan=${plan}&qty=${qty}&amount=${amount}`,{
            headers: authHeader()
        })
        .then((response)=>{
            
            setIsLoading(false)

            if(response.status==200){

                const json = response.data;

                if(json.status=="RC200"){
                    
                    window.location.href = json.data.url;

                }else{
                    toast.error(json.message)
                }

            }

        })
        .catch((error)=>{
            setIsLoading(false)
            toast.error("Error, please try again...")
        });
    }

    const getPrice = () => {
        if(props.discount!=0)
            return props.value - ((props.value * props.discount)/100);

        return props.value
    }

    return(
        <tr>
            <td>
                {props.name || ""}
            
                {props.discount!=0 && (
                    <>
                        <br /><span className="badge badge-success">{props.discount}% OFF</span>
                    </>
                )}
            </td>
            <td>{props.coin || "0"}</td>
            <td>
                {props.discount!=0 ? (
                    <>
                        <strong style={{textDecoration: "line-through", color: "#ff6161"}}>{Number(props.value).toLocaleString("en", {minimumFractionDigits: 2})}</strong>
                        <br />
                        <strong>{Number(getPrice()).toLocaleString("en", {minimumFractionDigits: 2})}</strong>
                    </>
                ) : (
                    <strong>{Number(props.value).toLocaleString("en", {minimumFractionDigits: 2})}</strong>
                )}
            </td>
            <td>
                <div style={{display:"flex", justifyContent:"space-between"}}>
                    <div style={{display:"inline-block"}}>
                        <ExCounter onChange={(v)=>setNo(v)} value={no} min="1" />
                    </div>
                </div>
            </td>
            <td>
                <div style={{display:"flex", justifyContent:"space-between"}}>
                    <ExtButton onClick={()=>{
                        
                        submit(no, props.value*no, props.plan)

                    }} isLoading={isLoading} type="button" className="btn btn-primary mtr-btn text-white mt-0 mw-400">Pay €{Number(getPrice()*no).toLocaleString("en", {minimumFractionDigits: 2})} for {props.coin*no} Coin{(props.coin*no)>1 ? "s" : ""}</ExtButton>
                </div>
            </td>
        </tr>
    )

}

export default PaymentPlansV2;