import axios from "axios";
import { useSelector } from "react-redux";
import { API_LOGIN, API_REGISTER, API_URL } from "./ApiEndpoints";

const login = (email, password, captchaToken) => {
    return axios
      .post(API_URL + API_LOGIN, {
        email,
        password,
        "recaptcha_token":captchaToken
      })
      .then((response) => {
          return response.data;
      });
  };

  const userRegister = (firstName,lastName, registerAs, email, password, confPassword, phone, dialCode, role, dob, termsAndConditions, receiveNews, captchaToken ) => {
      return axios
        .post(API_URL + API_REGISTER, {
            "first_name":firstName,
            "last_name":lastName,
            "register_as": registerAs,
            "email": email,
            "password": password,
            "conf_password": confPassword,
            "phone": phone,
            "phone_code": dialCode,
            "role": role,
            "date_of_birth": dob,
            "accept_terms_conditions": termsAndConditions,
            "when_to_receive_news": receiveNews,
            "recaptcha_token": captchaToken
        }).then((response)=>{
          return response.data;
        });
  };

  const logout = (redirectToHome) => {
    localStorage.removeItem("user");
    
    if(redirectToHome)
      window.location.href = "/"
  };

  const CurrentUser = () => {
      const { user: currentUser } = useSelector((state) => state.auth);

      const get_profile_image = () =>{
          return currentUser.profile_image;
      }
  }

  export default {
    login,
    logout,
    userRegister,
    CurrentUser,
  };