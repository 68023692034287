import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { API_RECENT_CONNECTED_USERS, API_URL } from "../../services/ApiEndpoints";
import authHeader from "../../services/auth-header";
import ExToolTip from '../libs/ExToolTip'
import { useAudio } from "../libs/useAudio";
import FriendsChat from "../private/chat/FriendsChat";
import { NotificationSound } from "../private/Consts";

const RightSideBar = () =>{

    const [playing, toggle] = useAudio(NotificationSound)

    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState([])

    const getData = () => {

        setIsLoading(true)

        axios
        .get(API_URL + API_RECENT_CONNECTED_USERS,{
            headers: authHeader()
        })
        .then((response)=>{
            
            if(response.status==200){

                const json = response.data;

                if(json.status=="RC200"){
                    setData(json.data)
                }

            }
            
            setIsLoading(false)

        })
        .catch((error)=>{

            setIsLoading(false)

        });
    }

    useEffect(()=>{
        getData()
    }, [])

    const [activeUserName, setActiveUserName] = useState("")
    const [activeUserImage, setActiveUserImage] = useState("")
    const [activeUserId, setActiveUserId] = useState(-1)
    const [open, setOpen] = useState(false)

    const {userData} = useSelector(state => state )

    const openChatWindow = (user) =>{
        setActiveUserId(user.id)
        setActiveUserName(user.full_name)
        setActiveUserImage(user.profile_image)
        setOpen(true)
        updateUnread(user.id, true)
    }

    useEffect(()=>{
        if(userData.isChatStatus)
            openChatWindow(userData.userData)
    }, [userData])

    const updateUnread = (sender_id, isZero)=>{
        setData( prev =>{
          const newState = prev.map(obj=>{
              if(obj.id==sender_id){
    
                let newCount = isZero ? 0 : (obj.unread+1);
    
                return {...obj, unread: newCount}
              }
    
              return obj
          })
    
          return newState
        })
      }

    return(
        
        <>
            <div className="phone-chat-users">
                <div className="phone-chat-users-content">
                    <div className="row">

                        <div className="col-8">
                            <h4>Chats</h4>
                        </div>
                        <div className="col-4 text-right">
                            <a className="fa fa-close" onClick={(e)=> window.toggle_chat()} style={{fontSize:"20px"}}></a>
                        </div>

                    </div>
                    <ul className="chat-users">
                        {isLoading ? ("loading recent chats") : (
                            data.map((user, index)=>(
                                <li onClick={(e)=>{
                                    openChatWindow(user)
                                }} key={user.id}>
                                    <div className="author-thmb">
                                        <img src={user.profile_image} alt="" />
                                        <span className="status f-online"></span>
                                        {user.unread>0 ? (
                                            <span className="badge badge-danger">{user.unread}</span>
                                        ) : ""}
                                    </div>
                                    <label>{user.full_name}</label>
                                </li>
                            ))
                        )}
                    </ul>
                </div>
            </div>

            <div className="fixed-sidebar right">
            
                <div className="chat-friendz">
                    <ul className="chat-users">
                        {isLoading ? ("loading recent chats") : (
                            data.map((user, index)=>(
                                <li onClick={(e)=>{
                                    // setActiveUserId(user.id)
                                    // setActiveUserName(user.full_name)
                                    // setActiveUserImage(user.profile_image)
                                    // setOpen(true)
                                    openChatWindow(user)
                                }} key={user.id}>
                                    <ExToolTip placement="left" name={user.full_name}>                  
                                        <div className="author-thmb">
                                            <img src={user.profile_image} alt="" />
                                            <span className="status f-online"></span>
                                            {user.unread>0 ? (
                                                <span className="badge badge-danger">{user.unread}</span>
                                            ) : ""}
                                        </div>
                                    </ExToolTip>
                                </li>
                            ))
                        )}
                    </ul>

                </div>

                
            </div>

            <div className="cht-cnt">
                <FriendsChat isOpenEvent={(is)=> {
                        if(!is){
                            setActiveUserId(-1)
                            setActiveUserName("")
                            setOpen(false)
                        }
                        
                    }} onOtherMessageReceived={ (message, auid) =>{
                        
                        /*if(!open && auid==-1){

                            let foundUser = data.filter((val, key)=>{
                                return val.id==message.sender_id
                            })

                            if(foundUser.length>0){
                                openChatWindow(foundUser[0])
                            }

                        }else{
                            
                        }*/

                        toggle()
                        updateUnread(message.sender_id, false)
                        
                    }} isOpenFrom={open} image={activeUserImage} activeUserName={activeUserName} id={activeUserId} />
            </div>
        </>
        
    );

};

export default RightSideBar;