import { Link } from "react-router-dom"
import Footer from "../layouts/Footer"
import ReactPlayer from "react-player"

const AboutUs = () =>{

    return(
        <section>
            <div className="theme-layout about-scions">
                <section>
                    <div className="ext-gap bluesh high-opacity">
                        <div className="content-bg-wrap" style={{background:"url(/assets/images/animated-bg2.png)"}}></div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="top-banner">
                                        <h1>About SCIONS</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="gap100 no-bottom">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="about-winku">
                                        <h4>Welcome to SCIONS</h4>
                                        <p>
                                            SCIONS is a secure community-based online marketplace platform that bridges the gap between academia and industry research needs. SCIONS is founded by Sigma Tech Solutions B.V. which aims to bridge
                                            the gap between academia and industry by making it easier for both sections to understand each other’s research needs, and therefore, allows dynamic collaboration between the two.
                                        </p>
                                        <p>The dynamic matchmaking between the two parties can lead to an outcome that is practical (not theoretical) and market-oriented and can be tailored for specific needs in the industry.</p>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    {/* <figure className="about-picture"><img src="/assets/images/find-job.png" alt="" /></figure> */}
                                    <ReactPlayer playing={true} height="300px" light="/assets/images/scions.tech-final4_about.jpg" width="100%" pip={false} controls={true} url="https://scionsfiles.blob.core.windows.net/static/scions.tech.mp4" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="gap100">
                        <div className="container scions-team">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-heading"><h2>Our Team</h2></div>
                                </div>



                                {/* <div className="col-md-3 col-sm-6 mb-4">
                                    <div className="our-teambox">
                                        <figure><img src="/assets/team/faris.jpg" alt="" /></figure>
                                        <div className="team-info">
                                            <h4>Faris Mokhtar</h4>
                                            <span>Sales Manager</span><br />
                                            <a href="mailto:faris@scions.tech"><i className="fa fa-envelope"></i> faris@scions.tech</a><br />
                                            <Link to="/" target="_blank"><img src="/favicon.png" className="team-member-image" /> faris</Link>
                                        </div>
                                    </div>
                                </div> */}
                                

                                {/* <div className="col-md-3 col-6 mb-4 offset-md-3">
                                    <div className="our-teambox">
                                        <figure><img src="/assets/team/roel.jpg" alt="" /></figure>
                                        <div className="team-info">
                                            <h4>Roel Bloys van Treslong</h4>
                                            <span>Accountant & Tax advisor</span><br />
                                            <a href="mailto:roel@scions.tech"><i className="fa fa-envelope"></i> roel@scions.tech</a><br />
                                            <Link to="/roel" target="_blank"><img src="/favicon.png" className="team-member-image" /> roel</Link>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            
                            <div className="col-md-10 col-12 offset-md-1">
                                
                                <div className="row justify-content-center">
                                    <div className="col-md-4 col-6 mb-4">
                                        <div className="our-teambox">
                                            <figure><img src="/assets/team/sattar.jpg" alt="" /></figure>
                                            <div className="team-info">
                                                <h4>Sattar S. Emamian</h4>
                                                <span>CEO / Founder</span><br />
                                                {/* <a href="mailto:sattar@scions.tech"><i className="fa fa-envelope"></i> sattar@scions.tech</a><br /> */}
                                                <Link to="/sattar.emamian" target="_blank"><img className="team-member-image" src="/favicon.png" /> sattar</Link>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-md-4 col-6 mb-4">
                                        <div className="our-teambox">
                                            <figure><img src="/assets/team/deepanshu.jpg" alt="" /></figure>
                                            <div className="team-info">
                                                <h4>Deepanshu Dhingra</h4>
                                                <span>CTO / Co-Founder</span><br />
                                                {/* <a href="mailto:deepanshu@scions.tech"><i className="fa fa-envelope"></i> deepanshu@scions.tech</a><br /> */}
                                                <Link to="/dhingra.deepanshu" target="_blank"><img src="/favicon.png" className="team-member-image" /> dhingra.deepanshu</Link>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="col-md-4 col-6 mb-4">
                                        <div className="our-teambox">
                                            <figure><img src="/assets/team/mokhtar.jpg" alt="" /></figure>
                                            <div className="team-info">
                                                <h4>Mokhtar Awang</h4>
                                                <span>COO / Asia Manager</span><br />
                                                {/* <a href="mailto:mokhtar@scions.tech"><i className="fa fa-envelope"></i> mokhtar@scions.tech</a><br /> */}
                                                <Link to="/mokhtar_awang" target="_blank"><img className="team-member-image" src="/favicon.png" /> mokhtar_awang</Link>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {/* <div className="col-md-3 col-6 mb-4">
                                        <div className="our-teambox">
                                            <figure><img src="/assets/team/nasrin-ostvar.png" alt="" /></figure>
                                            <div className="team-info">
                                                <h4>Nasrin Ostvar</h4>
                                                <span>Data Scientist</span><br />
                                                <Link to="/Nasrin.Ostovar" target="_blank"><img src="/favicon.png" className="team-member-image" /> Nasrin.Ostovar</Link>
                                            </div>
                                        </div>
                                    </div> */}
                                    
                                    {/* <div className="col-md-4 col-6 mb-4">
                                        <div className="our-teambox">
                                            <figure><img src="/assets/team/farnaz.jpg" alt="" /></figure>
                                            <div className="team-info">
                                                <h4>Farnaz Hanifeh</h4>
                                                <span>Business Analyst</span><br />

                                                <Link to="/hanifeh.farnaz" target="_blank"><img className="team-member-image" src="/favicon.png" /> hanifeh.farnaz</Link>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="col-md-4 col-6 mb-4">
                                        <div className="our-teambox">
                                            <figure><img src="/assets/team/vikas.jpg" alt="" /></figure>
                                            <div className="team-info">
                                                <h4>Vikas Kapoor</h4>
                                                <span>Project Manager</span><br />
                                                {/* <a href="mailto:vikas@scions.tech"><i className="fa fa-envelope"></i> vikas@scions.tech</a><br /> */}
                                                <Link to="/vikas0179" target="_blank"><img src="/favicon.png" className="team-member-image" /> vikas0179</Link>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4 col-6 mb-4">
                                        <div className="our-teambox">
                                            <figure><img src="/assets/team/suresh-shiyani.png" alt="" /></figure>
                                            <div className="team-info">
                                                <h4>Suresh Shiyani</h4>
                                                <span>Technical Team Lead</span><br />
                                                {/* <a href="mailto:suresh@scions.tech"><i className="fa fa-envelope"></i> suresh@scions.tech</a><br /> */}
                                                <Link to="/sshiyani12" target="_blank"><img src="/favicon.png" className="team-member-image" /> sshiyani12</Link>
                                            </div>
                                        </div>
                                    </div>

                                    

                                    
                                </div>

                            </div>

                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </section>
    )

}

export default AboutUs