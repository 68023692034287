import axios from "axios";
import { useEffect, useState } from "react";
import { API_ACCEPT_FRIEND_REQUEST, API_DELETE_FRIEND_REQUEST, API_FRIEND_REQUEST_LIST, API_URL } from "../../../services/ApiEndpoints";
import authHeader from "../../../services/auth-header";
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link } from "react-router-dom";
import ExtButton from "../../libs/ExtButton";

const FriendRequest = () => {

    const [canLoadMore, setCanLoadMore] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)

    const [data, setData] = useState([])

    useEffect(()=>{
        
        getData()

    }, []);

    const getData = () =>{

        axios
        .get(API_URL + API_FRIEND_REQUEST_LIST,{
            params:{
                page: currentPage
            },
            headers: authHeader()
        })
        .then((response)=>{
            
            if(response.status==200){

                const json = response.data;

                if(json.status=="RC200"){

                    var resp = json.data;

                    var list = resp.data;

                    setCanLoadMore( currentPage != resp.last_page )
                    setData(list)
                
                }

            }

        })
        .catch((error)=>{

        });

    };

    const loadFunc = () => {
        
        setCurrentPage(currentPage+1)

        getData();

    }

    const updateData = (index) => {
        
        const newData = [...data]

        newData.splice(index, 1);

        setData(newData)
        
    }

    return(
        <div className="" id="frends-req">

            <InfiniteScroll
                dataLength={data.length}
                next={loadFunc}
                hasMore={canLoadMore}
                endMessage={<div className="text-center"><br />That's all for now</div>}
                loader={<div className="loader text-center">Loading ...</div>}>
                
                <ul className="nearby-contct">
                    {data.map((item, index) => {
                        return(
                            
                            <SingleFriendRequest onUpdate={ (ind)=> updateData(ind) } key={index} item={item} index={index} />

                        )
                    })}
                </ul>

            </InfiniteScroll>

        </div>

    )

}

const SingleFriendRequest = (props) =>{

    const {item, index} = props

    const [isALoading, setIsALoading] = useState(false)
    const [isDLoading, setIsDLoading] = useState(false)

    const deleteFriendRequest = (e, id, index) =>{
        e.preventDefault()

        setIsDLoading(true)

        axios
        .post(API_URL + API_DELETE_FRIEND_REQUEST,{
            sender_id: id
        }, {
            headers: authHeader()
        })
        .then((response)=>{

            setIsDLoading(false)
            
            if(response.status==200){

                const json = response.data;

                if(json.status=="RC200"){

                    props.onUpdate(index)

                }

            }

        })
        .catch((error)=>{

        });
    }

    const acceptFriendRequest = (e, id, index) =>{
        e.preventDefault()

        setIsALoading(true)

        axios
        .post(API_URL + API_ACCEPT_FRIEND_REQUEST,{
            sender_id: id
        }, {
            headers: authHeader()
        })
        .then((response)=>{

            setIsALoading(false)
            
            if(response.status==200){

                const json = response.data;

                if(json.status=="RC200"){
                    
                    props.onUpdate(index)

                }

            }

        })
        .catch((error)=>{

        });
    }

    return(
        <li>
            <div className="nearly-pepls">
                <figure>
                    <Link to={`/` + item.public_url} title=""><img src={item.profile_image} alt="" /></Link>
                </figure>
                <div className="pepl-info">
                    <h4><Link to={`/` + item.public_url} title="">{item.full_name}</Link></h4>
                    <span>{item.eng_field}</span>
                    
                    <ExtButton type="link" isLoading={isDLoading} onClick={ (e) => deleteFriendRequest(e, item.id, index) } className="add-butn more-action">
                        delete Request
                    </ExtButton>

                    <ExtButton type="link" isLoading={isALoading} onClick={ (e) => acceptFriendRequest(e, item.id, index) } className="add-butn">
                        Confirm
                    </ExtButton>

                </div>
            </div>
        </li>
    )

}


export default FriendRequest;