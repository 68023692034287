import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_GET_JOB_DETAILS, API_URL } from "../../../services/ApiEndpoints";
import authHeader from "../../../services/auth-header";
import CompLoaderLayout from "../../libs/CompLoaderLayout";
import { organizationSize } from "../Consts";
import ApplyJobPopup from "./ApplyJobPopup";

const JobDetails = () =>{

    const jobTypeList = [
        "Full Time",
        "Part Time",
        "Freelancer"
    ]

    const { slug } = useParams()

    const [isLoading, setIsLoading] = useState(true)

    const [data, setData] = useState(null)

    const getData = () => {
        
        setIsLoading(true)

        axios
        .get(API_URL + API_GET_JOB_DETAILS + "/" + slug,{
            headers: authHeader()
        })
        .then((response)=>{
            
            setIsLoading(false)

            if(response.status==200){

                const json = response.data;

                const {status, data: MainData} = json

                if(status=="RC200"){
                    setData(MainData)
                }

            }

        })
        .catch((error)=>{
            setIsLoading(false)
        });

    }

    useEffect(()=>{
        getData()
    }, [])

    return(
        isLoading ? (
            <CompLoaderLayout />
        ) : (
            data===null ? (
                <section>
                        <div className="gap gray-bg">
                            <div className="container">
                                <div className="row" id="page-contents">
                                    <div className="col-lg-6">
                                        <h5>Job Not found</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                </section>
            ) : (
                <>
                    <section>
                        <div className="gap gray-bg">
                            <div className="container">
                                <div className="central-meta">
                                    <div className="row merged20" id="page-contents">
                                        <div className="col-lg-6">
                                            <div className="employer-info">
                                                <h2>{data.title}</h2>
                                                <ul>
                                                    <li>
                                                        <span><strong>Company:</strong></span> <i>{data.company_name}</i>
                                                    </li>
                                                    <li>
                                                        <span><strong>Location:</strong></span> <i>{data.location}</i>
                                                    </li>
                                                    <li>
                                                        <span><strong>Discipline:</strong></span> <i>{data.eng_field}, {data.discipline_name}, {data.sub_discipline_name}</i>
                                                    </li>
                                                    <li>
                                                        <span><strong>Type:</strong></span> <i>{jobTypeList[data.job_type]}</i>
                                                    </li>
                                                    <li>
                                                        <span><strong>Posted</strong>:</span> <i>{data.date}</i>
                                                    </li>
                                                    <li>
                                                        <span><strong>Application Deadline</strong>:</span> <i>{data.application_deadline}</i>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="salary-area">
                                                <h4>€ {data.salary}</h4>
                                                <span>min salary</span>
                                            </div>
                                            {data.allow_apply==1 && (
                                                <ApplyJobPopup className="add-butn" id={data.id} />
                                            )}
                                        </div>

                                        <div className="col-lg-12 mt-5">
                                            <h3>Job Description</h3>

                                            { data.description && data.description.split("\n").map((str, index) => <p key={`px-${index}`}>{str}</p>)}

                                            <h3 className="mt-4">About Company</h3>
                                            <div className="row">
                                                <div className="col-md-2"><img src={data.company_logo} /></div>
                                                <div className="col-md-10">
                                                    { data.about_company && data.about_company.split("\n").map((str, index) => <p key={`px-${index}`}>{str}</p>)}
                                                </div>
                                            </div>

                                            <br />
                                            <p><strong>Website:</strong> <a href={data.website} target="_blank">{data.website}</a></p>
                                            <p><strong>Phone:</strong> {data.phone}</p>
                                            <p><strong>Company size:</strong> {organizationSize[data.company_size].name} employees</p>

                                            {data.allow_apply==1 && (
                                                <div className="apply-bttons col-md-12 text-center">
                                                    <ApplyJobPopup id={data.id} />
                                                </div>
                                            )}
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )
        )
    )

}

export default JobDetails;