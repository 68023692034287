import React, { useState } from "react";
import { useSelector } from "react-redux";
import { API_CHANGE_PASSWORD, API_URL } from "../../../services/ApiEndpoints";
import axios from "axios";
import authHeader from "../../../services/auth-header";
import ExtButton from "../../libs/ExtButton";
import PasswordField from "../../libs/PasswordField";

const ChangePassword = () =>{
    
    const {user:currentUser} = useSelector((state) => state.auth);

    const [currentPassword, setCurrentPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const [isLoading, setIsLoading] = useState(false)
    const [hasError, setHasError] = useState(false) 
    const [msg, setMsg] = useState("") 

    const handleSubmit = (e) => {
        
        e.preventDefault()

        setIsLoading(true);
        setMsg("")

        axios.post(API_URL + API_CHANGE_PASSWORD, {
                current_password: currentPassword,
                new_password: newPassword,
                new_confirm_password: confirmPassword
            },{
                headers:authHeader()
            })
            .then((responsex) => {

                setIsLoading(false);

                if(responsex.data.status=="RC200"){
                    setHasError(false)
                }else{
                    setHasError(true)
                }

                setMsg(responsex.data.message)

                return responsex.data;
            });

        

    }

    return(
        <div className="central-meta" id="change-password">
            <div className="editing-info">
                <h5 className="f-title"><i className="ti-lock"></i>Change Password</h5>

                <form onSubmit={handleSubmit} method="post">
                    
                    <div className="row">
                        <div className="col-md-6">
                            <PasswordField value={currentPassword} label="Current password" keys="curr_pwd" onChange={ (e) => setCurrentPassword(e.target.value) } />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <PasswordField value={newPassword} label="New password" keys="new_pwd" onChange={ (e) => setNewPassword(e.target.value) } />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <PasswordField value={confirmPassword} label="Confirm password" keys="conf_pwd" onChange={ (e) => setConfirmPassword(e.target.value) } />
                        </div>
                    </div>


                    {msg && (
                        <div className={ hasError ? "alert alert-danger mt-10" : "alert alert-success mt-10" } role="alert">
                            {msg}
                        </div>
                    )}

                    <div className="submit-btns">
                        <ExtButton isLoading={isLoading} className="mtr-btn" type="submit">
                            <span>Update</span>
                        </ExtButton>
                    </div>
                </form>
            </div>
        </div>

    );
};

export default ChangePassword;