import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DatePicker, df, mode } from "../../../helpers/DatePicker";
import { API_ADD_UPDATE_JOB, API_MY_IDEA, API_ORG_COUNT, API_URL } from "../../../services/ApiEndpoints";
import authHeader from "../../../services/auth-header";
import ExtButton from "../../libs/ExtButton";
import FieldsSelector from "../../libs/FieldsSelector";
import ImageCropperPopup from "../../libs/image/ImageCropperPopup";
import OrgSelector from "../../libs/OrgSelector";
import Select2 from "../../libs/Select2";
import { organizationSize, typeOfOrganization } from "../Consts";

const CreateJob = () => {

    const jobTypeList = [
        {name:"Full Time", id:0},
        {name:"Part Time", id:1},
        {name:"Freelancer", id:2}
    ]

    const [jobTitle, setJobTitle] = useState("")
    const [jobField, setJobField] = useState("")
    const [jobDiscipline, setJobDiscipline] = useState("")
    const [jobSubDiscipline, setJobSubDiscipline] = useState("")
    const [jobType, setJobType] = useState("")
    const [jobSalary, setJobSalary] = useState("")
    const [applicationDeadline, setApplicationDeadline] = useState("")
    const [jobDesc, setJobDesc] = useState("")

    const [orgCompanyName, setOrgCompanyName] = useState("")
    const [orgAboutCompany, setOrgAboutCompany] = useState("")
    const [orgWebsite, setOrgWebsite] = useState("")
    const [orgPhone, setOrgPhone] = useState("")
    const [orgCompanySize, setOrgCompanySize] = useState("")
    const [orgCompanyLogoObj, setOrgCompanyLogoObj] = useState(null)
    const [location, setLocation] = useState("");
    const [orgType, setOrgType] = useState(-1);

    const [isPublish, setIsPublish] = useState(false)
    
    const [clicked, setClicked] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    const [hasError, setHasError] = useState(false) 
    const [msg, setMsg] = useState("")

    const handleSubmit = (e) =>{
        e.preventDefault()

        setIsLoading(true)

        const formdata = new FormData()
        
        formdata.append("id", -1)
        formdata.append("title", jobTitle)
        formdata.append("department", jobField)
        formdata.append("discipline", jobDiscipline)
        formdata.append("sub_discipline", jobSubDiscipline)
        formdata.append("description", jobDesc)
        formdata.append("job_type", jobType)
        formdata.append("salary", jobSalary)
        formdata.append("application_deadline", applicationDeadline)

        formdata.append("company_name", orgCompanyName)
        formdata.append("about_company", orgAboutCompany)
        formdata.append("website", orgWebsite)
        formdata.append("phone", orgPhone)
        formdata.append("company_size", orgCompanySize)
        formdata.append("location", location);
        formdata.append("type_of_organization", orgType);

        if(orgCompanyLogoObj!==null)
            formdata.append("company_logo", orgCompanyLogoObj)
        
        formdata.append("is_published", isPublish? 1 : 0)

        axios.post(API_URL + API_ADD_UPDATE_JOB, formdata,{
            headers:authHeader()
        })
        .then((responsex) => {

            setIsLoading(false)

            if(responsex.data.status=="RC200"){
                setHasError(false)
            }else{
                setHasError(true)
            }

            setMsg(responsex.data.message)

            return responsex.data;
        });

    }

    return (
        <section>
            <div className="gap gray-bg">
                <div className="container">
                    <div className="idea-sub-sections">
                        <h3>Create Job</h3>

                        <form onSubmit={handleSubmit} >

                            <div className="row">

                                <div className="col-md-12">
                                    <div className="form-group">
                                        
                                        <input type="text" 
                                            onChange={(e)=> setJobTitle(e.target.value) }
                                            value={jobTitle}
                                            required="required" />
                                        <label className="control-label">Job Title *</label>
                                        <i className="mtrl-select"></i>

                                    </div>
                                </div>

                            </div>

                            <FieldsSelector 
                                fplaceholder="Select your job field"
                                dplaceholder="Which one best describes your job discipline area"
                                sdplaceholder="Select your expertise area"
                                field={jobField}
                                discipline={jobDiscipline}
                                subDiscipline={jobSubDiscipline}
                                onSelectF={(val)=> {
                                    setJobField(val)
                                } }
                                onSelectD={(val)=> {
                                    setJobDiscipline(val)
                                } }
                                onSelectSD={(val)=> {
                                    setJobSubDiscipline(val)
                                } }
                            />

                            <div className="row">

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <Select2 placeholder={"Job Type"} 
                                            directValues={false} 
                                            onSelect={ (e) => {
                                                setJobType(parseInt(e.target.value))
                                            } } 
                                            value={jobType} 
                                            name="job_type" 
                                            keys="name" 
                                            data={jobTypeList} />
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <input type="text" 
                                            onChange={(e)=> setJobSalary(e.target.value) }
                                            value={jobSalary}
                                            required="required" />
                                        <label className="control-label">Add Salary (€) *</label>
                                        <i className="mtrl-select"></i></div>
                                </div>

                                <div className="col-md-4">
                                    <DatePicker readOnly={true} format={df.DMY} value={applicationDeadline || ""} mode={mode.day} onChange={ (e, date)=>{
                                        setApplicationDeadline(date)
                                    }} required="required">
                                        Application Deadline
                                    </DatePicker>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <textarea rows="4" 
                                            onChange={(e)=> setJobDesc(e.target.value) }
                                            value={jobDesc}
                                            required="required"></textarea>
                                        <label className="control-label">Job Description</label>
                                        <i className="mtrl-select"></i>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <input type="text"
                                                onChange={(e)=> setOrgCompanyName(e.target.value) }
                                                value={orgCompanyName}
                                                required="required" />
                                        <label className="control-label" >Organization Name *</label>
                                        <i className="mtrl-select"></i>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <Select2 placeholder={"Type of Organization"} 
                                            directValues={false} 
                                            onSelect={ (e) => {
                                                setOrgType(parseInt(e.target.value))
                                            } } 
                                            value={orgType} 
                                            name="type_of_organization" 
                                            keys="name" 
                                            data={typeOfOrganization} />
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <input type="text"
                                            onChange={(e)=> setLocation(e.target.value) }
                                            value={location}
                                            required="required" />
                                        <label className="control-label" >Location *</label>
                                        <i className="mtrl-select"></i>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <textarea rows="4" 
                                            onChange={(e)=> setOrgAboutCompany(e.target.value) }
                                            value={orgAboutCompany}
                                            required="required"></textarea>
                                        <label className="control-label">About Organization</label>
                                        <i className="mtrl-select"></i>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <input type="text" 
                                            onChange={(e)=> setOrgWebsite(e.target.value) }
                                            value={orgWebsite}
                                            required="required" />
                                        <label className="control-label">Website *</label>
                                        <i className="mtrl-select"></i>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <input type="text" 
                                            onChange={(e)=> setOrgPhone(e.target.value) }
                                            value={orgPhone}
                                            required="required" />
                                        <label className="control-label">Phone *</label>
                                        <i className="mtrl-select"></i>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <Select2 placeholder={"Organization Size"} 
                                            directValues={false} 
                                            onSelect={ (e) => {
                                                setOrgCompanySize(parseInt(e.target.value))
                                            } } 
                                            value={orgCompanySize} 
                                            name="size" 
                                            keys="name" 
                                            data={organizationSize} />
                                    </div>
                                </div>


                                <div className="col-md-12 mt-4">
                                    <p><strong>Add Company Logo</strong></p>
                                </div>

                                <div className="col-md-9">
                                    <ImageCropperPopup width={150} height={150} title="Select Organization Logo" className='form-control-file' onImageCroped={
                                        (base64, blob) => {
                                            setOrgCompanyLogoObj(blob)
                                        }
                                    } />
                                    {/* <input name="Upload Documents"
                                        onClick={ (e) => {
                                            e.target.value="";
                                            setOrgCompanyLogoObj(null)
                                        }}
                                        onChange={ (e)=> {
                                            var files = e.target.files;

                                            if(files.length==0) return;

                                            setOrgCompanyLogoObj(files[0])

                                        }}
                                        type="file"
                                        size="5"
                                        className="form-control-file" /> */}
                                </div>
                            </div>

                            <div className="col-md-12 text-center">
                                    
                                {msg && (
                                    <div className={ hasError ? "alert alert-danger mt-10 text-left" : "alert alert-success mt-10 text-left" } role="alert">
                                        {msg}
                                    </div>
                                )}
                                
                                <ExtButton name="save" onClick={ (e)=> {
                                    setClicked(0)
                                    setIsPublish(false)
                                } } isLoading={isLoading} showLoading={clicked==0} type="submit" className="mtr-btn">
                                    <span>Save Job</span>
                                </ExtButton> &nbsp; &nbsp; 
                                <ExtButton name="publish" onClick={ (e)=> {
                                    setClicked(1)
                                    setIsPublish(true)
                                } } isLoading={isLoading} showLoading={clicked==1} type="submit" className="mtr-btn">
                                    <span>Publish your Job</span>
                                </ExtButton>

                                <br /><br />
                            </div>

                        </form>

                    </div>
                </div>
            </div>
        </section>

  );

};

export default CreateJob;