import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import { useNavigate } from "react-router-dom"

const AccessPremiumFeaturePopup = (props) =>{

    const navigation = useNavigate()

    const cancelIt = () =>{
        navigation("/overview", {replace: true})
    }

    return(
        <div style={{minHeight:"1000px"}}>
            <div className={`custom-modal cm-upgrade-popup show`}>
                <div className="custom-modal-cnt text-center">
                    <div onClick={cancelIt} className="close-icon"><i className="fa fa-times-circle fa-2x"></i></div>
                    <br />
                    <p>You are currently a FREE member of SCIONS. This feature is only for PREMIUM members. Please upgrade to view this section.</p>

                    <button onClick={(e)=>{
                        navigation("/upgrade", {replace: true})
                    }} className="mtr-btn" type="button">
                        <span>Upgrade Now</span>
                    </button>
                </div>
            </div>
        </div>
    )

}

export default AccessPremiumFeaturePopup