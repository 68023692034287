import React, { useCallback, useEffect, useState } from "react";
import useWebSocket, { ReadyState } from 'react-use-websocket';
import authHeader, { socketIP } from "../../../services/auth-header";
import InfiniteScroll from 'react-infinite-scroll-component'
import axios from "axios";
import { API_GET_RECENT_CONNECTED_USERS_MESSAGES, API_URL } from "../../../services/ApiEndpoints";
import { makeItem, makeItems, ReverseScrollBar } from "../../libs/ReverseScrollBar";
import { useSelector } from "react-redux";
import LoaderWrapperView from "../../libs/LoaderWrapperView";

const FriendsChat = (props) =>{

    const { user: currentUser } = useSelector((state) => state.auth);

    const {activeUserName, image} = props

    const [socketUrl, setSocketUrl] = useState(socketIP());
    const [messageHistory, setMessageHistory] = useState([]);
  
    const [activeUserId, setActiveUserId] = useState(props.id)

    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);
  
    const [userMessage, setUserMessage] = useState("")
  
    useEffect(()=>{
        setMessageHistory([])
        getMessages();
    }, [activeUserId])

    useEffect(() => {
        if (lastMessage !== null) {

          const data = JSON.parse(lastMessage.data)

          if(data.hasOwnProperty("command")){

            if(data.type==1){
                if(data.sender_id==activeUserId){
                  
                    setMessageHistory((prev) => [{
                        "id":-1,
                        "is_received":true,
                        "date":"Now",
                        "sender_id":data.sender_id,
                        "message":data.message
                      }].concat(prev));
    
                  }else{
                      props.onOtherMessageReceived && props.onOtherMessageReceived(data, activeUserId)
                  }
            }

          }

        }
      }, [lastMessage]);
  
    const handleClickSendMessage = (e) => {
  
        e.preventDefault()

        if(userMessage){
            let msg = JSON.stringify({command: "message", to: activeUserId || -1, message: userMessage, type:1})
            sendMessage(msg)
            setUserMessage("")

            setMessageHistory((prev) => [{
                "id":-1,
                "is_received":false,
                "date":"Now",
                "message":userMessage,
                "created_at":"Now"
              }].concat(prev));

        }
  
    };
  
    useEffect(()=>{
        
        if(readyState==ReadyState.OPEN){
            console.log("connected")

            setMessageHistory([])

            sendMessage(JSON.stringify({command: "register"}))
            
        }
  
    }, [readyState])
  
      const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
      }[readyState];
  
      
      const [isLoading, setIsLoading] = useState(false)
      const [page, setPage] = useState(1)
      const [canLoadMore, setCanLoadMore] = useState(true)

      const getMessages = () =>{

        if(activeUserId==-1) return

        setIsLoading(true)

        axios
        .get( `${API_URL}${API_GET_RECENT_CONNECTED_USERS_MESSAGES}/${activeUserId}`,{
            params:{
              page: 1
            },
            headers: authHeader()
        })
        .then((response)=>{
            
            if(response.status==200){

                setPage(1)
  
                const json = response.data;

                if(json.status=="RC200"){
  
                    var data = json.data;

                    setCanLoadMore( page!=data.last_page )

                    const list = data.data

                    setMessageHistory(list)
                   
  
                }
  
            }
  
            setIsLoading(false)
  
        })
        .catch((error)=>{
            setIsLoading(false)
        });
  
    }

    const loadFunc = () => {

      if(activeUserId==-1) return
      if(!canLoadMore) return
      if(isLoading) return

      if(messageHistory.length==0) {
        setCanLoadMore(false)
        return
      }

        // setIsLoading(true)

        axios
        .get( `${API_URL}${API_GET_RECENT_CONNECTED_USERS_MESSAGES}/${activeUserId}`,{
            params:{
              page: page+1
            },
            headers: authHeader()
        })
        .then((response)=>{
            
            if(response.status==200){

                setPage(page+1)
  
                const json = response.data;

                if(json.status=="RC200"){
  
                    var data = json.data;

                    setCanLoadMore( (page+1)!=data.last_page )

                    const list = data.data

                    setMessageHistory(messageHistory.concat(list))

                }
  
            }
  
            // setIsLoading(false)
  
        })
        .catch((error)=>{
            // setIsLoading(false)
        });

  }

  useEffect(()=>{
    setActiveUserId(props.id)
  }, [props])

  const timelineRef = React.useRef();
  const chatList = React.useRef()

  const [expanded, setExpanded] = useState(false)

  useEffect(()=>{
    
  }, [])

      return(
        <div className={`cm-chat chat-box ${expanded ? `cb-expanded` : ``} ${props.isOpenFrom ? `show` : ``}`}>
            <div className="chat-head">
                <span className="status f-online"></span>
                <h6>{activeUserName}</h6>
                <div className="more">
                    
                    <span className="d-none d-md-inline-block" onClick={(e)=>{
                        e.preventDefault()
                        setExpanded(!expanded)
                    }}>
                        {expanded ? (
                            <img style={{width:"24px"}} src={require("../../../assets/images/ic-minimize.png")} />
                        ) : (
                            <img style={{width:"24px"}} src={require("../../../assets/images/ic-maximize.png")} />
                        )}
                        
                    </span>

                    <span onClick={(e)=>{
                        e.preventDefault()
                        props.isOpenEvent(false)
                    }} className="close-mesage"><i className="ti-close"></i></span>
                </div>
            </div>
            <div ref={chatList} className="chat-list">
                
                <div className="chat-lst" style={{ display: "flex" }}>
                    <ReverseScrollBar
                        canLoadMore={canLoadMore}
                        innerRef={timelineRef}
                        image={image}
                        expanded={expanded}
                        isLoading={isLoading}
                        items={messageHistory}
                        fetch={loadFunc}
                    >
                        <LoaderWrapperView isLoading={isLoading} >
                            {messageHistory.map((item, index) => (
                                <ChatMessage key={index} image={item.is_received ? image : currentUser.profile_image} val={item} />
                            ))}
                        </LoaderWrapperView>
                        
                        </ReverseScrollBar>
                </div>

                <form onSubmit={handleClickSendMessage} className="text-box">
                    <input onFocus={(e)=>{
                        chatList.current.scrollTo(0, 0)
                    }} readOnly={readyState !== ReadyState.OPEN} onChange={(e)=> setUserMessage(e.target.value) } value={userMessage} placeholder="Type message..." />
                    <button onClick={handleClickSendMessage} className="btn-send-msg" disabled={readyState !== ReadyState.OPEN} type="button" title="send"><i className="fa fa-paper-plane"></i></button>
                </form>
                {readyState !== ReadyState.OPEN && (
                    <small className="text-danger">Not connected to server</small>
                )}
            </div>
        </div>
      )
  
  }
  
const ChatMessage = ({ image, val, ...props }) => {
    return (
      
        <div key={val.id} className={val.is_received ? "message me" : "message you"}>
              <div className="chat-thumb"><img src={image} alt="" /></div>
              <div className="notification-event">
                  <span className="chat-message-item">
                  {val.message}
                  </span>
                  <span className="notification-date"><time className="entry-date updated">{val.date}</time></span>
              </div>
          </div>
    );
  };

  export default FriendsChat;