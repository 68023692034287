import React, {useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, Navigate} from 'react-router-dom'
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import axios from "axios";

import { ApiServices } from "../services/ApiServices";
import { API_RESET_PASSWORD, API_URL, API_VERIFY_TOKEN } from "../services/ApiEndpoints";

import {
    CLEAR_MESSAGE,
    LOGIN_FAIL,
    SET_MESSAGE,
  } from "../actions/types";
import PasswordField from "./libs/PasswordField";
import ReCaptchaV3 from "./libs/ReCaptchaV3";
import FormReCaptchaV3 from "./libs/FormReCaptchaV3";

const ResetPassword = (props) =>{

    /************* Password Reset Token Verification **********/

    let {token} = useParams();

    const [isLoading, setIsLoading] = useState(true)
    const [errorMsg, setErrorMsg] = useState("") 

    const response = ApiServices({
        method:"POST",
        url: API_VERIFY_TOKEN,
        data:{
            "token": token
        }
    });

    useEffect(()=>{
        
        if(response.has){
            
            if(response.status){
                setIsLoading(false)
                setErrorMsg(response.message)
            }

            if(!response.status){
                setIsLoading(false)
                setErrorMsg(response.message)
            }

        }

    });

    /************* Password Reset Form **********/

    const form = useRef();

    const [password, setPassword] = useState("")
    const [confPassword, setConfPassword] = useState("")
    const [hasError, setHasError] = useState(true);

    const [loading, setLoading] = useState(false);

    const { message } = useSelector(state => state.message);

    const dispatch = useDispatch();

    const handleLogin = (captchaToken) =>{

        setLoading(true);

        axios.post(API_URL + API_RESET_PASSWORD, {
            token: token,
            password: password,
            confirm_password: confPassword,
            recaptcha_token:captchaToken
            })
            .then((responsex) => {

                setLoading(false);

                if(responsex.data.status=="RC200"){
                    
                    setHasError(false)

                    dispatch({
                        type: SET_MESSAGE,
                        payload: responsex.data.message
                    });

                }else{

                    setHasError(true)

                    dispatch({
                        type: SET_MESSAGE,
                        payload: responsex.data.message
                    });

                }

                return responsex.data;
            });

    };

    /*if (!hasError) {
        return <Navigate to="/login" />;
    }*/

    return(
        <div className="theme-layout">
            <div className="container-fluid">
                <div className="row">

                    <div className="col-md-12 text-center mt-3">    
                        <a href="/"><img width="240px" src={require('../assets/images/logo-big.png')} alt="" /></a>
                    </div>

                    <div className="col-md-12">
                        <div className="log-reg-area mt-5" style={{margin:"0 auto 100px auto"}}>
                        <div className="login-reg-bg">
                                
                                <h3 className="log-title">Reset Password</h3>
                                
                                {isLoading ? (
                                    <p>loading please wait...</p>
                                ) : (

                                    !response.status ? (
                                        <div className="alert alert-danger" role="alert">
                                            {response.message} <Link className="btn-link" to="/forgot-password" >Click here to get new link</Link>
                                        </div>
                                    ) : (
                                        
                                        <div>
                                            <FormReCaptchaV3 onLoadStatusUpdate={(status)=>{
                                                setLoading(status)
                                            }} action="reset_password_change" onSubmit={handleLogin}>

                                                <PasswordField value={password} label="Password" keys="pwd" onChange={ (e) => setPassword(e.target.value) } />

                                                <PasswordField value={confPassword} label="Confirm Password" keys="cpwd" onChange={ (e) => setConfPassword(e.target.value) } />

                                                {message && (
                                                    <div className={ hasError ? "alert alert-danger mt-10" : "alert alert-success" } role="alert">
                                                        {message} &nbsp;
                                                        {
                                                            !hasError && <a className="btn-link" href="/login" >Click here to login</a>
                                                        }
                                                    </div>
                                                )}

                                                <div className="submit-btns">

                                                    <button className="mtr-btn signin" disabled={loading} type="submit">

                                                    {loading && (
                                                            <span className="spinner-border spinner-border-sm"></span>
                                                        )}
                                                        <span>Reset Password</span>
                                                    </button>

                                                </div>
                                            </FormReCaptchaV3>

                                        </div>

                                    )
                                    

                                )}
                            
                        </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;