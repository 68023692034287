import Linkify from "linkify-react";
import "linkify-plugin-hashtag";
import "linkify-plugin-mention"

const AutoLinks = (props) =>{

    const url = `${window.location.origin}`

    var linkifyOptions = {
        formatHref: {
            hashtag: (href) => `${url}/${href.substr(1)}`,
            mention: (href) => `${url}${href}`,
        },
        target: "_blank"
    }

    return(
        <Linkify options={linkifyOptions}>
            {props.children}
        </Linkify>
    )

}

export default AutoLinks