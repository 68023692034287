import { useState } from "react";
import { useNavigate } from "react-router-dom";

const SearchBar = (props) =>{

    const navigate = useNavigate();
    const [searchKeyword, setSearchKeyword] = useState("")

    const handleSearch = () => {

        if(searchKeyword.length>0){
            navigate(`/search/users/${searchKeyword}`)
        }

    }

    return(
        props.isResponsive ? (
            <form className="mh-form">
                <input onKeyDown={(e)=>{
                    if(e.key==="Enter"){
                        handleSearch()
                    }
                }} value={searchKeyword} onChange={ (e)=> setSearchKeyword(e.target.value) } type="text" placeholder="Search " />
                <a onClick={handleSearch} className="fa fa-search"></a>
            </form>
        ) : (
            <form method="post" onSubmit={ (e)=> e.preventDefault() } className="">
                <input onKeyDown={(e)=>{
                    if(e.key==="Enter"){
                        handleSearch()
                    }
                }} value={searchKeyword} onChange={ (e)=> setSearchKeyword(e.target.value) } type="text" placeholder="Search " />
                <button type="button" onClick={handleSearch} ><i className="ti-search"></i></button>
            </form>
        )
        
    )

}

export default SearchBar;