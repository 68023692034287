import { useLocation } from "react-router-dom";

const CountryBlocked = () =>{

    const location = useLocation();

    return(
        <div className="theme-layout">
            <div className="container-fluid">
                <div className="row">

                    <div className="col-md-12 text-center mt-3">    
                        <a href="/"><img width="240px" src={require('../../assets/images/logo-big.png')} alt="" /></a>
                    </div>

                    <div className="col-md-12">
                        <div className="log-reg-area mt-5" style={{margin:"0 auto 100px auto"}}>
                            <div className="login-reg-bg">
                                <h4 className="text-center">{ (location.state ? location.state.msg : "You do not have access to this page")}</h4>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
    
}

export default CountryBlocked;