import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  matchRoutes,
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Footer from "./components/layouts/Footer";
import LeftSideBar from "./components/layouts/LeftSideBar";
import ResponsiveHeader from "./components/layouts/ResponsiveHeader";
import RightSideBar from "./components/layouts/RightSideBar";
import TopBar from "./components/layouts/TopBar";
import $ from "jquery";
import "./assets/js/tooltip";
import { Helmet } from "react-helmet";
import CompLoaderLayout from "./components/libs/CompLoaderLayout";
import axios from "axios";
import { API_GET_USER_DATA, API_URL } from "./services/ApiEndpoints";
import authHeader from "./services/auth-header";
import { LOGIN_FAIL, SET_MESSAGE, UPDATE_PROFILE_STATE } from "./actions/types";
import { logout } from "./actions/auth";
import CookieConsent from "./components/libs/CookieConsent";
import CommonPagesFooter from "./components/pages/CommonPagesFooter";
import FooterHome from "./components/layouts/FooterHome";

const PrivateRoute = (props) => {
  const { isLoggedIn } = useSelector((state) => state.auth);
  const auth = isLoggedIn; // determine if authorized, from context or however you're doing it

  const { userData } = useSelector((state) => state.userData);

  const location = useLocation();
  const navigation = useNavigate();
  const dispatch = useDispatch();

  /*{
      route: "/organizations",
      role: [1, 2, 3],
      comment: "Organizations list",
    },
    {
      route: "/organizations/create",
      role: [1],
      comment: "create new organization",
    },
    {
      route: "/organizations/:id/edit",
      role: [1, 2],
      comment: "edit organization details",
    },
    {
      route: "/organizations/:id/view",
      role: [1, 2],
      comment: "view organization details",
    },
    {
      route: "/organizations/:id/admins",
      role: [1],
      comment: "Organizations admins",
    },
    {
      route: "/organizations/:id/users",
      role: [1, 2],
      comment: "Organizations users",
    },

     {
      route: "/ideas",
      role: [1, 2, 3, 4],
      comment: "idea list",
    },
    {
      route: "/ideas/view/:slug",
      role: [1, 2, 3, 4],
      comment: "idea list",
    },
    */

    const onlyOrgRegisterAs = [{
      route: "/organizations",
      role: [1, 2, 3],
      comment: "Organizations list",
    },
    {
      route: "/organizations/create",
      role: [1],
      comment: "create new organization",
    },
    {
      route: "/organizations/:id/edit",
      role: [1, 2],
      comment: "edit organization details",
    },
    {
      route: "/organizations/:id/view",
      role: [1, 2],
      comment: "view organization details",
    },
    {
      route: "/organizations/:id/admins",
      role: [1],
      comment: "Organizations admins",
    },
    {
      route: "/organizations/:id/users",
      role: [1, 2],
      comment: "Organizations users",
    }];

  const routesProtections = [
    {
      route: "/jobs/:slug",
      role: [1, 2, 3, 4],
      comment: "jobs list page",
    },
    {
      route: "/ideas/my-ideas",
      role: [1, 2, 3, 4],
      comment: "idea list",
    },
    {
      route: "/ideas/create",
      role: [1, 2, 3, 4],
      comment: "creator - create new idea",
    },
    {
      route: "/ideas/details/:slug",
      role: [1, 2, 3, 4],
      comment: "creator - idea details",
    },
    {
      route: "/ideas/details/:slug/edit",
      role: [1, 2, 3, 4],
      comment: "creator - idea edit",
    },
    {
      route: "/ideas/details/:slug/responses",
      role: [1, 2, 3, 4],
      comment: "creator - view responses/offers",
    },
    {
      route: "/ideas/details/:slug/chat",
      role: [1, 2, 3, 4],
      comment: "creator - chat with buyers",
    },
    {
      route: "/ideas/my-offers/:slug",
      role: [1, 2, 3, 4],
      comment: "Buyer - offers that user sent",
    },
    {
      route: "/ideas/my-offers/:slug/details",
      role: [1, 2, 3, 4],
      comment: "Buyer - idea details",
    },
    {
      route: "/ideas/my-offers/:slug/offers",
      role: [1, 2, 3, 4],
      comment: "Buyer - list of offers and response from creator",
    },
    {
      route: "/ideas/my-offers/:slug/chat",
      role: [1, 2, 3, 4],
      comment: "Buyer - chat with idea creator",
    },
    {
      route: "/my-jobs",
      role: [1, 2, 4],
      comment: "my job listing",
    },
    {
      route: "/my-jobs/create",
      role: [1, 2, 4],
      comment: "create new job",
    },
    {
      route: "/my-jobs/edit/:id",
      role: [1, 2, 4],
      comment: "edit job",
    },
    {
      route: "/organizations-list/:slug",
      role: [1, 2, 4],
      comment: "public org details",
    },
    {
      route: "/problems",
      role: [1, 2, 3, 4],
      comment: "problem list",
    },
    {
      route: "/problems/:slug",
      role: [1, 2, 3, 4],
      comment: "problem details",
    },
    {
      route: "/problemspace/my-problems",
      role: [1, 2, 3, 4],
      comment: "problem list",
    },
    {
      route: "/problemspace/create",
      role: [1, 2, 3, 4],
      comment: "creator - create new idea",
    },
    {
      route: "/problemspace/details/:slug",
      role: [1, 2, 3, 4],
      comment: "creator - idea details",
    },
    {
      route: "/problemspace/details/:slug/edit",
      role: [1, 2, 3, 4],
      comment: "creator - idea edit",
    },
    {
      route: "/problemspace/details/:slug/responses",
      role: [1, 2, 3, 4],
      comment: "creator - view responses/offers",
    },
    {
      route: "/problemspace/details/:slug/chat",
      role: [1, 2, 3, 4],
      comment: "creator - chat with buyers",
    },
    {
      route: "/problemspace/my-offers/:slug",
      role: [1, 2, 3, 4],
      comment: "Buyer - offers that user sent",
    },
    {
      route: "/problemspace/my-offers/:slug/details",
      role: [1, 2, 3, 4],
      comment: "Buyer - idea details",
    },
    {
      route: "/problemspace/my-offers/:slug/offers",
      role: [1, 2, 3, 4],
      comment: "Buyer - list of offers and response from creator",
    },
    {
      route: "/problemspace/my-offers/:slug/chat",
      role: [1, 2, 3, 4],
      comment: "Buyer - chat with idea creator",
    },
  ];

  const getData = () => {
    if (!auth) return;

    axios
      .get(API_URL + API_GET_USER_DATA, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          const json = response.data;

          if (json.status == "RC200") {
            dispatch({
              type: UPDATE_PROFILE_STATE,
              userData: json.data,
            });
          } else if (json.status == "RC400") {
            navigation(`/location-access-denied`, {
              state: { msg: json.message },
            });
          } else if (json.status == "RC300") {
            dispatch(logout(false));

            dispatch({
              type: LOGIN_FAIL,
            });

            dispatch({
              type: SET_MESSAGE,
              payload: json.message,
            });
          } else {
            //alert("ERROR: " + json.message)
          }
        }
      })
      .catch((error) => {
        //alert("ERROR C: " + error)
      });
  };

  useEffect(() => {
    if (userData !== undefined) {
      // Check that the user has saved all required profile details
      if (
        userData.force_redirect_to_profile == 0 &&
        location.pathname != "/profile/edit"
      ) {
        navigation(`/profile/edit`);
        return;
      }

      // here check if user is registered as org, if yes then allow access
      let routeRas = onlyOrgRegisterAs.filter((val, index) => {
        const routes = [{ path: val.route }];

        return matchRoutes(routes, location) !== null;
      });

      if (routeRas.length != 0) {
        routeRas = routeRas[0];
        console.log(routeRas)

        if (userData.register_as == 0) {
          navigation(`/access-premium-feature`);
          return;
        }
        
      }

      // check the current route is exist in the protected routes
      let route = routesProtections.filter((val, index) => {
        const routes = [{ path: val.route }];

        return matchRoutes(routes, location) !== null;
      });

      // if route found in protected route and check the user has access to it
      if (route.length != 0) {
        route = route[0];

        // check that the user is premium or not, if not premium use and access the protected route then redirect to payment page
        if (userData.is_premium_user == 0) {
          //navigation(`/upgrade`)
          navigation(`/access-premium-feature`);
          return;
        }

        // check that the user has correct access to the route, if not then redirect it to access denied page
        let hasAccess =
          route.role.filter((r, i) => r == userData.user_role).length != 0;

        if (!hasAccess) {
          navigation(`/access-denied`);
          return;
        }
      }
    }
  }, [location, userData]);

  const allow_private_route_publicly = ["/logout", "ideas/view/", ""];

  useEffect(() => {
    let route = location.pathname && location.pathname.replace(/^\/|\/$/g, "");

    let callApi =
      allow_private_route_publicly.filter((r, i) => r == route).length != 0;

    if (!callApi) {
      getData();
    }
  }, [location]);

  const param = useParams();

  const checkIsPublic = () => {
    let route = location.pathname && location.pathname.replace(/^\/|\/$/g, "");

    route = route.replace(param["slug"], "");
    route = route.replace(param["public_url"], "");

    let hasAccess =
      allow_private_route_publicly.filter((r, i) => r == route).length != 0;

    return hasAccess;
  };

  useEffect(() => {
    let externalScript = null;

    //window.addEventListener('scroll', function(){

    setTimeout(() => {
      if (document.getElementById("external-script") == null) {
        externalScript = document.createElement("script");
        externalScript.onerror = function () {};
        externalScript.id = "external-script";
        externalScript.async = true;
        externalScript.type = "text/javascript";
        externalScript.setAttribute("crossorigin", "anonymous");
        document.body.appendChild(externalScript);
        externalScript.src = "/script.js";
      }
    }, 1000);

    // });

    return () => {
      if (externalScript != null) {
        document.body.removeChild(externalScript);
      }
    };
  }, [userData]);

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return auth ? (
    <>
      {userData == undefined ? (
        <>
          <CompLoaderLayout className="full" />
        </>
      ) : (
        <div className="theme-layout">
          <div className="postoverlay"></div>
          <ResponsiveHeader />

          <TopBar />

          <RightSideBar />

          <LeftSideBar />

          <Suspense fallback={<CompLoaderLayout />}>
            <div style={{ marginTop: "60px" }}>
              <Outlet />
            </div>
          </Suspense>

          {location.pathname == "/scionsline" ? "" : <Footer />}

          <CookieConsent />
        </div>
      )}
    </>
  ) : checkIsPublic() ? (
    <div className="theme-layout">
      <div className="responsive-header">
        <div className="mh-head first mm-sticky mh-btns-left mh-btns-right mh-sticky">
          <span className="mh-text">
            <a href="/" title="">
              <img src={require("./assets/images/logo.png")} alt="" />
            </a>
          </span>
          <span className="mh-btns-right w-40">
            <a className="fa fa-align-justify" href="#othermenu"></a>
          </span>
        </div>
      </div>

      <nav id="othermenu" className="mm-menu mm-offcanvas mm-right">
        <ul>
          <li>
            <Link to="/login" title="">
              <i className="fa fa-sign-in"></i> &nbsp; LOGIN
            </Link>
          </li>
          <li>
            <Link to="/register" title="">
              <i className="fa fa-user-plus"></i> &nbsp; JOIN US
            </Link>
          </li>
        </ul>
      </nav>

      <div className="topbar stick">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3">
              <div
                className="logo"
                style={{ width: "100%", marginTop: "10px" }}
              >
                <a title="" href="/">
                  <img src={require("./assets/images/logo.png")} alt="" />
                </a>
              </div>
            </div>

            <div className="col-md-9 text-right">
              <div className="top-area" style={{ width: "100%" }}>
                <ul
                  className="main-menu"
                  style={{
                    float: "none",
                    lineHeight: "normal",
                    marginTop: "10px",
                  }}
                >
                  <li style={{ lineHeight: "normal" }}>
                    <Link
                      to="/login"
                      title=""
                      style={{
                        border: "1px solid #f05a24",
                        borderRadius: "25px",
                        padding: "10px 20px",
                      }}
                    >
                      <i className="fa fa-sign-in"></i> LOGIN
                    </Link>
                  </li>
                  <li style={{ lineHeight: "normal" }}>
                    <Link
                      to="/register"
                      title=""
                      style={{
                        border: "1px solid #f05a24",
                        borderRadius: "25px",
                        padding: "10px 20px",
                        background: "#f05a24",
                        color: "#fff",
                      }}
                    >
                      <i className="fa fa-user-plus"></i> JOIN US
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Suspense fallback={<CompLoaderLayout />}>
        <div style={{ marginTop: "60px" }}>
          <Outlet />
          <CookieConsent />
        </div>
      </Suspense>

      <FooterHome />
    </div>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
