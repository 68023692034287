import { Link, useNavigate } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component'
import { API_GET_ORG, API_IDEA_LIST, API_URL } from "../../../services/ApiEndpoints";
import axios from "axios";
import authHeader from "../../../services/auth-header";
import { useEffect, useState } from "react";
import CompLoaderLayout from '../../libs/CompLoaderLayout'
import { printPhoneNumber } from "../../../helpers/configs";

const Organizations = () => {

    const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [list, setList] = useState([])
  const [links, setLinks] = useState([])
  const navigate = useNavigate();

  const getData = () =>{

    setIsLoading(true)

    axios
        .get(API_URL + API_GET_ORG,{
                headers: authHeader()
            }
        )
        .then((response)=>{
            
            if(response.status==200){

                const json = response.data;

                if(json.status=="RC200"){

                    var resp = json.data;

                    setList(resp)

                    if(resp.length==0){
                        navigate("/organizations/create")  
                    }else{
                        let orgId = resp[0].id;
                        navigate(`/organizations/${orgId}/view`)  
                    }
                }

            }

            setIsLoading(false)

        })
        .catch((error)=>{
            setIsLoading(false)
        });

  }

  const handleLinkClick = (e, cPage) =>{
      e.preventDefault()
      setCurrentPage( cPage )
  }

  const printLinks = (link) =>{

    const isNullLink = link.url ? "" : "disabled" 

    if(link.label==="...") {
        return(<li key={link.label} className={`page-item ${isNullLink}`}>
            <a className="page-link" onClick={ (e) => e.preventDefault() } href="#a">{link.label}</a>
        </li>)
    }

    if(link.label==="&laquo; Previous") {
        return(
            <li key={link.label} className={`page-item ${isNullLink}`}>
                <a onClick={ (e)=> handleLinkClick(e, currentPage-1)} className="page-link prev" href="#a">
                  Previous
                </a>
            </li>
        )
    }
    
    if(link.label==="Next &raquo;") {
        return(
            <li key={link.label} className={`page-item ${isNullLink}`}>
                <a onClick={ (e)=>handleLinkClick(e, currentPage+1) } className="page-link next" href="#n" aria-label="Next">
                Next
                </a>
            </li>
        )
    }
    
    const isActive = link.active ? "active" : ""

    return(
        <li key={link.label} className={`page-item ${isActive}`}>
            <a className="page-link" onClick={ (e) => handleLinkClick(e, parseInt(link.label,10)) } href="#a">{link.label}</a>
        </li>
    )
}

  useEffect(()=>{
    getData()
  }, [currentPage])

  return (
        <section>
            <div className="mt-4">
                <div className="container">

                    {isLoading? (
                        <p>Loading...</p>
                    ) : (
                        <>
                            <div className="row">
                                <div className="col-md-10"></div>
                                <div className="col-md-2">
                                    <Link to="/organizations/create" style={{float:"none", position:"relative", top: "0px"}} className="add-butn">Create Organization</Link>
                                    <br />
                                    <br />
                                </div>
                            </div>
                        
                            <div className="row">
                            {list && list.map((org, i) => (
                                <SingleOrg key={org.id} data={org} />
                            ))}
                            </div>

                            { links.length>3 && (
                                <div className="col-md-12">
                                    <nav className="mt-4 mb-3">
                                        <ul className="pagination justify-content-center mb-0">

                                            { links.map((link)=>(

                                                printLinks(link)
                                                
                                            ))}
                                            
                                        </ul>
                                    </nav>
                                </div>
                            )}
                        </>
                    )}

                </div>
            </div>
        </section>
  );
};

const SingleOrg = (props) => {

  const {data} = props

  return(
    <div className="col-md-4">
        <div className="g-post-classic">
            <figure>
                <Link to={`/organizations/${data.id}/view`}>
                    <img alt={data.company_name} src={data.company_logo} />
                </Link>
            </figure>
            <div className="g-post-meta">
                <div className="post-title">
                    <strong>{data.company_name}</strong><br />
                    <span>Phone: {printPhoneNumber(data.phone_code, data.phone)}</span>
                </div>
                <div className="g-post-ranking">
                    <a title="" href={data.website} target="_blank" className="likes">{data.website}</a>
                </div>
            </div>
        </div>
    </div>
  )

};

export default Organizations;