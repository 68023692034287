import React, { Component, useEffect, useState } from "react";

import InfiniteScroll from 'react-infinite-scroll-component'
import { API_SEARCH_IDEAS, API_SIGN_NDA, API_URL } from "../../../services/ApiEndpoints";
import axios from "axios";
import authHeader from "../../../services/auth-header";
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
import LoaderWrapperView from "../../libs/LoaderWrapperView";
import { toast } from "react-toastify";
import { Button, Modal } from 'react-bootstrap';
import NDAText from "../ideas/NDAText";
import ExtButton from "../../libs/ExtButton";

const SearchIdeas = () =>{

    const {keyword} = useParams()
    const [isLoading, setIsLoading] = useState(true)
    const [resultCount, setResultCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [canLoadMore, setCanLoadMore] = useState(true)
    const [results, setResults] = useState([])

    useEffect(()=>{
        getData()

    }, [keyword])

    const getData = () => {

        if(currentPage==1)
            setIsLoading(true)

        axios
        .get(API_URL + API_SEARCH_IDEAS +"?keyword="+ keyword,{
            headers: authHeader()
        })
        .then((response)=>{
            
            if(response.status==200){

                const json = response.data;

                if(json.status=="RC200"){

                    var data = json.data;

                    setResultCount(data.count)

                    setCanLoadMore( currentPage!=data.ideas.last_page )
                    setResults(data.ideas.data)

                }

            }

            if(currentPage==1)
                setIsLoading(false)

        })
        .catch((error)=>{

            if(currentPage==1)
                setIsLoading(false)

        });
    }

    const loadFunc = () => {
        
        setCurrentPage(currentPage+1)

        getData();

    }

    const reloadFeed = () =>{

        this.setState({
            posts: [],
            currentPage:1
        });

        this.getPost();

        window.clearOverlay();

    }
    
    const [showNDA, setShowNDA] = useState(false)
    const [currentData, setCurrentData] = useState({})

    const [isNDALoading, setIsNDALoading] = useState(false)

    const navigate = useNavigate()

    const submitNDA = () =>{

        setIsNDALoading(true)

        var formdata = new FormData()
        formdata.append("slug", currentData.slug);

        axios.post(API_URL + API_SIGN_NDA, formdata,{
            headers:authHeader()
        })
        .then((responsex) => {

            setIsNDALoading(false)

            if(responsex.data.status=="RC200"){

                toast.success(responsex.data.message)

                setShowNDA(false)

                navigate(`/ideas/${currentData.slug}`)

            }

            return responsex.data;
        }).error(()=>{
            setIsNDALoading(false)
        });

    }

    return(

        <LoaderWrapperView isLoading={isLoading}>
            <div className="row">
                <div className="col-lg-12">
                    <h6>{resultCount} Result(s) for "{keyword}"</h6>

                    <div className="row merged20" id="page-contents">
                        <div className="col-lg-12">
                                <div className="frnds">

                                    <InfiniteScroll
                                        dataLength={results.length}
                                        next={loadFunc}
                                        hasMore={canLoadMore}
                                        endMessage={<div className="text-center"><br />That's all for now</div>}
                                        loader={<div className="loader text-center">Loading ...</div>}>
                                        
                                        <ul style={{marginTop:"10px"}} className="nearby-contct">
                                            {results.map((result, i) => <SingleIdea checkNDA={(e)=>{

                                                                            e.preventDefault()

                                                                            if(result.is_nda_done==1)
                                                                                navigate(`/ideas/view/${result.slug}`)
                                                                            else{
                                                                                setCurrentData(result)
                                                                                setShowNDA(true)
                                                                            }

                                                                        }}key={"si-"+result.id} idea={result} /> )}
                                        </ul>

                                    </InfiniteScroll>
                                
                                </div>
                            
                        </div>
                        
                    </div>
                </div>
            </div>

            <Modal noMargin dialogClassName={"full-modal"} show={showNDA} aria-labelledby="contained-modal-title-vcenter" onHide={()=>setShowNDA(false)}>  
                
                <Modal.Body>
                    
                    <NDAText secondParty={currentData ? currentData.full_name : ""} secondPartyRole={currentData ? currentData.role_name : ""} ideaTitle={currentData ? currentData.idea_title : ""} />

                    <br /><br />


                    <div className="row">
                        <div className="col-md-12 text-center">
                            <ExtButton isLoading={isNDALoading} showLoading={false} type="button" className="btn btn-link mr-50" onClick={()=>setShowNDA(false)}><span>Cancel</span></ExtButton>

                            <ExtButton isLoading={isNDALoading} showLoading={true} type="button" className="mtr-btn" onClick={(e)=>{
                                submitNDA()
                            }}><span>I understand and Agree</span></ExtButton>
                        </div>
                    </div>


                </Modal.Body>

            </Modal>

        </LoaderWrapperView>

    );

};

const SingleIdea = (props) =>{

    const {idea} = props

    return(
        <li>
            <a onClick={props.checkNDA} >
                <div className="nearly-pepls">
                    <div className="row">
                        <div className="col-md-3">
                            <img src={idea.idea_image} alt="" />
                        </div>
                        <div className="col-md-9">
                            <div className="pepl-info">
                                <h3>
                                    {idea.idea_title}
                                </h3>
                                <small>Shared on {idea.date}</small>
                                <span>{idea.eng_field}</span>
                                <p>{idea.introduction}</p>

                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </li>
    )

}

export default SearchIdeas;