import { useEffect, useState } from "react";
import $ from 'jquery'
import CheckboxTree from 'react-checkbox-tree';
import '../../../node_modules/react-checkbox-tree/lib/react-checkbox-tree.css';
import React from "react";
import LoaderWrapperView from "./LoaderWrapperView";
import authHeader from "../../services/auth-header";
import axios from "axios";
import { API_URL } from "../../services/ApiEndpoints";

const nodes = [{
    value: '34',
    label: 'Engineering & Technology',
    children: [
        { value: '37', label: 'Mechanical' },
        { value: '38', label: 'Chemical' },
    ],
},{
    value: '35',
    label: 'Technology',
    children: [
        { value: '39', label: 'Mechanical' },
        { value: '31', label: 'Chemical' },
    ],
},{
    value: '3',
    label: 'IT',
    children: null,
}];

class TreeView extends React.Component {

    constructor(props) {
        super();

        this.state = {
            isLoading:false,
            data:[],
            checked: [],
            expanded: [],
        };
        
    }

    componentDidMount = () =>{       
        this.get_list()
    }

    get_list = () => {

        if(!this.props.endpoint) return
        
        this.state.isLoading = true

        axios
        .get(`${API_URL}${this.props.endpoint}`,
            {
              headers: authHeader()
            }
        )
        .then((response)=>{
            
            if(response.status==200){

                const json = response.data;

                const selectedMainCategory = [];

                if(json.status=="RC200"){

                    var resp = json.data;

                    this.setState({
                        data: resp
                    });

                    if(this.props.value!=undefined){

                        resp.map((item, index)=>{
                            if(this.props.value==item.value){
                                item.children.map((subItem, indexx)=>{
                                    selectedMainCategory.push(
                                        subItem.value
                                    )
                                })
                            }
                        })
                    }
                    
                }

                this.setState({ checked:selectedMainCategory })
                this.setState({ expanded:[this.props.value] })

                this.state.isLoading = false

            }

        })
        .catch((error)=>{

            this.state.isLoading = false

        });

    }

    get_selected = () =>{
        return this.state.checked
    }

    render() {
        return (
            <LoaderWrapperView isLoading={this.state.isLoading} >
                <CheckboxTree
                    nodes={this.state.data}
                    checked={this.state.checked}
                    expanded={this.state.expanded}
                    onCheck={checked => {
                        this.setState({ checked })
                        this.props.onCheckChange && this.props.onCheckChange(checked)
                    }}
                    onExpand={expanded => this.setState({ expanded })}
                    icons={{
                        parentClose: "",
                        parentOpen: "",
                        leaf: ""
                    }}
                />
            </LoaderWrapperView>
        );
    }
}

/*const TreeView = () =>{



    // useEffect(()=>{
    //     const script = document.createElement('script');
    //     script.src = "/gijgo.js";
    //     script.async = true;
    //     document.body.appendChild(script);

    //     /*var tree = $('#tree').tree({
    //         primaryKey: 'id',
    //         dataSource: [ { text: 'foo', children: [ { text: 'bar' } ] } ],
    //         imageUrlField: 'flagUrl'
    //     });*/

    //     return () => {
    //         document.body.removeChild(script);
    //     }
    // })*/

    /*return(
        <div id="tree"></div>
    )
}*/

export default TreeView;