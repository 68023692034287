import React, { useEffect, useRef, useState } from "react";
import {Link, Outlet, Route, Routes, useParams} from 'react-router-dom'
import { useSelector } from "react-redux";
import Select2 from "../../libs/Select2";
import ExtButton from "../../libs/ExtButton";
import axios from "axios";
import { API_IDEA_MY_OFFERS, API_IDEA_OFFERS, API_RESPOND_TO_OFFER, API_URL } from "../../../services/ApiEndpoints";
import authHeader from "../../../services/auth-header";
import Popups from "../../libs/Popups";

const IdeaOffersList = () =>{

    let {slug} = useParams();
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([])

    const getDetails = () =>{

        setIsLoading(true)

        axios
        .get(API_URL + API_IDEA_MY_OFFERS,{
            params:{
                idea_id: slug
            },
            headers: authHeader()
        })
        .then((response)=>{
            
            if(response.status==200){

                const json = response.data;

                if(json.status=="RC200"){

                    var data = json.data;

                    setData(data)

                }

            }

            setIsLoading(false)

        })
        .catch((error)=>{
            setIsLoading(false)
        });

    }

    useEffect(()=>{
        getDetails()
    }, [])

    return(
        <div className="row">
            <div className="col-md-12">
                <div className="inbox-lists">
                    
                        { isLoading ? (
                            <p>Loading...</p>
                        ) : (
                            <div className="mesages-lists" style={{paddingTop: "0px"}}>
                                <ul id="message-list" className="message-list">
                                    
                                    {data.length==0 ? (
                                        <p>Not found</p>
                                    ) : (
                                        data.map((offer, index)=>(
                                            <SingleOffer ideaId={slug} data={offer} key={index} />
                                        ))
                                    )}

                                </ul>
                            </div>
                        )}

                </div>
            </div>
        </div>
    );
};

const SingleOffer = (props) =>{

    const {data, ideaId} = props

    const type = [
        "Want to sell Idea through SCIONS",
        "Want funds from Investors",
        "Want to Collaborate/Partner with like minded SCIONS",
        "Want to Hire employees for my Idea execution"
    ]

    return(
        <li className="unread">
            <h3 className="sender-name">FOR {type[data.type]}</h3>

            <br />
            <strong>My Offer Details: </strong>
            <p>
                { data.type==0 && (
                    <p style={{margin:"0px"}}><strong>Offer price:</strong> €{data.amount}</p>
                )}
            </p>

            { data.description && data.description.split("\n").map((str, index) => <p key={`px-${index}`}>{str}</p>)}

            <br />
            <strong>Status: </strong> <span className={`badge badge-`+(
                data.status==0 ? "warning" : (
                    data.status==1 ? "success" : "danger"
                )
            )}>{(
                data.status==0 ? "Pending" : (
                    data.status==1 ? "Accepted" : "Rejected"
                )
            )}</span>
            
            {data.status!=0 && (
                <div className="ofr-slr-rsp">
                    <strong>Comments from Seller: </strong>
                    { data.comments && data.comments.split("\n").map((str, index) => <p key={`px-${index}`}>{str}</p>)}
                </div>
            )}

        </li>
    )

}

export default IdeaOffersList;