import axios from "axios";
import {
  API_GET_INVESTOR_LIST_FOR_IDEA,
  API_URL,
} from "../../../services/ApiEndpoints";
import authHeader from "../../../services/auth-header";
import { useEffect, useState } from "react";
import linkdinlogo from "../../../assets/images/ic-linkedin-logo.png";
import scionlogo from "../../../assets/images/ic-scion-logo.png";
import { typeOfOrganization } from "../Consts";
import { NavLink, useParams } from "react-router-dom";
import LoaderWrapperView from "../../libs/LoaderWrapperView";
import { toast } from "react-toastify";

const IdeaAngelInvestor = () => {

  let {slug} = useParams();

  const [organization, setOrganization] = useState([]);
  const [user, setUser] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getEngFields = () => {
    setIsLoading(true);

    axios
      .get(API_URL + API_GET_INVESTOR_LIST_FOR_IDEA + "/"+slug, {
        headers: authHeader(),
      })
      .then((response) => {
        setIsLoading(false);

        const { status, data, message } = response.data;

        if (status == "RC200") {
          setOrganization(data.org);
          setUser(data.users);
        }else{
          toast.error(message)
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getEngFields();
  }, []);

  return (
    <div className="central-meta">
      <div className="row merged20" id="page-contents">
        <div className="col-md-12">
          <LoaderWrapperView isLoading={isLoading}>
            <h4>
              Top suggestions of
              <span style={{ color: "#f05a24" }}> Investor Companies</span> to
              support your Idea
            </h4>
            {/* Top suggestions of Investment Partnership to support your idea */}
            <br />
            {organization &&
              organization?.map((organization, i) => {
                return (
                  <>
                    <div className="row">
                      <div className="col-md-2">
                        <img
                          src={organization.company_logo}
                          style={{
                            boxShadow: "1px 1px 5px #ccc",
                          }}
                          className="img-thumbnail img-fluid mr-3"
                        />
                      </div>
                      <div className="col-md-10">
                        <strong style={{ color: "#f05a24" }}>
                          {organization.company_name}
                        </strong>
                        <br />
                        {organization.location && (
                          <>
                            <strong>Location: </strong> {organization.location}
                            <br />
                          </>
                        )}

                        {organization.specialities && (
                          <>
                            <strong>Specialities: </strong>{" "}
                            {organization.specialities}
                            <br />
                          </>
                        )}

                        <br />

                        {organization.linked_in_url ? (
                          <a href={organization.linked_in_url} target="_blank">
                            <img
                              src={linkdinlogo}
                              className="img-fluid img-thumbnail px-1"
                              style={{ width: "100px", "margin-right":"5px" }}
                            />
                          </a>
                        ) : (
                          ""
                        )}

                        {organization.slug ? (
                          <NavLink
                            to={`/organizations-list/${organization.slug}`}
                            target="_blank"
                          >
                            <img
                              src={scionlogo}
                              className="img-fluid img-thumbnail px-1"
                              style={{ width: "100px" }}
                            />
                          </NavLink>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <hr />
                  </>
                );
              })}

            <h4 className="mt-5">
              Top suggestions of{" "}
              <span style={{ color: "#f05a24" }}>Angel Investors</span> to
              support your Idea
            </h4>
            <br />

            {user &&
              user?.map((user, i) => {
                return (
                  <>
                    <div className="row">
                      <div className="col-md-2">
                        <img
                          src={user.profile_image}
                          style={{
                            boxShadow: "1px 1px 5px #ccc",
                          }}
                          className="img-thumbnail img-fluid mr-3"
                        />
                      </div>
                      <div className="col-md-10">
                        <strong style={{ color: "#f05a24" }}>
                          {user.first_name} {user.last_name}
                        </strong>
                        <br />
                        {user.city && (
                          <>
                            <strong>Location: </strong> {user.city}
                            <br />
                          </>
                        )}

                        {user.specialities && (
                          <>
                            <strong>Specialities: </strong> {user.specialities}
                            <br />
                          </>
                        )}

                        <br />

                        {user.linked_in_url ? (
                          <a href={user.linked_in_url} target="_blank">
                            <img
                              src={linkdinlogo}
                              className="img-fluid img-thumbnail px-1"
                              style={{ width: "100px", "margin-right":"5px" }}
                            />
                          </a>
                        ) : (
                          ""
                        )}

                        {user.public_url ? (
                          <NavLink to={`/${user.public_url}`} target="_blank">
                            <img
                              src={scionlogo}
                              className="img-fluid img-thumbnail px-1"
                              style={{ width: "100px" }}
                            />
                          </NavLink>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <hr />
                  </>
                );
              })}
          </LoaderWrapperView>
        </div>
      </div>
    </div>
  );
};

export default IdeaAngelInvestor;
