import axios from "axios";
import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom"
import { API_COOKIES, API_URL } from "../../services/ApiEndpoints";
import authHeader from "../../services/auth-header";

const CookieConsent = () =>{

    const [showCookiePopup, setShowCookiePopup] = useState(false)

    const { isLoggedIn } = useSelector(state => state.auth);

    const onAccept = (e) =>{
        localStorage.setItem('cookieStatus', 1)
        localStorage.setItem('cookieStatusUpdate', 0)
        setShowCookiePopup(false)
        onCookieAcceptReject()
    }
    
    const onReject = (e) =>{
        localStorage.setItem('cookieStatus', 0)
        localStorage.setItem('cookieStatusUpdate', 0)
        setShowCookiePopup(false)
        onCookieAcceptReject()
    }

    const onCookieAcceptReject = () =>{

        if(!isLoggedIn) return

        if( !(localStorage.getItem('cookieStatusUpdate')===null || localStorage.getItem('cookieStatusUpdate')==0)) return

        // allow to send to server
            
        var formdata = new FormData()
        formdata.append("status", localStorage.getItem('cookieStatus') )

        axios.post(API_URL + API_COOKIES, formdata,{
            headers:authHeader()
        })
        .then((responsex) => {

            if(responsex.data.status=="RC200"){
                localStorage.setItem('cookieStatusUpdate', 1)
            }

            return responsex.data;
        });

    }

    useEffect(()=>{
        setShowCookiePopup( localStorage.getItem('cookieStatus')===null )

        if( localStorage.getItem('cookieStatus')!==null ){
            onCookieAcceptReject()
        }
        
    }, [])

    return(
        showCookiePopup && (
            <div className="scion-cookies">
                <strong>SCIONS uses cookies</strong>
                <p>SCIONS uses cookies and similar techniques to analyze the use of the website, to make it secure, to tailor the content of the site and advertisements to your preferences. These cookies are also placed by third parties. By clicking 'Accept', you agree to this. If you do not give your consent, we will set functional and essential cookies only. You can read a description of the cookies for which we request your consent in our <NavLink target="_blank" to="/cookie-policy">cookie policy.</NavLink></p>

                <div className="row">
                    <div className="col-md-12 text-right">
                        <button type="button" onClick={onAccept} className="mtr-btn"><span>Accept</span></button> &nbsp;
                        <button type="button" onClick={onReject} className="mtr-btn"><span>Reject</span></button>
                    </div>
                </div>
            </div>
        )
    )

}

export default CookieConsent