import axios from "axios";
import { useEffect, useState } from "react";
import {
  API_FRIEND_LIST,
  API_UNFRIEND,
  API_URL,
} from "../../../services/ApiEndpoints";
import authHeader from "../../../services/auth-header";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import ExtButton from "../../libs/ExtButton";
import { useDispatch } from "react-redux";
import { OPEN_CHAT } from "../../../actions/types";

const MyFriends = () => {
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, [currentPage]);

  const getData = () => {
    axios
      .get(API_URL + API_FRIEND_LIST, {
        params: {
          page: currentPage,
        },
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          const json = response.data;

          if (json.status == "RC200") {
            var resp = json.data;

            var list = resp.data;

            setCanLoadMore(currentPage != resp.last_page);

            if (currentPage == 1) {
              setData(list);
            } else {
              const old = [...list];
              setData(data.concat(old));
            }
          }
        }
      })
      .catch((error) => {});
  };

  const loadFunc = () => {
    setCurrentPage(currentPage + 1);
  };

  const onUpdate = (index) => {
    const newData = [...data];

    newData.splice(index, 1);

    setData(newData);
  };

  return (
    <div className="" id="frends">
      <InfiniteScroll
        dataLength={data.length}
        next={loadFunc}
        hasMore={canLoadMore}
        endMessage={
          <div className="text-center">
            <br />
            That's all for now
          </div>
        }
        loader={<div className="loader text-center">Loading ...</div>}
      >
        <ul className="nearby-contct">
          {data.map((item, index) => {
            return (
              <SingleFriend
                onUpdate={onUpdate}
                item={item}
                index={index}
                key={index}
              />
            );
          })}
        </ul>
      </InfiniteScroll>
    </div>
  );
};

const SingleFriend = (props) => {
  const dispatch = useDispatch();

  const { item, index } = props;

  const [isLoading, setIsLoading] = useState(false);

  const unfriendUser = (e, id, index) => {
    e.preventDefault();

    setIsLoading(true);

    axios
      .post(
        API_URL + API_UNFRIEND,
        {
          friend_id: id,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        setIsLoading(false);

        if (response.status == 200) {
          const json = response.data;

          if (json.status == "RC200") {
            props.onUpdate(index);
          }
        }
      })
      .catch((error) => {});
  };

  return (
    <li>
      <div className="nearly-pepls">
        <figure>
          <Link to={`/` + item.public_url} title="">
            <img src={item.profile_image} alt="" />
          </Link>
        </figure>
        <div className="pepl-info">
          <h4>
            <Link to={`/` + item.public_url} title="">
              {item.full_name}
            </Link>
          </h4>
          <span>{item.eng_field}</span>

          <ExtButton
            isLoading={isLoading}
            onClick={(e) => unfriendUser(e, item.id, index)}
            className="add-butn more-action"
            type="link"
          >
            Disconnect Scion
          </ExtButton>

          <a
            href=""
            onClick={(e) => {
              e.preventDefault();

              // console.log(item)

              dispatch({
                type: OPEN_CHAT,
                userData: {
                  id: item.user_id,
                  full_name: item.full_name,
                  image: item.profile_image,
                },
              });
            }}
            title=""
            className="add-butn"
            data-ripple=""
          >
            Start Chat
          </a>
        </div>
      </div>
    </li>
  );
};

export default MyFriends;
