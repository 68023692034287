import React, { Component, useEffect, useState } from "react";

import InfiniteScroll from 'react-infinite-scroll-component'
import { API_SEARCH, API_URL } from "../../../services/ApiEndpoints";
import axios from "axios";
import authHeader from "../../../services/auth-header";
import SinglePost from "../feed/SinglePost";
import {useLocation, useParams} from 'react-router-dom'
import { ApiServices } from "../../../services/ApiServices";
import SearchSingleUser from "./SearchSingleUser";
import LoaderWrapperView from "../../libs/LoaderWrapperView";

const SearchUsers = () =>{

    const {keyword} = useParams()
    const [isLoading, setIsLoading] = useState(true)
    const [resultCount, setResultCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [canLoadMore, setCanLoadMore] = useState(true)
    const [results, setResults] = useState([])

    useEffect(()=>{
        getData()

    }, [keyword])

    const getData = () => {

        if(currentPage==1)
            setIsLoading(true)

        axios
        .get(API_URL + API_SEARCH +"?keyword="+ keyword,{
            headers: authHeader()
        })
        .then((response)=>{
            
            if(response.status==200){

                const json = response.data;

                if(json.status=="RC200"){

                    var data = json.data;

                    setResultCount(data.count)

                    setCanLoadMore( currentPage!=data.users.last_page )
                    setResults(data.users.data)

                }

            }

            if(currentPage==1)
                setIsLoading(false)

        })
        .catch((error)=>{

            if(currentPage==1)
                setIsLoading(false)

        });
    }

    const loadFunc = () => {
        
        setCurrentPage(currentPage+1)

        getData();

    }

    const reloadFeed = () =>{

        this.setState({
            posts: [],
            currentPage:1
        });

        this.getPost();

        window.clearOverlay();

    }
    

    return(
        <LoaderWrapperView isLoading={isLoading}>
            <div className="row">
                <div className="col-lg-12">
                    <h6>{resultCount} Result(s) for "{keyword}"</h6>

                    <div className="row merged20" id="page-contents">
                        <div className="col-lg-12">
                            <div className="frnds">

                                <InfiniteScroll
                                    dataLength={results.length}
                                    next={loadFunc}
                                    hasMore={canLoadMore}
                                    endMessage={<div className="text-center"><br />That's all for now</div>}
                                    loader={<div className="loader text-center">Loading ...</div>}>
                                    
                                    <ul style={{marginTop:"10px"}} className="nearby-contct">
                                        {results.map((result, i) => <SearchSingleUser key={"si-"+result.id} user={result} /> )}
                                    </ul>

                                </InfiniteScroll>
                            
                            </div>
                            
                        </div>
                        
                    </div>

                </div>
            </div>
        </LoaderWrapperView>

    );

};

export default SearchUsers;