import React, { useEffect, useRef, useState } from "react";
import {Link} from 'react-router-dom'
import { useSelector } from "react-redux";
import CommentList from './CommentList'
import { API_FEED_DELETE, API_FEED_REPORT_ABUSE, API_LIKE_DISLIKES, API_URL } from "../../../services/ApiEndpoints";
import axios from "axios";
import authHeader from "../../../services/auth-header";
import ReactPlayer from 'react-player'
import ExToolTip from "../../libs/ExToolTip";
import { toast } from "react-toastify";
import ExPopover from "../../libs/ExPopover";
import ViewImpressedList from "./ViewImpressedList";
import AutoLinks from "../../libs/AutoLinks";

// this component is used in feed/FeedList.js and timeline/MyFeedList.js and PublicFeedList,js
const SinglePost = (props) =>{

    const {post} = props
    const {likes} = post.total_likes
    const {dislikes} = post.total_dislikes

    const [totalLikes, setTotalLikes] = useState(0)
    const [totalDislikes, setTotalDislikes] = useState(0)

    const videoRef = useRef()
    
    useEffect(()=>{
        setTotalLikes(post.total_likes)
        setTotalDislikes(post.total_dislikes)
    }, [post])

    const handleLikeDislike = (e) => {
        e.preventDefault();
        
        axios
        .post(API_URL + API_LIKE_DISLIKES,{
            feed_id: post.id
        },{
            headers: authHeader()
        })
        .then((response)=>{
            
            if(response.status==200){

                const json = response.data;

                if(json.status=="RC200"){

                    setTotalLikes(json.data.likes)
                    setTotalDislikes(json.data.dislikes)

                }

            }

        })
        .catch((error)=>{

        });


    }

    const get_public_url = () =>{
        
        if(post.is_org==0) 
            return `/${post.public_url}`
        else if(post.is_org==1)
            return `/organizations-list/${post.public_url}`

        return `/`
    }

    const reportAbuse = () =>{

        axios
        .post(API_URL + API_FEED_REPORT_ABUSE,{
            feed_id: post.id
        },{
            headers: authHeader()
        })
        .then((response)=>{
            
            if(response.status==200){

                let json = response.data

                if(json.status=="RC200"){
                    toast.success(json.message)
                }else{
                    toast.error(json.message)
                }

            }

        })
        .catch((error)=>{

        });
        
    }

    
    const deletePost = () =>{

        axios
        .post(API_URL + API_FEED_DELETE,{
            feed_id: post.id
        },{
            headers: authHeader()
        })
        .then((response)=>{
            
            if(response.status==200){

                let json = response.data

                if(json.status=="RC200"){
                    toast.success(json.message)

                    props.onRemoved(post.id)

                }else{
                    toast.error(json.message)
                }

            }

        })
        .catch((error)=>{

        });
        
    }

    return(
        
        <div className="central-meta item">
            <div className="user-post">
                <div className="friend-info">

                    <figure>
                        <Link to={get_public_url()} title=""><img className="profile-img-feed" src={post.profile_image} alt="" /></Link>
                    </figure>

                    <div className="friend-name">
                        <ins>
                            <Link to={get_public_url()} title="">{post.full_name}</Link>
                        </ins>
                        <span>Shared: {post.date}</span>
                    </div>

                    <div className="post-meta">
                        
                        { post.attechment_type==0 ? (
                            <img src={post.attachment} alt="" />
                        ) : (
                            post.attechment_type==1 && (
                                
                                <ReactPlayer ref={videoRef} width="100%" height="auto" pip={true} controls={true} url={post.attachment} />
                                
                            )
                        ) }

                        <div className="we-video-info">
                            <ul>
                                {/* <li>
                                    <span className="views" data-toggle="tooltip" title="views">
                                        <i className="fa fa-eye"></i>
                                        <ins>{post.total_views}</ins>
                                    </span>
                                </li> */}
                                <li>
                                    <ExToolTip placement="bottom" name="Opinions">
                                        <span className="comment">
                                            <i className="fa fa-comments-o"></i>
                                            <ins>{post.total_comments}</ins>
                                        </span>
                                    </ExToolTip>
                                </li>
                                <li>
                                    <ExToolTip placement="bottom" name="Impressive">
                                        <span onClick={handleLikeDislike} className="like">
                                            <i className="fa fa-lightbulb-o"></i>
                                            <ins>{totalLikes}</ins>
                                        </span>
                                    </ExToolTip>
                                    
                                    {totalLikes!=0 && (
                                        <ViewImpressedList id={post.id}>
                                            Scion(s) impressed
                                        </ViewImpressedList>
                                    )}

                                </li>
                                
                                {post.can_delete ? (
                                    <li className="pull-right mr-0">
                                        <ExToolTip placement="bottom" name="Delete Post">
                                            <span onClick={deletePost}>
                                                <i className="ti-trash"></i>
                                            </span>
                                        </ExToolTip>
                                        
                                    </li>
                                ) : ""}
                                
                                <li className="pull-right mr-3">
                                    <ExToolTip placement="bottom" name="Report Abuse">
                                        <span onClick={reportAbuse}>
                                            <i className="ti-flag"></i>
                                        </span>
                                    </ExToolTip>
                                    
                                </li>
                                
                            </ul>
                        </div>
                        <div className="description">
                            <AutoLinks>
                                {post.content.split("\n").map((str, index) => <p key={"px-"+ index}>{str}</p>)}
                            </AutoLinks>
                        </div>
                    </div>

                </div>

                <div className="coment-area">
                    <CommentList key={"comment-list-" + post.id} postId={post.id} {...post} />
                </div>

            </div>
        </div>
        
    );
};

export default SinglePost;