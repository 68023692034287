import React, { Component } from "react";
import {Link} from 'react-router-dom'
import AutoLinks from "../../libs/AutoLinks";
import AddComment from "./AddComment";

class SingleSubComment extends Component{
    
    state = {
        comment: [],
        replyId: -1
    }
    
    onCommentRepliedClicked = () => {}

    constructor(props){
        super();
    }

    handleReplyClick = (e) =>{
        e.preventDefault();
       this.setState({
            replyId: this.props.commentId
       })
    }

    onCommentAdded = (comment) =>{
        this.props.OnSubCommentAdd && this.props.OnSubCommentAdd(comment)
        this.setState({
            replyId: -1
       })
    }

    componentDidMount = () =>{
        this.setState({
            comment: this.props.comment
       })
    }

    render(){
        return(
        
            this.state.comment && (
                <li>
                    <div className="comet-avatar">
                        <img className="profile-img-small" src={this.state.comment.profile_image} alt="" />
                    </div>
                    <div className="we-comment">
                        <div className="coment-head">
                            <h5><Link to={`/` + this.state.comment.public_url} title="">{this.state.comment.full_name}</Link></h5>
                            <span>{this.state.comment.created_date || "Now"}</span>
                            <a className="we-reply" href="#" onClick={this.handleReplyClick} title="Reply"><i className="fa fa-reply"></i></a>
                        </div>
                        <AutoLinks>
                            {this.state.comment!="" && this.state.comment.comment.split("\n").map((str, index) => <p key={index}>{str}</p>)}
                        </AutoLinks>
                    </div>

                    {this.state.replyId!=-1 && (
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-11">
                                <br />
                                <AddComment placeholder="Reply to this opinion" parentCallback={this.onCommentAdded} replyId={this.state.replyId} key={`add-comment-r${this.props.commentId}-${this.state.comment.id}`} postId={this.props.postId} />
                            </div>
                        </div>  
                    )}

                </li>
            )
            
            
        )
    }

}


export default SingleSubComment;