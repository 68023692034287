import React, { Component } from "react";
import jquery from "jquery";
import '../../assets/js/chosen.jquery'

class Select2 extends Component{

    state = {
        data: []
    }

    placeholder = "";
    keys = "";
    required = false;
    directValues = false
    name = ""
    className = ""
    
    constructor(props){
        super();
        this.state.data = props.data==undefined ? [] : props.data;
        this.placeholder = props.placeholder;
        this.keys = props.keys;
        this.required = props.required;
        this.directValues = props.directValues==undefined ? false : props.directValues;
        this.name = props.name==undefined ? props.keys : props.name;
        this.className = props.className===undefined ? "" : props.className;

    }

    componentDidUpdate(){
        jquery(this.refs['select2']).chosen().trigger("chosen:updated");
    }

    updateSelect(newData){

        this.setState({
            data:newData
        })

    }

    componentDidMount(){
        jquery(this.refs['select2']).chosen().on("change", this.props.onSelect);
    }

    render(){

        return(

            (this.directValues ? (
                <select value={this.props.value} onChange={this.props.onSelect} ref='select2' key={this.name} name={this.name} required={this.required} className={`form-control ${this.className}`}>
                    <option value="">{this.placeholder}</option>
                    {this.state.data && this.state.data.map((item, i) =>(
                        <option key={item} value={item}>{item}</option>
                    ))}
                </select>
            ) : (
                <select value={this.props.value} onChange={this.props.onSelect} ref='select2' key={this.name} name={this.name} required={this.required} className={`form-control ${this.className}`}>
                    <option value="">{this.placeholder}</option>
                    {this.state.data && this.state.data.map((item, i) =>(
                        <option key={item.id} value={item.id}>{item[this.keys]}</option>
                    ))}
                </select>
            ))
            
        );
    }
};

export default Select2;