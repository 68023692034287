import React, { useEffect, useRef, useState } from "react";
import { API_GET_DISCIPLINE_BY_ID, API_UPDATE_EDUCATION, API_URL } from "../../../services/ApiEndpoints";
import axios from "axios";
import authHeader from "../../../services/auth-header";
import Select2 from "../../libs/Select2";
import ExtButton from "../../libs/ExtButton";
import { DatePicker, df, mode } from "../../../helpers/DatePicker";
import { useOutletContext } from "react-router-dom";

const Education = (props) =>{

    const [allowReload, setAllowReload] = useOutletContext();

    const {countries, graduation, masters, profile,} = props

    const [graduationsList, setGraduationsList] = useState(graduation)
    const [mastersList, setMastersList] = useState(masters)
    const [countryList, setCountryList] = useState(countries)

    useEffect(() => {
        setCountryList(countries)
        setGraduationsList(graduation)
        setMastersList(masters)

    }, [countries, graduation, masters]);


    const [isLoading, setIsLoading] = useState(false)
    const [hasError, setHasError] = useState(false) 
    const [msg, setMsg] = useState("")

    const addMoreGraduations = (e) =>{
        e.preventDefault()

        setGraduationsList( graduationsList.concat({
            id:-1,
            college_name:"",
            from:"",
            to:"",
            city:"",
            country:"",
            degree:"",
        }) )
    }

    const handleGraduationsChange = (index, e) => {

        var gList = [...graduationsList]

        gList[index][e.target.name] = e.target.value

        setGraduationsList(gList)

    }
    
    const handleGraduationsSelectChange = (index, e) => {

        var gList = [...graduationsList]

        gList[index]["country"] = e.target.value

        setGraduationsList(gList)

    }

    const handleGraduationsRemove = (index, e) => {
        e.preventDefault()

        var gList = [...graduationsList]

        gList.splice(index, 1)

        setGraduationsList(gList)

    }

    const addMoreMasters = (e) =>{
        e.preventDefault()

        setMastersList( mastersList.concat({
            id:-1,
            college_name:"",
            from:"",
            to:"",
            city:"",
            country:"",
            degree:"",
        }) )
    }

    const handleMastersChange = (index, e) => {

        var mList = [...mastersList]
        mList[index][e.target.name] = e.target.value

        setMastersList(mList)

    }

    const handleMastersSelectChange = (index, e) => {

        var mList = [...mastersList]

        mList[index]["country"] = e.target.value

        setMastersList(mList)

    }

    const handleMastersRemove = (index, e) => {
        e.preventDefault()

        var mList = [...mastersList]
        mList.splice(index, 1)

        setMastersList(mList)

    }

    //handleDateChange(index, date, "from", true)
    const handleDateChange = (index, date, field, isGraduation) =>{

        if(isGraduation){
            var gList = [...graduationsList]

            gList[index][field] = date

            setGraduationsList(gList)
        }else{
            var mList = [...mastersList]
            mList[index][field] = date

            setMastersList(mList)
        }

    }

    const handleSubmit = (e) =>{

        e.preventDefault()

        setHasError(false)
        setMsg("")
        setIsLoading(true)

        var formdata = new FormData()

        var index = 0

        graduationsList.map((item, i) => {
            formdata.append("college_name["+ index +"]", item.college_name)
            formdata.append("from["+ index +"]", item.from)
            formdata.append("to["+ index +"]", item.to)
            formdata.append("city["+ index +"]", item.city)
            formdata.append("country["+ index +"]", item.country)
            formdata.append("degree["+ index +"]", item.degree)
            formdata.append("type["+ index +"]", 0)
            formdata.append("id["+ index +"]", item.id)

            index++
        })

        mastersList.map((item, i) => {
            formdata.append("college_name["+ index +"]", item.college_name)
            formdata.append("from["+ index +"]", item.from)
            formdata.append("to["+ index +"]", item.to)
            formdata.append("city["+ index +"]", item.city)
            formdata.append("country["+ index +"]", item.country)
            formdata.append("degree["+ index +"]", item.degree)
            formdata.append("type["+ index +"]", 1)
            formdata.append("id["+ index +"]", item.id)

            index++
        })

        axios.post(API_URL + API_UPDATE_EDUCATION, formdata,{
            headers:authHeader()
        })
        .then((responsex) => {

            setIsLoading(false)

            if(responsex.data.status=="RC200"){
                setHasError(false)

                /* update profile completeness */
                setAllowReload(allowReload+1)
            }else{
                setHasError(true)
            }

            setMsg(responsex.data.message)

            return responsex.data;
        });

    }

    return(
        <div className="central-meta" id="education">
            <div className="editing-info">
                <h5 className="f-title"> Education</h5>

                <form onSubmit={handleSubmit} method="post">

                    <h6>Higher Qualifications</h6>

                    { mastersList.length>0 && (
                        mastersList.map((item, index)=>{
                            return(
                                <div key={index} className="row">

                                    <div className="col-md-10">
                                        <div className="form-group">
                                            <input name="degree" value={item.degree || ""} type="text" onChange={ (e) => handleMastersChange(index, e) } required="required" />
                                            <label className="control-label">Master / Doctorate *</label>
                                            <i className="mtrl-select"></i>
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className="text-center form-group ">
                                            <a onClick={ (e) =>{ handleMastersRemove(index, e) } } className="btn btn-link text-danger"><i className="fa fa-close"></i></a>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input type="text" name="college_name" value={item.college_name || ""} onChange={ (e) => handleMastersChange(index, e) } required="required" />
                                            <label className="control-label" >College/University Name *</label>
                                            <i className="mtrl-select"></i>
                                        </div>
                                    </div>
                                
                                    <div className="col-md-6">
                                        <DatePicker readOnly={true} format={df.MY} mode={mode.month} value={item.from || ""} onChange={ (e, date)=>{
                                            handleDateChange(index, date, "from", false)
                                        }}  required={true} >
                                            From *
                                        </DatePicker>
                                    </div>
                                    <div className="col-md-6">
                                        <DatePicker readOnly={true} format={df.MY} mode={mode.month} value={item.to || ""} onChange={ (e, date)=>{
                                            handleDateChange(index, date, "to", false)
                                        }}  required={true} >
                                            To *
                                        </DatePicker>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input name="city" value={item.city || ""} type="text" onChange={ (e) => handleMastersChange(index, e) } required="required" />
                                            <label className="control-label" >City *</label>
                                            <i className="mtrl-select"></i>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <Select2 value={item.country} onSelect={ (e) =>{
                                                handleMastersSelectChange(index, e)
                                            } } data={countryList} keys={"country_name"} placeholder={"Select Country *"} />
                                        </div>
                                    </div>
                                    
                                </div>
                            )
                        })
                    ) }

                    <div className="submit-btns col-md-12 text-right">
                        <a onClick={addMoreMasters}><i className="fa fa-plus-circle"></i> Add Higher Qualification details</a>
                    </div>

                    <h6>Qualifications</h6>

                    { graduationsList.length>0 && (
                        graduationsList.map((item, index)=>{
                            return(
                                <div key={index} className="row">

                                    <div className="col-md-10">
                                        <div className="form-group">
                                            <input name="degree" value={item.degree || ""} type="text" onChange={ (e) => handleGraduationsChange(index, e) } required="required" />
                                            <label className="control-label">Certificate / Diploma / Degree *</label>
                                            <i className="mtrl-select"></i>
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className="text-center form-group ">
                                            <a onClick={ (e) =>{ handleGraduationsRemove(index, e) } } className="btn btn-link text-danger"><i className="fa fa-close"></i></a>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input type="text" name="college_name" value={item.college_name || ""} onChange={ (e) => handleGraduationsChange(index, e) } id="input" required="required" />
                                            <label className="control-label">College/University Name *</label>
                                            <i className="mtrl-select"></i>
                                        </div>
                                    </div>
                                
                                    <div className="col-md-6">

                                        <DatePicker readOnly={true} format={df.MY} mode={mode.month} value={item.from || ""} onChange={ (e, date)=>{
                                            handleDateChange(index, date, "from", true)
                                        }}  required={true} >
                                            From *
                                        </DatePicker>

                                    </div>
                                    <div className="col-md-6">
                                        
                                        <DatePicker readOnly={true} format={df.MY} mode={mode.month} value={item.to || ""} onChange={ (e, date)=>{
                                            handleDateChange(index, date, "to", true)
                                        }}  required={true} >
                                            To *
                                        </DatePicker>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input name="city" value={item.city || ""} type="text" onChange={ (e) => handleGraduationsChange(index, e) } required="required" />
                                            <label className="control-label">City *</label>
                                            <i className="mtrl-select"></i>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <Select2 onSelect={ (e) =>{
                                                handleGraduationsSelectChange(index, e)
                                            } } value={item.country} data={countryList} keys={"country_name"} placeholder={"Select Country *"} />
                                        </div>
                                    </div>
                                    
                                </div>
                            )
                        })
                    ) }
                    
                    <div className="submit-btns col-md-12 text-right">
                        <a onClick={addMoreGraduations}><i className="fa fa-plus-circle"></i> Add Qualification details</a>
                    </div>

                    {msg && (
                        <div className={ hasError ? "alert alert-danger mt-10" : "alert alert-success mt-10" } role="alert">
                            {msg}
                        </div>
                    )}

                    <div className="submit-btns">
                        
                        <ExtButton isLoading={isLoading} className="mtr-btn" type="submit">
                            <span>Update</span>
                        </ExtButton>

                    </div>
                </form>
            </div>
        </div>

    );
};


export default Education;