import React, { useEffect, useRef, useState } from "react";
import {Link, useNavigate} from 'react-router-dom'
import { useSelector } from "react-redux";
import Form from "react-validation/build/form";
import axios from "axios";
import { API_GET_ENGINEERING_FIELDS, API_GET_FEED_VISIBILITY, API_TIMELINE_ADD_POST, API_URL } from "../../../services/ApiEndpoints";
import authHeader from "../../../services/auth-header";
import { toast } from 'react-toastify';
import ExtButton from "../../libs/ExtButton";
import Select2 from "../../libs/Select2";
import ExToolTip from "../../libs/ExToolTip";
import $ from 'jquery'

const CreatePost = (props) =>{

    const { user: currentUser } = useSelector((state) => state.auth);

    const form = useRef();
    const imageSelection = useRef();
    const videoSelection = useRef();

    const refVisibilityOptions = useRef()

    useEffect(()=>{
        getVisibilityOptions()
    }, [])

    const getVisibilityOptions = () =>{

        axios
        .get(API_URL + API_GET_FEED_VISIBILITY,{
            headers: authHeader()
        })
        .then((response)=>{

            
            if(response.status==200){

                const json = response.data;

                if(json.status=="RC200"){

                    var data = json.data;

                    var {visibility, post_for, has_org} = data

                    setHasOrg(has_org)
                    setPostForList(post_for)

                    refVisibilityOptions.current.updateSelect(visibility);
                    

                }

            }

        })
        .catch((error)=>{
        });

    }
    
    const [postForList, setPostForList] = useState([])
    const [contentText, setContentText] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [video, setVideo] = useState(null)
    const [image, setImage] = useState(null)
    const [audience, setAudience] = useState(-1)
    const [postFor, setPostFor] = useState(0)
    const [hasOrg, setHasOrg] = useState(0)

    const handleSubmit = (e) => {
        e.preventDefault();

        if( !(contentText || image!=null || video!=null) ){
            toast.error("Please enter text, image or video to share")
            return
        }

        setIsLoading(true)

        var formdata = new FormData()
        formdata.append("content", contentText);
        formdata.append("field_id", audience);
        formdata.append("post_for", postFor);

        if(image!=null){
            formdata.append("attechment_type", 0);
            formdata.append("attachment", image);
        }else if(video!=null){
            formdata.append("attechment_type", 1);
            formdata.append("attachment", video);
        }

        axios
        .post(API_URL + API_TIMELINE_ADD_POST, formdata,{
            headers: authHeader()
        })
        .then((response)=>{

            setIsLoading(false)
            
            if(response.status==200){

                const json = response.data;

                if(json.status=="RC200"){

                    setContentText("")
                    
                    setImage(null)

                    if(imageSelection!=null)
                        imageSelection.current.value=""

                    setVideo(null)
                    if(videoSelection!=null)
                        videoSelection.current.value=""

                    props.reloadFeed()

                    toast.success(json.message)

                }else{
                    toast.error(json.message)
                }

            }

        })
        .catch((error)=>{

        });

    }

    return(
        
        <div className="central-meta new-pst">
            <div className="new-postbox">
                <div className="create-feed-profile">
                    <div className="pull-left">
                        <figure><img src={currentUser.profile_image} alt="" /></figure>
                    </div>
                    <div style={{width:"80%"}} className="pull-left pl-3">
                        <strong>{currentUser.first_name} {currentUser.last_name}</strong>
                        <br />
                        <div className="row">
                            <div className="col-md-6">
                                <Select2 ref={refVisibilityOptions} directValues={false} value={audience}  className={"no-search"} onSelect={
                                        (e) => {
                                            setAudience(parseInt(e.target.value))

                                        }
                                    } required={true} keys={"name"} placeholder={"Select Audience"} data={[]} />
                            </div>

                            {hasOrg==1 ? (
                                <div className="col-md-6">
                                    <Select2 directValues={false} value={postFor}  className={"no-search"} onSelect={
                                            (e) => {
                                                setPostFor(parseInt(e.target.value))
                                            }
                                        } required={true} keys={"company_name"}  data={postForList} />
                                </div>
                            ) : ""}

                        </div>
                    </div>
                </div>
                <div className="">
                    <Form onSubmit={handleSubmit} ref={form} >
                        <textarea onFocus={(e)=>{
                            $(".postoverlay").fadeIn(500);
                        }} rows="5" value={contentText} onChange={(e)=>setContentText(e.target.value)} placeholder="Would you like to share your thoughts here?"></textarea>
                        <div className="attachments">
                            <ul>
                                <li>
                                    <ExToolTip placement="bottom" name="Choose your image">
                                        <i className="fa fa-image"></i>
                                        <label className="fileContainer">
                                            <input ref={imageSelection} onChange={ (e) =>{
                                                var files = e.target.files;

                                                if(files.length==0) return;
                                                
                                                setImage(files[0])

                                            } } onClick={ (e) => {
                                                e.target.value=""
                                                setImage(null)
                                            } } type="file" />
                                        </label>
                                    </ExToolTip>
                                </li>
                                <li>
                                    <ExToolTip placement="bottom" name="Choose your video">
                                        <i className="fa fa-file-video-o"></i>
                                        <label className="fileContainer">
                                            <input ref={videoSelection} onChange={ (e) =>{
                                                var files = e.target.files;

                                                if(files.length==0) return;
                                                
                                                setVideo(files[0])

                                            } } onClick={ (e) => {
                                                e.target.value=""
                                                setVideo(null)
                                            } } type="file" />
                                        </label>
                                    </ExToolTip>
                                </li>
                                <li>
                                    <ExtButton isLoading={isLoading} type="submit">Share</ExtButton>
                                </li>
                            </ul>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
        
    );
};

export default CreatePost;