import React from "react";
import { NavLink } from "react-router-dom";
import FooterInfo from "./FooterInfo";
import FooterLinks from "./FooterLinks";

const Footer = () =>{

    return(
        <footer>
            <div className="container">
                <div className="row mobile-footer">
                    <div className="col-md-4"><FooterInfo /></div>
                    <div className="col-md-8 text-right">
                        <FooterLinks />
                    </div>
                </div>
            </div>
        </footer>
    );

};

export default Footer;