import axios from "axios"
import { useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"
import { API_IDEA_OPT_INOUT, API_URL } from "../../../services/ApiEndpoints"
import authHeader from "../../../services/auth-header"
import ExToolTip from "../../libs/ExToolTip"

const IdeaOptInOut = (props) =>{
    
    const {ideaId, current, slug} = props

    const [ideaUrl, setIdeaUrl] = useState("")
    const [status, setStatus] = useState(props.current)

    useEffect(()=>{
        setIdeaUrl(`${window.location.origin}/ideas/view/${slug}`)
    }, [])

    let toastRef = useRef(null);

    const update = (e) => {

        toastRef = toast.loading("Loading...", {closeOnClick: true})

        let cstatus = e.target.checked ? 1 : 0

        var formdata = new FormData()
        formdata.append("idea_id", ideaId)
        formdata.append("status", cstatus)

        axios.post(API_URL + API_IDEA_OPT_INOUT, formdata,{
            headers:authHeader()
        })
        .then((responsex) => {

            const msg = responsex.data.message

            if(responsex.data.status=="RC200"){
                setStatus(cstatus)
                toast.update(toastRef, { render: msg, type: "success", isLoading: false, autoClose: 5000 });
            }else{
                toast.update(toastRef, { render: msg, type: "error", isLoading: false, autoClose: 5000 });
            }

            return responsex.data;
        });
    }

    return(
        <div className="central-meta p-3 mb-5">
            
            <div className="row">
                <div className="col-md-12">
                    <div className="onoff-options">
                        <div className={`setting-row ${status==0 ? "no-border" : ""}`}>
                            <span><h5 className="text-left">Opt-In/Out for making your Idea details</h5></span>
                            <p>Enabling this option will make your idea details publicly available to users outside SCIONS too. <br />Since there is no NDA to be signed, a few confidential documents won't be visible to them.</p>
                            <input type="checkbox" defaultChecked={status==1} onChange={(e)=> {
                                update(e)
                            } } id="chk_enable_pv" />
                            <label htmlFor="chk_enable_pv" data-on-label="ON" data-off-label="OFF"></label>
                        </div>
                    </div>
                </div>
                
                {status==1 ? (
                    <div className="col-md-12"> <br />
                        <span>Idea Public URL : <br />
                        <a target="_blank" className="text-theme" style={{border:"0px"}} href={`${ideaUrl}`}>{`${ideaUrl}`}</a> &nbsp; 
                            <ExToolTip name="Copy Idea URL">
                                <i onClick={() => {
                                    navigator.clipboard.writeText(ideaUrl)
                                    toast.success("Idea url copied.")
                                }} style={{border:"0px"}} className="text-theme fa fa-copy"></i>
                            </ExToolTip>
                        </span>
                    </div>
                ) : ""}
                
            </div>
            
        </div>
    )
}

export default IdeaOptInOut