import React, { useEffect, useState, useRef } from "react";

import { API_INVITE_USERS, API_URL, API_WIDGET_IDEAS } from "../../../services/ApiEndpoints";
import axios from "axios";
import authHeader from "../../../services/auth-header";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import ExTagsInput from "../../libs/ExTagsInput";

const InviteUsersWidget = (props) =>{

    const [isLoading, setIsLoading] = useState(false)

    const submit_data = (e) =>{
        e.preventDefault()
        const val = emails.current.get_val();

        if(isLoading) return;
        
        if(val.length>0){
            setIsLoading(true)

            var formdata = new FormData()
            formdata.append("email", val);
    
            axios.post(API_URL + API_INVITE_USERS, formdata,{
                headers:authHeader()
            })
            .then((responsex) => {
    
                setIsLoading(false)
    
                if(responsex.data.status=="RC200"){
                    toast.success(responsex.data.message)
                }else{
                    toast.error(responsex.data.message)
                }
    
                return responsex.data;
            });
        }
    }

    let emails = useRef()

    return(
        <div className="widget">
            <div className="banner medium-opacity bluesh">
                <div>
                    <h5>Invite Users</h5><br />

                    <button type="button" className="close close-btn d-none" onClick={(e)=>{
                            props.close && props.close()
                        }} ><i className="fa fa-times"></i></button>
                </div>
                
                <label>Enter multiple comma separated email address</label>
                <ExTagsInput ref={emails} />
                
                <div className="banermeta">
                    <a onClick={submit_data} href="">{isLoading ? "Loading..." : "start now!"}</a>
                </div>
            </div>
        </div>
    );

};

export default InviteUsersWidget;