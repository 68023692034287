import jquery from 'jquery'
import "bootstrap"
import React, { Component, useEffect } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { Tooltip } from 'react-bootstrap';
import LoaderWrapperView from './LoaderWrapperView';
import { Link } from 'react-router-dom';
import Popups from './Popups';

const UpdatingPopover = React.forwardRef(
    ({ popper, children, show: _, ...props }, ref) => {
      useEffect(() => {
        popper.scheduleUpdate();
      }, [children, popper]);
  
      return (
        <Popover ref={ref} body {...props}>
          <Popover.Header as="h3">Popover bottom</Popover.Header>
          <Popover.Body>{children}</Popover.Body>
        </Popover>
      );
    },
  );

class ExPopover extends Component{

    constructor(props){
        super(props);
       
    }

    componentDidMount(){
    }

    componentDidUpdate(){
    }

    render(){

        

        return(
           <OverlayTrigger onToggle={this.props.onToggle} trigger={['click']} className="ex-popover" placement="bottom" overlay={(
                <Popover className="dark-popover" id="popover-contained">
                    <Popover.Body>
                        {this.props.content}
                    </Popover.Body>
                </Popover>
           )}>
            <span className="ex-popover-span">{this.props.children}</span>
        </OverlayTrigger>
        )
    }

}

export default ExPopover