import React, { Suspense, lazy, useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";

import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";
import { history } from "./helpers/history";
import PrivateRoute from "./PrivateRoute";

import Login from "./components/Login";
import Register from "./components/Register";
import ForgotPassword from "./components/ForgotPassword";
import Logout from "./components/Logout";

import ResetPassword from "./components/ResetPassword";
import RegisterVerify from "./components/RegistrationVerify";

import MyTimeline from "./components/private/timeline/MyTimeline";
import MyScions from "./components/private/scions/MyScions";
import MyFriends from "./components/private/scions/MyFriends";
import FriendRequest from "./components/private/scions/FriendRequest";
import EditProfile from "./components/private/profile/EditProfile";
import SearchUsers from "./components/private/search/SearchUsers";
import CreateIdea from "./components/private/ideas/CreateIdea";
import EditIdea from "./components/private/ideas/EditIdea";
import IdeaList from "./components/private/ideas/IdeaList";
import IdeaView from "./components/private/ideas/IdeaVIew";
import IdeaResponses from "./components/private/ideas/IdeaOffers";
import OffersView from "./components/private/ideas/OffersView";
import IdeaOffersIdeaDetails from "./components/private/ideas/IdeaOffersIdeaDetails";
import IdeaOffersList from "./components/private/ideas/IdeaOffersList";
import Organizations from "./components/private/organizations/Organizations";
import AddOrganizations from "./components/private/organizations/AddOrganizations";
import EditOrganizations from "./components/private/organizations/EditOrganizations";
import GetVerificationLink from "./components/GetVerificationLink";
import MyJobs from "./components/private/jobs/MyJobs";
import CreateJob from "./components/private/jobs/CreateJob";
import EditJob from "./components/private/jobs/EditJob";
import JobList from "./components/private/jobs/JobList";
import JobDetails from "./components/private/jobs/JobDetails";
import IdeaCreatorChat from "./components/private/ideas/IdeaCreatorChat";
import IdeaBuyerChat from "./components/private/ideas/IdeaBuyerChat";
import AccountSettings from "./components/private/profile/AccountSettings";
import Index from "./components";
import OrganizationDetailsView from "./components/private/organizations/OrganizationDetailsView";
import ViewOrganization from "./components/private/organizations/ViewOrganization";
import OrgAdmins from "./components/private/organizations/OrgAdmins";
import OrgUsers from "./components/private/organizations/OrgUsers";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import TermsAndConditions from "./components/pages/TermsAndConditions";
import PublicRoutes from "./PublicRoutes";
import PaymentPlansV2 from "./components/private/upgrade/PaymentPlansV2";
import AccessDenied from "./components/pages/AccessDenied";
import PaymentSuccess from "./components/private/upgrade/PaymentSuccess";
import PaymentFail from "./components/private/upgrade/PaymentFail";
import SearchIdeas from "./components/private/search/SearchIdeas";
import SearchJobs from "./components/private/search/SearchJobs";
import SearchCommonLayout from "./components/private/search/SearchCommonLayout";
import Subscription from "./components/private/upgrade/Subscription";
import OrganizaationList from "./components/private/organizations/public/OrganizaationList";
import OrganizationDetails from "./components/private/organizations/public/OrganizationDetails";
import CookiePolicy from "./components/pages/CookiePolicy";
import FeedbackPage from "./components/pages/FeedbackPage";
import FeedbackForm from "./components/FeedbackForm";
import CountryBlocked from "./components/pages/CountryBlocked";
import AboutUs from "./components/pages/AboutUs";
import ContactUs from "./components/pages/ContactUs";
import HowItWorks from "./components/private/ideas/HowItWorks";
import InviteUsers from "./components/private/feed/InviteUsers";
import AccountDelete from "./components/pages/AccountDelete";
import withClearCache from "./ClearCache";
import RecoverAccount from "./components/recover_account/RecoverAccount";
import RecoverAccountVerify from "./components/recover_account/RecoverAccountVerify";
import WhatsNew from "./components/pages/WhatsNew";
import MyProblems from "./components/private/problems/my/MyProblems";
import CreateProblem from "./components/private/problems/CreateProblem";
import MyProblemView from "./components/private/problems/my/MyProblemView";
import EditProblem from "./components/private/problems/my/EditProblem";
import MyProblemViewDetails from "./components/private/problems/my/MyProblemViewDetails";
import ProblemList from "./components/private/problems/public/ProblemList";
import ProblemDetails from "./components/private/problems/public/ProblemDetails";
import ProblemOffers from "./components/private/problems/my/ProblemOffers";
import ProblemCreatorChat from "./components/private/problems/my/ProblemCreatorChat";
import ProblemOffersView from "./components/private/problems/offers/ProblemOffersView";
import ProblemOffersList from "./components/private/problems/offers/ProblemOffersList";
import ProblemBuyerChat from "./components/private/problems/offers/ProblemBuyerChat";
import NotFound from "./components/pages/NotFound";
import Select2Multiple from "./components/libs/Select2Multiple";
import AccessPremiumFeaturePopup from "./components/private/AccessPremiumFeaturePopup";
import AssignIdea from "./components/private/ideas/AssignIdea";
import Test from "./components/Test";
import IdeaAngelInvestor from "./components/private/ideas/IdeaAngelInvestor";
import AngelInvestor from "./components/private/angelInvestor/AngelInvestor";
import IdeaAngelPartnership from "./components/private/ideas/IdeaAngelPartnership";
import Overview from "./components/private/overview/Overview";
import AIHub from "./components/private/ideas/AIHub";

// Runtime component load
const MyIdeas = React.lazy(() => import("./components/private/ideas/MyIdeas"));
const IdeaDetails = React.lazy(() =>
  import("./components/private/ideas/public/IdeaDetails")
);
const UserProfile = React.lazy(() =>
  import("./components/private/profile/UserProfile")
);
const FeedComponent = React.lazy(() =>
  import("./components/private/feed/FeedComponent")
);
const Profile = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      () => resolve(import("./components/private/profile/Profile")),
      300
    );
  });
});

const NotificationList = React.lazy(() =>
  import("./components/private/notifications/NotificationList")
);

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp() {
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    const externalScript = document.createElement("script");
    externalScript.onerror = function () {};
    externalScript.id = "external";
    externalScript.async = true;
    externalScript.type = "text/javascript";
    externalScript.setAttribute("crossorigin", "anonymous");
    document.body.appendChild(externalScript);
    externalScript.src = "/main.min.js";

    return () => {
      document.body.removeChild(externalScript);
    };
  }, []);

  useEffect(() => {
    history.listen((location, action) => {
      dispatch(clearMessage()); // clear message when changing location
    });
  }, [dispatch]);

  return (
    <div>
      <Router history={history}>
        <Routes>
          <Route exact path="/" element={<PublicRoutes />}>
            <Route path="/" element={<Index />} />
            <Route path="/login" element={<Login />} />

            <Route path="/register" element={<Register />} />
            <Route
              path="/resend-email-verification-link"
              element={<GetVerificationLink />}
            />

            <Route
              path="/registration-verify/:token"
              element={<RegisterVerify />}
            />

            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/account-removed" element={<AccountDelete />} />

            <Route path="/recover-account" element={<RecoverAccount />} />
            <Route
              path="/account-reactivation-verify/:token"
              element={<RecoverAccountVerify />}
            />
          </Route>

          <Route exact path="/" element={<PrivateRoute />}>
            <Route
              path="/access-premium-feature"
              element={<AccessPremiumFeaturePopup />}
            />

            <Route path="/payment-success" element={<PaymentSuccess />} />
            <Route path="/payment-fail" element={<PaymentFail />} />

            <Route path="/:public_url" element={<UserProfile />} />

            <Route path="/scionsline" element={<FeedComponent />} />

            <Route path="/overview" element={<Overview />} />

            <Route path="/invite-users" element={<InviteUsers />} />

            <Route path="/profile" element={<Profile />}>
              <Route path="scionsline" element={<MyTimeline />} />
              <Route path="edit" element={<EditProfile />} />

              <Route path="scions" element={<MyScions />}>
                <Route
                  path="/profile/scions"
                  to={<Navigate to="/profile/scions/my-scions" />}
                />
                <Route path="my-scions" element={<MyFriends />} />
                <Route path="friend-request" element={<FriendRequest />} />
              </Route>

              <Route path="account-settings" element={<AccountSettings />} />
            </Route>

            {/* Organization start */}

            <Route path="/organizations" element={<Organizations />} />
            <Route
              path="/organizations/create"
              element={<AddOrganizations />}
            />

            <Route
              path="/organizations/:id"
              element={<OrganizationDetailsView />}
            >
              <Route path="edit" element={<EditOrganizations />} />
              <Route path="view" element={<ViewOrganization />} />
              <Route path="admins" element={<OrgAdmins />} />
              <Route path="users" element={<OrgUsers />} />
            </Route>

            {/* Organization end */}

            {/* Ideas for public start */}
            <Route path="/ideas" element={<IdeaList />} />

            <Route path="/ideas/view/:slug" element={<IdeaDetails />} />
            {/* Ideas for public start */}

            {/* My Ideas start */}
            <Route path="/ideas/my-ideas" element={<MyIdeas />} />
            <Route path="/ideas/assign" element={<AssignIdea />} />
            <Route path="/ideas/create" element={<CreateIdea />} />
            {/* <Route path="/ideas/edit/:slug" element={<EditIdea />} /> */}

            <Route path="/ideas/details/:slug" element={<IdeaView />}>
              <Route
                path="/ideas/details/:slug"
                to={<Navigate to="/ideas/details/:slug/responses" />}
              />
              <Route path="edit" element={<EditIdea />} />
              <Route path="responses" element={<IdeaResponses />} />
              <Route path="explore-investment" element={<IdeaAngelInvestor />} />
              <Route path="explore-partnership" element={<IdeaAngelPartnership />} />
              <Route path="chat" element={<IdeaCreatorChat />} />
              <Route path="ai-hub" element={<AIHub />} />
              
              <Route path="details" element={<IdeaOffersIdeaDetails />} />
            </Route>

            <Route path="/ideas/my-offers/:slug" element={<OffersView />}>
              <Route
                path="/ideas/my-offers/:slug"
                to={<Navigate to="/ideas/my-offers/:slug/offers" />}
              />
              <Route path="details" element={<IdeaOffersIdeaDetails />} />
              <Route path="offers" element={<IdeaOffersList />} />
              <Route path="chat" element={<IdeaBuyerChat />} />
            </Route>

            {/* My Ideas end */}

            {/* My Problems Start */}

            <Route path="/problemspace/my-problems" element={<MyProblems />} />
            <Route path="/problemspace/create" element={<CreateProblem />} />

            <Route
              path="/problemspace/details/:slug"
              element={<MyProblemView />}
            >
              <Route
                path="/problemspace/details/:slug"
                to={<Navigate to="/problemspace/details/:slug/responses" />}
              />
              <Route path="edit" element={<EditProblem />} />
              <Route path="responses" element={<ProblemOffers />} />
              <Route path="chat" element={<ProblemCreatorChat />} />
              <Route path="details" element={<MyProblemViewDetails />} />
            </Route>

            <Route
              path="/problemspace/my-offers/:slug"
              element={<ProblemOffersView />}
            >
              <Route
                path="/problemspace/my-offers/:slug"
                to={<Navigate to="/problemspace/my-offers/:slug/offers" />}
              />
              <Route path="details" element={<MyProblemViewDetails />} />
              <Route path="offers" element={<ProblemOffersList />} />
              <Route path="chat" element={<ProblemBuyerChat />} />
            </Route>

            {/* My Problems End */}

            {/* Problems for public start */}
            <Route path="/problems" element={<ProblemList />} />

            <Route path="/problems/:slug" element={<ProblemDetails />} />
            {/* Problems for public start */}

            {/* My Jobs start */}

            <Route path="/my-jobs" element={<MyJobs />} />
            <Route path="/my-jobs/create" element={<CreateJob />} />
            <Route path="/my-jobs/edit/:id" element={<EditJob />} />

            {/* My Jobs end */}

            {/* Jobs for public start */}

            <Route path="/jobs" element={<JobList />} />
            <Route path="/jobs/:slug" element={<JobDetails />} />

            {/* Jobs for public end */}

            {/* Public Organizations list start */}

            <Route path="/organizations-list" element={<OrganizaationList />} />
            <Route
              path="/organizations-list/:slug"
              element={<OrganizationDetails />}
            />
            <Route path="/angelinvestor" element={<AngelInvestor />} />

            {/* Public Organizations list end */}

            <Route path="/notifications" element={<NotificationList />} />

            <Route path="/search" element={<SearchCommonLayout />}>
              <Route path="users/:keyword" element={<SearchUsers />} />
              <Route path="ideas/:keyword" element={<SearchIdeas />} />
              <Route path="jobs/:keyword" element={<SearchJobs />} />
            </Route>

            <Route path="/upgrade" element={<PaymentPlansV2 />} />
            <Route path="/subscription" element={<Subscription />} />

            <Route path="/access-denied" element={<AccessDenied />} />

            <Route path="/user-feedback" element={<FeedbackForm />} />

            <Route path="*" element={<NotFound />} />
          </Route>

          <Route path="/logout" element={<Logout />} />

          {/* Pages that anyone can access */}
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/feedback" element={<FeedbackPage />} />
          <Route path="/about-scions" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/how-idea-works" element={<HowItWorks />} />

          <Route path="/location-access-denied" element={<CountryBlocked />} />
          <Route path="/whatsnew" element={<WhatsNew />} />
          <Route path="/test" element={<Test />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
