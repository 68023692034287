import React, {useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, Navigate, useNavigate} from 'react-router-dom'
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import axios from "axios";

import { ApiServices } from "../services/ApiServices";
import { API_REGISTER_VERIFY_TOKEN, API_URL } from "../services/ApiEndpoints";

import {
    CLEAR_MESSAGE,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    MSG_ERROR,
    MSG_SUCCESS,
    SET_MESSAGE,
  } from "../actions/types";
import authHeader from "../services/auth-header";
import ReCaptchaV3 from "./libs/ReCaptchaV3";
import FormReCaptchaV3 from "./libs/FormReCaptchaV3";

const RegisterVerify = (props) =>{

    /************* Token Verification **********/

    const [errorMsg, setErrorMsg] = useState("") 

    let {token} = useParams();

    const [isLoading, setIsLoading] = useState(true)
    const [message, setMessage] = useState("");
    const [isError, setisError] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const verifyToken = (captchaToken) =>{

        setIsLoading(true)

        axios
        .post(API_URL + API_REGISTER_VERIFY_TOKEN,{
            token: token,
            "recaptcha_token":captchaToken
        },{
            headers: authHeader()
        })
        .then((response)=>{
            
            setIsLoading(false)

            const json = response.data
            
            setMessage(json.message)

            if(json.status=="RC200"){
                setisError(false)
                setMessage(json.message  + ", Please wait while redirecting you....")

                setTimeout(() => {
                    localStorage.setItem("user", JSON.stringify(json.data));

                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: { user: json.data },
                    });

                    navigate("/profile/edit") 
                }, 5000)

            }else{
                setisError(true)
            }

        })
        .catch((error)=>{
            setIsLoading(false)
        });
    }

    useEffect(()=>{
        //verifyToken()
    },[])

    /************* Password Reset Form **********/

    return(
        <div className="theme-layout">
            <div className="container-fluid">

                <div className="row">

                    <div className="col-md-12 text-center mt-3">    
                        <a href="/"><img width="240px" src={require('../assets/images/logo-big.png')} alt="" /></a>
                    </div>

                    <FormReCaptchaV3 noForm={true} onLoadStatusUpdate={(status)=>{
                            setIsLoading(status)
                        }} action="register_verify_email" onSubmit={verifyToken} />

                    <div className="col-md-12">
                        <div className="log-reg-area mt-5" style={{margin:"0 auto 100px auto"}}>
                            {isLoading ? (
                                <p>loading please wait...</p>
                            ) : (
                                
                                message && (
                                    <div className={ isError ? "alert alert-danger" : "alert alert-success" } role="alert">
                                        {message}
                                    </div>
                                )
                                
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegisterVerify;