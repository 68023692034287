import axios from "axios"
import { useEffect, useRef, useState } from "react"
import { API_GET_DISCIPLINE_BY_ID, API_GET_ENGINEERING_FIELDS, API_URL } from "../../services/ApiEndpoints"
import authHeader from "../../services/auth-header"
import Select2 from "./Select2"

const FieldsSelector = (props) =>{

    const {disc, sDisc, fieldList} = props
    const [isAutoFillDone, setIsAutoFillDone] = useState(false)

    const select2PD = useRef(null)
    const select2PSD = useRef(null)

    const [engFieldList, setEngFieldList] = useState(props.fieldList)
    const [disciplineList, setDisciplineList] = useState(props.disc)
    const [subDisciplineList, setSubDisciplineList] = useState(props.sDisc)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(()=>{
        setDisciplineList(disc)
        setSubDisciplineList(sDisc)
        setEngFieldList(fieldList)
    }, [disc, sDisc, fieldList])

    const setAutoFill = () =>{
        if(props.autoFill!==undefined){
            if(props.field){
                getDeciplinesById(props.field)
            }
        }
    }

    const getEngFields = () =>{

        setIsLoading(true)

        axios
        .get(API_URL + API_GET_ENGINEERING_FIELDS,{
            headers: authHeader()
        })
        .then((response)=>{
            
            setIsLoading(false)

            if(response.status==200){

                const json = response.data;

                setEngFieldList(json.data.engineering_field)

                setAutoFill();
            }

        })
        .catch((error)=>{
            setIsLoading(false)
        });
    }

    const getDeciplinesById = (id) =>{

        axios
         .get(API_URL + API_GET_DISCIPLINE_BY_ID +"/"+ id,{
             headers: authHeader()
         })
         .then((response)=>{
             
             if(response.status==200){
 
                 const json = response.data.data;
 
                 if(select2PD!=null){
                    select2PD.current.updateSelect(json)
                 }

                 if(select2PSD!=null){
                    select2PSD.current.updateSelect([])
                 }

                 if(!isAutoFillDone && props.autoFill!==undefined){
                    
                    if(props.discipline){
                        setIsAutoFillDone(true)
                        getSubDeciplinesById(props.discipline)
                    }
                }
                 
             }
 
         })
         .catch((error)=>{
 
         });
 
     }

     const getSubDeciplinesById = (id) =>{

        axios
         .get(API_URL + API_GET_DISCIPLINE_BY_ID +"/"+ id,{
             headers: authHeader()
         })
         .then((response)=>{
             
             if(response.status==200){
 
                 const json = response.data.data;
 
                 if(select2PSD!=null){
                    select2PSD.current.updateSelect(json)
                 }
                 
             }
 
         })
         .catch((error)=>{
 
         });
 
     }

     useEffect(()=>{
        if(engFieldList===undefined || engFieldList.length==0){
            getEngFields()
        }
     }, [])

     return(
        <div className="row">
            <div className="col-md-4">
                {isLoading ? (
                    <p>loading...</p>
                ) : (
                    <div className="form-group">
                        <Select2 xxx value={props.field || ""} onSelect={
                            (e) => {
                                getDeciplinesById(e.target.value)
                                props?.onSelectF(e.target.value)
                            }
                        } required={false} keys={"field_name"} directValues={false} placeholder={ props.fplaceholder || "Select your field"} data={engFieldList} />
                    </div>
                )}
            </div>
            <div className="col-md-4">
                <div className="form-group">
                <Select2 ref={select2PD} value={props.discipline || ""} onSelect={ (e) => {
                    getSubDeciplinesById(e.target.value)
                    props?.onSelectD(e.target.value)
                } } name="discipline" keys={"field_name"} data={disciplineList} directValues={false} placeholder={props.dplaceholder || "Which one best describes your discipline area"} />
                </div>
            </div>

            <div className="col-md-4">
                <div className="form-group">
                    <Select2 ref={select2PSD} 
                        value={props.subDiscipline || ""} 
                        onSelect={ (e) => props?.onSelectSD(e.target.value) } 
                        name="sub_discipline"
                        keys={"field_name"}
                        data={subDisciplineList}
                        placeholder={props.sdplaceholder || "Select your expertise area"} />

                </div>
            </div>
        </div>
     )

}

export default FieldsSelector;