import { Link, Navigate, NavLink, Outlet, useNavigate, useParams } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component'
import Footer from "../layouts/Footer";
import CommonPagesFooter from "./CommonPagesFooter";
import { useSelector } from "react-redux";
import FeedbackForm from "../FeedbackForm";
import { useEffect } from "react";

const FeedbackPage = () => {

  const { isLoggedIn } = useSelector(state => state.auth);

  const navigate = useNavigate()

  useEffect(()=>{
    if(isLoggedIn){
      navigate("/user-feedback")
    }
  }, [])

  return (
    <>
        <div className="theme-layout">

            <CommonPagesFooter />

            <div style={{marginTop:"60px"}}>
              <FeedbackForm />
            </div>
            
            <Footer />
        </div>
    </>
  );
};

export default FeedbackPage;