import React from "react";
import {Link} from 'react-router-dom'
import { useSelector } from "react-redux";
import SearchBar from "./SearchBar";
import { menu } from "../private/Consts";

const ResponsiveHeader = () =>{

    const {userData} = useSelector(state => state.userData )

    return(
        <div className="responsive-header">
            <div className="mh-head first Sticky">
                <span className="mh-btns-left">
                    <a className="" href="#menu"><i className="fa fa-align-justify"></i></a>
                </span>
                <span className="mh-text">
                    <Link to="/"><img src={require("../../assets/images/logo.png")} alt="" /></Link>
                </span>
                
                <span className="mh-btns-right">
                    <a onClick={(e)=> window.toggle_chat()} className=""><img style={{width:"20px"}} src={require("../../assets/images/ic_chat.png")} /></a>
                    <a className="fa fa-sliders" href="#shoppingbag"></a>
                </span>
                
            </div>

            <div className="mh-head second">
                
                <SearchBar isResponsive={true} />
                
            </div>

            <nav id="menu" className="res-menu mm-menu mm-offcanvas">
                <ul>
                    {menu.map( (item, index)=>(
                        
                        <li key={index}>
                            <Link to={item.route}>
                                <i className={item.icon}></i> {item.title}
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>

            <nav id="shoppingbag" className="mm-menu mm-offcanvas mm-right">
                <ul>
                    {userData.is_premium_user==undefined ? "" : (
                        userData.user_role==0 || userData.user_role==1 || userData.user_role==4 ? (
                            userData.is_premium_user==0 ? (
                                <li>
                                    <Link to="/upgrade" title=""><img src={require("../../assets/images/ic-subscribe.png")} /> &nbsp; Upgrade to Premium</Link>
                                </li>
                            ) : (
                                <li>
                                    <Link to="/subscription" title=""><img src={require("../../assets/images/ic-my-subscription.png")} /> &nbsp; My Package</Link>
                                </li>
                            )
                        ) : ""
                    )}
                    
                    <li>
                        <Link to="/profile/edit" title=""><img src={require("../../assets/images/ic_edit-profile.png")} /> &nbsp; Edit Profile</Link>
                    </li>
                    <li>
                        <Link to="/ideas/my-ideas" title=""><img src={require("../../assets/images/ic_my-idea.png")} /> &nbsp; Ideaspace</Link>
                    </li>

                    {/* <li>
                        <Link to="/problemspace/my-problems"><img src={require("../../assets/images/ic-problemspace.png")} /> Eureka Space</Link>
                    </li> */}

                    {/* <li>
                        <Link to="/my-jobs" title=""><img src={require("../../assets/images/ic_my-jobs.png")} /> &nbsp; My Jobs</Link>
                    </li> */}

                    {userData.user_role!=4 && (
                        <li>
                            <Link to="/organizations"><img src={require("../../assets/images/ic_my-org.png")} /> &nbsp; My Organizations</Link>
                        </li>
                    )}
                    
                    <li>
                        <Link to="/invite-users" title=""><img src={require("../../assets/images/ic-invite-users.png")} /> invite users</Link>
                    </li>
                    <li>
                        <Link to="/profile/account-settings" title=""><img src={require("../../assets/images/ic_settings.png")} /> &nbsp; Account Setting</Link>
                    </li>
                    <li>
                        <Link to="/logout"><img src={require("../../assets/images/ic_logout.png")} /> &nbsp; Log Out</Link>
                    </li>
                </ul>
            </nav>

        </div>
    );
};

export default ResponsiveHeader;