import jquery from 'jquery'
import '../../assets/js/tooltip'
import "bootstrap"
import { Link } from 'react-router-dom';
import { Component } from 'react';

class LinkToolTip extends Component{

    state = {
        route: "/",
        name: "",
        icon:"",
        placement:"right"
    }

    constructor(props){
        super();

        this.state.name = props.name!==undefined ? props.name : ""
        this.state.route = props.route!==undefined ? props.route : "/"
        this.state.icon = props.icon!==undefined ? props.icon : ""
        this.state.placement = props.placement!==undefined ? props.placement : "right"
    }

    componentDidMount(){
        jquery(this.refs['tooltip']).tooltip();

        

    }

    render(){
        return(
            <Link ref='tooltip' to={this.state.route} data-placement={this.state.placement} title={this.state.name}>
                <i className={this.state.icon}></i>
            </Link>
        )
    }

}

export default LinkToolTip;