import { Link, NavLink, Outlet, useParams } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component'
import Footer from "../layouts/Footer";
import CommonPagesFooter from "./CommonPagesFooter";

const TermsAndConditions = () => {

  return (
    <>
        <div className="theme-layout">
            
            <CommonPagesFooter />

            
            <div style={{marginTop:"60px"}} className="container">
                <br />

                <div className="central-meta text-justify no-justify-phone">
                    <h1>Terms of Use</h1>
                    <p>Our website, www.SCIONS.tech (the "Site" or the "Website"), is owned and operated by Sigma Tech Solutions B.V., a company with its mailing address at Oostmeerlaan 106, 2652AE Berkel en Rodenrijs, The Netherlands.</p>
                    <p>This Site is provided to you ("you," "your," or "user") by Sigma Tech Solutions B.V. under the following Terms and Conditions ("Terms of Use"). By using our Site in any manner, including but not limited to browsing or visiting, you agree to be bound by these Terms of Use, our Privacy Policy, and any other procedures, policies, or rules that may be published by us from time to time on the Site. Each of these documents is incorporated by reference and may be updated periodically without notice. By continuing to use our Site, you agree to be bound by any such updates; therefore, it is essential that you review the Terms of Use on the Site frequently.</p>
                    <h5>Introduction</h5>
                    <p>SCIONS is a secure, community-based online marketplace platform designed to unite academia and industry players in collaborative efforts to address research needs that contribute to a sustainable world. Founded by Sigma Tech Solutions B.V., headquartered in the Netherlands, SCIONS facilitates dynamic communication between these two sectors, resulting in practical, market-oriented outcomes tailored to specific industry needs.</p>
                    <p>The platform serves as a one-stop hub for individuals, researchers, academics, investors, entrepreneurs, and industry practitioners to discuss and commercialize innovative ideas. Through dynamic matchmaking, SCIONS ensures that collaborations lead to practical, not just theoretical, results that are specifically tailored to industry requirements.</p>
                    <p>To safeguard data privacy, SCIONS establishes Non-Disclosure Agreement (NDA) and ensures that all discussions are recorded, minuted, and stored on a secure cloud platform. This commitment guarantees data privacy for both idea owners and users.</p>

                    <h5>Membership Models</h5>
                    <p>There are two types of options in the platform:</p>
                    <ol>
                    <li><strong>Individual:</strong> Users have the option to unlock premium features by purchasing a single SCIONScoin. Each SCIONScoin holds a value of €50, but students can enjoy a 50% discount. Notably, these SCIONScoins have no expiration date, ensuring ongoing access to premium benefits. However, it's essential to note that premium access is valid for one year initially, and subsequent renewal necessitates the use of a single SCIONScoin. Following renewal, any remaining SCIONScoins can still be utilized as before.<br />
                    Premium access for individual option:
                    <ol>
                    <li>Utilize SCIONS AI hub to identify the ideal investor, company, and experts.</li>
                    <li>Social Networking with all SCIONS members</li>
                    <li>Chat with fellow SCIONS</li>
                    <li>Full access to idea marketplace</li>
                    <li>Access to investment opportunities</li>
                    <li>Access to collaboration opportunities</li>
                    </ol>
                    </li>
                    <br/>

                    <li><strong>Organizational:</strong> There are 3 packages available for organizations that wish to upload multiple ideas. These packages are detailed in the table below. In addition to the packages, organizations have the option to purchase individual SCIONScoins as well. Similar to the individual option, these SCIONScoins have no expiration date, ensuring ongoing access to premium benefits. However, it's essential to note that premium access is valid for one year initially, and subsequent renewal necessitates the use of a single SCIONScoin. After renewal, any remaining SCIONScoins can continue to be used as before.

                    <table width="100%" border="0" class="table table-bordered" style={{marginTop:"20px"}}>
                    <tr style={{background:"#f1f1f1"}}>
                    <td width="33%"><strong>Packages</strong></td>
                    <td width="33%" class="text-center"><strong>SCIONScoins</strong></td>
                    <td width="33%" class="text-center"><strong>Price (€)</strong></td>
                    </tr>

                    <tr>
                    <td><strong>Small</strong></td>
                    <td class="text-center">50</td>
                    <td class="text-center">2200</td>
                    </tr>

                    <tr>
                    <td><strong>Medium</strong></td>
                    <td class="text-center">100</td>
                    <td class="text-center">4000</td>
                    </tr>

                    <tr>
                    <td><strong>Large</strong></td>
                    <td class="text-center">700</td>
                    <td class="text-center">20,000</td>
                    </tr>
                    </table>

                    <p>Premium access for organizational option:</p>
                    <ul>
                    <li>Utilize SCIONS AI hub to identify the ideal investor, company, and experts.</li>
                    <li>Social Networking with all SCIONS</li>
                    <li>Chat with fellow SCIONS</li>
                    <li>Full access to idea marketplace</li>
                    <li>Access to investment opportunities</li>
                    <li>Access to collaboration opportunities</li>
                    <li>Create sub-admins for task sharing</li>
                    <li>Invite your staff/ student and make them as paid users</li>
                    </ul>
                    <p>Free Access to the platform:</p>
                    <ul>
                    <li>Networking with all SCIONS members</li>
                    <li>Private chat</li>
                    <li>Starters access to the idea list</li>
                    <li>Alerts</li>
                    </ul>
                    </li>
                    </ol>
                    <p><strong>Note:</strong> The SCIONScoins can be utilized to execute the AI hub to find a solution or matchmaking for the ideas.?</p>
                    <h5>Transaction Process and deal life cycle</h5>
                    <p>At this stage, SCIONS will not manage the entire transaction process. Our business model is based on purchasing SCIONScoins to access premium features and utilize the AI hub.</p>
                    <p><strong><em>Selling research ideas or research findings</em></strong></p>
                    <p>A premium user with research ideas logs into the web application and uploads their ideas in full detail, visible only to other SCIONS premium users. They have the option to keep their ideas private, in which case no one can see them, but they can still utilize our services for connections. General information about the idea can be made visible to all users. To view the full details of an idea, an NDA must be signed.</p>
                    <p>If any user is interested in an idea, they can submit an offer through their account. All communications via SCIONS are retrievable and recorded in our cloud database. Users are responsible for any transactions that may occur for the ideas on the SCIONS platform.</p>

                    <p><strong><em>Finding investor(s)</em></strong></p>
                    <p>A premium user with research ideas logs into the web application and uploads their ideas in full detail, visible only to other SCIONS premium users. They have the option to keep their ideas private, in which case no one can see them, but they can still utilize our services for connections. General information about the idea can be made visible to all users. To view the full details of an idea, an NDA must be signed.</p>
                    <p>If an investor is interested in an idea, they can make an offer through their account. All communications via SCIONS are recorded in our cloud database and can be retrieved later, subject to data availability on our cloud platform. Users are responsible for any transactions that occur on the SCIONS platform. Additionally, investors can easily search for various opportunities on the SCIONS platform.</p>

                    <p><strong><em>Finding co-founder(s)</em></strong></p>
                    <p>A premium user with research ideas logs into the web application and uploads their ideas in full detail, visible only to other SCIONS premium users. They have the option to keep their ideas private, in which case no one can see them, but they can still utilize our services for connections. General information about the idea can be made visible to all users. To view the full details of an idea, an NDA must be signed. The idea owner can specify their requirements for finding a co-founder.</p>
                    <p>If a user is interested in an idea, they can make an offer through their account. All communications via SCIONS are recorded in our cloud database and can be retrieved later, subject to data availability. Users are responsible for any transactions that occur on the SCIONS platform.</p>

                    <h5>Terms of Use and Disclosures Related to Securities Offerings</h5>
                    <p>SCIONS is not an investment adviser or a registered broker-dealer. We do not provide investment recommendations or financial advice; any representations or information on the Site do not constitute such recommendations or advice.</p>
                    <p>SCIONS does not generate any revenue from securities offered, nor do we issue securities or receive compensation from their sale on this Site.</p>
                    <p>It should be noted that SCIONS does not own the data shown on the platform but instead it uses third party data sources.</p>

                    <h5>Forward Looking Statements</h5>
                    <p>Any offering statements and other information provided by issuers of securities through this Site may contain forward-looking statements about the issuers, including their industry, strategy, and business plans. These forward-looking statements reflect the views of the issuers' management regarding future events based on currently available information. However, they are subject to uncertainties and risks that could cause the actual results to differ materially.</p>
                    <p>Investors are advised not to rely on these statements as they are intended for illustrative purposes only and do not guarantee future achievements, performance, levels of activity, or results.</p>
                    <p>No entity or person assumes responsibility for the completeness and accuracy of forward-looking statements, and there is no obligation to update any such statements to reflect actual results. Except as required by law, neither the issuers of securities on this Site nor any other person assumes responsibility for the accuracy and completeness of these forward-looking statements. No issuer is obligated to publicly update any forward-looking statements to conform to actual results or changes in expectations.</p>

                    <h5>Account Registration</h5>
                    <p>Some of the information on our site is publicly accessible and downloadable. However, to make an investment, post comments, or access certain offering materials, you need to register for an account ("Account").</p>
                    <p>This Site is intended solely for users who are at least 18 years old. Use of or access to the Site, and any account creation by anyone under 18 is unauthorized and in violation of these Terms of Use. By creating an account and/or using the Site, you represent that you are 18 years of age or older, are of legal age to form a binding contract where you reside, and that all registration information you submit is truthful and accurate. You must complete the registration process by providing accurate, complete, and current information. Additionally, you need to choose a username and password. Maintaining the confidentiality of your password and username is your responsibility. You must immediately notify SCIONS of any unauthorized use of your Account or any other security breach. You agree not to share your password, let anyone else access your Account, or do anything else that may jeopardize the security of your Account and the Site. You are entirely responsible for any loss or consequences resulting from activities associated with your Account, whether unauthorized or authorized.</p>
                    <p>You agree that your Account will be entirely "self-directed," meaning you are solely responsible for all instructions, investment decisions, orders, and purchases placed in or through your Account. Although the Site may provide content, information, or data from other parties related to investment opportunities and strategies to buy or sell securities, you should not interpret any such content as investment, financial, legal, or tax advice or as a recommendation to invest in any offering posted on the Site. Any decision to invest must be your own, based on your due diligence and analysis of the risks involved. You accept the risks associated with your decisions, including the risk of losing your entire investment. It is strongly advised to consult a licensed professional for investment, insurance, tax, or legal advice; the Site cannot and does not provide these types of professional services.</p>
                    <p>The Site allows authorized users to find the right network through our database. Users are expressly prohibited from posting libelous, defamatory, misleading, inaccurate, or false content; collecting personal information from other users; and using the Site, services, or tools if they are suspended, under 18, or unable to form legally binding contracts.</p>
                    <p>SCIONS, at its sole discretion, may refuse access to the Site for any entity or person and may change its eligibility criteria at any time. We reserve the right to terminate or suspend your access to the Site at any time for any reason, including abuse or misrepresentation of information. Use of this Site is void where prohibited by law, and in such jurisdictions, the right to access the Site is revoked. If you are using the Site on behalf of any entity, you represent that you are authorized to accept this Agreement on the entity’s behalf and that the entity agrees to indemnify SCIONS for violations of this Agreement.</p>

                    <h5>General Site Guidelines</h5>
                    <p>You represent that all information you provide on SCIONS is truthful, complete, and accurate. You agree and acknowledge that SCIONS and its agents are entitled to rely on the information you provide as being complete, accurate, and true without independent verification. You are prohibited from transmitting or posting any material on the site that is profane, pornographic, inflammatory, scandalous, obscene, libelous, defamatory, threatening, unlawful, fraudulent, offensive, or any material that could constitute or encourage conduct leading to civil liability, be considered a criminal offense, or otherwise violate any regulation or law.</p>
                    <p>In using our Site, you will <strong>not</strong>:</p>
                    <ul>
                    <li>You are prohibited from transmitting or disseminating passive collection mechanisms ("PCMs"), cancelbots, adware, spyware, time bombs, keyloggers, RATs, Trojan horses, worms, viruses, or any other invasive or malicious code or program. You may not transmit or upload (or attempt to transmit or upload) any material that acts as an active or passive information collection or transmission mechanism, including but not limited to cookies, web bugs, 1x1 pixels, clear GIFs, or other similar devices.</li>
                    <li>You are also prohibited from adapting or copying the site's software, including but not limited to JavaScript, HTML, PHP, Flash, or other code. You may not modify, reverse assemble, decompile, reverse engineer, or attempt to discover any software (object code or source code) that the Site creates to generate web pages or any software or other products or processes accessible through the Site.</li>
                    <li>Except as may be the result of standard browser or search engine usage, you may not distribute, develop, launch, or use any automated system, including but not limited to offline readers, scrapers, cheat utilities, robots (or "bots"), or spiders that access the Site. You are also prohibited from launching or using any unauthorized script or other software.</li>
                    <li>You must not fabricate any TCP/IP packet header or any part of the header information in any email or posting, or use the Site to send altered, deceptive, or false source-identifying information. Using tools to anonymize your IP address (e.g., anonymous proxy) to access the Site, or disguising the source of materials or other information you submit to the Site, is also prohibited.</li>
                    <li>You may not circumvent or interfere with any security feature of the Site or any feature that enforces or restricts limitations on access to or use of the content, user content, or services. Testing, scanning, or probing the vulnerability of any system or network, or breaching or circumventing any security or authentication measures, is prohibited.</li>
                    <li>Additionally, you must not disrupt or interfere with (or attempt to do so) the access of any network, host, or user. This includes, but is not limited to, mail-bombing the Site, spamming, flooding, overloading, sending a virus, or scripting the creation of content in a manner that interferes with or creates an undue burden on the Site.</li>
                    </ul>
                    <p>Please note that SCIONS will fully cooperate with any law enforcement authorities' validly issued court orders and subpoenas requesting or directing SCIONS to disclose the identity of anyone posting materials or information on the Site who is alleged to have engaged in violative conduct. SCIONS assumes no obligation to provide prior notice of such disclosure.</p>

                    <h5>Communication Channel Guidelines</h5>
                    <p>Online communication channels for offerings may be monitored. These channels are intended for prospective investors to engage in dialogue with each other and the issuer of a particular offering. Any individual who promotes an offering in exchange for compensation, or who is a founder or an employee of an issuer, should disclose their involvement in promotional activities on behalf of the issuer. Issuers are encouraged to participate in the communication channels as long as comments adhere to the following guidelines. The following types of comments are not permitted and will be removed:</p>
                    <ul>
                    <li>Spam messages</li>
                    <li>Indirect or direct solicitation of or promotion with respect to securities offerings on other sites.</li>
                    <li>Indirect or direct solicitation of or promotion with respect to securities offerings on other sites may be misleading, are not permitted and will be removed. This includes both direct solicitation or comments purporting to be genuine comments but that indirectly promote other offerings. Commenters who genuinely wish to compare offerings made on other sites should raise specific questions about the issuer’s business, prospects or valuation.</li>
                    <li>Profanity or derogatory language based on presumed sexual preference, national origin, age, sex, race, or otherwise inappropriate or coarse language that that is in violation of our Terms of Use</li>
                    <li>Comments from Users of the Site, or any services and or tools, if they are temporarily, or indefinitely, suspended from using the Site, services or any tools</li>
                    <li>Comments which attempt to collect personal information about other Users.</li>
                    <li>Duplicate comments</li>
                    <li>Comments which are not compliant, and have been posted by the Issuer</li>
                    <li>Comments which include phone numbers, direct email addresses, or anything else which suggest communication outside of the platform.</li>
                    </ul>

                    <h5>No Representations or Warranties</h5>
                    <p>To the extent permitted by law, SCIONS does not assume any responsibility or liability for any omissions or errors in the content of the Site. We do not represent or endorse the accuracy, reliability, or completeness of any information or content distributed through or accessed from the Site, nor have we conducted any investigation into such information. SCIONS shall not be held accountable for any investment decisions made based on such information. You agree that any reliance upon any information or content distributed through or accessed from the Site is at your sole risk.</p>
                    <p>SCIONS is entitled to rely on the information provided by its Users. You agree and acknowledge that SCIONS does not provide any assurance, representation, or warranty that the offerings on the Site comply with state and/or Federal securities laws, including any exemption from the offer or sale of unregistered securities and the prohibition against the general solicitation of unregistered securities. Each Issuer, and not the Site, is responsible for ensuring that any securities offering is conducted in accordance with applicable laws. We make no warranties or representations regarding the compliance or legality of any offering.</p>
                    <p>SCIONS has not reviewed all the links provided on the Site and is not responsible for the content of any off-Site pages. Clicking on hyperlinks and visiting off-Site pages is solely at your own risk.</p>

                    <h5>Site and Customer Intellectual Property</h5>
                    <p>The content and images on the site are either the property of SCIONS or used with permission. Unless otherwise permitted by these Terms of Use or elsewhere on the Site, you are prohibited from using the content or images. SCIONS does not provide compensation for the use of your content. We make no representations or warranties regarding the infringement of third-party rights unrelated to SCIONS by your use of materials displayed on the Site.</p>
                    <p>The slogans, logos, and trademarks displayed on the site, including the marks "SCIONS" and "SCIONS" (collectively the "Trademarks"), are owned by Sigma Tech Solutions B.V. and others. Nothing on this Site should be construed as granting any right or license to use the Trademarks without the written permission of the third-party owner of the Trademarks or SCIONS. Misusing any content on this site or the Trademarks provided on this site, except as provided in these Terms of Use, is strictly prohibited. You are cautioned and advised that SCIONS will aggressively enforce its intellectual property rights to the fullest extent.</p>

                    <h5>Communications</h5>
                    <p>You acknowledge that SCIONS will send communications to you electronically via the email address provided in your Account. Additionally, you agree to inform us promptly of any changes to your contact or address details. Communications shall be considered delivered to you upon sending, not upon receipt. Your use of electronic signatures to sign documents legally binds you in the same manner as if you had manually signed them. By signing electronically, you represent that you have the ability to retain and access a record of such documents. You agree that understanding these documents is solely your responsibility, and you consent to conducting business by electronic means. You are required to periodically review the Site for modifications and changes and agree not to contest the enforceability or admissibility of the Site’s electronically stored copy of this Agreement in any proceeding arising from it.</p>
                    <p>You represent and agree that you possess a suitable computer with an Internet Service Provider, Internet access, and an email address, and that you can download, save, and/or print communications to retain a record of them. Moreover, you agree to take sole responsibility for maintaining these services and equipment. Any information transmitted to the Site or SCIONS by you, including suggestions, comments, questions, videos, pictures, website addresses, and links to other articles/websites, other than Personally Identifiable Information as defined in our Privacy Policy, is non-proprietary and non-confidential. SCIONS or its affiliates may use such information for the purposes described in the Privacy Policy.</p>

                    <h5>Change or Termination</h5>
                    <p>We reserve the right to make changes to the site without prior notice. Additionally, we may, without prior notice, cease providing the Site, services, or applications. We may temporarily or permanently suspend or terminate your account access to the Site without liability or notice, for any reason or for no reason, at our sole discretion if we determine that you violate any provision of these Terms of Use. Upon termination of your access to the Site or these Terms of Use, for any reason or no reason, you will continue to be bound by those provisions of the Terms of Use which, by their nature, should survive termination, including limitations of liability, indemnification, warranty disclaimers, and ownership provisions.</p>
                    <p>SCIONS reserves the right, at its sole discretion, without any notice requirement and without any obligation, to correct, improve, or change the descriptions, materials, and information on the Site for any reason. The materials and information on the Site may contain typographical inaccuracies or errors. Any dated information is published as of its date only, and SCIONS does not undertake any responsibility or obligation to amend or update any such information. You agree that SCIONS and its affiliates will not be liable to you or to any third party for any such discontinuance, suspension, or modification.</p>

                    <h5>Disclaimer of Liability</h5>
                    <p>You use the site at your own risk. Neither scions nor any other party involved in delivering, producing, or creating the site shall be liable for any consequential, incidental, direct, punitive or indirect damages arising out of your access to, or use of, the site. Without limiting the foregoing, everything on the site is provided to you "as is" without warranty of any kind, either implied or express, including but not limited to, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement. Please be aware that the exclusion of implied warranties may not be allowed by some jurisdictions, thus, some of the exclusions that have been mentioned above may not apply to you. Check your local laws for any limitations or restrictions regarding the exclusion of implied warranties.</p>
                    <p>SCIONS shall not be liable for, and assumes no responsibility for, any damages to, or bots, spyware, malware, viruses, or the like that may infect your computer equipment or other property on account of your access to, browsing on, or use of the Site or your downloading of any audio, video, images, text, data, or materials from the Site.</p>
                    <p>Although SCIONS may from time-to-time review or monitor transmissions, postings, discussions, and the like on the Site, SCIONS is under no obligation to do so and assumes no liability or responsibility arising from such content nor for any inaccuracy, profanity, obscenity, falsehood, omission, slander, libel, defamation, or error contained in any information within such locations on the Site. SCIONS reserves the right to remove any content from the Site or to restrict access to the Site for any reason without notice.</p>

                    <h5>Dispute Resolutions</h5>
                    <p>In the event of any alleged dispute, controversy, or claim between you and SCIONS or its affiliates ("Dispute"), you agree to attempt in good faith to amicably resolve any Dispute at least 30 days before initiating any legal proceeding. Each party agrees to submit any Dispute for resolution by final binding arbitration after serving written notice, detailing the controversy, question, claim, or alleged breach along with your attempt to resolve such Dispute. Upon such notice and attempt to resolve, either party may commence an arbitration proceeding. Any proceedings will be held in the Netherlands.</p>
                    <p>The decision(s) of the arbitrator(s) shall be final and binding and may not be appealed to any court, except on very limited legal grounds. The implementation of arbitration decision(s) shall not be delayed or impaired pending the outcome of any appeal. Judgment upon the award rendered in arbitration may be entered by any court having jurisdiction. You agree that all disputes will be limited between you, individually, and SCIONS. To the fullest extent allowed by law, you agree that no arbitration proceeding or other dispute resolution proceeding shall be joined with any other party or decided on a class-action basis.</p>
                    <p>However, you agree that the following matters shall not, at the election of SCIONS, be subject to binding arbitration: (1) any claim for injunctive relief; (2) any dispute concerning SCIONS' intellectual property rights; and (3) any dispute arising from, or related to allegations of criminal activity. Any court action initiated by SCIONS or you in accordance with these Terms of Use shall be brought in the Netherlands courts, and you and we hereby consent to and submit to the personal jurisdiction of, and the service of process by, that Court.</p>

                    <h5>Indemnification</h5>
                    <p>You agree to hold, defend, and indemnify SCIONS and its affiliates, assigns, successors, suppliers, advertisers, licensors, representatives, agents, employees, directors, officers, and operational service providers harmless against and from any and all costs (including reasonable attorneys’ fees), damages, expenses, losses, actions, and claims resulting from any violation or breach of these Terms of Use by you or arising from your use of or access to the Site. This includes any infringement by you, or any third party using your account, of any intellectual property or other right of any person or entity, as well as any content posted through the Site by you (including claims related to defamation, invasion of privacy, or other violation of a person's rights).</p>
                    <p>SCIONS reserves the right, at its sole discretion and expense, to assume the exclusive defense and control of any such claim or action and all negotiations for settlement or compromise. You agree to fully cooperate with SCIONS in the defense of any such negotiations, settlement, action, or claim, as requested by SCIONS. The indemnification obligations set forth above shall survive your cessation of use of the Site.</p>

                    <h5>Notification Procedures</h5>
                    <p>SCIONS will provide notifications, including those required by law and modifications to these Terms of Use. While we are not obligated to do so, we may provide notifications of modifications for marketing or other business-related purposes. Notifications will be posted on our Site or emailed to you, as determined by us in our sole discretion. We reserve the right to determine the means and form of providing notifications to Site visitors. We are not responsible for any automatic filtering that you or your network provider may apply to email notifications that we send to the email address you provided to us.</p>

                    <h5>General</h5>
                    <p>If SCIONS fails to enforce any right or provision of these Terms of Use, it should not and will not be constituted as a waiver of future enforcement of that right or provision. The waiver of any such provision or right will be effective only if in writing and signed by a duly authorized representative of SCIONS. Except as expressly set forth in these Terms of Use, the exercise by either party of any of its remedies under these Terms of Use will be without prejudice to its other remedies under these Terms of Use or otherwise. If for any reason a court or arbitral tribunal, as applicable, of competent jurisdiction finds any provision of these Terms of Use invalid or unenforceable, that provision will be enforced to the maximum extent permissible and the other provisions of these Terms of Use will remain in full force and effect.</p>

                    <h5>Miscellaneous</h5>
                    <p>There shall be no third-party beneficiaries to the Terms of Use, except as expressly provided in these Terms of Use. In the event that any provision of these Terms of Use, or the application thereof, becomes or is declared by a court of competent jurisdiction to be unenforceable, void or illegal, the remainder of these Terms of Use will continue in full force and effect and the application of such provision to other persons or circumstances will be interpreted so as reasonably to effect the intent of the parties hereto. In addition, you agree to replace such unenforceable or void provision of these Terms of Use with an enforceable and valid provision that will achieve, to the extent possible, the business, economic, and other purposes of such unenforceable or void provision.</p>

                    <p>Last updated on June 14, 2024</p>
                </div>


            </div>

            <Footer />
        </div>
    </>
  );
};

export default TermsAndConditions;