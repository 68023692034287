import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { API_PROBLEM_MAKE_AN_OFFER, API_PROBLEM_START_CHAT, API_PROBLEM_DETAILS_PUBLIC, API_SIGN_NDA, API_URL, API_GET_RELATED_PROBLEMS, API_DOWNLOAD_NDS_PROBLEMS } from "../../../../services/ApiEndpoints";
import authHeader from "../../../../services/auth-header";
import ExCarousel from "../../../libs/ExCarousel";
import ExtButton from "../../../libs/ExtButton";
import ExToolTip from "../../../libs/ExToolTip";
import Popups from "../../../libs/Popups";
import { Button, Modal } from 'react-bootstrap';
import NDAText from "../../ideas/NDAText";

const ProblemDetails = () =>{

    let {slug} = useParams();
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState({})
    const navigation = useNavigate()

    const getDetails = () =>{

        setIsLoading(true)

        axios
        .get(API_URL + API_PROBLEM_DETAILS_PUBLIC +"/"+ slug,{
            headers: authHeader()
        })
        .then((response)=>{

            setIsLoading(false)
            
            if(response.status==200){

                const json = response.data;

                if(json.status=="RC200"){

                    var data = json.data;

                    setData(data)

                    getRelatedProblemList(data.field)

                }else{
                    toast.error(json.message)
                    navigation("/problems")
                }

            }

        })
        .catch((error)=>{
            setIsLoading(false)
        });

    }

    const [isRealtedProblemsLoading, setIsRealtedProblemsLoading] = useState(true)
    const [relatedProblems, setRelatedProblems] = useState({})

    const getRelatedProblemList = (EngId) =>{
        
        setIsRealtedProblemsLoading(true)

        axios
        .get(API_URL + API_GET_RELATED_PROBLEMS +"/"+ EngId,{
            headers: authHeader()
        })
        .then((response)=>{

            setIsRealtedProblemsLoading(false)
            
            if(response.status==200){

                const json = response.data;

                if(json.status=="RC200"){

                    var data = json.data;
                    setRelatedProblems(data)
                }

            }

        })
        .catch((error)=>{
            setIsRealtedProblemsLoading(false)
        });
    }

    const [isSCLoading, setIsSCLoading] = useState(false)

    const startChat = () => {

        setIsSCLoading(true)

        const formdata = new FormData()
        
        formdata.append("problem_id", data.id)
        formdata.append("offer_id", -1) // -1 for current user

        axios.post(API_URL + API_PROBLEM_START_CHAT, formdata,{
            headers:authHeader()
        })
        .then((responsex) => {

            setIsSCLoading(false)

            if(responsex.data.status=="RC200"){

                navigation(`/problemspace/my-offers/${data.id}/chat`)
                
                toast.success(responsex.data.message)
                    
            }else{
                toast.error(responsex.data.message)
            }

            return responsex.data;
        });

    }

    useEffect(()=>{
        getDetails()
    }, [slug])

    const [showNDA, setShowNDA] = useState(false)
    const [currentData, setCurrentData] = useState({})

    const [isNDALoading, setIsNDALoading] = useState(false)

    const navigate = useNavigate()

    const submitNDA = () =>{

        setIsNDALoading(true)

        var formdata = new FormData()
        formdata.append("slug", currentData.slug);

        axios.post(API_URL + API_SIGN_NDA, formdata,{
            headers:authHeader()
        })
        .then((responsex) => {

            setIsNDALoading(false)

            if(responsex.data.status=="RC200"){

                toast.success(responsex.data.message)

                setShowNDA(false)

                navigate(`/problems/${currentData.slug}`)

            }

            return responsex.data;
        }).error(()=>{
            setIsNDALoading(false)
        });

    }

    const onIdeaClick = (e, val) =>{

        e.preventDefault()

        if(val.is_nda_done==1)
            navigate(`/problems/${val.slug}`)
        else{
            setCurrentData(val)
            setShowNDA(true)
        }
    }

    let toastIdNDA = useRef(null);

    const downloadNDAPDF = (e) => {

        e.preventDefault()
        
        toastIdNDA = toast.loading("Downloading is in progress...", {closeOnClick: true})

        axios
        .get(`${API_URL}${API_DOWNLOAD_NDS_PROBLEMS}?problem_id=${data.id}`,{
            headers: authHeader(),
            responseType: 'blob'
        })
        .then((responsex) => {

            if( responsex.headers["content-type"]=="application/pdf" ){
                
                // create file link in browser's memory
                const href = URL.createObjectURL(responsex.data);
            
                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'NDA.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();
            
                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);

                toast.update(toastIdNDA, { render: "Signed NDA is downloaded successfully.", type: "success", isLoading: false, autoClose: 5000 });

            }else{
                toast.update(toastIdNDA, { render: "Problem not found. Please try after some time.", type: "error", isLoading: false, autoClose: 5000 });
            }

            return responsex.data;
        }).catch((error)=>{
            if(error.response.status==404){
                toast.update(toastIdNDA, { render: "Business Problem not found. Please try after some time.", type: "error", isLoading: false, autoClose: 5000 });
            }else if(error.response.status==401){
                toast.update(toastIdNDA, { render: "You are not authorized to access this resource.", type: "error", isLoading: false, autoClose: 5000 });
            }
        });

    }

    const popupHowItWork = useRef()

    return(
        <section>
            <div className="gap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            
                            {isLoading ? (
                                <>Loading</>
                            ) : (
                                <div id="page-contents" className="row merged20">
                                    <div className="col-lg-12">
                                        
                                        {data.offer_sent==1 && (
                                            <div className="alert alert2 alert-success">
                                                <p>You have already sent an offer to this business problem. Click <Link to={`/problemspace/my-offers/${data.id}/offers`}>here</Link> to View offer details</p>
                                            </div>
                                        )}

                                        <div className="main-wraper" id="idea_panel">
                                            <div className="row">
                                                <div className="col-md-7">
                                                    <div className="course-details">
                                                        <div className="row">
                                                            <div className="col-md-7">
                                                                <h3>{data.pain_point}</h3>
                                                                <h6 style={{"color":"#f05a24"}} className="mb-3">{data.unique_id}</h6>
                                                            </div>
                                                            
                                                            {data.problem_patented===1 && (
                                                                <div className="col-md-5"><span className="course-price">Patented with {data.problem_patented_with}</span><span className="course-price">Patent ID: {data.patented_number}</span></div>
                                                            )}

                                                        </div>
                                                        <div className="create-by">
                                                            <figure><img src={data.profile_image} alt="" /></figure>
                                                            <div>
                                                                <span>{data.full_name}&nbsp;&nbsp;</span>
                                                                <em>Added: {data.date}</em>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                
                                                <div className="col-md-5">
                                                    <div className="course-video">
                                                    
                                                    {/* <TransPopup
                                                        ref={popupHowItWork}
                                                        label={["How it Works ?", <br />, <br />]}
                                                        className="pull-right"
                                                        title=""
                                                        dClassName="modal-lg modal-transp"
                                                        btnCancel={false}
                                                        btnCancelLabel="Close"
                                                        onSaveClickListener={(e)=>{
                                                            popupHowItWork.current.close()
                                                        }}
                                                    >
                                                        <HowItWorks hide={(e)=>{
                                                            popupHowItWork.current.close()
                                                        }} />
                                                    </TransPopup> */}
                                                        
                                                        <figure><img style={{maxHeight:"200px", width:"auto"}} src={data.image} alt="" /></figure>
                                                        {data.allow_chat && (
                                                            <ExtButton onClick={startChat} isLoading={isSCLoading} type="link" className="main-btn"><i className="fa fa-envelope"></i> Contact Owner</ExtButton>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <h4 className="idea-title">Details of the Business Problem</h4>
                                                    <strong>Company Name: </strong> {data.company_name}<br />
                                                    <strong>Location: </strong> {data.location}<br />
                                                    <strong>Offered Price: </strong> €{data.offered_price}<br />
                                                    <strong>Proposal deadine date: </strong> {data.proposal_date_deadline}<br />
                                                    <strong>Proposal deadine due date: </strong> {data.proposal_date_due_deadline}<br />
                                                    <hr />

                                                    <strong>Description</strong><br />
                                                    <hr />
                                                    { data.description && data.description.split("\n").map((str, index) => <p key={`px-${index}`}>{str}</p>)}

                                                </div>

                                                <div className="col-md-6">
                                                    <h4 className="idea-title">Collaborate with Owner</h4>

                                                    <div className="accordion mt-0" id="accordion">
                                                        <div className="card">
                                                            <div className="card-header" id="headingOne">
                                                                <h5 className="mb-0">
                                                                    <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">DOCUMENTS</button>
                                                                </h5>
                                                            </div>

                                                            <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                                                <div className="card-body">

                                                                    <h6>Documents</h6>

                                                                    {data.upload_document && data.upload_document.length>0 ? (
                                                                        <ul>
                                                                            {data.upload_document.map((val, index)=>(
                                                                                <li><a target="_blank" href={val}>Document {index+1}</a></li>
                                                                            ))}
                                                                        </ul>
                                                                    ) : (
                                                                        <p>Documents not uploaded</p>
                                                                    )}
                                                                    
                                                                    <hr />
                                                                    <h6>Signed NDA with Business Problem Owner</h6>
                                                                    
                                                                    <i className="fa fa-file-pdf-o mr-2"></i>
                                                                    <a href="" onClick={downloadNDAPDF} >Download here</a>
                                                                    

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <MakeOffer data={data} />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {isRealtedProblemsLoading ? (
                                <p>Loading...</p>
                            ) : (

                                relatedProblems.length>0 && (
                                    
                                    <div className="main-wraper">
                                        <h4 className="main-title mb-3 mt-5">Related Eureka Space you can Explore</h4>
                                        <br /><br />

                                        <div className="post-content">

                                            <div className="pst-carousel">

                                                <ExCarousel>
                                                    {relatedProblems.map((val)=>(
                                                        <div className="pst-frmt">
                                                            <a onClick={(e)=> onIdeaClick(e, val) }>
                                                                <img src={val.image} alt="" />
                                                            </a>
                                                            <div className="pst-inf mt-2">
                                                                <h6>
                                                                    <a onClick={(e)=> onIdeaClick(e, val) }>
                                                                        {val.pain_point}
                                                                    </a>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </ExCarousel>

                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                )

                                
                            )}

                            <Modal noMargin dialogClassName={"full-modal"} show={showNDA} aria-labelledby="contained-modal-title-vcenter" onHide={()=>setShowNDA(false)}>  
                                
                                <Modal.Body>
                                    
                                    <NDAText secondParty={currentData ? currentData.full_name : ""} secondPartyRole={currentData ? currentData.role_name : ""} ideaTitle={currentData ? currentData.idea_title : ""} />

                                    <br /><br />

                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <ExtButton isLoading={isNDALoading} showLoading={false} type="button" className="btn btn-link mr-50" onClick={()=>setShowNDA(false)}><span>Cancel</span></ExtButton>

                                            <ExtButton isLoading={isNDALoading} showLoading={true} type="button" className="mtr-btn" onClick={(e)=>{
                                                submitNDA()
                                            }}><span>I understand and Agree</span></ExtButton>
                                        </div>
                                    </div>


                                </Modal.Body>

                            </Modal>

                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

const MakeOffer = (props) =>{

    const { data } = props

    const [isLoading, setIsLoading] = useState(false)
    const [hasError, setHasError] = useState(false) 
    const [msg, setMsg] = useState("")
    const [expAroundProblem, setExpAroundProblem] = useState("")
    const [conditions, setConditions] = useState("")
    const [amount, setAmount] = useState("")
    const [attachment, setAttchment] = useState(null)

    const handleSubmit = (e) =>{
        e.preventDefault()

        setIsLoading(true)
        setMsg("")

        const formdata = new FormData()
        formdata.append("problem_id", data.id)
        formdata.append("offer_conditions", conditions)
        formdata.append("offer_price", amount)
        formdata.append("experience_around_the_problem", expAroundProblem)

        if(attachment!=null)
            formdata.append("upload_document[0]", attachment)

        axios.post(API_URL + API_PROBLEM_MAKE_AN_OFFER, formdata,{
            headers:authHeader()
        })
        .then((responsex) => {

            setIsLoading(false)

            if(responsex.data.status=="RC200"){
                setHasError(false)
                setExpAroundProblem("")
                setAmount("")

                popup.current.close()
                toast.success(responsex.data.message)
            }else{
                setHasError(true)
                setMsg(responsex.data.message)
            }

            return responsex.data;
        });
        
    }

    const popup = useRef()

    return(
        <div className="col-md-12 mt-5">

            <div className="row">
                
                {data.allow_offer==1 && (
                    <div className="col-md-12 text-center">
                        <br />
                        <Popups
                            ref={popup}
                            label="Make an Offer"
                            title="Make an Offer"
                            className="btn btn-success"
                            dClassName="modal-lg"
                        >
                            <form onSubmit={handleSubmit}>

                                <div className="form-group">
                                    <input value={amount || ""} onChange={(e)=> setAmount(e.target.value)} type="number" step="any" required="required" />
                                    <label className="control-label" for="input">Offer price (€)</label>
                                    <i className="mtrl-select"></i>
                                </div>

                                <div className="form-group">
                                    <label for="input">Experience around the problem <ExToolTip name="You can counter the payment conditions and post-settlement options what you have discussed with the problem owner on the chat. If you agree with all the conditions then you may accept it in your notes."></ExToolTip></label>
                                    <textarea value={expAroundProblem || ""} onChange={(e)=> setExpAroundProblem(e.target.value)} rows="3" id="textarea"  required="required"></textarea>    
                                    <i className="mtrl-select"></i>
                                </div>

                                <div className="form-group">
                                    <label for="input">Offer conditions</label>
                                    <textarea value={conditions || ""} onChange={(e)=> setConditions(e.target.value)} rows="3" id="textarea"  required="required"></textarea>    
                                    <i className="mtrl-select"></i>
                                </div>

                                <label className="control-label" for="input">Attachment (Optional)</label>
                                
                                <input onClick={ (e) => {
                                    e.target.value=""
                                    setAttchment(null)
                                } } onChange={ (e)=> {

                                    var files = e.target.files;
                                    if(files.length==0) return;
                                    setAttchment(files[0]);

                                }} name="attechment" type="file" className="form-control-file file-picker-theme" />
                                
                                <br />

                                {msg && (
                                    <div className={ hasError ? "alert alert-danger mt-10 text-left" : "alert alert-success mt-10 text-left" } role="alert">
                                        {msg}
                                    </div>
                                )}

                                <div>
                                    <ExtButton className="btn btn-success" isLoading={isLoading} type="submit">
                                        <i className="fa fa-check"></i> Submit
                                    </ExtButton>
                                </div>
                            </form>
                        </Popups>
                    </div>
                )}

            </div>
        </div>
    )
}

export default ProblemDetails;