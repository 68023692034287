let TEST_URL = "https://testadmin.scions.tech/";
let LIVE_URL = "https://admin.scions.tech/";

export const WEB_URL =
  process.env.NODE_ENV === "development" ||
  window.location.hostname === "test.scions.tech"
    ? TEST_URL
    : LIVE_URL;

export const TEST_SOCKET_IP = "wss://testadmin.scions.tech";
export const LIVE_SOCKET_IP = "wss://admin.scions.tech";

export const RE_CAPTCHA_SITE_KEY = "6LeU2Q0jAAAAAFUlCvt2Ho3gacvDsJ67E3zSODDr";

export const API_URL = WEB_URL + "api/";

export const API_GET_ENGINEERING_FIELDS = "country-engineering-field";
export const API_LOGIN = "login";

// forgot password
export const API_GET_PWD_RESET_LINK = "forget-password";
export const API_VERIFY_TOKEN = "verify-token";
export const API_RESET_PASSWORD = "reset-password";

// registration
export const API_REGISTER = "user-registration";
export const API_RESEND_VERIFICATION_LINK = "resend-verification-link-to-user";
export const API_REGISTER_VERIFY_TOKEN = "verify-token-register";

// feed
export const API_GET_FEED_VISIBILITY = "get-feed-visibility";
export const API_TIMELINE = "timeline";
export const API_MY_TIMELINE = "mytimeline";
export const API_TIMELINE_ADD_COMMENT = "add-comment";
export const API_TIMELINE_ADD_POST = "add-post";
export const API_LIKE_DISLIKES = "like-dislike";
export const API_GET_COMMENTS_BY_POST = "get-comment-by-post";

// profile
export const API_GET_USER_DATA = "get-user-data";
export const API_GET_PROFILE = "get-profile";
export const API_UPDATE_PROFILE_IMAGE = "update-profile-image";
export const API_CHANGE_PASSWORD = "change-password";
export const API_UPDATE_PROFILE = "update-profile";
export const API_UPDATE_LANGUAGES = "update-language";
export const API_UPDATE_SKILLS = "update-skill";
export const API_UPDATE_PUBLICATIONS = "update-publication";
export const API_UPDATE_EXPERIENCE = "update-work-experience";
export const API_UPDATE_EDUCATION = "update-education";
export const API_UPDATE_INTEREST = "update-interest";
export const API_GET_DISCIPLINE_BY_ID = "get-disciplines-by-id";

export const API_SEARCH = "search/users";
export const API_SEARCH_IDEAS = "search/ideas";
export const API_SEARCH_JOBS = "search/jobs";
export const API_SEND_FRIEND_REQUEST = "send-friend-request";
export const API_GET_NOTIFICATIONS = "get-notifications";

export const API_MY_SCIONS_COUNTS = "get-friend-count";
export const API_FRIEND_LIST = "friend-list";
export const API_UNFRIEND = "unfriend";

export const API_FRIEND_REQUEST_LIST = "get-friend-request";
export const API_DELETE_FRIEND_REQUEST = "delete-friend-request";
export const API_ACCEPT_FRIEND_REQUEST = "accept-friend-request";

export const API_GET_NOTIFICATION_LIST = "get-notification-list";

export const API_GET_USER_PROFILE = "get-user-profile";

export const API_CREATE_IDEA = "create-idea";
export const API_GET_IDEA = "get-idea";
export const API_GET_IDEA_DETAILS = "get-idea-details";
export const API_MY_IDEA = "my-ideas";
export const API_UPDATE_IDEA = "update-idea";
export const API_IDEA_LIST = "idea-list";
export const API_IDEA_OFFERS = "get-idea-offers";
export const API_RESPOND_TO_OFFER = "respond-to-idea-offer";
export const API_IDEA_MAKE_OFFER = "save-idea-offer";
export const API_IDEA_MY_OFFERS = "my-offers";
export const API_GET_RELATED_IDEAS = "get-related-ideas";
export const API_GET_IDEA_OFFERS_CONNECTED_USERS =
  "get-idea-offers-connected-users";
export const API_GET_IDEA_OFFERS_CONNECTED_USERS_MESSAGES =
  "get-idea-offers-connected-users-messages";
export const API_GET_INVESTOR_LIST_FOR_IDEA = "get-investor-list-for-idea";
export const API_GET_INVESTMENT_PARTNERSHIP_FOR_IDEA =
  "get-investment-partnership-for-idea";
export const API_GET_INVESTOR_LIST = "get-investor-list";

export const API_FIELD_TREE = "get-fields-tree";
export const API_DOWNLOAD_NDS = "download-idea-nda";

export const API_GET_ORG = "organization-list";
export const API_GET_ORG_UPDATE = "organization-update";
export const API_GET_ORG_DETAILS = "get-single-organization";
export const API_GET_ORG_ADMINS = "org-get-admin";
export const API_GET_ORG_USERS = "org-get-user";
export const API_ORG_INVITE_ADMIN = "org-invite-admin";
export const API_ORG_INVITE_USERS = "org-invite-users";
export const API_ORG_RESEND_INVITE = "org-resend-invite";
export const API_ORG_REVOKE_ACCESS = "org-delete-invite-admin";
export const API_ORG_DELETE_USER = "org-delete-invite-user";

export const API_WIDGET_IDEAS = "widget-get-latest-ideas";
export const API_WIDGET_JOBS = "widget-get-latest-jobs";
export const API_WIDGET_STATISTICS_BY_USER_CATEGORIES =
  "get-statistics-by-user-categories";

export const API_ORG_COUNT = "organization-count";
export const API_MY_JOBS = "my-jobs";
export const API_GET_JOB_DETAILS = "get-job";
export const API_ADD_UPDATE_JOB = "add-job";
export const API_JOB_LIST = "job-list";
export const API_APPLY_JOB = "apply-jobs";

export const API_INVITE_USERS = "invite-user";
export const API_GET_ACCOUNT_SETTINGS = "get-account-settings";
export const API_UPDATE_ACCOUNT_SETTINGS = "update-account-settings";

export const API_ORG_ACCEPT_INVITE = "org-invite-accept";
export const API_ORG_REJECT_INVITE = "org-invite-reject";
export const API_NOTIFICATION_READ = "notification-read-status";

export const API_SIGN_NDA = "idea-nda";
export const API_USER_TIMELINE = "user-timeline";
export const API_FEED_REPORT_ABUSE = "feed-report-abuse";
export const API_DELETE_IDEA = "delete-idea";
export const API_DUPLICATE_IDEA = "dublicate-idea";
export const API_IDEA_DEAL_COMPLETED = "idea-mark-deal-completed";
export const API_GET_PAYMENT_INTENT = "stripe-get-intent";

export const API_GET_SUBSCRIPTION = "stripe-get-subscription";
export const API_GET_STRIPE_CUSTOMER_PORTAL = "stripe-get-customer-portal";
export const API_GET_STRIPE_CANCEL_SUBSCRIPTION = "stripe-cancel-subscription";

export const API_RECENT_CONNECTED_USERS = "recent-connected-users";
export const API_GET_RECENT_CONNECTED_USERS_MESSAGES =
  "recent-connected-users-message";

export const API_GET_PUBLIC_ORG_LIST = "get-organizations";
export const API_GET_PUBLIC_ORG_DETAILS = "get-organizations";
export const API_IDEA_START_CHAT = "start-chat-idea";
export const API_FEEDBACK = "feedback";

export const API_IDEA_OFFER_ACCEPTED_USERS = "idea-offer-accepted-user-list";
export const API_CHECK_USER_COUNTRY_STATUS = "check-user-country-status";
export const API_CONTACT_US = "contact-us";
export const API_COOKIES = "update-cookie-status";

export const API_DELETE_ACCOUNT = "delete-account";
export const API_DELETE_ACCOUNT_RECOVER = "recover-my-account";
export const API_DELETE_ACCOUNT_RECOVER_VERIFY = "recover-my-account-verify";

export const API_GET_PROFILE_COMPLETENESS = "get-profile-completeness";
export const API_GET_WHATS_NEW = "get-whats-new";

export const API_GET_IMPRESSED_LIST = "get-impressed-list";

export const API_CREATE_PROBLEM = "my-problems-create";
export const API_MY_PROBLEMS = "my-problems-list";
export const API_GET_PROBLEM = "my-problems-get-details";
export const API_UPDATE_PROBLEM = "my-problems-edit";
export const API_PROBLEM_LIST = "get-problems-list";
export const API_SIGN_PROBLEM_NDA = "problems-sign-nda";
export const API_PROBLEM_DETAILS_PUBLIC = "get-problem-details-public";
export const API_PROBLEM_START_CHAT = "start-chat-problem";
export const API_PROBLEM_MAKE_AN_OFFER = "make-offer-to-problem";
export const API_GET_RELATED_PROBLEMS = "get-related-problems";
export const API_PROBLEM_OFFERS = "get-problem-offers";
export const API_RESPOND_TO_PROBLEM_OFFER = "respond-to-problem-offer";
export const API_DELETE_PROBLEM = "my-problems-delete";
export const API_DUPLICATE_PROBLEM = "dublicate-problem";
export const API_PROBLEM_DEAL_COMPLETED = "problem-mark-deal-completed";
export const API_PROBLEM_OFFER_ACCEPTED_USERS =
  "problem-offer-accepted-user-list";
export const API_PROBLEM_MY_OFFERS = "my-problem-offers";
export const API_GET_PROBLEM_OFFERS_CONNECTED_USERS =
  "get-problem-offers-connected-users";
export const API_GET_PROBLEM_OFFERS_CONNECTED_USERS_MESSAGES =
  "get-problem-offers-connected-users-messages";
export const API_DOWNLOAD_NDS_PROBLEMS = "download-problem-nda";

export const API_GET_USER_PROFILE_PUBLIC = "get-user-profile-public";
export const API_GET_IDEA_DETAILS_PUBLIC = "get-idea-details-public";
export const API_IDEA_OPT_INOUT = "idea-opt-in-out";

export const API_GET_ORG_CURRENT_USERS_LIST = "get-current-users-of-org";
export const API_ASSIGN_IDEAS_TO_ORG_USERS = "idea-assign";

export const API_GET_IDEA_DETAILS_BY_PATENT_NUMBER =
  "get-idea-details-by-patent-number";

export const API_GET_DISCIPLINES_BY_FIELD_ID = "get-disciplines-by-field-id";
export const API_FEED_DELETE = "delete-post";
export const API_OVERVIEW = "get-overview"

export const API_RUN_AI_CHECK = "idea-check-ai-status"
export const API_RUN_AI = "idea/run-ai"
export const API_RUN_AI_NEXT = "idea/run-ai-next"

export const API_MAKE_PAYMENT = "stripe-make-payment"
export const API_GET_DISCOUNT = "get-std-discount"

export const API_GET_INVESTOR_DETAILS_AIHUB = "idea/get-investor-details"