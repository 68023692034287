import React from "react";
import { NavLink } from "react-router-dom";

const FooterInfo = () =>{

    return(
        <>© Sigma Tech Solutions B.V. All rights reserved.</>
    );

};

export default FooterInfo;