import React, { Component } from "react";
import jquery from "jquery";
import '../assets/css/bootstrap-datepicker.css'
import '../assets/js/bootstrap-datepicker.js'

const df = {
    MY : "mm-yyyy",
    DMY : "dd-mm-yyyy"
}

const mode = {
    day: "days",
    month: "months",
    year: "years"
}

class DatePicker extends Component{

    placeholder = "";
    name = "";
    required = false;
    value = ""
    format = df.DMY
    startView = mode.day
    mode = this.startView
    allowPastDate = true
    allowFutureDate = true
    withLabel = false
    
    constructor(props){
        super();
        this.placeholder = props.placeholder;
        this.name = props.name;
        this.required = props.required;
        this.value = props.value;
        this.format = props.format!=undefined ? props.format : this.format
        this.startView = props.startView!=undefined ? props.startView : this.startView
        this.mode = props.mode!=undefined ? props.mode : this.mode
        this.allowPastDate = props.allowPastDate!=undefined ? props.allowPastDate : true
        this.allowFutureDate = props.allowFutureDate!=undefined ? props.allowFutureDate : true
        this.withLabel = props.withLabel!=undefined ? props.withLabel : false

    }

    componentDidMount(){

        const this_var = this

        let options = {
            format: this_var.format,
            dateFormat: this_var.format,
            startView: this_var.startView,
            minViewMode: this_var.mode,
            autoclose: true
        }

        if(!this_var.allowPastDate)
            options.startDate = new Date()

        if(!this_var.allowFutureDate)
            options.endDate = new Date()

        jquery(this_var.refs['date-picker']).datepicker(options).on("change", function(e){
            this_var.props.onChange(
                e,
                jquery(e.currentTarget).find("input").val()
            )
        });
    }

    render(){

        return(
            this.withLabel ? (
                <>
                    <label style={{display:"block", width:"100%"}}>{this.props.children || "Date"}</label>
                    <div ref='date-picker' className="form-group date no-margin">
                        <input type="text" value={this.props.value} name={this.name} required={this.required} className="form-control" />
                        <span className="input-group-addon">
                            <i className="glyphicon glyphicon-th"></i>
                        </span>
                        <i className="mtrl-select"></i>
                    </div>
                </>
            ) : (
                <div ref='date-picker' className="form-group date">
                    <input type="text" value={this.props.value} name={this.name} required={this.required} className="form-control" />
                    <label className="control-label" style={{display:"block", width:"100%"}}>{this.props.children || "Date"}</label>
                    <span className="input-group-addon">
                        <i className="glyphicon glyphicon-th"></i>
                    </span>
                    <i className="mtrl-select"></i>
                </div>
            )
            
        );
    }
};

export {
    DatePicker, df, mode
};