import React from "react";
import { range } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";

const generateRand = (arg) => {
    return Math.floor(Math.random() * arg);
  };
  
export const getRandomHeight = () => generateRand(100);

export const makeItem = (startOffset = 0) => ({
    id: startOffset,
    firstName: "abc",
    lastName: "xyz",
    height: getRandomHeight()
  });

  export const makeItems = (startOffset = 0, size = 15) =>
  range(startOffset, size).map((idx) => ({
    id: idx,
    firstName: "abc",
    lastName: "xyz",
    height: getRandomHeight()
  }));

export const ReverseScrollBar = ({ image, items, fetch, innerRef, canLoadMore, ...props }) =>{

    return (
        <div
          id={props.id ? props.id : "scrollableDiv"}
          style={{
            height: props.expanded ? 550 : 300,
            overflow: "auto",
            display: "flex",
            flexDirection: "column-reverse"
          }}
          ref={innerRef}
        >
          <InfiniteScroll
            dataLength={items.length}
            next={fetch}
            style={{ display: "flex", flexDirection: "column-reverse" }} //To put endMessage and loader to the top.
            inverse={true}
            hasMore={canLoadMore}
            endMessage={items.length>10 ? <div className="text-center"><br />Messages not found</div> : ""}
            loader={ items.length>=10 ? <div className="loader text-center">Loading ...</div> : ""}
            scrollableTarget={props.id ? props.id : "scrollableDiv"}
          >
                <>
                  {!props.isLoading && items.length==0 ? (<div className="text-center"><br />Type in the textbox below to start chat</div>): ""}
                  {props.children}
                </>
            
          </InfiniteScroll>
        </div>
      );

}
