import React, { useEffect, useRef } from "react";
import LinkToolTip from "../libs/LinkToolTip";
import { menu } from "../private/Consts";

const LeftSideBar = () =>{

    return(
        <div className="fixed-sidebar left">
            <div className="menu-left">
                <ul className="left-menu">
                    
                    {menu.map( (item, index)=>(
                        
                        <li key={index}>
                            <LinkToolTip name={item.title} route={item.route} icon={item.icon} />
                        </li>
                    ))}

                </ul>
            </div>
        </div>
    );

};

export default LeftSideBar;