import { useEffect } from "react";
import { NavLink, Outlet, useParams } from "react-router-dom";

const SearchCommonLayout = () =>{

    const {keyword} = useParams()
    
    return(
        <section>
            <div className="gap gray-bg">
                <div className="container">
                    <div className="row merged20" id="page-contents">
                        <div className="col-lg-12">
                            <div className="central-meta">
                                
                                <div className="frnds">
                                    <ul className="nav nav-tabs">
                                        <li className="nav-item">
                                            <NavLink title="" data-ripple="" data-toggle="tab" to={`/search/users/${keyword}`}>Users</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink title="" data-ripple="" data-toggle="tab" to={`/search/ideas/${keyword}`}>Ideas</NavLink>
                                        </li>
                                        {/* <li className="nav-item">
                                            <NavLink title="" data-ripple="" data-toggle="tab" to={`/search/jobs/${keyword}`}>Jobs</NavLink>
                                        </li> */}
                                    </ul>

                                    <div style={{padding:"30px 20px"}} className="tab-content">
                                        
                                        <Outlet />
                                        
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default SearchCommonLayout;