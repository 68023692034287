import React, { useEffect, useState } from "react";

import { API_URL, API_WIDGET_STATISTICS_BY_USER_CATEGORIES } from "../../../services/ApiEndpoints";
import axios from "axios";
import authHeader from "../../../services/auth-header";
import { Link } from "react-router-dom";

const StatisticsByUserCategoryWidget = () =>{

    const [isLoading, setIsLoading] = useState(true)

    const [data, setData] = useState([])

    useEffect(()=>{
        
        getData()

    }, []);

    const getData = () =>{

        setIsLoading(true)

        axios
        .get(API_URL + API_WIDGET_STATISTICS_BY_USER_CATEGORIES,{
            headers: authHeader()
        })
        .then((response)=>{
            
            if(response.status==200){

                const json = response.data;

                if(json.status=="RC200"){

                    setData(json.data)
                
                }

            }

            setIsLoading(false)

        })
        .catch((error)=>{
            setIsLoading(false)
        });

    };

    return(
        
        isLoading ? (
            <p>loading....</p>
        ) : (
            <>
                <div className="widget ">
                    <h4 className="widget-title">Whats new in {data.primary_field_name}</h4>
                    <div className="row">
                        <div className="col-12 d-flex flex-column justify-content-center align-items-start">
                            
                            {data.primary_field_name && (
                                <>
                                    <WidgetItem to={`/ideas?field=${data.primary_field_id}`} icon="ic_idea.png" label={`${data.primary_idea_count} new ideas shared in last 7 days`} />

                                    {/* <WidgetItem to={`/jobs?field=${data.primary_field_id}`} icon="ic_job-search.png" label={`${data.primary_job_count} new jobs shared in last 7 days`} /> */}

                                    <WidgetItem to={`/ideas?investments=1&field=${data.primary_field_id}`} icon="ic_investment.png" label={`${data.primary_investment_count} new investment opportunities shared in last 7 days`} />

                                    <WidgetItem to={`/ideas?collaboration=1&field=${data.primary_field_id}`} icon="ic_handshake.png" label={`${data.primary_collaboration_count} new collaboration opportunities shared in last 7 days`} />
                                </>
                            )}
                            
                        </div>

                    </div>
                </div>

                {data.secondary_field_name && (
                    <div className="widget ">
                        <h4 className="widget-title">Whats new in {data.secondary_field_name}</h4>
                        <div className="row">
                            <div className="col-12 d-flex flex-column justify-content-center align-items-start">
                                <WidgetItem to={`/ideas?field=${data.secondary_field_id}`} icon="ic_idea.png" label={`${data.secondary_idea_count} new ideas shared  in last 7 days`} />

                                <WidgetItem to={`/jobs?field=${data.secondary_field_id}`} icon="ic_job-search.png" label={`${data.secondary_job_count} new jobs shared in last 7 days`} />

                                <WidgetItem to={`/ideas?investments=1&field=${data.secondary_field_id}`} icon="ic_investment.png" label={`${data.secondary_investment_count} new investment opportunities shared in last 7 days`} />

                                <WidgetItem to={`/ideas?collaboration=1&field=${data.secondary_field_id}`} icon="ic_handshake.png" label={`${data.secondary_collaboration_count} new collaboration opportunities shared in last 7 days`} />
                            </div>
                        </div>
                    </div>
                )}
                        
            </>
        )
        
    );

};

const WidgetItem = (props) =>{

    return(
        <Link to={props.to || "/overview"}>
            <div className="row align-items-center p-3">
                <div className="col-3 text-center">
                    <div className="random-alert-icon-bg">
                        {props.icon && (
                            <img src={`/assets/images/${props.icon}`}  alt="File User" />
                        )}
                    </div>
                </div>
                <div className="col-9 random-alert-text  pl-0">{props.label || ""}</div>
            </div>
        </Link>
    )
}

/*<div className="widget ">
                    <div className="row">
                        <div className="col-12 d-flex flex-column justify-content-center align-items-start">
                            
                            {data.primary_field_name && (
                                <>
                                    <WidgetItem to="/ideas" icon="light-bulb-icon.svg" label={`${data.primary_idea_count} new ideas shared in the field of ${data.primary_field_name} in last 7 days`} />

                                    <WidgetItem to="/jobs" icon="file-user-icon.svg" label={`${data.primary_job_count} new jobs shared in the field of ${data.primary_field_name} in last 7 days`} />

                                    <WidgetItem to="/ideas" icon="leaf-icon.svg" label={`${data.primary_investment_count} new investment opportunities shared in the field of ${data.primary_field_name} in last 7 days`} />

                                    <WidgetItem to="/ideas" icon="leaf-icon.svg" label={`${data.primary_collaboration_count} new collaboration opportunities shared in the field of ${data.primary_field_name} in last 7 days`} />
                                </>
                            )}
                            
                        </div>

                    </div>
                </div>

                {data.secondary_field_name && (
                    <div className="widget ">
                        <div className="row">
                            <div className="col-12 d-flex flex-column justify-content-center align-items-start">
                                <WidgetItem to="/ideas" icon="light-bulb-icon.svg" label={`${data.secondary_idea_count} new ideas shared in the field of ${data.secondary_field_name} in last 7 days`} />

                                <WidgetItem to="/jobs" icon="file-user-icon.svg" label={`${data.secondary_job_count} new jobs shared in the field of ${data.secondary_field_name} in last 7 days`} />

                                <WidgetItem to="/ideas" icon="leaf-icon.svg" label={`${data.secondary_investment_count} new investment opportunities shared in the field of ${data.secondary_field_name} in last 7 days`} />

                                <WidgetItem to="/ideas" icon="leaf-icon.svg" label={`${data.secondary_collaboration_count} new collaboration opportunities shared in the field of ${data.secondary_field_name} in last 7 days`} />
                            </div>
                        </div>
                    </div>
                )}*/

export default StatisticsByUserCategoryWidget;