import { Link, NavLink, Outlet, useParams } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component'
import { API_GET_ORG_USERS, API_ORG_DELETE_USER, API_ORG_INVITE_USERS, API_ORG_RESEND_INVITE, API_URL } from "../../../services/ApiEndpoints";
import axios from "axios";
import authHeader from "../../../services/auth-header";
import { useEffect, useState } from "react";
import { useRef } from "react";
import ExTagsInput from "../../libs/ExTagsInput";
import ExtButton from "../../libs/ExtButton";
import { toast } from "react-toastify";
import { confirm } from "react-confirm-box";
import { confirmDialogOptions } from "../../../helpers/configs";

const OrgUsers = () => {

    let {id} = useParams()
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([])

    const getData = () =>{

        setIsLoading(true)

        axios
            .get(API_URL + API_GET_ORG_USERS +"/"+ id,{
                    headers: authHeader()
                }
            )
            .then((response)=>{
                
                if(response.status==200){

                    const json = response.data;

                    if(json.status=="RC200"){

                        var resp = json.data;

                        setData(resp)
                    }

                }

                setIsLoading(false)

            })
            .catch((error)=>{
                setIsLoading(false)
            });

    }

    useEffect(()=>{
        getData()
    }, [])

    let emails = useRef()

    const [isLoadingSend, setIsLoadingSend] = useState(false)
    const [hasError, setHasError] = useState(false) 
    const [msg, setMsg] = useState("")
    

    const submit_data = () =>{
        const val = emails.current.get_val();

        if(isLoading) return;
        
        if(val.length>0){
            setIsLoadingSend(true)
            setHasError(false)
            setMsg("")

            var formdata = new FormData()
            formdata.append("org_id", id);
            formdata.append("email", val);
    
            axios.post(API_URL + API_ORG_INVITE_USERS, formdata,{
                headers:authHeader()
            })
            .then((responsex) => {
    
                setIsLoadingSend(false)
    
                if(responsex.data.status=="RC200"){
                    setHasError(false)
                }else{
                    setHasError(true)
                }
                getData()

                setMsg(responsex.data.message)
    
                return responsex.data;
            });
        }
    }

  return (
    <>

        <div className="central-meta">
            <div className="editing-info">
                <div className="friend-info">
                    <h6>Invite user(s)</h6>

                    <div className="row">
                        <div className="col-md-8">
                            <label>Enter multiple email address (comma separated)</label>
                            <ExTagsInput ref={emails} />
                        </div>
                        <div className="col-md-3"><br />
                            <ExtButton isLoading={isLoadingSend} onClick={submit_data} className="mtr-btn">
                                <span>Invite</span>
                            </ExtButton>
                        </div>
                    </div>

                    {msg && (
                        <div className="col-md-12"><br />
                            <div className={ hasError ? "alert alert-danger mt-10" : "alert alert-success mt-10" } role="alert">
                                { msg && msg.split("\n").map((str, index) => <p key={`px-${index}`}>{str}</p>)}
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </div>
        
        { isLoading ? (<p>Loading...</p>) : (
            
            <div className="central-meta">
                <div className="editing-info">
                    <div className="friend-info">
                        <div className="table-responsive">
                            <table className="table table-bordered" width="100%">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Status</th>
                                        <th>#</th>
                                    </tr>
                                </thead>

                                <tbody>
                                        {data.length>0 ? (
                                            data.map((user, index)=>(
                                                <SingleView onRefresh={()=>{
                                                    getData()
                                                }} org_id={id} index={index} user={user} />
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="4">No User(s) invited</td>
                                            </tr>
                                        )}
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )}

    </>
  );
};

const SingleView = (props) => {

    const {user, index, org_id} = props

    const [isLoadingResend, setIsLoadingResend] = useState(false)
    const [isLoadingRevoke, setIsLoadingRevoke] = useState(false)

    const resendInvite = (e, idx) =>{
        
        if(isLoadingResend) return;
        
        setIsLoadingResend(true)

        var formdata = new FormData()
        formdata.append("id", idx);
        formdata.append("org_id", org_id);

        axios.post(API_URL + API_ORG_RESEND_INVITE, formdata,{
            headers:authHeader()
        })
        .then((responsex) => {

            setIsLoadingResend(false)

            const msg = responsex.data.message

            if(responsex.data.status=="RC200"){
                toast.success(msg)
            }else{
                toast.error(msg)
            }

            return responsex.data;
        });
    }

    const deleteUser = (e, idx) =>{
        
        if(isLoadingRevoke) return;
        
        setIsLoadingRevoke(true)

        var formdata = new FormData()
        formdata.append("id", idx);
        formdata.append("org_id", org_id);

        axios.post(API_URL + API_ORG_DELETE_USER, formdata,{
            headers:authHeader()
        })
        .then((responsex) => {

            setIsLoadingRevoke(false)

            const msg = responsex.data.message

            if(responsex.data.status=="RC200"){
                toast.success(msg)

                props.onRefresh()
            }else{
                toast.error(msg)
            }

            return responsex.data;
        });
    }

    const onButtonClick = async (e, message) => {
        
        const result = await confirm(`Are you sure do you want to ${message}?`, confirmDialogOptions)

        if (result) {
            deleteUser(e, user.id)
        }

    }

    return(
        <tr key={user.id}>
            <td>{(index+1)}</td>
            <td>{user.name}</td>
            <td>{user.email}</td>
            <td>
                {user.status==0 ? (
                    <span className="badge badge-warning">Pending</span>
                ) : (user.status==1 ? (
                    <span className="badge badge-success">Active</span>
                ) : (
                    <span className="badge badge-danger">Rejected</span>
                ))}
            </td>
            <td>

                {user.status==0 || user.status==2 ? (
                    <>
                        <ExtButton type="link" isLoading={isLoadingResend} 
                            onClick={ (e) => resendInvite(e, user.id) } className="btn more-action">
                            Resend request
                        </ExtButton>
                    </>
                ) : ""}

                {user.status==0 ? (
                    <>
                        <ExtButton type="link" isLoading={isLoadingRevoke} 
                            onClick={(e)=>onButtonClick(e, "delete request")} className="btn btn-theme more-action">
                            Delete Request
                        </ExtButton>
                    </>
                ):(
                    <ExtButton type="link" isLoading={isLoadingRevoke} 
                        onClick={(e)=>onButtonClick(e, "remove user")} className="btn btn-theme more-action">
                        Remove User
                    </ExtButton>
                )}
            </td>
        </tr>
    )

}


export default OrgUsers;