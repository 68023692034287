import jquery from 'jquery'
import "bootstrap"
import OwlCarousel from 'react-owl-carousel';

const ExCarousel = (props) => {

    const resp = {0:{
        items:1
    }, 600:{
        items:4
    }}

    return(
        <OwlCarousel loop={props.loop || false} responsive={resp} items={props.len || 4} nav={props.nav || false} margin={props.gap || 15} dots={props.dots || false} className="pst-carousel">
            {props.children}
        </OwlCarousel>
    )

}

export default ExCarousel;