import { Link } from "react-router-dom";

const ExtButton = (props) => {

  const className = (props.className != undefined && props.className != "") ? props.className : ""
  const isDisabled = props.isLoading != undefined && props.isLoading
  const type = props.type != undefined && props.type != "" ? props.type : "button"
  const to = props.to != undefined && props.to != "" ? props.to : ""
  const directLink = props.directLink != undefined && props.directLink != "" ? props.directLink : false
  
  const logding = props.showLoading == undefined ? (
    props.isLoading || false
  ) : (
    props.isLoading && props.showLoading || false
  )

  return (

    type=="link" ? (
      <a href={to} onClick={ (e)=> {
        
        if(!directLink){
          e.preventDefault()
        }

        props?.onClick(e)
      }} className={className + ` ` + (isDisabled ? "off" : "" )}>
          {logding && (
              <i className="fa fa-spinner fa-spin mr-1"></i>
            )}
          {props.children}
      </a>
    ) : (
        <button ref={props.ref} style={{display: props.none ? "none" : ""}} onClick={props?.onClick} type={type} className={className} disabled={isDisabled} >
            {logding && (
              <i className="fa fa-spinner fa-spin mr-1"></i>
            )}
            {props.children}
        </button>
    )

  );

};

export default ExtButton;
