import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    SET_MESSAGE,
  } from "./types";

import AuthService from "../services/auth.service";

export const register = (firstName,lastName, registerAs, email, password, confPassword, phone, dialCode, role, dob, termsAndConditions, receiveNews, captchaToken) => (dispatch) => {
    
    return AuthService.userRegister(firstName, lastName, registerAs, email, password, confPassword, phone, dialCode, role, dob, termsAndConditions, receiveNews, captchaToken)
        .then(
            (data)=>{
                
                if(data.status=="RC200"){
                    dispatch({
                        type: SET_MESSAGE,
                        payload: data.message
                    });
                }else{
                    dispatch({
                        type: SET_MESSAGE,
                        payload: data.message
                    });
                }

                return Promise.resolve(data);

            },(error)=>{
                const message =
                (error.response &&
                error.response.data &&
                error.response.data.message) ||
                error.message ||
                error.toString();
    
                dispatch({
                    type: LOGIN_FAIL
                });
    
                dispatch({
                    type: SET_MESSAGE,
                    payload: message
                });
                
                return Promise.resolve();
            });
};

export const login = (username, password, captchaToken) => (dispatch) => {
    return AuthService.login(username, password, captchaToken).then(
        (data) =>{
            
            if(data.status=="RC200"){

                localStorage.setItem("user", JSON.stringify(data.data));

                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: { user: data.data },
                });
            }else{
                dispatch({
                    type: LOGIN_FAIL
                });
                dispatch({
                    type: SET_MESSAGE,
                    payload: data.message
                });
            }

            return Promise.resolve();
        },
        (error) =>{
            const message =
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString();

            dispatch({
                type: LOGIN_FAIL
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message
            });
            
            return Promise.resolve();
        }
    );
};

export const logout = (redirectToHome) => (dispatch) =>{
    AuthService.logout(redirectToHome);
    dispatch({
        type: LOGOUT
    });
};