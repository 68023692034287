import React, { Component, useState } from "react";
import {Link, Outlet, Route, Routes} from 'react-router-dom'
import { useSelector } from "react-redux";
import Select2 from "../../libs/Select2";
import { render } from "@testing-library/react";
import axios from "axios";
import authHeader from "../../../services/auth-header";
import { API_UPDATE_LANGUAGES, API_URL } from "../../../services/ApiEndpoints";
import ExtButton from "../../libs/ExtButton";

class Languages extends Component{
    
    state = {
        selectProficiency: [
            {"id":0,"proficiency":"A1"},
            {"id":1,"proficiency":"A2"},
            {"id":2,"proficiency":"B1"},
            {"id":3,"proficiency":"B2"},
            {"id":4,"proficiency":"C1"},
            {"id":5,"proficiency":"C2"}
        ],
        languages : [],
        isLoading:false,
        msg:"",
        hasError:false,
    }

    constructor(props){
        super()

        this.state.languages = props.data;

    }

    addMoreLanguage = (e) =>{
        e.preventDefault()

        this.setState({
            languages: this.state.languages.concat({id:-1, name:"", proficiency:-1})
        })

    }

    handleChange = (index, e) =>{

        let languages = this.state.languages;
        languages[index][e.target.name] = e.target.value;
        
        this.setState({ languages });

    }

    handleRemove = (index, e) => {
        e.preventDefault()

        let languages = this.state.languages;
        languages.splice(index, 1)

        this.setState({ languages })

    }

    handleSubmit = (e) => {
        e.preventDefault()

        var error = 0

        this.state.languages.map( (item, index) => {
            if(item.proficiency==-1)
                error++
        } )

        if(error>0){
            this.setState({hasError: true, msg:"Please select proficiency"})
            return
        }

        this.setState({isLoading: true, msg:""})

        var formdata = new FormData()

        this.state.languages.map((item, index) => {
            formdata.append("name["+ index +"]", item.name)
            formdata.append("proficiancy["+ index +"]", item.proficiency)
            formdata.append("id["+ index +"]", item.id)
        })


        axios.post(API_URL + API_UPDATE_LANGUAGES, formdata,{
            headers:authHeader()
        })
        .then((responsex) => {

            this.setState({isLoading: false})

            if(responsex.data.status=="RC200"){
                this.setState({hasError: false})
            }else{
                this.setState({hasError: true})
            }

            this.setState({msg: responsex.data.message})

            return responsex.data;
        });

    }


    render(){
        return(
            <div className="central-meta" id="languages">
                <div className="editing-info">
                    <h5 className="f-title">Languages</h5>
    
                    <form onSubmit={this.handleSubmit} method="post">
                        
                        { this.state.languages.length>0 ? (
                            this.state.languages.map((item, index) => { return(

                                <div className="row" key={index} >
                                    <div className="col-6 col-md-5">
                                        <div className="form-group ">
                                            <input type="text" name="name" id={"lng-name-"+ index} value={ item.name || "" } onChange={ (e) =>{
                                                this.handleChange(index, e)
                                            } } required="required" />
                                            <label className="control-label" htmlFor={"lng-name-"+ index}>Language Name *</label>
                                            <i className="mtrl-select"></i>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-5">
                                        <div className="form-group">
                                            <Select2 onSelect={ (e) =>{
                                                this.handleChange(index, e)
                                            } } value={ item.proficiency } placeholder={"Select Proficiency *"} keys={"proficiency"} data={this.state.selectProficiency} />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="text-center form-group ">
                                            <a onClick={ (e) =>{ this.handleRemove(index, e) } } className="btn btn-link text-danger"><i className="fa fa-close"></i></a>
                                        </div>
                                    </div>
                                </div>

                            )})
                        ) : (
                            <p>Please select language you know</p>
                        )}

                        <div className="submit-btns col-md-12 text-right">
                            <br />
                            <a onClick={this.addMoreLanguage} ><i className="fa fa-plus-circle"></i> Add</a>
                        </div>
    
                        {this.state.msg && (
                            <div className={ this.state.hasError ? "alert alert-danger mt-10" : "alert alert-success mt-10" } role="alert">
                                {this.state.msg}
                            </div>
                        )}

                        <div className="submit-btns">
                            <ExtButton isLoading={this.state.isLoading} className="mtr-btn" type="submit">
                                <span>Update</span>
                            </ExtButton>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
};

export default Languages;