import jquery from 'jquery'
import '../../assets/js/tooltip'
import "bootstrap"
import { Component } from 'react';

class ExToolTip extends Component{

    constructor(props){
        super();
    }

    componentDidMount(){
        jquery(this.refs['tooltip']).tooltip();
    }

    componentDidUpdate(){
        jquery(this.refs['tooltip']).tooltip().attr('data-original-title', this.props.name);
    }

    render(){
        return(
            <div className={`ex-tooltip ${this.props.className || ""}`} ref='tooltip' data-placement={this.props.placement || "right"} data-title={this.props.name}>
                {this.props.children ? this.props.children : (
                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                )}
            </div>
        )
    }

}

export default ExToolTip;