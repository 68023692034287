import { useEffect } from "react";

const LoaderWrapperView = (props) => {
  return props.isLoading ? (
    <p className="text-center">loading...</p>
  ) : (
    props.children || ""
  );
};

export default LoaderWrapperView;
