import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, Outlet, useParams } from "react-router-dom";
import { API_GET_IDEA, API_URL } from "../../../services/ApiEndpoints";
import authHeader from "../../../services/auth-header";
import Popups from "../../libs/Popups";
import TransPopup from "../../libs/TransPopup";
import { print_idea_status } from "../Consts";
import HowItWorks from "./HowItWorks";

const IdeaView = () => {
  let { slug } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState({});

  const getDetails = () => {
    setIsLoading(true);

    axios
      .get(API_URL + API_GET_IDEA + "/" + slug, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          const json = response.data;

          if (json.status == "RC200") {
            var data = json.data;

            setData(data);
          }
        }

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getDetails();
  }, []);

  const [allowReload, setAllowReload] = useState(0);

  useEffect(() => {
    getDetails();
  }, [allowReload]);

  const popup = useRef();

  return (
    <section>
      <div className="gap gray-bg">
        <div className="container">
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            <div className="row">
              <div className="col-lg-12">
                <div className="row merged20" id="page-contents">
                  <div className="col-md-12">
                    <div className="central-meta">
                      <div className="row">
                        <div className="col-md-9">
                          <h3>{data.idea_title}</h3>
                        </div>
                        <div className="col-md-3">
                          <TransPopup
                            ref={popup}
                            label="How it works?"
                            className="add-butn no-text-transform"
                            title=""
                            button={false}
                            dClassName="modal-lg modal-transp"
                            btnCancel={false}
                            btnCancelLabel="Close"
                            onSaveClickListener={(e) => {
                              popup.current.close();
                            }}
                          >
                            <HowItWorks
                              hide={(e) => {
                                popup.current.close();
                              }}
                            />
                          </TransPopup>
                        </div>
                      </div>
                      <h6 style={{ color: "#f05a24" }} className="mb-3">
                        {data.unique_id}
                      </h6>
                      <p className="m-0">
                        {data.introduction &&
                          data.introduction
                            .split("\n")
                            .map((str, index) => (
                              <p key={`px-${index}`}>{str}</p>
                            ))}
                      </p>
                      <em>Idea Created: {data.date}</em>
                      <br />
                      <em>Last Updated: {data.date_updated}</em>
                      <br />
                      Status: &nbsp; {print_idea_status(data)}
                      <div className="frnds mt-4">
                        <ul className="nav nav-tabs nav-tabs-heiglights">

                          {data.is_published === 1 ? (
                            <li className="nav-item heiglighted">
                              <NavLink to={"ai-hub"}>SCIONS AI Hub</NavLink>
                            </li>
                          ) : ""}
                          
                          {/* {data.is_published === 1 ? (
                            <li className="nav-item">
                              <NavLink to={"explore-investment"}>
                                Explore Investment
                              </NavLink>
                            </li>
                          ) : (
                            ""
                          )}
                          {data.is_published === 1 ? (
                            <li className="nav-item">
                              <NavLink to={"explore-partnership"}>
                                Explore Partnership
                              </NavLink>
                            </li>
                          ) : (
                            ""
                          )} */}
                          
                          <li className="nav-item">
                            <NavLink to={"responses"}>Idea Responses</NavLink>{" "}
                            <span>{data.total_offers}</span>
                          </li>
                          <li className="nav-item">
                            <NavLink to={"chat"}>Chat(s)</NavLink>{" "}
                            <span>{data.total_chats}</span>
                          </li>
                          <li className="nav-item">
                            <NavLink to={"edit"}>Edit Idea Details</NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink to={"details"}>Idea Details</NavLink>
                          </li>
                        </ul>

                        <div className="gap gray-bg">
                          <div className="container">
                            <Outlet context={[allowReload, setAllowReload]} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default IdeaView;
