import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { API_ASSIGN_IDEAS_TO_ORG_USERS, API_GET_ORG_CURRENT_USERS_LIST, API_URL } from "../../../services/ApiEndpoints";
import authHeader from "../../../services/auth-header";
import ExtButton from "../../libs/ExtButton";
import LoaderWrapperView from "../../libs/LoaderWrapperView";
import Select2 from "../../libs/Select2";

const AssignIdea = (props) => {

    const [data, setData] = useState([{title:"", patentId:"", userId:-1}])

    const handleChangeTitle = (index, e) => {
        var dt = [...data]
        dt[index]["title"] = e.target.value
        setData(dt)
    }

    const handleChangePatentId = (index, e) =>{
        var dt = [...data]
        dt[index]["patentId"] = e.target.value
        setData(dt)
    }

    const handleChangeUserId = (index, e) =>{
        var dt = [...data]
        dt[index]["userId"] = e.target.value
        setData(dt)
    }

    const addNewOne = () => {
        setData(data.concat({title:"", patentId:"", userId:-1}))
    }

    const removeOne = (index) =>{
        var dt = [...data]
        dt.splice(index, 1)
        setData(dt)
    }

    const [isLoading, setIsLoading] = useState(false)
    const [orgUsersList, setOrgUsersList] = useState([])

    const getOrgUserList = () =>{

        setIsLoading(true)

        axios
        .get(API_URL + API_GET_ORG_CURRENT_USERS_LIST,{
            headers: authHeader()
        })
        .then((response)=>{
            
            setIsLoading(false)

            if(response.status==200){

                const json = response.data;

                setOrgUsersList(json.data)

                setIsLoading(false)

            }

        })
        .catch((error)=>{
            setIsLoading(false)
        });
    }

    const [isLoadingUpload, setIsLoadingUpload] = useState(false)

    const submitData = () =>{

        setIsLoading(true)

        var formdata = new FormData()

        let ind = 0;

        data.map((val, index)=>{
            formdata.append(`title[${ind}]`, val.title);
            formdata.append(`patented_number[${ind}]`, val.patentId);
            formdata.append(`user_id[${ind}]`, val.userId);

            ind++;
        })


        axios.post(API_URL + API_ASSIGN_IDEAS_TO_ORG_USERS, formdata,{
            headers:authHeader()
        })
        .then((responsex) => {

            setIsLoading(false)

            if(responsex.data.status=="RC200"){
                toast.success(responsex.data.message)
                setData([{title:"", patentId:"", userId:-1}])
            }else{
                toast.error(responsex.data.message)
            }

            return responsex.data;
        });

    }

    useEffect(()=>{
        getOrgUserList()
    }, [])

    return (
        <section>
            <div className="gap gray-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <LoaderWrapperView isLoading={isLoading}>
                                <div className="central-meta">
                                    <div className="row">
                                        <div className="col-md-9">
                                            <h3>Assign Ideas to Users</h3>
                                        </div>

                                        <div className="col-md-12">
                                            <br />
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Idea Title</th>
                                                        <th>Patent Id</th>
                                                        <th>Select User</th>
                                                        <th>#</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data.map((val, key)=>(
                                                        <tr>
                                                            <td>
                                                                <div className="form-group no-margin">
                                                                    <input placeholder="Enter title" name="name" value={val.title || ""} onChange={
                                                                        (e) => handleChangeTitle(key, e)
                                                                    } type="text" id={key + "-idea-"+ key} required="required" />
                                                                    <i className="mtrl-select"></i>
                                                                </div>  
                                                            </td>
                                                            <td>
                                                                <div className="form-group no-margin">
                                                                    <input placeholder="Enter Patent Id" name="ptid" value={val.patentId || ""} onChange={
                                                                        (e) => handleChangePatentId(key, e)
                                                                    } type="text" id={key + "-ideaptid-"+ key} required="required" />
                                                                    <i className="mtrl-select"></i>
                                                                </div>  
                                                            </td>
                                                            <td>
                                                                <Select2 value={val.userId} onSelect={ (e) => {
                                                                    handleChangeUserId(key, e)
                                                                } } name="role" keys={"full_name"} data={orgUsersList} directValues={false} placeholder={"Select User *"} />
                                                            </td>
                                                            <td>
                                                                <button onClick={(e)=>removeOne(key)} type="button" className="btn btn-sm btn-danger"><i className="fa fa-close"></i></button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>


                                        </div>

                                        <div className="col-md-12 text-right">
                                            <button type="button" onClick={addNewOne} style={{top:0}} className="btn btn-sm btn-success">Add New Idea</button>
                                        </div>
                                    </div>

                                    <div className="submit-btns mb-2">
                                        
                                        {data.length!=0 && (
                                            <ExtButton onClick={submitData} isLoading={isLoadingUpload} type="button" className="mtr-btn">
                                                <span>Assign Ideas</span>
                                            </ExtButton>
                                        )}
                                    </div>

                                </div>
                            </LoaderWrapperView>
                        </div>
                        
                    </div>
                </div>
            </div>

        </section>
    );

};

export default AssignIdea;
