import React from "react";
import { useSelector } from "react-redux";
import LatestIdeasWidget from "../widgets/LatestIdeasWidget";
import LatestJobsWidget from "../widgets/LatestJobsWidget";
import FooterLinks from "../../layouts/FooterLinks";
import InviteUsersWidget from "../widgets/InviteUsersWidget";
import StatisticsByUserCategoryWidget from "../widgets/StatisticsByUserCategoryWidget";

const RightPanel = () =>{

    const { user: currentUser } = useSelector((state) => state.auth);

    return(
        
        <aside className="sidebar static right d-none d-md-block d-sm-block">
            
            <InviteUsersWidget />

            <StatisticsByUserCategoryWidget />

            <LatestIdeasWidget />

            {/* <LatestJobsWidget /> */}

            <div className="widget stick-widget ft-links-widget">
                <p>
                    <FooterLinks />
                </p>
            </div>


        </aside>
        
    );
};

export default RightPanel;