export const menu = [
  {
    route: "/scionsline",
    title: "SCIONS Line",
    icon: "fa fa-timeline",
  },
  {
    route: "/profile/scions/my-scions",
    title: "My SCIONS",
    icon: "far fa-handshake",
  },
  // {
  //   route: "/jobs",
  //   title: "Jobs",
  //   icon: "fa fa-suitcase",
  // },
  {
    route: "/ideas",
    title: "Ideas",
    icon: "ti-light-bulb",
  },
  // {
  //   route: "/problems",
  //   title: "Eureka Space",
  //   icon: "fa fa-puzzle-piece",
  // },
  {
    route: "/organizations-list",
    title: "Organizations",
    icon: "far fa-building",
  },
  {
    route: "/angelinvestor",
    title: "Angel Investors",
    icon: "fa fa-thin fa-hand-holding-dollar",
  },
  {
    route: "/notifications",
    title: "Notifications",
    icon: "ti-bell",
  },
  {
    route: "/profile/account-settings",
    title: "Account Settings",
    icon: "ti-panel",
  },
  {
    route: "/logout",
    title: "Logout",
    icon: "ti-power-off",
  },
];

export const typeOfOrganization = [
  { name: "University", id: 0 },
  { name: "Company", id: 1 },
  { name: "Research institute", id: 2 },
  { name: "Incubator", id: 3 },
  { name: "Investment firm", id: 4 },
];

export const organizationSize = [
  { name: "0-50", id: 0 },
  { name: "50-500", id: 1 },
  { name: "500-5000", id: 2 },
  { name: "5000+", id: 3 },
];

export const NotificationSound = "/assets/images/message-tone.wav";

export const print_idea_status = (idea) => {
  if (idea.is_deal_completed == 1)
    return <span className="badge badge-primary">Deal Closed</span>;
  else if (idea.is_published == 0)
    return <span className="badge badge-warning">Pending Publish</span>;
  else if (idea.is_published == 1)
    return <span className="badge badge-success">Published</span>;
};

export const print_problem_status = (problem) => {
  if (problem.is_deal_completed == 1)
    return <span className="badge badge-primary">Deal Closed</span>;
  else if (problem.is_published == 0)
    return <span className="badge badge-warning">Pending Publish</span>;
  else if (problem.is_published == 1)
    return <span className="badge badge-success">Published</span>;
};

export const wordCount = (val, max = 20) => {
  let len = 0;

  if (val.length != 0) {
    val = val.split(" ");

    len = val.length;

    if (val[val.length - 1].trim().length == 0) {
      len = len - 1;
    }
  }

  return len;
};
