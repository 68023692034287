import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { API_FEEDBACK, API_GET_PROFILE, API_URL } from "../services/ApiEndpoints";
import authHeader from "../services/auth-header";
import ExtButton from "./libs/ExtButton";
import FormReCaptchaV3 from "./libs/FormReCaptchaV3";
import LoaderWrapperView from "./libs/LoaderWrapperView";
import ReCaptchaV3 from "./libs/ReCaptchaV3";
import Select2 from "./libs/Select2";

const FeedbackForm = () =>{

    const {user:currentUser} = useSelector((state) => state.auth);
    const [isLoading, setIsLoading] = useState(false)
    const [msg, setMsg] = useState("");
    const [hasError, setHasError] = useState(false);

    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [interestedIn, setInterestedIn] = useState("")
    const [name, setName] = useState("")
    const [message, setMessage] = useState("")

    const interestedInList = [
        "Possible New User",
        "Billing",
        "Support",
        "General Questions",
        "Inquiry"
    ]

    useEffect(()=>{
        if(currentUser){
            getData()
        }
    }, []);

    const getData = () =>{
        
        setIsLoading(true)

        axios
        .get(API_URL + API_GET_PROFILE,{
            headers: authHeader()
        })
        .then((response)=>{
            
            if(response.status==200){
  
                const json = response.data;
  
                if(json.status=="RC200"){
  
                    setEmail(json.data.profile_details.email)
                    setPhone(json.data.profile_details.phone)
                    setName(json.data.profile_details.first_name + " " + json.data.profile_details.last_name)
  
                }
  
            }
  
            setIsLoading(false)
  
        })
        .catch((error)=>{
          setIsLoading(false)
        });
  
    };

    const [isLoadingSend, setIsLoadingSend] = useState(false)

    const handleSubmit = (captchaToken) =>{

        setHasError(false)
        setMsg("")
        setIsLoadingSend(true)

        var formdata = new FormData()
        formdata.append("name", name)
        formdata.append("email", email)
        formdata.append("phone", phone)
        formdata.append("interested_in", interestedIn)
        formdata.append("message", message)
        formdata.append("recaptcha_token", captchaToken)

        axios.post(API_URL + API_FEEDBACK, formdata,{
            headers:authHeader()
        })
        .then((responsex) => {

            setIsLoadingSend(false)

            if(responsex.data.status=="RC200"){
                setHasError(false)

                setEmail("")
                setName("")
                setPhone("")
                setMessage("")
                setInterestedIn("")
            }else{
                setHasError(true)
            }

            setMsg(responsex.data.message)

            return responsex.data;
        });

    }

    return(
        <div className="container">
            <br />
                    
            <div className="central-meta text-justify">
                <h1>Feedback</h1>

                <LoaderWrapperView isLoading={isLoading}>
                    
                    <FormReCaptchaV3 onLoadStatusUpdate={(status)=>{
                            setIsLoadingSend(status)
                        }} action="feedback_form" onSubmit={handleSubmit}>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input type="text" id="inp_fname" onChange={(e)=> setName(e.target.value) } value={name} name="name" required="required" />
                                    <label className="control-label" htmlFor="inp_fname">Full Name *</label>
                                    <i className="mtrl-select"></i>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input type="text" id="inp_email" onChange={(e)=> setEmail(e.target.value) } value={email} name="email" required="required" />
                                    <label className="control-label" htmlFor="inp_email">Email *</label>
                                    <i className="mtrl-select"></i>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <input type="text" id="inp_phone" name="phone" onChange={(e)=> setPhone(e.target.value) } value={phone || ""} required="required" />
                                    <label className="control-label" htmlFor="inp_phone">Phone *</label>
                                    <i className="mtrl-select"></i>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <Select2 directValues={true} value={interestedIn} onSelect={ (e) => setInterestedIn(e.target.value) } data={interestedInList} keys={"nm"} placeholder={"Subject *"} />
                                </div>
                            </div>

                        </div>

                        <div className="form-group">
                            <textarea rows="4" name="message" onChange={(e)=> setMessage(e.target.value) } value={message || ""} required="required"></textarea>
                            <label className="control-label" htmlFor="textarea">Message *</label><i className="mtrl-select"></i>
                        </div>
                        <br /><br />

                        {msg && (
                            <div className={ hasError ? "alert alert-danger mt-10" : "alert alert-success mt-10" } role="alert">
                                {msg}
                            </div>
                        )}

                        <div className="submit-btns">
                            <ExtButton isLoading={isLoadingSend} className="mtr-btn" type="submit">
                                <span>Submit</span>
                            </ExtButton>
                        </div>
                    </FormReCaptchaV3>
                </LoaderWrapperView>

            </div>
        </div>
    )

}

export default FeedbackForm;