import { Link, NavLink, Outlet, useParams } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component'
import Footer from "../layouts/Footer";
import CommonPagesFooter from "./CommonPagesFooter";

const PrivacyPolicy = () => {

  return (
    <>
        <div className="theme-layout">

            <CommonPagesFooter />

            <div style={{marginTop:"60px"}} className="container">
                <br />
                <div className="central-meta text-justify no-justify-phone">
                    <h1>Privacy Policy</h1>
                    <p class="small">Last Modified: June 14, 2024</p>

                    <h5>About This Website; Company Information</h5>
                    <p>This website, www.scions.tech (the "Site"), is owned by Sigma Tech Solutions B.V., a company with its mailing address at Oostmeerlaan 106, 2652 AE Berkel en Rodenrijs, The Netherlands. SCIONS and Sigma Tech Solutions B.V. are not registered as broker-dealers or investment advisers. We do not provide investment recommendations or financial advice. Any representations or information made on our site should not be construed as investment recommendations or advice.
                    SCIONS has not taken steps to verify the completeness, accuracy, or adequacy of any information. Neither SCIONS nor any of its employees, agents, directors, or officers makes any warranty, implied or express, regarding the completeness, accuracy, or adequacy of any information or the use of information on this site.</p>
                    <p>As explained, when you interact with or use our site, we collect information from you ("user", "your", or "you") in various ways. Please refer to our terms of use to see the terms and conditions of using or visiting our site.</p>

                    <h5>Your Consent to this Privacy Policy; Withdrawal of Consent</h5>
                    <p>Before using our site, you must consent to our collection and use of information as outlined in this Privacy Policy, and you must agree to our Terms of Use. If we process Personal Data based on your consent, you have the right to withdraw it. You can do so by sending an email to <a href="mailto:support@scions.tech">support@scions.tech</a> with the subject "CONSENT WITHDRAWN".</p>
                    <p>Periodically, we may update this Privacy Policy. If you continue to use our site after such updates, it indicates your consent to the latest version of our Privacy Policy. Therefore, if you do not agree with this Privacy Policy, you should refrain from using our Site. Additionally, please be aware that if you decide to engage in transactions on our site, you may be required to accept the terms of separate agreements from the parties involved.</p>

                    <h5>Purpose of this Privacy Policy</h5>
                    <p>At SCIONS, we hold your privacy in high regard and are committed to collecting personal information through lawful and fair means. Our Privacy Policy outlines the types of information collected when you use our site or associated services. Furthermore, it delineates how your collected information will be utilized and provides you with control over it, including options to collect, erase, rectify, and export your data to another location unrelated to our site.</p>
                    <p>All information you provide will be managed in accordance with this Privacy Policy unless you explicitly authorize us otherwise. You retain the right to withdraw this authorization at any time by notifying us at <a href="mailto:support@scions.tech">support@scions.tech</a> with the subject “WITHDRAWAL OF AUTHORIZATION”. However, please note that SCIONS may use or disclose your information when necessary to comply with a court order or other legal process.</p>

                    <h5>Information we collect about you</h5>
                    <p>Personal data, also referred to as "personally identifiable information" in the United States ("Personal Data"), encompasses details that can uniquely identify you, such as your name, online identifiers, location data, or identification numbers. Additionally, it may encompass facets of your social, cultural, economic, mental, genetic, physiological, or physical identity that could aid others in pinpointing your individuality. When you interact with our platform, SCIONS, whether by creating an account, contacting us through our website, or filling out applications or forms, we collect your personal data. Furthermore, SCIONS may gather information present in server logs, including your Internet Protocol ("IP") address.</p>
                    <p>The types of Personal Data we collect through our Site include, but are not limited to:</p>
                    <ol>
                    <li>Personal name</li>
                    <li>Physical address</li>
                    <li>Email address</li>
                    <li>Phone number</li>
                    <li>Social Security Number (if needed)</li>
                    <li>Date of birth</li>
                    <li>Business name</li>
                    <li>Business address</li>
                    <li>Information from identity documents such as passports or driver’s licenses</li>
                    <li>Job title</li>
                    <li>Bank account information (if needed)</li>
                    <li>Credit card information (if needed)</li>
                    <li>Articles of incorporation</li>
                    <li>Any other Personal Data you may voluntarily provide</li>
                    </ol>
                    <p>Moreover, additional data stored in our log files may comprise, but are not confined to, details such as dates and times of visits to our Site, duration spent on specific pages, visit frequency, clickstream data, operating systems, cookies present on your device, device type (desktop or mobile), browser type, and sites visited before landing on our Site. This information, collected through automated tracking technologies discussed below, may be amalgamated with other data we collect about you. We utilize this amalgamated information to enhance our site and the services we provide to you.</p>
                    <p>Personal Data may also be sourced from third-party providers, including but not limited to:</p>
                    <ul>
                    <li>Payment facilitation services</li>
                    <li>Anti-money laundering screening services</li>
                    <li>Identity and background verification services</li>
                    </ul>
                    <p>We are committed to safeguarding your privacy and ensuring transparency in how your personal data is collected, used, and protected.</p>

                    <h5>How we use information we collect about you</h5>
                    <p>At SCIONS, we adhere to this Privacy Policy as the guiding principle for utilizing the information gathered about you. When we process and share your collected personal data for our own purposes or with designated recipients, we do so based on your consent or in furtherance of our legitimate interests. These interests encompass safeguarding the security of our customers and systems, fulfilling contractual and legal obligations, conducting business operations, and providing the website features you utilize. Rest assured, your information will not be sold or rented except as described in the "Transfer of Your Information" section below.</p>
                    <p>The data you provide, including your Personal Data, is utilized to enhance your experience on our Site and for other purposes outlined in this Privacy Policy that align with our legitimate interests. This may entail copying or modifying the Personal Data, textual content, or images you provide to ensure a seamless and enjoyable user experience. Refer to the "How We Collect Information about You" section above for insights into how analytics, log file data, and web beacons are utilized.</p>
                    <p>Your Personal Data and other information are utilized by SCIONS for various purposes:</p>
                    <ol>
                    <li>Marketing our Site or affiliate services, products, and websites.</li>
                    <li>Analyzing data to send targeted messages.</li>
                    <li>Conducting research to improve our Site, services, and products.</li>
                    <li>Enabling contractors and vendors to provide support in analysis, troubleshooting, operation, and marketing.</li>
                    <li>Fulfilling service and product requests.</li>
                    <li>Sending email messages such as service announcements, newsletters, and special offers.</li>
                    <li>Complying with regulatory requirements.</li>
                    <li>Protecting our Site and users.</li>
                    <li>Engaging in lawful activities consistent with our objectives.</li>
                    <li>Responding to legal obligations and safeguarding rights.</li>
                    </ol>
                    <p>We may engage third parties to provide or obtain the above services, necessitating the sharing of Personal Data. However, with regards to marketing, none of the information is shared beyond Sigma Tech Solutions affiliates.</p>
                    <p>Apart from the described uses, your Personal Data facilitates activities such as:</p>
                    <ol>
                    <li>Market research and statistical analysis.</li>
                    <li>Fraud prevention.</li>
                    <li>Payment and transaction facilitation.</li>
                    <li>Credit, background, and identity verification.</li>
                    <li>Communication maintenance.</li>
                    <li>Enforcement of legal agreements.</li>
                    </ol>
                    <p>Additionally, entities involved in equity or debt financing, issuers, investors, or securities purchasers, may utilize Personal Data for:</p>
                    <ol>
                    <li>Posting investor profiles.</li>
                    <li>Facilitating transactions and identity verification.</li>
                    <li>Regulatory compliance checks.</li>
                    <li>Communication maintenance.</li>
                    </ol>
                    <p>Certain Personal Data may be visible on our Site, such as first names and last name initials. Opt-in features allow users to display more information, like full names, social media handles, and bios. Prospective borrowers may provide extensive Personal Data for syndicated loan offerings.</p>
                    <p>SCIONS may share your personal information with affiliated companies for lawful purposes outlined in this Policy. Third-party individuals or companies employed by SCIONS may access your information solely to perform designated services or functions on our behalf. However, they are restricted from using your information for other purposes. Any Non-Personal or Personal Data provided to such third parties is strictly utilized for their prescribed functions or services.</p>


                    <h5>Transfer of your information</h5>
                    <p><strong>Transfers Relating to Our Corporate Transactions:</strong> There may arise instances where your information needs to be transferred to a buyer or other successors to our business due to scenarios such as dissolution, reorganization, restructuring, divestiture, merger, or other transfer or sale of some or all of SCIONS’s assets. This includes situations where Personal Data held by us about our Site users is among the assets being transferred, whether as a going concern or part of a liquidation, bankruptcy, or similar proceeding.</p>
                    <p><strong>Cross-Border Transfers:</strong> As detailed in the "Security" section of this Privacy Policy, the information you provide to us is encrypted and securely stored on public cloud servers via third-party service providers located in the Netherlands. These server entities are contractually bound to maintain redundancies to protect data in the event of outages or other issues. Our commitment is to ensure that your Personal Data is processed in accordance with the provisions outlined in this Privacy Policy and in compliance with the laws of the jurisdiction where the data is stored.</p>
                    <p>The European Commission, under the General Data Protection Regulation ("GDPR"), assesses whether a specific country provides an adequate level of protection for Personal Data. This determination is based on factors such as the country's data protection laws, the presence of an independent enforcement body, and international commitments to Personal Data protection. The Commission has recognized the Netherlands as offering adequate protection, particularly within the Privacy Shield framework, discussed below.</p>
                    <p>Our aim is to guarantee that your data enjoys the rights and protections outlined in this Privacy Policy whenever it is transferred from the European Economic Area and Switzerland to the United States. To achieve this, we utilize various safeguards, including standard data protection clauses approved by the European Commission.</p>
                    <p>SCIONS asserts compliance with the EU-US Privacy Shield Framework and Swiss-U.S. Privacy Shield Framework as established by the US Department of Commerce concerning the collection, use, and retention of personal information transferred from the European Union and Switzerland to the United States. If you have any questions or complaints regarding SCIONS's participation in the Swiss-US or EU-US Privacy Shield, please reach out to us at <a href="mailto:support@scions.tech">support@scions.tech</a>. In cases where SCIONS cannot directly resolve complaints related to the Privacy Shield frameworks, we cooperate with a panel established by European data protection authorities or the relevant Data Protection Authority for dispute resolution. Please contact us if you require contact information for your Data Protection Authority. Additionally, as outlined in the Privacy Shield Principles available at https://www.privacyshield.org, binding arbitration is available to address unresolved complaints.</p>

                    <h5>Third Party Websites and Services</h5>
                    <p>There may be links contained in the SCIONS Site that will lead to other websites and services that are not maintained by us. In addition, there may be links in other websites or other services that refer to our Site. We encourage you to be aware of the fact that these other websites are not subject to our Privacy Policy and we are not responsible for their content or for the policies or practices they apply to the treatment of your information, when you enter or leave our Site. We also encourage you to read the privacy statements of each website that you visit. We do not approve, screen, or endorse, and are not responsible for the content or the privacy practices of other websites or services.</p>

                    <h5>Opt-out Policy</h5>
                    <p>There may be times that we or our affiliates send you e-mail communications with general marketing information, despite the fact that none of your Personal Data is shared for marketing purposes beyond affiliates of SCIONS. If it is in your preference not to receive such promotional or marketing e-mails, you may unsubscribe by emailing us at <a href="mailto:support@scions.tech">support@scions.tech</a>, or you may unsubscribe via your account dashboard, or you may choose to stop receiving our marketing or newsletter emails by following the "unsubscribe" instructions included in these emails. However, even if you choose not to receive any of these emails, there are some types of emails that you cannot choose not to receive them such as emails regarding emails responding to specific inquiries from you, legal notices we may be required to give, or specific transactions you have participated in on our Site.</p>
                    <p>In addition, please note that at times we may need to send you e-mail communications which are transactional in nature and which you will not be able to opt-out of, such as payment confirmations, or termination or service announcements.</p>

                    <h5>Accessing, changing and otherwise controlling your personal data</h5>
                    <p>There are certain right for you about the data we process about you. This includes, among others, the right to export, erase, and rectify your Personal Data, to object to the processing of your personal data, and to access your Personal Data.</p>
                    <p>Based on your consent, we process your Personal Data and concerning this process, you have the right to withdraw your consent. You may withdraw your consent by emailing <a href="mailto:support@scions.tech">support@scions.tech</a> with "CONSENT WITHDRAWN" in the subject line.</p>
                    <p>If we process your personal data based on the legitimate interests of our organization or the legitimate interests of third parties, you have the right to raise an objection to such processing by contacting us at <a href="mailto:support@scions.tech">support@scions.tech</a>.</p>
                    <p>You will be able to view all of your own information that you submit. Certain highly sensitive information (such as your bank account numbers or Social Security Number) may be blocked for security reasons with only the last four digits displayed. According to our Site’s Terms of Use, you will be able to access, update, and modify the information concerning your account by going to your profile page and editing certain information that you wish to alter. For instance, by visiting your profile page and account’s settings page you will be able to change your contact information, password, your notification preferences, your e-mail address, or other login information.</p>
                    <p>You will be able to choose to remove your account and any of your Personal Data that is associated with that account. However, we will reserve the right to retain and use your Personal Data as necessary to comply with our legal obligations, enforce our legal agreements, resolve disputes, and provide our services. For example, we may need to retain archival copies of your information for compliance or legal purposes even after you have requested the deletion of your information.</p>
                    <p>It is not our responsibility to remove your Personal Data from the lists of any third-party websites or other third parties who have previously been provided with your information in accordance with this Privacy Policy.</p>
                    <p>Your personal data will generally be retained only for as long as necessary to accomplish the purposes for which we processed the data, which may include delivering the services to you for which you accessed our website.</p>
                    <p>With regard to certain types of Personal Data, you have provided consent for a retention period of longer duration than the period we would be required to preserve it in order to complete the processing for the purposes you intended. In that case, we will retain the data in accordance with your consent.</p>
                    <p>If the type of an item of Personal Data is particularly sensitive, we will shorten the retention time period that we would otherwise normally observe.</p>
                    <p>In some cases, you have provided Personal Data with the manifested intention that it be retained until you yourself initiate its removal. Nevertheless, in this case and other cases, we may be forced to delete the data earlier than you wish if, for example, you exceed the limit on the quantity of data that we can store in your account.</p>
                    <p>Automated controls may be provided by our website which will give you the freedom to delete Personal Data any time you wish. When such controls are absent, we are likely to prescribe a shortened data retention period.</p>
                    <p>If we adopt a data retention program or schedule prescribing various retention periods for different types of Personal Data, we will post it on our website.</p>
                    <p>There are certain exceptions to our policy of retaining your Personal Data for only as long as is necessary to accomplish the purposes for which you wanted to have the data processed in the first place. Your Personal Data will be retained for as long as is necessary to comply with our legal obligations, including contractual obligations, to serve our legitimate interests, and to pursue our remedies and rights and defend ourselves in litigation and other proceedings with governmental authorities, business entities, and individuals. The law may require us to preserve information, including Personal Data, that is relevant to such proceedings for a prescribed period of time. Proper institutional and technical measures will be taken by us to safeguard your freedoms and rights as required by the GDPR (General Data Protection Regulation).</p>
                    <p>Depending upon your use of various features of our website and the services we provide through our website, the nature of our interactions with you, the country or other jurisdiction in question, and the data type, the periods for which we are required to retain data can vary. We may be subject to mandatory data removal laws or data retention laws based on applicable rules of court or arbitration procedure, administrative order, data required to be retained in adversarial proceedings pursuant to a court order, government orders to preserve data in connection with an investigation, or content.</p>

                    <h5>Social media widgets</h5>
                    <p>Certain interactive mini-programs that run on our Site, and widgets, such as the "Share this" button, or social media features, such as Facebook "Follow Us" button, may be included in our Site. These features may set a cookie to enable the feature to function properly, or gather the data about which page you are visiting on our Site, or collect your IP address. Social media features and widgets are either hosted directly on our Site or hosted by a third party. Your interactions with these features are governed by the privacy policy of the third-party website providing such features.</p>

                    <h5>Security</h5>
                    <p>We use reasonable efforts to secure access to Personal Data in our possession but will not be liable for any failure to safeguard such information, whether inadvertent or otherwise. Information you provide to us is encrypted and stored securely on private cloud servers through third-party service providers that we use. We also take commercially reasonable efforts to store as little sensitive information as possible.</p>
                    <p>We are required to notify you without undue delay in the event of a data breach on our part causing high risk to data subjects. The notification must include at least: any measures taken by us to mitigate or remedy the breach; the likely consequences of the data breach; and the name and contact details of the DPO (or other relevant point of contact). However, we may be exempt from this requirement if: the notification requires disproportionate effort (in which case we must issue a public notice of the breach); we have taken measures to protect against the harm (e.g., suspending affected accounts); or the risk of harm is remote because the affected data are protected (e.g., through strong encryption).</p>
                    <p>Although we and our service providers, who have informed us that they also use reasonable efforts to secure Personal Data, take commercially reasonable efforts to keep secure any Personal Data, there is no guarantee that any communication over the public Internet is 100% secure at all times. Therefore, neither we nor our service providers shall be held liable for the failure to safeguard Personal Data, whether inadvertent or otherwise, except where you, as a data subject, prove that you have suffered material or non-material damage as a result of our infringement of the GDPR, in which event you shall have the right to receive compensation from us, in our capacity as data controller or processor, for the damage suffered.</p>
                    <p>In addition, it is important for you to take appropriate measures to make sure your computer and your password are protected against unauthorized access. Always make sure to log off any computer or device you use to access your account on our Site after you have finished.</p>

                    <h5>Children's Privacy Policy</h5>
                    <p>The SCIONS platform is not intended for use by children. No one under eighteen (18) years of age is allowed to receive our email distributions, provide any Personal Data, or use our Site. We do not knowingly solicit or collect Personal Data from children under the age of thirteen (13) or anyone under eighteen years of age. If you believe that anyone under these ages has disclosed Personal Data to SCIONS, please report this to us immediately by emailing us as <a href="mailto:support@scions.tech">support@scions.tech</a>.</p>

                    <h5>Changes to this Privacy Policy</h5>
                    <p>We reserve the right, in our sole discretion, to change, modify, add, or remove portions of this Privacy Policy at any time. Any updates or changes will be effective immediately upon posting to our Site. If any material changes are made to our Site, commercially reasonable efforts will be taken by us in order to notify you by means of a notice posted on our Site or by email (sent to the specified e-mail address provided in your account). Notwithstanding that, you should review this Privacy Policy regularly for changes, and you can determine if changes have been made by checking the effective date at the beginning of the Privacy Policy. Your continued use of our Site following the posting of any modified versions of this Privacy Policy means you accept those changes, and agree to be bound by the updated Privacy Policy.</p>

                    <h5>Your Rights Regarding Any Complaints You May Have About Our Protection of Your Personal Data.</h5>
                    <p>The SCIONS platform is maintained in Netherlands. By accessing our Site, you consent to and authorize the export of your Personal Data to Netherlands and its storage and use as specified in this Privacy Policy. For users in the EU, you have the right to bring a complaint with the independent public authority responsible for data protection in the EU member country of your habitual residence, place of work or place where you think our processing of your Personal Data infringed the GDPR. Before you do so, we ask that you give us the opportunity to resolve your complaint to your satisfaction within ninety (90) days of your contacting us about it at <a href="mailto:support@scions.tech">support@scions.tech</a>.</p>
                    <p>It should be noted that SCIONS does not own the data shown on the platform but instead it uses third party data sources.</p>
                    <p>Please check back regularly for updates to this Privacy Policy. If you have any questions regarding this Privacy Policy, please email us at <a href="mailto:support@scions.tech">support@scions.tech</a>.</p>
                </div>
            </div>


            <Footer />
        </div>
    </>
  );
};

export default PrivacyPolicy;