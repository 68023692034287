import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import LeftPanel from "../feed/LeftPanel";
import RightPanel from "../feed/RightPanel";
import MyFeedList from "./MyFeedList";

const MyTimeline = () =>{

    const { user: currentUser } = useSelector((state) => state.auth);

    useEffect(()=>{
        // importJsFile('main.min.js');
        // importJsFile('script.js');
    })

    return(
        
        <section>
            <div className="gap2 gray-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">

                                <div className="row merged20" id="page-contents">
                                    
                                    <div className="col-md-1"></div>
                                    <div className="col-md-7">

                                        {/* feed list */}

                                        <MyFeedList />

                                    </div>

                                    <div className="col-md-3">
                                        
                                        <RightPanel />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
        
    );
};

export default MyTimeline;