import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API_MY_JOBS, API_URL } from "../../../services/ApiEndpoints";
import authHeader from "../../../services/auth-header";

const MyJobs = () => {

    const [isLoading, setIsLoading] = useState(false)
    const [list, setList] = useState([])

    const getDetails = () =>{

        setIsLoading(true)

        axios
        .get(API_URL + API_MY_JOBS,{
            headers: authHeader()
        })
        .then((response)=>{
            
            setIsLoading(false)

            if(response.status==200){

                const json = response.data;

                setList(json.data)

            }

        })
        .catch((error)=>{
            setIsLoading(false)
        });
    }

    useEffect(()=>{
        getDetails()
    }, [])

  return (
        <section>
            <div className="gap gray-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="central-meta">
                                <div className="frnds">
                                    <div className="row">
                                        <div className="col-8 col-md-6">
                                            <h3>My Jobs</h3>
                                        </div>

                                        <div className="col-4 col-md-6">
                                            <Link style={{top:0}} to="/my-jobs/create" className="add-butn">Create Job</Link>
                                        </div>
                                    </div>

                                    { isLoading ? (
                                        <p>Loading...</p>
                                    ) : (
                                        
                                        list.length==0 ? (
                                            <p className="text-center"><strong>You have not posted any jobs yet.</strong></p>
                                        ) : (
                                            <ul className="nearby-contct">
                                                
                                                {list.map((item, index)=>(
                                                    <li key={item.id}>
                                                        <Link to={`/my-jobs/edit/${item.id}`}>
                                                            <div className="row">
                                                                <div className="col-md-2 text-center">
                                                                    <figure>
                                                                        <Link to={`/my-jobs/edit/${item.id}`} title="" style={{verticalAlign:"top"}}><img className="img-fluid" src={item.company_logo} alt={item.title} /></Link>
                                                                    </figure>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <h4><Link to={`/my-jobs/edit/${item.id}`} title="">{item.title}</Link></h4>
                                                                    <small>
                                                                        {item.description}
                                                                    </small>
                                                                    <p><small>Published on: {item.date}</small></p> 
                                                                    {item.is_published==0 ? (
                                                                        <span className="badge badge-warning">Pending Publish</span>
                                                                    ) : (
                                                                        <span className="badge badge-success">Published</span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </li>
                                                ))}

                                            </ul>  
                                        )

                                        
                                    )}
                                    
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>

  );

};

export default MyJobs;