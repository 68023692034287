import React, { Component } from "react";
import jquery from "jquery";
import '../../assets/js/bootstrap-tagsinput'
import '../../assets/css/bootstrap-tagsinput.css'

class ExTagsInput extends Component{

    constructor(props){
        super();

    }

    componentDidUpdate(){
    }

    componentDidMount(){
        jquery(this.refs['tagsinput']).tagsinput({tagClass:"badge badge-info",maxTags:5});
    }

    get_val(){
        const val = this.refs['tagsinput'].value

        if(val){
            return val.split(",");
        }

        return ""
    }

    render(){

        return(
            <>
                <input type="text" ref="tagsinput" />
            </>
        );
    }
};

export default ExTagsInput;