import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  API_CREATE_IDEA,
  API_GET_DISCIPLINES_BY_FIELD_ID,
  API_GET_DISCIPLINE_BY_ID,
  API_GET_ENGINEERING_FIELDS,
  API_URL,
} from "../../../services/ApiEndpoints";
import authHeader from "../../../services/auth-header";
import ExtButton from "../../libs/ExtButton";
import ExToolTip from "../../libs/ExToolTip";
import Select2 from "../../libs/Select2";
import { Button, Modal } from "react-bootstrap";
import NDAText from "./NDAText";
import IdeaTLRV2 from "./IdeaTLRV2";
import { wordCount } from "../Consts";
import IdeaTLRV3 from "./IdeaTLRV3";
import IdeaTLRV4 from "./IdeaTLRV4";
import WordLimiter from "../../libs/WordLimiter";
import TransPopup from "../../libs/TransPopup";
import HowItWorks from "./HowItWorks";
import Select2Multiple from "../../libs/Select2Multiple";
import ImageCropperPopup from "../../libs/image/ImageCropperPopup";
import { DatePicker, df, mode } from "../../../helpers/DatePicker";
import IdeaDescViewByPatentId from "./IdeaDescViewByPatentId";
import Select2GroupsMultiple from "../../libs/Select2GroupsMultiple";

const CreateIdea = (props) => {
  const select2PD = useRef();
  const select2PSD = useRef();

  // Predefined values or from first time api calls
  const [listPatientedWith] = useState([
    { isChecked: false, name: "EU Body", id: 0 },
    { isChecked: false, name: "USA Body", id: 1 },
    { isChecked: false, name: "Asian Organization", id: 2 },
  ]);

  const [listEngineeringField, setListEngineeringField] = useState([]);

  const [isLoadingEngField, setIsLoadingEngField] = useState(true);

  const getEngFields = () => {
    setIsLoadingEngField(true);

    axios
      .get(API_URL + API_GET_ENGINEERING_FIELDS, {
        headers: authHeader(),
      })
      .then((response) => {
        setIsLoadingEngField(false);

        if (response.status == 200) {
          const json = response.data;

          setListEngineeringField(json.data.engineering_field);
        }
      })
      .catch((error) => {});
  };

  const getDeciplinesById = (targetOne, targetTwo, id) => {
    axios
      .get(API_URL + API_GET_DISCIPLINES_BY_FIELD_ID + "?id=" + id, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          const json = response.data.data;

          if (targetOne != null) targetOne.current.updateSelect(json);

          if (targetTwo != null) targetTwo.current.updateSelect([]);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getEngFields();
  }, []);

  // states for the fields start

  const [title, setTitle] = useState("");
  const [isPatented, setIsPatented] = useState(0);
  const [patentedWith, setPatentedWith] = useState("");
  const [patentedNumber, setPatentedNumber] = useState("");
  const [patentRegDate, setPatentRegDate] = useState("");
  const [filedName, setFiledName] = useState("");
  const [disciplineName, setDisciplineName] = useState("");
  const [engField, setEngField] = useState("");
  const [discipline, setDiscipline] = useState([]);
  const [subDiscipline, setSubDiscipline] = useState(-1);

  const [valueProposition, setValueProposition] = useState("");
  const [abstract, setAbstract] = useState("");
  const [description, setDescription] = useState("");

  const [image, setImage] = useState(null);

  const [intellectualPropertyDocuments, setIntellectualPropertyDocuments] =
    useState([null]);
  const [otherDocument, setOtherDocument] = useState([null]);
  const [certifications, setCertifications] = useState([null]);

  const [ipInitial, setIpInitial] = useState(false);
  const [ipBusinessPlan, setIpBusinessPlan] = useState(false);
  const [ipPrototypeAvailable, setIpPrototypeAvailable] = useState(false);
  const [ipExperiencingCustomer, setIpExperiencingCustomer] = useState(false);
  const [ipCurrentSelected, setIpCurrentSelected] = useState(-1);

  const [patientFiledBy, setPatientFiledBy] = useState(0);
  const [patientFiledByName, setPatientFiledByName] = useState("");
  const [acceptNDA, setAcceptNDA] = useState();
  const [tlr, setTlr] = useState(-1);

  let patientFiledByList = [
    { name: "Self", id: 0 },
    { name: "Other", id: 1 },
  ];

  // states for the fields end

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [msg, setMsg] = useState("");

  const navigation = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);

    var formdata = new FormData();
    formdata.append("idea_title", title);

    formdata.append("field_name", filedName);
    formdata.append("discipline_name", disciplineName);

    formdata.append("idea_patented", isPatented);
    formdata.append("filed_by", patientFiledBy);
    formdata.append("filed_by_name", patientFiledByName);
    formdata.append("idea_patented_with", patentedWith);
    formdata.append("patent_number", patentedNumber);
    formdata.append("idea_patent_date_of_registration", patentRegDate);

    formdata.append("category", engField);
    formdata.append("sub_category", discipline.join(","));
    formdata.append("field", subDiscipline);
    formdata.append("accept_nda", acceptNDA ? 1 : 0);
    formdata.append("tech_readiness_level", tlr);

    if (image !== null) formdata.append("idea_image", image);

    intellectualPropertyDocuments.length > 0 &&
      intellectualPropertyDocuments.map((val, index) => {
        if (val !== null)
          formdata.append(
            "intellectual_property_documents[" + index + "]",
            val
          );
      });

    otherDocument.length > 0 &&
      otherDocument.map((val, index) => {
        if (val !== null) formdata.append("other_document[" + index + "]", val);
      });

    certifications.length > 0 &&
      certifications.map((val, index) => {
        if (val !== null) formdata.append("certifications[" + index + "]", val);
      });

    formdata.append("introduction", valueProposition);
    formdata.append("abstract", abstract);
    formdata.append("description", description);

    formdata.append("idea_phase_initial", ipInitial ? 1 : 0);
    formdata.append("idea_business_plan", ipBusinessPlan ? 1 : 0);
    formdata.append("idea_prototype_available", ipPrototypeAvailable ? 1 : 0);
    formdata.append(
      "idea_experiencing_customer",
      ipExperiencingCustomer ? 1 : 0
    );

    axios
      .post(API_URL + API_CREATE_IDEA, formdata, {
        headers: authHeader(),
      })
      .then((responsex) => {
        setIsLoading(false);

        if (responsex.data.status == "RC200") {
          setHasError(false);

          const id = responsex.data.data.id;

          toast.success(responsex.data.message);

          navigation(`/ideas/details/${id}/edit`);
        } else {
          setHasError(true);
        }

        setMsg(responsex.data.message);

        return responsex.data;
      })
      .error(() => {
        setIsLoading(false);
      });
  };

  const [showNDA, setShowNDA] = useState(false);

  const popup = useRef();
  const ideaDescPtDetView = useRef();

  return (
    <section>
      <div className="gap gray-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="central-meta">
                <TransPopup
                  ref={popup}
                  label="How it works?"
                  className="add-butn no-text-transform hiw-pa-t0-r15 btn-hiw"
                  title=""
                  button={false}
                  dClassName="modal-lg modal-transp"
                  btnCancel={false}
                  btnCancelLabel="Close"
                  onSaveClickListener={(e) => {
                    popup.current.close();
                  }}
                >
                  <HowItWorks
                    hide={(e) => {
                      popup.current.close();
                    }}
                  />
                </TransPopup>

                <div className="row">
                  <div className="col-md-9">
                    <h3>List your Idea on SCIONS</h3>
                  </div>
                </div>

                <form onSubmit={handleSubmit} method="post">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-radio">
                        Is your Idea patented? *&nbsp;&nbsp;&nbsp;
                        <div className="radio">
                          <label>
                            <input
                              type="radio"
                              onChange={(e) =>
                                setIsPatented(parseInt(e.target.value))
                              }
                              value="1"
                              defaultChecked={isPatented === 1}
                              name="radio"
                            />
                            <i className="check-box"></i>YES
                          </label>
                        </div>
                        <div className="radio">
                          <label>
                            <input
                              type="radio"
                              onChange={(e) => setIsPatented(e.target.value)}
                              defaultChecked={isPatented === 0}
                              value="0"
                              name="radio"
                            />
                            <i className="check-box"></i>NO
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {isPatented === 1 && (
                    <>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Patent ID*</label>
                            <input
                              type="text"
                              value={patentedNumber}
                              onBlur={(e) => {
                                ideaDescPtDetView.current.getPatentDetails(
                                  e.target.value
                                );
                              }}
                              onChange={(e) =>
                                setPatentedNumber(e.target.value)
                              }
                              required="required"
                            />
                            <i className="mtrl-select"></i>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Where your idea is patented? *{" "}
                              <ExToolTip name="Please provide the region where your idea is protected. You can use multiple regions with the delimeter 'semicolon;'."></ExToolTip>{" "}
                            </label>

                            <input
                              type="text"
                              value={patentedWith}
                              onChange={(e) => setPatentedWith(e.target.value)}
                              required="required"
                            />
                            <i className="mtrl-select"></i>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <DatePicker
                              withLabel={true}
                              readOnly={true}
                              format={df.DMY}
                              value={patentRegDate || ""}
                              mode={mode.day}
                              onChange={(e, date) => {
                                setPatentRegDate(date);
                              }}
                              required="required"
                            >
                              Patent Registration Date*
                            </DatePicker>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Patent filed by *</label>
                            <Select2
                              placeholder={"Select appropriate option"}
                              directValues={false}
                              onSelect={(e) =>
                                setPatientFiledBy(parseInt(e.target.value))
                              }
                              value={patientFiledBy}
                              name="patente_filed_by"
                              keys="name"
                              data={patientFiledByList}
                            />
                          </div>
                        </div>

                        {patientFiledBy == 1 && (
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Person name or Organization name *</label>
                              <input
                                type="text"
                                value={patientFiledByName}
                                onChange={(e) =>
                                  setPatientFiledByName(e.target.value)
                                }
                                required={patientFiledBy == 1}
                              />
                              <i className="mtrl-select"></i>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}

                  <IdeaDescViewByPatentId
                    ref={ideaDescPtDetView}
                    onYes={(title, desc, abs) => {
                      setTitle(title);
                      setAbstract(abs);
                      setDescription(desc);
                    }}
                  />

                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          required="required"
                        />
                        <label className="control-label">Idea Title *</label>
                        <i className="mtrl-select"></i>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group ">
                        {isLoadingEngField ? (
                          <p>loading...</p>
                        ) : (
                          <>
                            <label>Select your idea field *</label>
                            <Select2Multiple
                              onSelect={(array) => {
                                setEngField(array.join(","));
                                getDeciplinesById(
                                  select2PD,
                                  select2PSD,
                                  array.join(",")
                                );
                              }}
                              required={false}
                              keys={"field_name"}
                              placeholder={"Select"}
                              data={listEngineeringField}
                            />
                          </>
                        )}
                      </div>

                      {engField.split(",").some((id) => {
                        return id == -1;
                      }) ? (
                        <>
                          <div className="col-md-12">
                            <div className="form-group">
                              <input
                                value={filedName}
                                onChange={(e) => setFiledName(e.target.value)}
                                required="required"
                              />
                              <label className="control-label">
                                Enter your idea field *
                              </label>
                              <i className="mtrl-select"></i>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="col-md-8">
                      <div className="form-group">
                        <label>
                          Which one best describe your idea discipline *
                        </label>

                        <Select2GroupsMultiple
                          onSelect={(array) => {
                            setDiscipline(array);
                          }}
                          keys="field_name"
                          required={false}
                          ref={select2PD}
                          placeholder="Select"
                        />

                        {/* <Select2 multiple={false} ref={select2PD} value={""} onSelect={ (e) => {
                                                    //setDiscipline(e.target.value)
                                                    console.log(e.option)
                                                } } name="discipline" keys={"field_name"} placeholder={"Select"} /> */}
                      </div>

                      {engField.split(",").some((id) => {
                        return id == -1;
                      }) ? (
                        <>
                          <div className="col-md-12">
                            <div className="form-group">
                              <input
                                value={disciplineName}
                                onChange={(e) =>
                                  setDisciplineName(e.target.value)
                                }
                                required="required"
                              />
                              <label className="control-label">
                                Enter your idea discipline *
                              </label>
                              <i className="mtrl-select"></i>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>

                    {/* <div className="col-md-4">
                                            <div className="form-group">
                                                <Select2 ref={select2PSD} 
                                                    value={subDiscipline} 
                                                    onSelect={ (e) => setSubDiscipline(e.target.value) } 
                                                    name="sub_discipline"
                                                    keys={"field_name"}
                                                    placeholder={"--- Select Sub Discipline ---"} />

                                            </div>
                                        </div>  */}

                    <div className="col-md-12">
                      <label style={{fontSize: ".8rem", fontWeight: "400", color:"#2a2a2a", margin:"0px"}}>Value Proposition * (max. 50 words) <ExToolTip name="Please note that a strong value proposition is crucial for demonstrating your idea and its market potential. Ensure that it is crafted with precision and clarity." /></label>
                      <WordLimiter
                        req={true}
                        rows="4"
                        maxWords="50"
                         className="m-0"
                        value={valueProposition}
                        onChange={(val) => {
                          setValueProposition(val);
                        }}
                        label=""
                      />
                    </div>
                    

                    <div className="col-md-12">
                      <br />
                      <WordLimiter
                        req={false}
                        rows="4"
                        maxWords="250"
                        value={abstract}
                        onChange={(val) => {
                          setAbstract(val);
                        }}
                        label="Abstract *"
                      />
                    </div>
                    
                    <div className="col-md-12">
                    <br />
                      <WordLimiter
                        req={false}
                        rows="4"
                        maxWords="500"
                        value={description}
                        onChange={(val) => {
                          setDescription(val);
                        }}
                        label="Description"
                      />
                      <br />
                      <br />
                    </div>

                    <div className="col-md-5">
                      <div className="form-group">
                        Upload a suitable image for your Idea * <br />
                        <small>(Note: Only PNG, JPG and JPEG allowed)</small>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <ImageCropperPopup
                        width={200}
                        height={200}
                        title="Select Idea Image"
                        className="form-control file-picker-theme"
                        onImageCroped={(base64, blob) => {
                          setImage(blob);
                        }}
                      />

                      {/* <input onClick={ (e) => e.target.value="" } onChange={ (e)=> {
                                                    var files = e.target.files;

                                                    if(files.length==0) return;

                                                    setImage(files[0])

                                                }} name="" className="form-control-file file-picker-theme" type="file" /> */}
                    </div>

                    <div className="col-md-12 mt-5">
                      <h5>Upload all Idea related documents</h5>
                      <small>(Note: Only PDF, JPEG, PNG images allowed)</small>
                    </div>

                    {/* Intellectual Property start */}

                    <div className="col-md-12 mt-4">
                      <p>
                        <strong>
                          Intellectual Property Documents (if any){" "}
                        </strong>
                      </p>
                    </div>

                    <div className="col-md-6">
                      {intellectualPropertyDocuments.map((value, index) => (
                        <input
                          key={index}
                          onClick={(e) => (e.target.value = "")}
                          onChange={(e) => {
                            var files = e.target.files;

                            if (files.length == 0) return;

                            let ipd = [...intellectualPropertyDocuments];

                            ipd[index] = files[0];

                            setIntellectualPropertyDocuments(ipd);
                          }}
                          name="ipd"
                          type="file"
                          className="form-control-file file-picker-theme"
                        />
                      ))}
                    </div>

                    <div className="col-md-6">
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setIntellectualPropertyDocuments(
                            intellectualPropertyDocuments.concat(null)
                          );
                        }}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Add more documents"
                      >
                        <i className="fa fa-plus-circle"></i>
                      </a>
                    </div>

                    {/* Intellectual Property end */}

                    {/* Certifications start */}

                    <div className="col-md-12 mt-4">
                      <p>
                        <strong>Certifications (if any)</strong>
                      </p>
                    </div>

                    <div className="col-md-6">
                      {certifications.map((value, index) => (
                        <input
                          key={index}
                          onClick={(e) => (e.target.value = "")}
                          onChange={(e) => {
                            var files = e.target.files;

                            if (files.length == 0) return;

                            let ipd = [...certifications];

                            ipd[index] = files[0];

                            setCertifications(ipd);
                          }}
                          name="ipd"
                          type="file"
                          className="form-control-file file-picker-theme"
                        />
                      ))}
                    </div>

                    <div className="col-md-6">
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setCertifications(certifications.concat(null));
                        }}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Add more documents"
                      >
                        <i className="fa fa-plus-circle"></i>
                      </a>
                    </div>

                    {/* Certifications end */}

                    {/* Other documents start */}

                    <div className="col-md-12 mt-4">
                      <p>
                        <strong>Other Documents (if any)</strong>
                      </p>
                    </div>

                    <div className="col-md-6">
                      {otherDocument.map((value, index) => (
                        <input
                          key={index}
                          onClick={(e) => (e.target.value = "")}
                          onChange={(e) => {
                            var files = e.target.files;

                            if (files.length == 0) return;

                            let ipd = [...otherDocument];

                            ipd[index] = files[0];

                            setOtherDocument(ipd);
                          }}
                          name="ipd"
                          type="file"
                          className="form-control-file file-picker-theme"
                        />
                      ))}
                    </div>

                    <div className="col-md-6">
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setOtherDocument(otherDocument.concat(null));
                        }}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Add more documents"
                      >
                        <i className="fa fa-plus-circle"></i>
                      </a>
                    </div>

                    {/* Other documents end */}

                    <div className="col-md-12">
                      <hr />
                    </div>
                    <div className="col-md-12 mt-3">
                      <h5>Idea Phase *</h5>
                    </div>

                    <div className="col-md-3">
                      {/* disabled={ipCurrentSelected>=2} */}
                      <input
                        checked={ipInitial}
                        onChange={(e) => {
                          setIpInitial(e.target.checked);

                          // setIpCurrentSelected(e.target.checked ? 1 : -1)
                        }}
                        type="checkbox"
                        value="1"
                      />{" "}
                      Initial
                    </div>

                    <div className="col-md-3">
                      {/* disabled={ipCurrentSelected>=3}  */}
                      <input
                        checked={ipBusinessPlan}
                        onChange={(e) => {
                          setIpBusinessPlan(e.target.checked);

                          // setIpCurrentSelected(e.target.checked ? 2 : -1)

                          // setIpInitial(e.target.checked)
                        }}
                        type="checkbox"
                        value="1"
                      />{" "}
                      Business Plan
                    </div>

                    <div className="col-md-3">
                      {/* disabled={ipCurrentSelected>=4}  */}
                      <input
                        checked={ipPrototypeAvailable}
                        onChange={(e) => {
                          setIpPrototypeAvailable(e.target.checked);

                          // setIpCurrentSelected(e.target.checked ? 3 : -1)

                          // setIpInitial(e.target.checked)
                          // setIpBusinessPlan(e.target.checked)
                        }}
                        type="checkbox"
                        value="1"
                      />{" "}
                      Prototype Available
                    </div>

                    <div className="col-md-3">
                      <input
                        checked={ipExperiencingCustomer}
                        onChange={(e) => {
                          setIpExperiencingCustomer(e.target.checked);

                          // setIpCurrentSelected(e.target.checked ? 4 : -1)

                          // setIpInitial(e.target.checked)
                          // setIpBusinessPlan(e.target.checked)
                          // setIpPrototypeAvailable(e.target.checked)
                        }}
                        type="checkbox"
                        value="1"
                      />{" "}
                      Experiencing Customer
                    </div>

                    <div className="col-md-12">
                      <hr />
                      <IdeaTLRV4
                        onSelected={(id) => {
                          setTlr(id);
                        }}
                      />
                    </div>

                    <div className="col-md-12">
                      <br />
                      <input
                        defaultChecked={acceptNDA}
                        onChange={(e) => {
                          setAcceptNDA(e.target.checked);
                        }}
                        type="checkbox"
                        value="1"
                      />{" "}
                      I acknowledge that I have read and agreed to the{" "}
                      <a
                        className="links"
                        onClick={(e) => {
                          e.preventDefault();
                          setShowNDA(true);
                        }}
                      >
                        NDA
                      </a>
                      .
                    </div>

                    {msg && (
                      <div className="col-md-12">
                        <br />
                        <div
                          className={
                            hasError
                              ? "alert alert-danger mt-10"
                              : "alert alert-success mt-10"
                          }
                          role="alert"
                        >
                          {msg}
                        </div>
                      </div>
                    )}

                    <div className="col-md-12">
                      <div className="submit-btns mb-2">
                        <ExtButton
                          isLoading={isLoading}
                          type="submit"
                          className="mtr-btn"
                        >
                          <span>Save Idea</span>
                        </ExtButton>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        noMargin
        dialogClassName={"full-modal"}
        show={showNDA}
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => setShowNDA(false)}
      >
        <Modal.Body>
          <NDAText
            readOnly={true}
            secondParty=""
            secondPartyRole=""
            ideaTitle=""
          />

          <br />
          <br />

          <div className="row">
            <div className="col-md-12 text-right">
              <ExtButton
                type="button"
                className="btn btn-link mr-50"
                onClick={() => {
                  setShowNDA(false);
                  //setAcceptNDA(false)
                }}
              >
                <span>Close</span>
              </ExtButton>

              {/* <ExtButton type="button" className="mtr-btn" onClick={(e)=>{
                                setShowNDA(false)
                                setAcceptNDA(true)
                            }}><span>I understand and Agree</span></ExtButton> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default CreateIdea;
