import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import Select2 from "../../libs/Select2";
import { API_UPDATE_PROFILE, API_URL } from "../../../services/ApiEndpoints";
import axios from "axios";
import authHeader, { updateRole } from "../../../services/auth-header";
import ExtButton from "../../libs/ExtButton";
import { LOGIN_SUCCESS, UPDATE_PROFILE_STATE } from "../../../actions/types";
import FieldsSelector from "../../libs/FieldsSelector";
import ExToolTip from '../../libs/ExToolTip'
import { Modal } from "react-bootstrap";
import { useOutletContext } from "react-router-dom";

const PersonalInfo = (props) => {

    const [allowReload, setAllowReload] = useOutletContext();

    const { profile, engFieldList, pSelectedDisciplines, pSelectedSubDisciplines, sSelectedDisciplines, sSelectedSubDisciplines } = props

    const { userData } = useSelector(state => state.userData)

    const [firstName, setFirstName] = useState(props.data.first_name);
    const [lastName, setLastName] = useState(props.data.last_name);
    const [email, setEmail] = useState(props.data.email);
    const [phone, setPhone] = useState(props.data.phone);
    const [isValidPhone, setIsValidPhone] = useState(false)
    const [phoneDialCode, setPhoneDialCode] = useState(props.data.phone_code)
    const [defaultLocationCode, setDefaultLocationCode] = useState(props.data.country_iso)
    const [city, setCity] = useState(props.data.city);
    const [gender, setGender] = useState(props.data.gender);
    const [state, setState] = useState(props.data.state);
    const [country, setCountry] = useState(props.data.country);
    const [aboutMe, setAboutMe] = useState(props.data.about_me);
    const [day, setDay] = useState(props.data.day);
    const [month, setMonth] = useState(props.data.month);
    const [year, setYear] = useState(props.data.year);
    const [selectDays, setSelectDays] = useState([]);
    const [selectMonths, setSelectMonths] = useState([]);
    const [selectYears, setSelectYears] = useState([]);
    const [selectCountries, setSelectCountries] = useState(props.countries);
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("");
    const [hasError, setHasError] = useState(false);
    const [role, setRole] = useState(props.data.type_id);

    const [roleList, setRoleList] = useState(props.type)

    const [pEngField, setPEngField] = useState(profile.engineering_field)
    const [pDiscipline, setPDiscipline] = useState(profile.discipline)
    const [pSubDiscipline, setPSubDiscipline] = useState(profile.sub_discipline)

    const [sEngField, setSEngField] = useState(profile.secondary_engineering_field)
    const [sDiscipline, setSDiscipline] = useState(profile.secondary_discipline)
    const [sSubDiscipline, setSSubDiscipline] = useState(profile.secondary_sub_discipline)

    const dispatch = useDispatch()

    useEffect(() => {
        setSelectDays([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31])
        setSelectMonths([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])

        var currentYear = new Date().getFullYear()
        var yearArr = []

        for (let y = 1960; y <= currentYear; y++) {
            yearArr.push(y)
        }

        setSelectYears(yearArr)


    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();

        setIsLoading(true)
        setMsg("")

        axios.post(API_URL + API_UPDATE_PROFILE, {
            first_name: firstName,
            last_name: lastName,
            email: email,
            phone: phone.replace(" ", ""),
            phone_code: phoneDialCode,
            birth_of_day: day,
            birth_of_month: month,
            birth_of_year: year,
            gender: gender,
            state : state,
            city: city,
            country: country,
            about_me: aboutMe,
            engineering_field: pEngField,
            discipline: pDiscipline,
            sub_discipline: pSubDiscipline,
            secondary_engineering_field: sEngField,
            secondary_discipline: sDiscipline,
            secondary_sub_discipline: sSubDiscipline,
            type: role
        }, {
            headers: authHeader()
        })
            .then((responsex) => {

                setIsLoading(false)

                if (responsex.data.status == "RC200") {

                    /* update profile completeness */
                    setAllowReload(allowReload + 1)

                    setHasError(false)

                    userData.force_redirect_to_profile = responsex.data.data.force_redirect_to_profile

                    dispatch({
                        type: UPDATE_PROFILE_STATE,
                        userData: userData
                    });

                    /******** Update profile image ***********/
                    const juser = JSON.parse(localStorage.getItem("user"));

                    juser.profile_image = responsex.data.data.profile;

                    localStorage.setItem("user", JSON.stringify(juser));

                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: { user: juser }
                    });

                    let hasRole = roleList.filter((val) => val.id == role)

                    if (hasRole.length > 0) {
                        let type_name = hasRole[0].type_name
                        let datax = updateRole(role, type_name)

                        dispatch({
                            type: LOGIN_SUCCESS,
                            payload: { user: datax },
                        });
                    }

                } else {
                    setHasError(true)
                }

                setMsg(responsex.data.message)

                return responsex.data;
            });


    };

    const [modalStatus, setModalStatus] = useState(true)

    return (
        <div className={`central-meta ${userData.force_redirect_to_profile == 0 ? "card-highlight" : ""} `} id="personal-info">
            <div className="editing-info">

                {userData.force_redirect_to_profile == 0 && (
                    <div className={`custom-modal ${modalStatus ? "show" : ""}`}>
                        <div className="custom-modal-cnt text-center">
                            <p>Please update the required profile details in the highlighted section to explore SCIONS completely.</p>

                            <button onClick={(e) => {
                                setModalStatus(false)
                            }} className="mtr-btn" type="button">
                                <span>Okay</span>
                            </button>
                        </div>
                    </div>
                )}

                <h5 className="f-title"><ExToolTip placement="right" name="You may choose your primary and secondary (Optional) Topic of your focus area for convenient networking."><i className="ti-info-alt"></i></ExToolTip> Focus Area</h5>

                <h6>Primary *</h6>

                <FieldsSelector
                    field={pEngField}
                    discipline={pDiscipline}
                    subDiscipline={pSubDiscipline}
                    fieldList={engFieldList}
                    disc={pSelectedDisciplines}
                    sDisc={pSelectedSubDisciplines}
                    onSelectF={(val) => {
                        setPEngField(val)
                    }}
                    onSelectD={(val) => {
                        setPDiscipline(val)
                    }}
                    onSelectSD={(val) => {
                        setPSubDiscipline(val)
                    }}
                /><br />

                <h6>Secondary (Optional)</h6>

                <FieldsSelector
                    field={sEngField}
                    discipline={sDiscipline}
                    subDiscipline={sSubDiscipline}
                    fieldList={engFieldList}
                    disc={sSelectedDisciplines}
                    sDisc={sSelectedSubDisciplines}
                    onSelectF={(val) => {
                        setSEngField(val)
                    }}
                    onSelectD={(val) => {
                        setSDiscipline(val)
                    }}
                    onSelectSD={(val) => {
                        setSSubDiscipline(val)
                    }}
                />

                <div className="row">
                    <div className="col-md-6">
                        <br />
                        <div className="form-group">
                            <h6>What is your role on SCIONS? *</h6>
                            <Select2 value={role} onSelect={(e) => {
                                setRole(e.target.value)
                            }} name="role" keys={"type_name"} data={roleList} directValues={false} placeholder={"Select appropriate option"} />
                        </div>
                    </div>
                </div>

                <br /><br />

                <h5 className="f-title"> Personal Information</h5>

                <form onSubmit={handleSubmit} method="post">

                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <input type="text" id="inp_fname" onChange={(e) => setFirstName(e.target.value)} value={firstName} name="first_name" required="required" />
                                <label className="control-label" htmlFor="inp_fname">First Name *</label>
                                <i className="mtrl-select"></i>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <input type="text" id="inp_lname" onChange={(e) => setLastName(e.target.value)} value={lastName} name="last_name" required="required" />
                                <label className="control-label" htmlFor="inp_lname">Last Name *</label>
                                <i className="mtrl-select"></i>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <input type="text" id="inp_email" readOnly={true} onChange={(e) => setEmail(e.target.value)} value={email} name="email" required="required" />
                                <label className="control-label" htmlFor="inp_email">Email * <span className="verified">(Verified)</span></label>
                                <i className="mtrl-select"></i>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <IntlTelInput preferredCountries={[]} formatOnInit={false} separateDialCode={true} value={phone} onPhoneNumberChange={
                                    (status, phoneNumber, country, fullNumber, extension) => {
                                        setPhone(phoneNumber)
                                        setIsValidPhone(status)
                                    }
                                }
                                    onSelectFlag={
                                        (currentNumber, selectedCountryData) => {
                                            setDefaultLocationCode(selectedCountryData.iso2)
                                            setPhoneDialCode(selectedCountryData.dialCode)
                                        }
                                    }
                                    defaultCountry={defaultLocationCode.toLowerCase()}
                                    format={false}
                                    input
                                    type="tel"
                                    placeholder={'Phone Number *'}
                                    id="input-phone" />
                                <i className="mtrl-select"></i>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group " style={{ paddingRight: "0px", marginBottom:"0px" }}>Gender *</div>
                            <div className="form-radio">
                                <div className="radio">
                                    <label><input value="0" type="radio" defaultChecked={gender == 0} onChange={(e) => {
                                        setGender(parseInt(e.target.value))
                                    }} name="gender" /><i className="check-box"></i>Male</label>
                                </div>
                                <div className="radio">
                                    <label><input value="1" type="radio" defaultChecked={gender == 1} onChange={(e) => {
                                        setGender(parseInt(e.target.value))
                                    }} name="gender" /><i className="check-box"></i>Female</label>
                                </div>
                                <div className="radio">
                                    <label><input value="2" type="radio" defaultChecked={gender == 2} onChange={(e) => {
                                        setGender(parseInt(e.target.value))
                                    }} name="gender" /><i className="check-box"></i>Prefer not to say</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group " style={{ paddingRight: "0px", marginBottom:"0px" }}>DOB *</div>
                            <div className="dob">
                                <div style={{ marginRight: "10px", width: "30%" }} className="form-group">
                                    {selectDays.length > 0 && (
                                        <Select2 placeholder={"Day"} onSelect={(e) => setDay(parseInt(e.target.value))} value={day} directValues={true} keys={"day"} data={selectDays} />
                                    )}
                                </div>
                                <div style={{ marginRight: "10px", width: "30%" }} className="form-group">
                                    {selectMonths.length > 0 && (
                                        <Select2 placeholder={"Month"} onSelect={(e) => setMonth(parseInt(e.target.value))} value={month} directValues={true} keys={"month"} data={selectMonths} />
                                    )}
                                </div>
                                <div style={{ width: "33%" }} className="form-group">
                                    {selectYears.length > 0 && (
                                        <Select2 placeholder={"Year"} onSelect={(e) => setYear(parseInt(e.target.value))} value={year} directValues={true} keys={"year"} data={selectYears} />
                                    )}
                                </div>
                            </div>
                        </div>
                        
                        



                        <div className="col-md-4">
                            <div className="form-group">
                                <input type="text" id="inp_city" name="city" onChange={(e) => setCity(e.target.value)} value={city || ""} required="required" />
                                <label className="control-label" htmlFor="inp_city">City *</label>
                                <i className="mtrl-select"></i>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="form-group">
                                <input type="text" id="inp_state" name="state" onChange={(e) => setState(e.target.value)} value={state || ""}  />
                                <label className="control-label" htmlFor="inp_state">State</label>
                                <i className="mtrl-select"></i>
                            </div>
                        </div>
                    
                        {/* <div className="form-group col-md-3 dobm">Country of Residence *</div> */}

                        <div className="col-md-4 cor">
                            <div className="form-group">
                                <Select2 value={country} onSelect={(e) => setCountry(parseInt(e.target.value))} data={selectCountries} keys={"country_name"} placeholder={"Country of Residence *"} />
                            </div>
                        </div>

                    </div>

                    <div className="form-group">
                        <textarea rows="4" name="about_me" onChange={(e) => setAboutMe(e.target.value)} value={aboutMe || ""} required="required"></textarea>
                        <label className="control-label" htmlFor="textarea">About Me *</label><i className="mtrl-select"></i>
                    </div>
                    <br /><br />

                    {msg && (
                        <div className={hasError ? "alert alert-danger mt-10" : "alert alert-success mt-10"} role="alert">
                            {msg}
                        </div>
                    )}

                    <div className="submit-btns">
                        <ExtButton isLoading={isLoading} className="mtr-btn" type="submit">
                            <span>Update</span>
                        </ExtButton>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default PersonalInfo;