import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { API_GET_WHATS_NEW, API_URL } from "../../services/ApiEndpoints";
import Footer from "../layouts/Footer";
import LoaderWrapperView from "../libs/LoaderWrapperView";
import CommonPagesFooter from "./CommonPagesFooter";
import packageJson from '../../../package.json'

const WhatsNew = () =>{

    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState({})

    const getData = () =>{

        setIsLoading(true)
        
        axios
        .get(API_URL + API_GET_WHATS_NEW)
        .then((response)=>{
            
            setIsLoading(false)

            const json = response.data

            if(json.status=="RC200"){
                setData(json.data)
            }

        })
        .catch((error)=>{
            setIsLoading(false)
        });
    }

    useEffect(()=>{
        getData()
    }, [])

    return(
        <>
            <div className="theme-layout">

                <CommonPagesFooter />

                <div style={{marginTop:"60px"}}>
                    <div className="container">
                        <br />    
                        <LoaderWrapperView isLoading={isLoading}>

                            <div className="central-meta text-justify">
                                <h4>Current Versions</h4>
                                <hr />

                                <div className="row">
                                    <div className="col-md-6">
                                        {data.laravel && (
                                            <table className="table table-bordered">
                                                <tr>
                                                    <th className="text-center">Back-End (API)</th>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>Current Version :</strong> {data.laravel.version_name}<br />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>Updated Date :</strong> {data.laravel.date} <br />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="whats-new">
                                                        <strong>Whats New :</strong><br />
                                                        { data.laravel.whats_new && data.laravel.whats_new.split("\n").map((str, index) => <p key={`px-${index}`}>{str}</p>)}
                                                    </td>
                                                </tr>
                                            </table>
                                        )}
                                    </div>

                                    <div className="col-md-6">
                                        {data.react && (
                                            <table className="table table-bordered">
                                                <tr>
                                                    <th className="text-center">Front-End</th>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>Current Version :</strong> {packageJson.version}<br />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>Updated Date :</strong> {data.react.date} <br />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="whats-new">
                                                        <strong>Whats New :</strong><br />
                                                        { data.react.whats_new && data.react.whats_new.split("\n").map((str, index) => <p key={`px-${index}`} >{str}</p>)}
                                                    </td>
                                                </tr>
                                            </table>
                                        )}
                                        
                                    </div>
                                </div>

                                <strong>Notes: </strong>
                                For version updates identification we have divided it in 3 classification like x.y.z (eg. 1.0.0)<br />
                                x = New Features introduced<br />
                                y = Existing Features improved/changes/updates<br />
                                z = Bugs/glitch fixed <br />
                            </div>

                        </LoaderWrapperView>
                    </div>
                </div>
                
                <Footer />
            </div>
        </>
    )

}

export default WhatsNew