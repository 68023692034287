import { useEffect, useState } from "react"

const AddMoreFields = (props) =>{

    const {lst, key, title} = props

    const [list, setList] = useState(lst)

    useEffect(()=>{
        setList(lst)
    }, [lst])

    const addNew = () =>{
        props.onAdd && props.onAdd({id:-1, name:"", filename:null, document: ""})
    }

    const handleChange = (index, e) => {
        props.onNameChange && props.onNameChange(index, e.target.value)
    }

    const handleChangeFile = (index, filename) => {
        props.onFileChange && props.onFileChange(index, filename)
    }

    return(
        <>
            <h6>{title}</h6><br />
            {list && list.length>0 ? (
                list.map((item, index)=>(
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group ">
                                <input name="name" value={item.name || ""} onChange={
                                    (e) => handleChange(index, e)
                                } type="text" id={key + "-doc-name-"+ index} required="required" />
                                <label className="control-label" htmlFor={key +"-doc-name-"+ index}>Enter Name *</label>
                                <i className="mtrl-select"></i>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <input onClick={ (e) => e.target.value="" } onChange={ (e)=> {
                                var files = e.target.files;

                                if(files.length==0) return;

                                handleChangeFile(index, files[0])

                            }} name="" className="form-control-file file-picker-theme" type="file" />

                            {item.document && (
                                <small><a href={item.document} target="_blank">Download Document</a></small>
                            )}

                        </div>
                        <div className="col-md-2">
                            <div className="text-center form-group ">
                                <a onClick={ (e) =>{ props.onRemove && props.onRemove(index) } } className="btn btn-link text-danger"><i className="fa fa-close"></i></a>
                            </div>
                        </div>
                    </div>
                ))
            ) : ("Please add atleast one document")}

            <div className="submit-btns col-md-12">
                <a onClick={addNew}><i className="fa fa-plus-circle"></i> Add</a><br /><br />
            </div>
        </>
    )


}

export default AddMoreFields;