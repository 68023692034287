import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import authHeader from "../../../services/auth-header";
import { API_UPDATE_INTEREST, API_URL } from "../../../services/ApiEndpoints";
import ExtButton from "../../libs/ExtButton";
import { useOutletContext } from "react-router-dom";

const Interests = (props) =>{

    const [allowReload, setAllowReload] = useOutletContext();

    const { interests } = props

    const [data, setData] = useState(interests)

    useEffect(() => {
        setData(interests)
    }, [interests])

    const [interest, setInterest] = useState("")

    const [isLoading, setIsLoading] = useState(false)
    const [hasError, setHasError] = useState(false) 
    const [msg, setMsg] = useState("")

    const addNew = (e) =>{
        e.preventDefault()

        if(interest=="")
            return

        setData( data.concat(interest) )
        setInterest("")

    }

    const removeInterest = (index, e) =>{
        e.preventDefault()

        const lst = [...data]

        lst.splice(index, 1)

        setData(lst)
    }

    const saveInerest = () => {
        
        setHasError(false)
        setMsg("")
        setIsLoading(true)

        var formdata = new FormData()

        data.map((item, index)=>{
            formdata.append("intrest["+ index +"]", item)
        })


        axios.post(API_URL + API_UPDATE_INTEREST, formdata,{
            headers:authHeader()
        })
        .then((responsex) => {

            setIsLoading(false)

            if(responsex.data.status=="RC200"){
                setHasError(false)

                /* update profile completeness */
                setAllowReload(allowReload+1)
            }else{
                setHasError(true)
            }

            setMsg(responsex.data.message)

            return responsex.data;
        });

    }

    return(
        <div className="central-meta" id="interests">
            <div className="editing-interest">
                <h5 className="f-title">My interests</h5>
                <form method="post">
                    <input value={interest} onChange={ (e) => setInterest(e.target.value) } type="text" placeholder="Photography, Cycling, traveling." />
                    
                    <button type="button" onClick={addNew}>Add</button>

                    { data.length>0 && (
                        <ol className="interest-added">
                            { data.map( (item, index)=> {
                                return(
                                    <li key={index}>
                                        <a href="#" onClick={ (e) => removeInterest(index, e)} title="">{item}
                                        <span className="remove" title="remove">
                                            <i className="fa fa-close"></i>
                                        </span></a>
                                    </li>
                                )
                            }) }
                        </ol>
                    )}

                    {msg && (
                        <div className={ hasError ? "alert alert-danger mt-10" : "alert alert-success mt-10" } role="alert">
                            {msg}
                        </div>
                    )}

                    <div className="submit-btns">
                        <ExtButton isLoading={isLoading} onClick={saveInerest} className="mtr-btn" type="button">
                            <span>Update</span>
                        </ExtButton>
                    </div>
                </form>
            </div>
        </div>

    );
};

export default Interests;