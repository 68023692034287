import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useParams } from "react-router-dom";
import { API_PROBLEM_DETAILS_PUBLIC, API_URL } from "../../../../services/ApiEndpoints";
import authHeader from "../../../../services/auth-header";
import { print_problem_status } from "../../Consts";

const ProblemOffersView = () =>{

    let {slug} = useParams();
    const [isLoading, setIsLoading] = useState(false)

    const [data, setData] = useState({})

    const getDetails = () =>{

        setIsLoading(true)

        axios
        .get(API_URL + API_PROBLEM_DETAILS_PUBLIC +"/"+ slug,{
            headers: authHeader()
        })
        .then((response)=>{
            
            if(response.status==200){

                const json = response.data;

                if(json.status=="RC200"){

                    var data = json.data;

                    setData(data)

                }

            }

            setIsLoading(false)

        })
        .catch((error)=>{
            setIsLoading(false)
        });

    }

    useEffect(()=>{
        getDetails()
    }, [])

    return(
        <section>
            <div className="gap gray-bg">
                <div className="container">
                    
                    { isLoading ? (<p>Loading...</p>) : (
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="row merged20" id="page-contents">
                                    <div className="col-md-12">
                                        <div className="central-meta">
                                            <h3>{data.pain_point}</h3>
                                            <h6 style={{"color":"#f05a24"}} className="mb-3">{data.unique_id}</h6>
                                            <em>Added: {data.date}</em>
                                            <br />
                                            Status: &nbsp; {print_problem_status(data)}

                                            <div className="frnds mt-4">
                                                <ul className="nav nav-tabs mb-2">
                                                    <li className="nav-item">
                                                        <NavLink to={"offers"} >My Offers</NavLink>
                                                    </li>
                                                    <li className="nav-item">
                                                        <NavLink to={"chat"} >Chat(s)</NavLink>
                                                    </li>
                                                    <li className="nav-item">
                                                        <NavLink to={"details"} >Business Problem Details</NavLink>
                                                    </li>
                                                </ul>

                                                <div className="gap gray-bg">
                                                    <div className="container">
                                                        <Outlet />
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </section>
    );
};


export default ProblemOffersView;