import { useEffect, useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { API_MY_SCIONS_COUNTS } from "../../../services/ApiEndpoints";
import { ApiServices } from "../../../services/ApiServices";


const MyScions = () => {

    const [isLoading, setIsLoading] = useState(true)

    const response = ApiServices({
        method:"GET",
        url: API_MY_SCIONS_COUNTS
    });

    const [totalFriends, setTotalFriends] = useState(0)
    const [totalFriendRequest, setTotalFriendRequest] = useState(0)

    useEffect(()=>{
        
        if(response.has){

            if(response.status){
                
                setTotalFriends(response.data.freind_count)
                setTotalFriendRequest(response.data.request_count)

            }

            setIsLoading(false)

        }

    });

    return(
        <div className="gap gray-bg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row merged20" id="page-contents">
                            <div className="col-md-12">
                                <div className="central-meta">
                                    <div className="frnds">
                                        <ul className="nav nav-tabs">
                                            <li className="nav-item">
                                                <NavLink title="" data-ripple="" data-toggle="tab" to='/profile/scions/my-scions'>My SCIONS</NavLink>
                                                <span>{totalFriends}</span>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink title="" data-ripple="" data-toggle="tab" to='/profile/scions/friend-request'>SCIONS Requests</NavLink>
                                                <span>{totalFriendRequest}</span>
                                            </li>
                                        </ul>

                                        <div className="tab-content">
                                            
                                            <Outlet />
                                            
                                        </div>
                                    </div>
                                </div>
                                
                            </div>

                            {/* <div className="col-lg-3">
                                <aside className="sidebar static">
                                    <div className="widget">
                                        <h4 className="widget-title">Your page</h4>
                                        <div className="your-page">
                                            <figure>
                                                <a title="" href="#"><img alt="" src="images/resources/friend-avatar9.jpg" /></a>
                                            </figure>
                                            <div className="page-meta">
                                                <a className="underline" title="" href="#">My page</a>
                                                <span><i className="ti-comment"></i>Messages <em>9</em></span>
                                                <span><i className="ti-bell"></i>Notifications <em>2</em></span>
                                            </div>
                                            <div className="page-likes">
                                                <ul className="nav nav-tabs likes-btn">
                                                    <li className="nav-item"><a data-toggle="tab" href="#link1" className="active">likes</a></li>
                                                    <li className="nav-item"><a data-toggle="tab" href="#link2" className="">views</a></li>
                                                </ul>
                                                
                                                <div className="tab-content">
                                                    <div id="link1" className="tab-pane active fade show">
                                                        <span><i className="ti-heart"></i>884</span>
                                                        <a title="weekly-likes" href="#">35 new likes this week</a>
                                                        <div className="users-thumb-list">
                                                            <a data-toggle="tooltip" title="" href="#" data-original-title="Anderw">
                                                                <img alt="" src="images/resources/userlist-1.jpg" />
                                                            </a>
                                                            <a data-toggle="tooltip" title="" href="#" data-original-title="frank">
                                                                <img alt="" src="images/resources/userlist-2.jpg" />
                                                            </a>
                                                            <a data-toggle="tooltip" title="" href="#" data-original-title="Sara">
                                                                <img alt="" src="images/resources/userlist-3.jpg" />
                                                            </a>
                                                            <a data-toggle="tooltip" title="" href="#" data-original-title="Amy">
                                                                <img alt="" src="images/resources/userlist-4.jpg" />
                                                            </a>
                                                            <a data-toggle="tooltip" title="" href="#" data-original-title="Ema">
                                                                <img alt="" src="images/resources/userlist-5.jpg" />
                                                            </a>
                                                            <a data-toggle="tooltip" title="" href="#" data-original-title="Sophie">
                                                                <img alt="" src="images/resources/userlist-6.jpg" />
                                                            </a>
                                                            <a data-toggle="tooltip" title="" href="#" data-original-title="Maria">
                                                                <img alt="" src="images/resources/userlist-7.jpg" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div id="link2" className="tab-pane fade">
                                                        <span><i className="ti-eye"></i>445</span>
                                                        <a title="weekly-likes" href="#">440 new views this week</a>
                                                        <div className="users-thumb-list">
                                                            <a data-toggle="tooltip" title="" href="#" data-original-title="Anderw">
                                                                <img alt="" src="images/resources/userlist-1.jpg" />
                                                            </a>
                                                            <a data-toggle="tooltip" title="" href="#" data-original-title="frank">
                                                                <img alt="" src="images/resources/userlist-2.jpg" />
                                                            </a>
                                                            <a data-toggle="tooltip" title="" href="#" data-original-title="Sara">
                                                                <img alt="" src="images/resources/userlist-3.jpg" />
                                                            </a>
                                                            <a data-toggle="tooltip" title="" href="#" data-original-title="Amy">
                                                                <img alt="" src="images/resources/userlist-4.jpg" />
                                                            </a>
                                                            <a data-toggle="tooltip" title="" href="#" data-original-title="Ema">
                                                                <img alt="" src="images/resources/userlist-5.jpg" />
                                                            </a>
                                                            <a data-toggle="tooltip" title="" href="#" data-original-title="Sophie">
                                                                <img alt="" src="images/resources/userlist-6.jpg" />
                                                            </a>
                                                            <a data-toggle="tooltip" title="" href="#" data-original-title="Maria">
                                                                <img alt="" src="images/resources/userlist-7.jpg" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="widget stick-widget">
                                        <h4 className="widget-title">Who's follownig</h4>
                                        <ul className="followers ps-container ps-theme-default ps-active-y" data-ps-id="b028db8f-efda-35ea-e30a-3a0fa0715cf2">
                                            <li>
                                                <figure><img src="images/resources/friend-avatar2.jpg" alt="" /></figure>
                                                <div className="friend-meta">
                                                    <h4><a href="time-line.html" title="">Kelly Bill</a></h4>
                                                    <a href="#" title="" className="underline">Add Friend</a>
                                                </div>
                                            </li>
                                            <li>
                                                <figure><img src="images/resources/friend-avatar4.jpg" alt="" /></figure>
                                                <div className="friend-meta">
                                                    <h4><a href="time-line.html" title="">Issabel</a></h4>
                                                    <a href="#" title="" className="underline">Add Friend</a>
                                                </div>
                                            </li>
                                            <li>
                                                <figure><img src="images/resources/friend-avatar6.jpg" alt="" /></figure>
                                                <div className="friend-meta">
                                                    <h4><a href="time-line.html" title="">Andrew</a></h4>
                                                    <a href="#" title="" className="underline">Add Friend</a>
                                                </div>
                                            </li>
                                            <li>
                                                <figure><img src="images/resources/friend-avatar8.jpg" alt="" /></figure>
                                                <div className="friend-meta">
                                                    <h4><a href="time-line.html" title="">Sophia</a></h4>
                                                    <a href="#" title="" className="underline">Add Friend</a>
                                                </div>
                                            </li>
                                            <li>
                                                <figure><img src="images/resources/friend-avatar3.jpg" alt="" /></figure>
                                                <div className="friend-meta">
                                                    <h4><a href="time-line.html" title="">Allen</a></h4>
                                                    <a href="#" title="" className="underline">Add Friend</a>
                                                </div>
                                            </li>
                                            <div className="ps-scrollbar-x-rail" style={{left:"0px",bottom:"0px"}}>
                                                <div className="ps-scrollbar-x" tabIndex="0" style={{left:"0px",width:"0px"}} ></div>
                                            </div>
                                            <div className="ps-scrollbar-y-rail" style={{top:"0px",height:"260px", right:"0px"}} >
                                                <div className="ps-scrollbar-y" tabIndex="0" style={{top:"0px",height:"187px"}}></div>
                                            </div>
                                        </ul>
                                    </div>
                                    
                                </aside>
                            </div> */}
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )

}


export default MyScions;