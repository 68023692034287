export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_MESSAGE = "SET_MESSAGE";
export const MSG_SUCCESS = "MSG_SUCCESS";
export const MSG_ERROR = "MSG_ERROR";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const SET_MESSAGE_TYPE = "SET_MESSAGE_TYPE";
export const UPDATE_PROFILE_STATE = "userData"
export const OPEN_CHAT = "triggerChatOpenEvent"

export const LOGIN_FAIL_REACTIVATE_ACCOUNT = "RC201"