import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { API_GET_IDEA, API_IDEA_MAKE_OFFER, API_URL } from "../../../services/ApiEndpoints";
import authHeader from "../../../services/auth-header";
import ExtButton from "../../libs/ExtButton";
import Popups from "../../libs/Popups";

const IdeaOffersIdeaDetails = () =>{

    let {slug} = useParams();
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState({})

    // make sure to set order when change this, 0,1,2
    const listPatientedWith = ["EU Body","USA Body","Asian Organization"]

    const getDetails = () =>{

        setIsLoading(true)

        axios
        .get(API_URL + API_GET_IDEA +"/"+ slug,{
            headers: authHeader()
        })
        .then((response)=>{

            setIsLoading(false)
            
            if(response.status==200){

                const json = response.data;

                if(json.status=="RC200"){

                    var data = json.data;

                    setData(data)

                }

            }

        })
        .catch((error)=>{
            setIsLoading(false)
        });

    }

    useEffect(()=>{
        getDetails()
    }, [])

    return(
        <section>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            
                            {isLoading ? (
                                <>Loading</>
                            ) : (
                                <div id="page-contents" className="row merged20">
                                    <div className="col-lg-12">

                                        <div className="main-wraper" id="idea_panel">
                                            <div className="row">
                                                <div className="col-md-7">
                                                    <div className="course-details">
                                                        <div className="row">
                                                            <div className="col-md-7">
                                                                <h3>{data.idea_title}</h3>
                                                                <h5></h5>
                                                            </div>
                                                            
                                                            {data.idea_patented===1 && (
                                                                <div className="col-md-5"><span className="course-price">Patented with {data.idea_patented_with}</span><span className="course-price">Patent ID: {data.patent_number}</span></div>
                                                                // <div className="col-md-5"><span className="course-price">* Patented with {listPatientedWith[data.idea_patented_with]}</span></div>
                                                            )}

                                                        </div>
                                                        <div className="create-by">
                                                            <figure><img src={data.profile_image} style={{width:"45px", height:"45px"}} alt="" /></figure>
                                                            <div>
                                                                <span>{data.full_name}&nbsp;&nbsp;
                                                                    <i className="fa fa-comment" title="Chat with Owner" data-toggle="tooltip" data-placement="right"></i>
                                                                </span>
                                                                <em>Added: {data.date}</em>
                                                            </div>
                                                        </div>

                                                        <p className="mt-4">
                                                            { data.introduction && data.introduction.split("\n").map((str, index) => <p key={`px-${index}`}>{str}</p>)}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="col-md-5">
                                                    <div className="course-video">
                                                        <figure><img style={{maxHeight:"200px", width:"auto"}} src={data.idea_image} alt="" /></figure>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <h4 className="idea-title">Details of the Idea</h4>
                                                    <strong>Abstract</strong><br />
                                                    <hr />
                                                    { data.abstract && data.abstract.split("\n").map((str, index) => <p key={`px-${index}`}>{str}</p>)}
                                                    <br />

                                                    <strong>Description</strong><br />
                                                    <hr />
                                                    { data.description && data.description.split("\n").map((str, index) => <p key={`px-${index}`}>{str}</p>)}

                                                </div>

                                                <div className="col-md-6">
                                                    <h4 className="idea-title">Collaborate with Owner</h4>

                                                    <div className="accordion mt-0" id="accordion">
                                                        <div className="card">
                                                            <div className="card-header" id="headingOne">
                                                                <h5 className="mb-0">
                                                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">DOCUMENTS</button>
                                                                </h5>
                                                            </div> 

                                                            <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                                <div className="card-body">

                                                                    <h6>Intellectual Property Documents</h6>

                                                                    {data.intellectual_property_documents && data.intellectual_property_documents.length>0 ? (
                                                                        <ul>
                                                                            {data.intellectual_property_documents.map((val, index)=>(
                                                                                <li><a target="_blank" href={val}>Document {index+1}</a></li>
                                                                            ))}
                                                                        </ul>
                                                                    ) : (
                                                                        <p>Documents not uploaded</p>
                                                                    )}

                                                                    <hr />
                                                                    <h6>Other Documents</h6>

                                                                    {data.other_document && data.other_document.length>0 ? (
                                                                        <ul>
                                                                            {data.other_document.map((val, index)=>(
                                                                                <li><a target="_blank" href={val}>Document {index+1}</a></li>
                                                                            ))}
                                                                        </ul>
                                                                    ):(
                                                                        <p>Documents not uploaded</p>
                                                                    )}

                                                                    <hr />

                                                                    <h6>Certifications</h6>

                                                                    {data.certifications && data.certifications.length>0 ? (
                                                                        <ul>
                                                                            {data.certifications.map((val, index)=>(
                                                                                <li><a target="_blank" href={val}>Certification {index+1}</a></li>
                                                                            ))}
                                                                        </ul>
                                                                    ): (
                                                                        <p>Certificates not uploaded</p>
                                                                    )}

                                                                </div>
                                                            </div>

                                                            <div className="card-header" id="headingtwo">
                                                                <h5 className="mb-0">
                                                                    <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapsetwo" aria-expanded="true" aria-controls="collapsetwo">IDEA PHASE</button>
                                                                </h5>
                                                            </div>

                                                            <div id="collapsetwo" className="collapse show" aria-labelledby="headingtwo" data-parent="#accordion">
                                                                <div className="card-body">
                                                                    <div className="steps-form">
                                                                        <div className="steps-row setup-panel">
                                                                            <div className="steps-step">
                                                                                <i className="fa fa-check fa-2x" style={{color: (data.idea_phase_initial==1 ? "#079747" : "#CCC") }}></i>
                                                                                <p>
                                                                                    Initial<br />
                                                                                    Start
                                                                                </p>
                                                                            </div>
                                                                            <div className="steps-step">
                                                                                <i className="fa fa-check fa-2x" style={{color: (data.idea_business_plan==1 ? "#079747" : "#CCC") }}></i>
                                                                                <p>
                                                                                    Business Plan<br />
                                                                                    Ready
                                                                                </p>
                                                                            </div>
                                                                            <div className="steps-step">
                                                                                <i className="fa fa-check fa-2x" style={{color: (data.idea_prototype_available==1 ? "#079747" : "#CCC") }}></i>
                                                                                <p>
                                                                                    Prototype<br />
                                                                                    Available
                                                                                </p>
                                                                            </div>
                                                                            <div className="steps-step">
                                                                                <i className="fa fa-check fa-2x" style={{color: (data.idea_experiencing_customer==1 ? "#079747" : "#CCC")}}></i>
                                                                                <p>Experiencing Customer</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                {data.has_sell_idea==1 && (
                                                    <SellIdea data={data} />
                                                )}

                                                {data.has_collaborate==1 && (
                                                    <CollaboratePartner data={data} />
                                                )}

                                                {data.has_fund_investor==1 && (
                                                    <Investor data={data} />
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            
                        </div>
                    </div>
                </div>
        </section>
    )
}

const SellIdea = (props) =>{

    const { data } = props


    return(
        <div className="col-md-12 mt-5">
            <h6>Idea owner wants to sell his idea through SCIONS</h6>

            <div className="row">
                <div className="form-group col-md-12">Offer expectation: €{data.sell_idea_amount} {data.sell_idea_price_fixed===0 && ("(This price is Negotiable)")} </div>

                <div className="form-group col-md-12">Offer valid till: {data.sell_idea_offer_valid_till}</div>

                <div className="form-group col-md-2">Ownership Information:</div>
                <div className="form-group col-md-10">
                    {data.sell_idea_ownership_info && data.sell_idea_ownership_info.split("\n").map((str, index) => <p key={`px-${index}`}>{str}</p>)}
                </div>

                <div className="form-group col-md-2">Payment Conditions:</div>
                <div className="form-group col-md-10">
                    {data.sell_idea_pay_condition && data.sell_idea_pay_condition.split("\n").map((str, index) => <p key={`px-${index}`}>{str}</p>)}
                </div>

                <div className="form-group col-md-2">Post-Settlement:</div>
                <div className="form-group col-md-10">
                    {data.sell_idea_post_settlement && data.sell_idea_post_settlement.split("\n").map((str, index) => <p key={`px-${index}`}>{str}</p>)}
                </div>

            </div>
        </div>
    )
}

const CollaboratePartner = (props) =>{

    const { data } = props


    return(
        <div className="col-md-12 mt-5">
            <h6>Idea owner wants to Collaborate/Partner with like minded SCIONS</h6>
            <div className="row">
                <div className="form-group col-md-2">Position offered:</div>
                <div className="form-group col-md-10">{data.collaborate_position_offered}</div>

                <div className="form-group col-md-2">Partnership proposal:</div>
                <div className="form-group col-md-10">
                    {data.collaborate_partnership_proposal && data.collaborate_partnership_proposal.split("\n").map((str, index) => <p key={`px-${index}`}>{str}</p>)}
                </div>

                <div className="form-group col-md-2">Conditions:</div>
                <div className="form-group col-md-10">
                    { data.collaborate_conditions && data.collaborate_conditions.split("\n").map((str, index) => <p key={`px-${index}`}>{str}</p>)}
                </div>

                <div className="form-radio col-md-2">Deal Completed</div>
                <div className="form-radio col-md-10">
                    {data.collaborate_is_completed==0 ? "No" : "Yes"}
                </div>

                
            </div>
        </div>
    )
}

const Investor = (props) =>{

    const { data } = props

    const typeList = [
        "Loan",
        "Investment"
    ]

    const stageList = [
        // "Startup stage",
        "Idea Stage",
        "Pre-Seed Stage",
        "Seed Stage",
        "Early Stage",
        "Growth Stage",
        "Expansion Stage"
    ]


    return(
        <div className="col-md-12 mt-5">
            <h6>Idea Owner is looking for Investors</h6>
            <div className="row">
                <div className="form-group col-md-2">Ownership information:</div>
                <div className="form-group col-md-10">
                    {data.fund_investor_ownership_info && data.fund_investor_ownership_info.split("\n").map((str, index) => <p key={`px-${index}`}>{str}</p>)}
                </div>

                <div className="form-group col-md-2">Investment Type:</div>
                <div className="form-group col-md-10">
                    {typeList[data.fund_investment_type]}
                </div>

                <div className="form-group col-md-2">Startup Stage:</div>
                <div className="form-group col-md-10">
                    {stageList[data.fund_investment_stage]}
                </div>

                <div className="form-group col-md-2">Investment Offer:</div>
                <div className="col-md-10">
                    {data.fund_investment_offer && data.fund_investment_offer.split("\n").map((str, index) => <p key={`px-${index}`}>{str}</p>)}
                </div>

                <div className="form-group col-md-2">Loan Offer:</div>
                <div className="col-md-10">
                    {data.fund_investment_loan_offer && data.fund_investment_loan_offer.split("\n").map((str, index) => <p key={`px-${index}`}>{str}</p>)}
                </div>

                <div className="form-radio col-md-2">Investment Completed</div>
                <div className="form-radio col-md-10">
                    {data.fund_investment_is_completed==0 ? "No" : "Yes"}
                </div>

                
            </div>
        </div>
    )
}

export default IdeaOffersIdeaDetails;