import React, { Component, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import Select2 from "./libs/Select2";
import { ApiServices } from "../services/ApiServices";
import {
  API_GET_ENGINEERING_FIELDS,
  RE_CAPTCHA_SITE_KEY,
} from "../services/ApiEndpoints";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../actions/auth";
import { MSG_ERROR, MSG_SUCCESS, SET_MESSAGE } from "../actions/types";
import ExtButton from "./libs/ExtButton";
import { DatePicker, df, mode } from "../helpers/DatePicker";
import CompLoaderLayout from "./libs/CompLoaderLayout";
import PasswordField from "./libs/PasswordField";
import ReCaptchaV3 from "./libs/ReCaptchaV3";
import FormReCaptchaV3 from "./libs/FormReCaptchaV3";

const Register = () => {
  const form = useRef();

  const dispatch = useDispatch();

  const { message } = useSelector((state) => state.message);
  const { msgType } = useSelector((state) => state.msgType);

  const [registerAs, setRegisterAs] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [dob, setDOB] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneDialCode, setPhoneDialCode] = useState("");
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [country, setCountry] = useState(null);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [receiveNews, setReceiveNews] = useState(false);
  const [role, setRole] = useState(-1);
  // const [selEngField, setSelEngField] = useState(-1)

  const [engineeringField, setEngineeringField] = useState([]);
  const [userTypes, setUserTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [submitLoading, setSubmitLoading] = useState(false);

  const [defaultLocationCode, setDefaultLocationCode] = useState("");

  const response = ApiServices({
    method: "GET",
    url: API_GET_ENGINEERING_FIELDS,
  });

  useEffect(() => {
    if (response.has) {
      if (response.status) {
        setEngineeringField(response.data.engineering_field);
        setUserTypes(response.data.type);

        if(roleRef.current){
          roleRef.current.updateSelect(
            response.data.type.filter((v)=>v.is_org_type== (registerAs ? 1 :0) )
          )
        }

        setIsLoading(false);
        setDefaultLocationCode(response.data.default_location.country_code);
        setPhoneDialCode(response.data.default_dial_code);
      }

      if (!response.status) {
        setIsLoading(false);
      }
    }
  });

  useEffect(()=>{

    if(roleRef.current){
      roleRef.current.updateSelect(
        userTypes.filter((v)=>v.is_org_type== (registerAs ? 1 :0) )
      )
    }

  }, [registerAs])

  const roleRef = useRef()

  // useEffect(()=>{

  //   console.log("userType", userTypes)

  // }, [userTypes])

  /******** Form Submit Process *******/
  const handleSubmit = (captchaToken) => {
    setPhone(phone ? phone : "");

    if (country == null) {
      dispatch({
        type: SET_MESSAGE,
        payload: "Please select phone number",
      });
      return;
    }

    setSubmitLoading(true);

    dispatch(
      register(
        firstName,
        lastName,
        registerAs,
        email,
        password,
        confirmPassword,
        phone,
        country.dialCode,
        role,
        dob,
        termsAndConditions ? 1 : 0,
        receiveNews ? 1 : 0,
        captchaToken
      )
    )
      .then((response) => {
        if (response.status == "RC200") {
          setFirstName("");
          setLastName("");
          setPhone("");
          setCountry(null);
          setIsValidPhone(false);
          setEmail("");
          setPassword("");
          setConfirmPassword("");
          setRole(-1);
          setDOB("");
          setTermsAndConditions(false);
          setReceiveNews(false);
        }

        dispatch({
          type: SET_MESSAGE,
          msgType: response.status == "RC200" ? MSG_SUCCESS : MSG_ERROR,
          payload: response.message,
        });

        setSubmitLoading(false);
      })
      .catch(() => {
        dispatch({
          type: SET_MESSAGE,
          payload: "Registration failed, please try again...",
        });

        setSubmitLoading(false);
      });
  };

  return isLoading ? (
    <CompLoaderLayout className="full" />
  ) : (
    <div className="theme-layout">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 text-center mt-3">
            <a href="/">
              <img width="240px" src={require("../assets/images/logo-big.png")} alt="" />
            </a>
          </div>

          <div className="col-md-12">
            <div
              className="log-reg-area mt-5"
              style={{ margin: "0 auto 100px auto" }}
            >
              <h3 className="log-title text-center">Register</h3>

              <h6 className="text-center">A New SCION? Join now</h6>

              <br />
              <FormReCaptchaV3
                onLoadStatusUpdate={(status) => {
                  setSubmitLoading(status);
                }}
                action="register"
                onSubmit={handleSubmit}
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="input-fname"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required="required"
                      />
                      <label className="control-label" htmlFor="input-fname">
                        First Name *
                      </label>
                      <i className="mtrl-select"></i>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="input-fname"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required="required"
                      />
                      <label className="control-label" htmlFor="input-fname">
                        Last Name *
                      </label>
                      <i className="mtrl-select"></i>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="input-email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required="required"
                      />
                      <label className="control-label" htmlFor="input-email">
                        Email (Username) *
                      </label>
                      <i className="mtrl-select"></i>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <IntlTelInput
                        preferredCountries={[]}
                        separateDialCode={true}
                        value={phone}
                        onPhoneNumberChange={(
                          status,
                          phoneNumber,
                          country,
                          fullNumber,
                          extension
                        ) => {
                          setPhone(phoneNumber);
                          setCountry(country);
                          setIsValidPhone(status);
                        }}
                        defaultCountry={defaultLocationCode.toLowerCase()}
                        format={false}
                        input
                        type="tel"
                        placeholder={"Phone Number *"}
                        id="input-phone"
                      />
                      <i className="mtrl-select"></i>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <PasswordField
                      value={password}
                      label="Password"
                      keys="pwd"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6">
                    <PasswordField
                      value={confirmPassword}
                      label="Confirm Password"
                      keys="confpwd"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row">
                  {/* <div className="col-md-12">
                                            <div className="form-group">
                                                {isLoading ? (
                                                    <p>loading...</p>
                                                ) : (
                                                    <Select2 value={selEngField} onSelect={
                                                        (e) => setSelEngField(e.target.value)
                                                    } required={false} keys={"field_name"} placeholder={"Please select your field *"} data={engineeringField} />
                                                )}
                                            </div>
                                        </div> */}

                  <div className="col-md-12">
                      Are you an individual or belongs to an organization?
                      <div className="form-radio">
                        <div className="radio">
                              <label><input value="0" type="radio" defaultChecked={registerAs==0} onChange={(e) => {
                                  setRegisterAs(parseInt(e.target.value))
                              }} name="user_type" /><i className="check-box"></i>Individual</label>
                          </div>
                          <div className="radio">
                              <label><input value="1" type="radio" defaultChecked={registerAs==1} onChange={(e) => {
                                  setRegisterAs(parseInt(e.target.value))
                              }} name="user_type" /><i className="check-box"></i>Organization</label>
                          </div>
                  </div>
                      
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <Select2
                        value={role}
                        onSelect={(e) => {
                          setRole(e.target.value);
                        }}
                        name="role"
                        keys={"type_name"}
                        data={userTypes}
                        ref={roleRef}
                        directValues={false}
                        placeholder={"What is your role on SCIONS? *"}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <DatePicker
                      allowFutureDate={false}
                      readOnly={true}
                      format={df.DMY}
                      mode={mode.day}
                      value={dob}
                      onChange={(e, date) => {
                        setDOB(date);
                      }}
                      required={true}
                    >
                      Date of Birth *
                    </DatePicker>
                  </div>
                </div>

                <div className="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      onChange={(e) => setTermsAndConditions(e.target.checked)}
                      defaultChecked={termsAndConditions}
                    />
                    <i className="check-box"></i>I acknowledge that I have read
                    and agreed to the{" "}
                    <a
                      className="links"
                      target="_blank"
                      href="/terms-and-conditions"
                    >
                      Terms & Conditions
                    </a>
                    ,{" "}
                    <a target="_blank" className="links" href="/privacy-policy">
                      Privacy Policy
                    </a>{" "}
                    and{" "}
                    <a target="_blank" href="/cookie-policy" className="links">
                      Cookie Policy
                    </a>
                    .
                  </label>
                </div>

                <div className="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      onChange={(e) => setReceiveNews(e.target.checked)}
                      defaultChecked={receiveNews}
                    />
                    <i className="check-box"></i>I want to receive news, feature updates, discounts and offers from Scions
                  </label>
                </div>

                <div className="alert alert-info" style={{padding: "10px", background:"#ffefea", borderColor:"#ffefea"}}>
                    <div  style={{alignItems: "center", display:"flex", justifyContent:"end"}}>
                        <img style={{width:"32px"}} src="/coins.png" />
                        <div style={{"font-size":"14px", "margin-left": "10px"}}>On registration we are providing 1 SCIONSCoin(s) to you, with 1 month validity, FREE. On your first login, you can use this SCIONSCoin(s) and use it to execute SCIONS proposition.</div>
                    </div>
                </div>

                {message && (
                  <div
                    className={
                      msgType == MSG_SUCCESS
                        ? "alert alert-success"
                        : "alert alert-danger"
                    }
                    role="alert"
                  >
                    {message}
                  </div>
                )}

                <ExtButton
                  isLoading={submitLoading}
                  className="mtr-btn signup"
                  type="submit"
                >
                  <span>Register</span>
                </ExtButton>

                <br />
                <br />

                <Link to="/login" className="already-have">
                  Already have an account
                </Link>
              </FormReCaptchaV3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
