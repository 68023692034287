import axios from "axios";
import { data } from "jquery";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useLocation } from "react-router-dom";
import { API_FIELD_TREE, API_JOB_LIST, API_URL } from "../../../services/ApiEndpoints";
import authHeader from "../../../services/auth-header";
import LoaderWrapperView from "../../libs/LoaderWrapperView";
import TreeView from "../../libs/TreeView";

const JobList = () =>{

    const queryString = require('query-string');
    const location = useLocation();
    const query = queryString.parse(location.search)

    const [list, setList] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [canLoadMore, setCanLoadMore] = useState(true)
    const [isLoading, setIsLoading] = useState(true)

    const [filters, setFilters] = useState({
        type_full_time:0,
        type_part_time:0,
        type_freelancer:0,
        fields: query["field"]!=undefined ? [query["field"]] : []
    })

    const updateFilter = (e, key) =>{
    
        const obj = {...filters}

        obj[key] = e.target.checked ? 1 : 0

        setFilters(obj)
    }

    const getData = () => {
        
        setIsLoading(true)

        axios
        .get(API_URL + API_JOB_LIST,{
            params:{
                page: currentPage,
                type_full_time:filters.type_full_time,
                type_part_time:filters.type_part_time,
                type_freelancer:filters.type_freelancer,
                fields:filters.fields
            },
            headers: authHeader()
        })
        .then((response)=>{
            
            if(response.status==200){

                const json = response.data;

                const {status, data: MainData} = json

                if(status=="RC200"){

                    const {data, last_page} = MainData

                    setCanLoadMore( !( currentPage == last_page) )

                    if(currentPage==1){
                        setList(data)
                    }else{
                        const old = [...list]
                        setList(old.concat(data))
                    }

                }

            }

            setIsLoading(false)

        })
        .catch((error)=>{
            setIsLoading(false)
        });

    }

    const loadFunc = () => {
        setCurrentPage(currentPage+1)
        //getData()
    }

    useEffect(()=>{
        getData()
    }, [currentPage])

    useEffect(()=>{
        setList([])
        setCurrentPage(1)
        getData()
    }, [filters])

    const jobTypeList = [
        "Full Time",
        "Part Time",
        "Freelancer"
    ]

    return(
        <section>
            <div className="gap gray-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-lg-3">
                                    <aside className="sidebar static">

                                        <div className="widget">
                                            <h4 className="widget-title">Filter by Categories</h4>
                                            <div className="">
                                                <TreeView endpoint={API_FIELD_TREE} value={filters.fields} onCheckChange={(checked)=>{

                                                        const obj = {...filters}
                                                        obj["fields"] = checked
                                                        setFilters(obj)

                                                    }} />
                                            </div>
                                        </div>

                                        <div className="widget">
                                            <h4 className="widget-title">Filter by Job Type</h4>
                                            <ul className="mb0">
                                                            <li>
                                                                <input onChange={(e)=> updateFilter(e, "type_full_time")}  type="checkbox" value="" /> Full Time
                                                            </li>
                                                            <li>
                                                                <input onChange={(e)=> updateFilter(e, "type_part_time")} type="checkbox" value="" /> Part Time
                                                            </li>
                                                            <li>
                                                                <input onChange={(e)=> updateFilter(e, "type_freelancer")} type="checkbox" value="" /> Freelancer
                                                            </li>
                                                        </ul>
                                        </div>

                                    </aside>
                                </div>
                                <div className="col-lg-9">
                                    <div className="central-meta">
                                        <div className="frnds">
                                            <h3>Job Listing</h3>

                                                <LoaderWrapperView isLoading={currentPage==1 && isLoading}>
                                                    <InfiniteScroll
                                                        dataLength={list.length}
                                                        next={loadFunc}
                                                        hasMore={canLoadMore}
                                                        endMessage={<div className="text-center">No more jobs found</div>}
                                                        loader={<div className="loader text-center">Loading ...</div>}>
                                                        
                                                        <ul className="nearby-contct">

                                                            {list.map((item, index)=>(
                                                                <li key={item.id}>
                                                                    <div className="nearly-pepls">
                                                                        <figure>
                                                                            <Link to={`/jobs/${item.slug}`} title="" style={{verticalAlign:"top"}}><img src={item.company_logo} alt={item.title} /></Link>
                                                                        </figure>
                                                                        <div className="pepl-info">
                                                                            <h4>
                                                                                <Link to={`/jobs/${item.slug}`} title="">{item.title}</Link>
                                                                            </h4>
                                                                            <span>{item.company_name}</span><br />
                                                                            <small>
                                                                                <b>{item.eng_field} | {item.discipline} | {item.sub_discipline}</b><br />
                                                                                
                                                                                <a href={item.website} target="_blank">{item.website}</a>, {item.phone}<br />
                                                                                
                                                                                {item.date} | Actively Hiring | {jobTypeList[item.job_type]}
                                                                            </small>
                                                                            
                                                                            <Link to={`/jobs/${item.slug}`} title="" className="add-butn" data-ripple="">View Job</Link>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            ))}

                                                        </ul>

                                                    </InfiniteScroll>
                                                </LoaderWrapperView>

                                            
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default JobList;