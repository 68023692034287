import axios from "axios";
import { useRef, useState } from "react";
import { API_APPLY_JOB, API_URL } from "../../../services/ApiEndpoints";
import authHeader from "../../../services/auth-header";
import ExtButton from "../../libs/ExtButton";
import Popups from "../../libs/Popups";

const ApplyJobPopup = (props) =>{

    const {id} = props

    const popup = useRef()
    const [isLoading, setIsLoading] = useState(false)
    const [hasError, setHasError] = useState(false) 
    const [msg, setMsg] = useState("")
    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [resume, setResume] = useState(null);

    const handleSubmit = (e) =>{
        e.preventDefault()

        setIsLoading(true)

        const formdata = new FormData()
        formdata.append("job_id", id)
        formdata.append("name", name)
        formdata.append("phone", phone)
        formdata.append("email", email)
        formdata.append("resume", resume)

        axios.post(API_URL + API_APPLY_JOB, formdata,{
            headers:authHeader()
        })
        .then((responsex) => {

            setIsLoading(false)

            if(responsex.data.status=="RC200"){
                setHasError(false)

                setEmail("")
                setPhone("")
                setName("")
                setResume(null)
            }else{
                setHasError(true)
            }

            setMsg(responsex.data.message)

            return responsex.data;
        });
        
    }

    return(
        <Popups
            ref={popup}
            label="Apply Now"
            title="Apply Now"
            className={props.className}
            dClassName="modal-lg"
        >
            <form onSubmit={handleSubmit}>

                <div className="row">
                    <div className="col-md-2">
                    </div>
                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <input value={name || ""} onChange={(e)=> setName(e.target.value)} type="text" required="required" />
                                    <label className="control-label" for="input">Name</label>
                                    <i className="mtrl-select"></i>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <input value={phone || ""} onChange={(e)=> setPhone(e.target.value)} type="text" required="required" />
                                    <label className="control-label" for="input">Phone Number</label>
                                    <i className="mtrl-select"></i>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <input value={email || ""} onChange={(e)=> setEmail(e.target.value)} type="text" required="required" />
                                    <label className="control-label" for="input">Email Address</label>
                                    <i className="mtrl-select"></i>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <label className="control-label" for="input">Upload Resume</label>
                                <input name="Upload Resume" type="file" onClick={ (e) => e.target.value="" } onChange={ (e)=> {
                                    var files = e.target.files;

                                    if(files.length==0) return;

                                    setResume(files[0])

                                }} className="form-control form-control-file" />
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-12 text-center">
                        <br />
                        {msg && (
                            <div className={ hasError ? "alert alert-danger mt-10 text-left" : "alert alert-success mt-10 text-left" } role="alert">
                                {msg}
                            </div>
                        )}

                        <div>
                            <ExtButton className="btn btn-success" isLoading={isLoading} type="submit">
                                <i className="fa fa-check"></i> Submit
                            </ExtButton>
                        </div>
                    </div>
                </div>
                <br />

                
            </form>
        </Popups>
    )

}

export default ApplyJobPopup;