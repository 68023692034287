const PaymentSuccess = () =>{

    return(
        <section className="mb-5 mt-4">
            <div className="gap gray-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="alert text-center alert-success">
                                <h5>Thank you for your payment.</h5>
                                <h5>Your SCIONS premium package is now active and SCIONScoins has been credited to your account.</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default PaymentSuccess;