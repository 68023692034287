import { Link, NavLink, Outlet, useParams } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component'
import { API_GET_ORG, API_GET_ORG_DETAILS, API_IDEA_LIST, API_URL } from "../../../services/ApiEndpoints";
import axios from "axios";
import authHeader from "../../../services/auth-header";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { printPhoneNumber, USER_ROLE_SUPER_ADMIN } from "../../../helpers/configs";

const OrganizationDetailsView = () => {

    const {userData} = useSelector(state => state.userData )

    let {id} = useParams()
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState({})

    const getData = () =>{

        setIsLoading(true)

        axios
            .get(API_URL + API_GET_ORG_DETAILS +"/"+ id,{
                    headers: authHeader()
                }
            )
            .then((response)=>{
                
                if(response.status==200){

                    const json = response.data;

                    if(json.status=="RC200"){

                        var resp = json.data;

                        setData(resp)
                    }

                }

                setIsLoading(false)

            })
            .catch((error)=>{
                setIsLoading(false)
            });

    }

  useEffect(()=>{
    getData()
  }, [])

  return (
    <section>
        <div className="gap gray-bg">
            <div className="container">
                
                { isLoading ? (<p>Loading...</p>) : (
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row merged20" id="page-contents">
                                <div className="col-md-12">
                                    <div className="central-meta">
                                        <h3>{data.company_name}</h3>
                                        <em>Address: {data.location}, Phone: {printPhoneNumber(data.phone_code, data.phone)}, Website: <a href={data.website} target="_blank">{data.website}</a></em>

                                        <div className="frnds mt-4">
                                            <ul className="nav nav-tabs mb-2">
                                                <li className="nav-item">
                                                    <NavLink to={"view"} >Details</NavLink>
                                                </li>

                                                {/* Check if super admin then show the tab */}
                                                {userData.user_role==USER_ROLE_SUPER_ADMIN ? (
                                                    <li className="nav-item">
                                                        <NavLink to={"admins"} >Admin(s)</NavLink> <span>{data.admin_count}</span>
                                                    </li>
                                                ) : ""}
                                                
                                                <li className="nav-item">
                                                    <NavLink to={"users"} >User(s)</NavLink> <span>{data.user_count}</span>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to={"edit"} >Edit Details</NavLink>
                                                </li>
                                            </ul>

                                            <div className="gap gray-bg">
                                                <div className="container">
                                                    <Outlet />
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </div>
    </section>
  );
};


export default OrganizationDetailsView;