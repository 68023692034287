import React, { Component } from "react";
import jquery from "jquery";
import '../../assets/js/chosen.jquery'

class Select2GroupsMultiple extends Component{

    state = {
        data: [],
        value: []
    }

    placeholder = "";
    keys = "";
    required = false;
    name = ""
    className = ""
    
    constructor(props){
        super();
        this.state.data = props.data==undefined ? [] : props.data;
        this.placeholder = props.placeholder;
        this.keys = props.keys;
        this.required = props.required;
        this.name = props.name==undefined ? props.keys : props.name;
        this.className = props.className===undefined ? "" : props.className;
        
    }

    componentDidUpdate(){
        jquery(this.refs['select2']).chosen({no_results_text:"Not found"}).trigger("chosen:updated");
    }

    updateSelect(newData){

        this.setState({
            data:newData
        })

    }

    componentDidMount(){

        let ele = jquery(this.refs['select2']);
        let pr = this.props;

        ele.chosen().on("change", function(){
            let vl = ele.chosen().val()
            pr.onSelect && pr.onSelect(vl)
        });
    }

    render(){

        return(

            <select multiple value={this.props.value} data-placeholder={this.placeholder} onChange={this.props.onSelect} ref='select2' key={this.name} name={this.name} required={this.required} className={`form-control ${this.className}`}>
                {this.state.data && this.state.data.map((item, i) =>(
                    item.children ? (
                        <optgroup key={`optg-l1-${i}`} label={item[this.keys]}>
                            {item.children.map((item2, i2)=>(
                                <option key={item2.id} value={item2.id}>{item2[this.keys]}</option>
                            ))}
                        </optgroup>
                    ) : (
                        <option key={item.id} value={item.id}>{item[this.keys]}</option>
                    )
                    
                ))}
            </select>
            
        );
    }
};

export default Select2GroupsMultiple;