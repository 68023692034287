import { Link, useNavigate } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component'
import { API_CREATE_IDEA, API_GET_ORG, API_GET_ORG_UPDATE, API_IDEA_LIST, API_URL } from "../../../services/ApiEndpoints";
import axios from "axios";
import authHeader from "../../../services/auth-header";
import { useEffect, useState } from "react";
import ExtButton from "../../libs/ExtButton";
import { organizationSize, typeOfOrganization } from "../Consts";
import Select2 from "../../libs/Select2";
import { toast } from "react-toastify";
import AddMoreFields from "./AddMoreFields";
import IntlTelInput from "react-intl-tel-input";
import ImageCropperPopup from "../../libs/image/ImageCropperPopup";

const AddOrganizations = () => {

    const [isLoading, setIsLoading] = useState(false)
    const [hasError, setHasError] = useState(false) 
    const [msg, setMsg] = useState("")

    const [name, setName] = useState("");
    const [about, setAbout] = useState("");
    const [website, setWebsite] = useState("");
    const [phone, setPhone] = useState("");
    const [cSize, setCSize] = useState("");
    const [logo, setLogo] = useState(null);

    
    const [location, setLocation] = useState("");
    const [orgType, setOrgType] = useState(-1);

    const [numberOfPatentedIdeas, setNumberOfPatentedIdeas] = useState("")
    const [numberOfPatentedIdeasLastOneYear, setNumberOfPatentedIdeasLastOneYear] = useState("")
    const [numberOfStudents, setNumberOfStudents] = useState("")
    const [analyticalTestFacilities, setAnalyticalTestFacilities] = useState([])
    const [laboratoryEquipments, setLaboratoryEquipments] = useState([])
    const [itFacilities, setItFacilities] = useState([])

    const [isValidPhone, setIsValidPhone] = useState(false)
    const [defaultLocationCode, setDefaultLocationCode] = useState("NL")
    const [phoneDialCode, setPhoneDialCode] = useState()

    const navigate = useNavigate();

    const handleSubmit = (e) =>{
        e.preventDefault()

        setIsLoading(true)

        var formdata = new FormData()
        formdata.append("id", -1);
        formdata.append("company_name", name);
        formdata.append("about_company", about);
        formdata.append("website", website);
        formdata.append("phone", phone);
        formdata.append("phone_code", phoneDialCode);
        formdata.append("company_size", cSize);
        formdata.append("company_logo", logo);
        formdata.append("location", location);
        formdata.append("type_of_organization", orgType);
        formdata.append("number_of_patented_ideas", numberOfPatentedIdeas);
        formdata.append("number_of_patented_ideas_last_one_year", numberOfPatentedIdeasLastOneYear);
        formdata.append("number_of_students", numberOfStudents);

        let ind = 0;

        analyticalTestFacilities.map((val, index)=>{
            formdata.append(`docs[${ind}]`, val.filename);
            formdata.append(`docs_id[${ind}]`, val.id);
            formdata.append(`name[${ind}]`, val.name);
            formdata.append(`type[${ind}]`, 0);

            ind++;
        })

        laboratoryEquipments.map((val, index)=>{
            formdata.append(`docs[${ind}]`, val.filename);
            formdata.append(`docs_id[${ind}]`, val.id);
            formdata.append(`name[${ind}]`, val.name);
            formdata.append(`type[${ind}]`, 1);

            ind++;
        })

        itFacilities.map((val, index)=>{
            formdata.append(`docs[${ind}]`, val.filename);
            formdata.append(`docs_id[${ind}]`, val.id);
            formdata.append(`name[${ind}]`, val.name);
            formdata.append(`type[${ind}]`, 2);

            ind++;
        })

        axios.post(API_URL + API_GET_ORG_UPDATE, formdata,{
            headers:authHeader()
        })
        .then((responsex) => {

            setIsLoading(false)

            if(responsex.data.status=="RC200"){
                setHasError(false)

                setName("")
                setAbout("")
                setWebsite("")
                setPhone("")
                setCSize("")
                setLogo(null)
                setOrgType(-1)
                setLocation("")
                setNumberOfPatentedIdeasLastOneYear("")
                setNumberOfPatentedIdeas("")
                setNumberOfStudents("")

                toast.success(responsex.data.message)
                navigate("/organizations")

            }else{
                setHasError(true)
                setMsg(responsex.data.message)
            }

            return responsex.data;
        });

    }

    useEffect(()=>{
    }, [])

  return (
    <section>
        <div className="mt-4">
            <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="central-meta">
                        <h3>Create Organization</h3>
                        <form onSubmit={handleSubmit} method="post">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group ">
                                        <input type="text" id="input" required="required" value={name} onChange={(e)=> setName(e.target.value)} />
                                        <label className="control-label" for="input">Organization Name *</label>
                                        <i className="mtrl-select"></i>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <Select2 placeholder={"Type of Organization"} 
                                            directValues={false} 
                                            onSelect={ (e) => {
                                                setOrgType(parseInt(e.target.value))
                                            } } 
                                            value={orgType} 
                                            name="type_of_organization" 
                                            keys="name" 
                                            data={typeOfOrganization} />
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <input type="text"
                                            onChange={(e)=> setLocation(e.target.value) }
                                            value={location}
                                            required="required" />
                                        <label className="control-label" >Location *</label>
                                        <i className="mtrl-select"></i>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <input type="text" id="input" required="required" value={website} onChange={(e)=> setWebsite(e.target.value)} />
                                        <label className="control-label" for="input">Website *</label>
                                        <i className="mtrl-select"></i>
                                    </div>
                                </div>
                                
                                <div className="col-md-4">
                                    
                                    <div className="form-group">
                                        <IntlTelInput preferredCountries={[]} formatOnInit={false} separateDialCode={true} value={phone} onPhoneNumberChange={
                                            (status, phoneNumber, country, fullNumber, extension) => {
                                                setPhone(phoneNumber)
                                                setIsValidPhone(status)
                                            }
                                        }
                                        onSelectFlag={
                                            (currentNumber, selectedCountryData)=>{
                                                setDefaultLocationCode(selectedCountryData.iso2)
                                                setPhoneDialCode(selectedCountryData.dialCode)
                                            }
                                        }
                                        defaultCountry={defaultLocationCode.toLowerCase()}
                                        format={false}
                                        input
                                        type="tel"
                                        placeholder={'Phone Number *'}
                                        id="input-phone" />
                                        <i className="mtrl-select"></i>
                                    </div>

                                    {/* <div className="form-group">
                                        <input type="text" id="input" required="required" value={phone} onChange={(e)=> setPhone(e.target.value)} />
                                        <label className="control-label" for="input">Phone *</label>
                                        <i className="mtrl-select"></i>
                                    </div> */}
                                </div>
                                
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <Select2 placeholder={"Organization Size"} 
                                            directValues={false} 
                                            onSelect={ (e) => {
                                                setCSize(parseInt(e.target.value))
                                            } } 
                                            value={cSize} 
                                            name="size" 
                                            keys="name" 
                                            data={organizationSize} />
                                    </div>
                                </div>

                                {/* <div className="col-md-4">
                                    <div className="form-group">
                                        <input type="text" id="input" required="required" value={cSize} onChange={(e)=> setCSize(e.target.value)} />
                                        <label className="control-label" for="input">Organization Size *</label>
                                        <i className="mtrl-select"></i>
                                    </div>
                                </div> */}

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <input type="text" id="input" required="required" value={numberOfPatentedIdeas} onChange={(e)=> setNumberOfPatentedIdeas(e.target.value)} />
                                        <label className="control-label" for="input">Total active number of patented ideas *</label>
                                        <i className="mtrl-select"></i>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <input type="text" id="input" required="required" value={numberOfPatentedIdeasLastOneYear} onChange={(e)=> setNumberOfPatentedIdeasLastOneYear(e.target.value)} />
                                        <label className="control-label" for="input">Number of patented ideas in last one year *</label>
                                        <i className="mtrl-select"></i>
                                    </div>
                                </div>

                                {orgType==1 ? "" : (
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <input type="text" id="input" required="required" value={numberOfStudents} onChange={(e)=> setNumberOfStudents(e.target.value)} />
                                            <label className="control-label" for="input">Number of students *</label>
                                            <i className="mtrl-select"></i>
                                        </div>
                                    </div>
                                )}

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <textarea rows="4" id="textarea" required="required"  value={about} onChange={(e)=> setAbout(e.target.value)}></textarea>
                                        <label className="control-label" for="textarea">About Organization *</label>
                                        <i className="mtrl-select"></i>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <AddMoreFields title="Analytical Test Facilities" key="atf" lst={analyticalTestFacilities} onRemove={(index)=>{
                                        var data = [...analyticalTestFacilities]
                                        data.splice(index, 1)
                                        setAnalyticalTestFacilities(data)
                                    }} onFileChange={(index, filename)=>{
                                        
                                        var data = [...analyticalTestFacilities]
                                        data[index]["filename"] = filename
                                        setAnalyticalTestFacilities(data)

                                    }} onNameChange={(index, nm)=>{
                                        
                                        var data = [...analyticalTestFacilities]
                                        data[index]["name"] = nm
                                        setAnalyticalTestFacilities(data)

                                    }} onAdd={(data)=>{
                                        setAnalyticalTestFacilities( analyticalTestFacilities.concat(data) )
                                    }} />
                                    <hr />
                                </div>

                                <div className="col-md-12">
                                    <AddMoreFields title="Laboratory Equipments" key="le" lst={laboratoryEquipments} onRemove={(index)=>{
                                        var data = [...laboratoryEquipments]
                                        data.splice(index, 1)
                                        setLaboratoryEquipments(data)
                                    }} onFileChange={(index, filename)=>{
                                        
                                        var data = [...laboratoryEquipments]
                                        data[index]["filename"] = filename
                                        setLaboratoryEquipments(data)

                                    }} onNameChange={(index, nm)=>{
                                        
                                        var data = [...laboratoryEquipments]
                                        data[index]["name"] = nm
                                        setLaboratoryEquipments(data)

                                    }} onAdd={(data)=>{
                                        setLaboratoryEquipments( laboratoryEquipments.concat(data) )
                                    }} />
                                    <hr />
                                </div>

                                <div className="col-md-12">
                                    <AddMoreFields title="IT Facilities" key="itf" lst={itFacilities} onRemove={(index)=>{
                                        var data = [...itFacilities]
                                        data.splice(index, 1)
                                        setItFacilities(data)
                                    }} onFileChange={(index, filename)=>{
                                        
                                        var data = [...itFacilities]
                                        data[index]["filename"] = filename
                                        setItFacilities(data)

                                    }} onNameChange={(index, nm)=>{
                                        
                                        var data = [...itFacilities]
                                        data[index]["name"] = nm
                                        setItFacilities(data)

                                    }} onAdd={(data)=>{
                                        setItFacilities( itFacilities.concat(data) )
                                    }} />
                                    <hr />
                                </div>

                                {/* <div className="col-md-12">
                                    <div className="form-group">
                                        <textarea rows="3" id="textarea" required="required"  value={analyticalTestFacilities} onChange={(e)=> setAnalyticalTestFacilities(e.target.value)}></textarea>
                                        <label className="control-label" for="textarea">Analytical Test Facilities</label>
                                        <i className="mtrl-select"></i>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <textarea rows="3" id="textarea" required="required"  value={laboratoryEquipments} onChange={(e)=> setLaboratoryEquipments(e.target.value)}></textarea>
                                        <label className="control-label" for="textarea">Laboratory Equipments</label>
                                        <i className="mtrl-select"></i>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <textarea rows="3" id="textarea" required="required"  value={itFacilities} onChange={(e)=> setItFacilities(e.target.value)}></textarea>
                                        <label className="control-label" for="textarea">IT Facilities</label>
                                        <i className="mtrl-select"></i>
                                    </div>
                                </div> */}

                                <div className="col-md-12 mt-4">
                                    <p><strong>Add Organization Logo</strong></p>
                                </div>
                                
                                <div className="col-md-9">
                                    <ImageCropperPopup width={250} height={250} title="Select Organization Logo" className='form-control-file' onImageCroped={
                                        (base64, blob) => {
                                            setLogo(blob)
                                        }
                                    } />
                                    {/* <input name="Upload Documents" type="file" onClick={ (e) => e.target.value="" } onChange={ (e)=> {
                                        var files = e.target.files;

                                        if(files.length==0) return;

                                        setLogo(files[0])

                                    }} className="form-control-file" /> */}
                                </div>

                                <div className="col-md-12">

                                    <div className="submit-btns mb-2">
                                        
                                        {msg && (
                                            <div className={ hasError ? "alert alert-danger mt-10" : "alert alert-success mt-10" } role="alert">
                                                {msg}
                                            </div>
                                        )}
                                        
                                        <ExtButton isLoading={isLoading} type="submit" className="mtr-btn">
                                            <span>Create</span>
                                        </ExtButton>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
          </div>
       </div>
    </section>
  );
};


export default AddOrganizations;