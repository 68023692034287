import React from "react";
import { NavLink } from "react-router-dom";

const FooterLinks = () =>{

    return(
        <>
            <NavLink target="_blank" to="/about-scions">About Us</NavLink> |&nbsp;
            <NavLink target="_blank" to="/contact-us">Contact Us</NavLink> |&nbsp;
            <NavLink target="_blank" to="/privacy-policy">Privacy Policy</NavLink> |&nbsp;
            <NavLink target="_blank" to="/cookie-policy">Cookie Policy</NavLink> |&nbsp;
            <NavLink target="_blank" to="/terms-and-conditions">Terms &amp; Conditions</NavLink> |&nbsp;
            <a target="_blank" href="https://forms.gle/GvGbQLiRVrLnx5DG9">Feedback</a>
        </>
    );

};

export default FooterLinks;