import React, { Component, useEffect, useState } from "react";
import {Link} from 'react-router-dom'
import AutoLinks from "../../libs/AutoLinks";
import AddComment from "./AddComment";
import SingleSubComment from "./SingleSubComment";

class SingleComment extends Component{
    
    state = {
        comment: [],
        replylist: [],
        replyId: -1
    }
    
    onCommentRepliedClicked = () => {}

    constructor(props){
        super();

        this.onCommentRepliedClicked = props.onCommentRepliedClicked!=undefined ? props.onCommentRepliedClicked : this.onCommentRepliedClicked
    }

    handleReplyClick = (e) =>{
        e.preventDefault();
        this.setState({
            replyId: this.state.comment.id
       })
    }

    onCommentAdded = (comment) =>{
        this.setState({
            replylist: this.state.replylist.concat(comment),
            replyId: -1
       })
    }

    componentDidMount = () =>{

        this.setState({
            comment: this.props.comment,
            replylist: this.props.comment.replied_comments
       })

    }

    render(){
        return(
        
            this.state.comment && (
                <li>
                    <div className="comet-avatar">
                        <img className="profile-img-small" src={this.state.comment.profile_image} alt="" />
                    </div>
                    <div className="we-comment">
                        <div className="coment-head">
                            <h5><Link to={`/` + this.state.comment.public_url} title="">{this.state.comment.full_name}</Link></h5>
                            <span>{this.state.comment.created_date || "Now"}</span>
                            <a className="we-reply" href="#" onClick={this.handleReplyClick} title="Reply"><i className="fa fa-reply"></i></a>
                        </div>
                        <AutoLinks>
                            {this.state.comment!="" && this.state.comment.comment.split("\n").map((str, index) => <p key={index}>{str}</p>)}
                        </AutoLinks>
                    </div>

                    {this.state.replyId!=-1 && (
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-11">
                                <br />
                                <AddComment placeholder="Reply to this opinion" parentCallback={this.onCommentAdded} replyId={this.state.replyId} key={"add-comment-"+ this.state.comment.id} postId={this.props.postId} />
                            </div>
                        </div>  
                    )}

                    {this.state.replylist.length>0 && (
                        <RepliedCommentList postId={this.props.postId} commentId={this.state.comment.id} list={this.state.replylist} />
                    )}

                </li>
            )
            
        )
    }

}

const RepliedCommentList = (props) =>{

    const [list, setList] = useState(props.list)

    const OnSubCommentAdd = (comment) =>{
        let newList = [...list]
        setList(newList.concat(comment))
    }

    useEffect(()=>{
        setList(props.list)
    }, [props])

    return(
        <ul>
            {list && list.map((commentReplied, i) => <SingleSubComment OnSubCommentAdd={OnSubCommentAdd} postId={props.postId} commentId={props.commentId} key={"rc-"+ props.commentId + "-" + commentReplied.id} comment={commentReplied} /> )}
        </ul>
    )

}

export default SingleComment;